import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.css';
interface Interface {
  videoSizeSetCallable: any;
  children: any;
}
const VideoSizeSelectorContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const items: MenuProps['items'] = [
    {
      key: 0,
      label: <div className="non-selectable-context">{t('Quality')}</div>,
      disabled: true,
    },
    { type: 'divider' },
    {
      key: 1,
      label: <div onClick={() => props.videoSizeSetCallable(320)}>320p</div>,
      disabled: true,
    },
    {
      key: 2,
      label: <div onClick={() => props.videoSizeSetCallable(480)}>480p</div>,
      disabled: true,
    },
    {
      key: 3,
      label: <div onClick={() => props.videoSizeSetCallable(720)}>720p</div>,
      disabled: true,
    },
    {
      key: 4,
      label: <div onClick={() => props.videoSizeSetCallable('FHD')}>FHD</div>,
      disabled: true,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
          // lineHeight: 16
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['hover']}
        overlayStyle={{
          width: 'max-content',
        }}
        placement="top"
      >
        {props.children}
      </Dropdown>
    </ConfigProvider>
  );
};
export default VideoSizeSelectorContext;
