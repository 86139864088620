/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { SendResetPasswordRequestAction } from 'actions/auth.actions';
import InputWithClear from 'components/InputWithClear';
import { AppStateType } from 'reducers';

const RecoverPasswordPage = () => {
  const [email, setEmail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailIsOnFocus, setEmailIsOnFocus] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const emailRef = useRef(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [requestSent, setRequestSent] = useState(false);
  const { recoverSent } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  // const validateEmail = (event: any) => {
  //   const isValid = /\S+@\S+\.\S+/.test(email);
  //   setEmailIsValid((prev) => isValid);
  //   updateFocus();
  // };
  useEffect(() => {
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  useEffect(() => {
    setRequestSent(recoverSent);
  }, [recoverSent]);

  const dispatch = useDispatch();
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (emailIsValid) {
      // @ts-ignore
      dispatch(SendResetPasswordRequestAction(email));
      setRequestSent(true);
    }
  };
  const navigate = useNavigate();
  const goToLogin = (event: any) => {
    event.preventDefault();
    navigate('/login');
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);
  // const getTopOffset = (fieldRef: any) => {
  //   if (fieldRef.current) {
  //     return fieldRef?.current?.getBoundingClientRect().top - 8;
  //   }
  // };
  // const getLeftOffset = (fieldRef: any) => {
  //   return fieldRef?.current?.offsetLeft + fieldRef.current.clientWidth + 20;
  // };
  const [t] = useTranslation();
  return !recoverSent ? (
    <div className="loginFormContainer">
      <div className="loginFormHeader">{t('Recover password')}</div>
      <div className="loginForm">
        <form name="recoverForm">
          <div>
            <InputWithClear
              isRequired={true}
              valueController={{ value: email, setValue: setEmail }}
              fieldType={'email'}
              fieldName={'email'}
              labelText={t('Enter email:')}
              validate={true}
              validationCallback={setEmailIsValid}
            />
          </div>
          <div className="buttonContainer">
            <button onClick={handleSubmit}>{t('Recover')}</button>
          </div>
          <div className="linkContainer">
            <Link to="/login">{t('Отмена')}</Link>
          </div>
        </form>
      </div>
    </div>
  ) : (
    <div className="loginFormContainer">
      <div className="loginFormHeader">{t('Email has been sent')}</div>
      <div className="loginForm">
        <form>
          <div>
            <div style={{ lineHeight: '24px', fontSize: '16px' }}>
              <span>
                {t('If you were registered with your email')}
                <strong> {email}</strong>,{' '}
                {t('we shall send you a message with password recover link.')}
              </span>
            </div>
          </div>
          <div className="buttonContainer">
            <button onClick={goToLogin}>{t('OK')}</button>
          </div>
          <div className="linkContainer">
            <Link to="#" onClick={() => setRequestSent(false)}>
              {t('Another email')}
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};
export default RecoverPasswordPage;
