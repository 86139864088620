import { createSlice } from '@reduxjs/toolkit';

import { PlayerActionTypes } from 'actions/player.acitons';
import { MODES } from 'components/VideoListController';
import {
  ADD_EVENTS_TO_EXPANDED_VIDEO,
  ADD_USER_EVENTS_TO_EXPANDED_VIDEO,
  APPEND_DISPLAYED_FILTERS,
  CLEAR_EVENTS_FILTERS,
  CLEAR_TRAININGS_FILTERS,
  GET_PLAYLISTS_REQUEST,
  GET_VIDEO_EPISODES_ERROR,
  GET_VIDEO_EPISODES_REQUEST,
  GET_VIDEO_EPISODES_SUCCESS,
  INIT_TEAM_TOURNAMENT_DATES_ERROR,
  INIT_TEAM_TOURNAMENT_DATES_REQUEST,
  INIT_TEAM_TOURNAMENT_DATES_SUCCESS,
  OPEN_FILTERS_PANEL_STATE,
  RENAME_PLAYLIST,
  REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO,
  SET_ACTIVE_VIDEO,
  SET_ALLOW_PLAY_FIRST,
  SET_APPLIED_FILTERS,
  SET_APPLIED_SELECTED_GAMES,
  SET_APPLIED_SELECTED_TRAININGS,
  SET_DISPLAYED_FILTERS,
  SET_FILTERED_VIDEOS,
  SET_IS_PLAYING,
  SET_PLAY_STATE,
  SET_PLAYER_COMMAND,
  SET_PLAYER_HOTKEYS_ALLOWED,
  SET_PLAYER_LOADING_VIDEO,
  SET_PLAYER_MODE,
  SET_PLAYER_PAUSED_AT,
  SET_PLAYLIST,
  SET_SELECTED_GAMES,
  SET_SELECTED_TRAININGS,
  SET_SHARED_PLAYLIST,
  SET_USER_EDITED_EPISODE_DRAGGED,
  SET_USER_EDITED_EPISODES,
  SET_USER_EDITED_TEMP_EPISODES,
  SET_VIDEO_TIME_ZOOM,
  SET_VIDEOS_LIST_EDIT_MODE,
  SET_VISIBLE_RANGE,
  SHOW_DOWNLOAD_EPISODES_MODAL,
  TOGGLE_VIDEO_COLLAPSED,
} from 'types/action.types';
import { filterAndIndex } from 'types/functions';
import {
  EpisodeAttributeType,
  EpisodeFilterTypes,
  GameVideoEpisodeType,
  GameVideoType,
  PlayCommandType,
  PlaylistType,
  VideoFileType,
} from 'types/types';

export type AppliedFilters = {
  [key in EpisodeFilterTypes]: any;
};

type InitialStateType = {
  activeVideo: VideoFileType | null;
  playerRef: any;
  episodeAttributes: Array<EpisodeAttributeType>;
  filteredVideos: Array<GameVideoType>;
  allowPlayFirst: boolean;
  error: any;
  isLoading: boolean;
  displayedFilters: AppliedFilters | null;
  appliedFilters: AppliedFilters | null;
  createEpisodeMode: boolean;
  videosListEditMode: string | null;
  editedEpisodeRange: number[];
  editedFileEpisodeRange: number[];
  sharedPlaylist: any;
  isPlaying: boolean;
  playCommand: PlayCommandType;
  playConsecutive: boolean;
  autoplay: boolean;
  playedEpisode: GameVideoEpisodeType | null;
  playlists: { playlists: Array<GameVideoType> };
  playedVideoSet: GameVideoType | PlaylistType | null; // ЭТО ИЛИ ПЛЕЙЛИСТ ИЛИ СОБЫТИЯ В ИГРЕ, ПРОШЕДШИЕ ФИЛЬТР
  playerMode: string;
  loadingActions: string[];
  selectedGames: string[];
  appliedSelectedGames: string[];
  selectedTrainings: string[];
  appliedSelectedTrainings: string[];
  prefilteredGames: any[];
  prefilteredGamesLoading: boolean;
  currentTeamTournaments: any[];
  currentTeamDateLimits: any[];
  clearEventsFilters: boolean;
  clearTrainingsFilter: boolean;
  filterPanelState: string; // мониторинг того, в каком состоянии панель фильтров
  userEditedEpisode: { startTime: string | null; endTime: string | null };
  userEditedEpisodeTemporary: {
    startTime: string | null;
    endTime: string | null;
  };
  playerPausedAt: number | null | undefined;
  userEditedEpisodeDragged: boolean;
  videoTimeZoom: number;
  playerHotkeysAllowed: boolean;
  downloadEpisodesModalMode: string | null;
  visibleRange: number[];
  videoDimensions: any;
  wideScreenMode: boolean;
};
const initialState: InitialStateType = {
  videoDimensions: {
    height: 0,
    width: 0,
  },
  activeVideo: null,
  allowPlayFirst: true,
  isPlaying: false,
  playCommand: PlayCommandType.none,
  playerRef: null,
  createEpisodeMode: false,
  episodeAttributes: [],
  filteredVideos: [],
  error: null,
  isLoading: false,
  appliedFilters: null,
  displayedFilters: null,
  videosListEditMode: null,
  editedEpisodeRange: [],
  editedFileEpisodeRange: [],
  sharedPlaylist: null,
  playConsecutive: true,
  autoplay: false,
  playedEpisode: null,
  playedVideoSet: null,
  playlists: { playlists: [] },
  playerMode: MODES.episodes,
  loadingActions: [],
  selectedGames: [],
  appliedSelectedGames: [],
  selectedTrainings: [],
  appliedSelectedTrainings: [],
  prefilteredGames: [],
  prefilteredGamesLoading: false,
  currentTeamTournaments: [],
  currentTeamDateLimits: [],
  clearEventsFilters: false,
  clearTrainingsFilter: false,
  filterPanelState: '',
  userEditedEpisode: { startTime: '00:00:00', endTime: '00:20:00' },
  userEditedEpisodeTemporary: { startTime: '00:00:00', endTime: '00:20:00' },
  playerPausedAt: null,
  userEditedEpisodeDragged: false,
  videoTimeZoom: 0,
  playerHotkeysAllowed: true,
  downloadEpisodesModalMode: null,
  visibleRange: [],
  wideScreenMode: false,
};
export const playerReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: PlayerActionTypes,
  // eslint-disable-next-line
): InitialStateType => {
  switch (action.type) {
    case SET_APPLIED_FILTERS:
      return {
        ...state,
        appliedFilters: action.payload,
      };
    case SET_DISPLAYED_FILTERS:
      return {
        ...state,
        displayedFilters: { ...action.payload },
      };
    case APPEND_DISPLAYED_FILTERS:
      return {
        ...state,
        displayedFilters: { ...state.displayedFilters, ...action.payload },
      };
    case GET_VIDEO_EPISODES_REQUEST:
      return {
        ...state,
        filteredVideos: [],
        isLoading: true,
        loadingActions: [...state.loadingActions, 'GET_VIDEO_EPISODES'],
      };
    case GET_VIDEO_EPISODES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        loadingActions: [
          ...state.loadingActions.filter((el) => el !== 'GET_VIDEO_EPISODES'),
        ],
      };
    case GET_VIDEO_EPISODES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        filteredVideos: action.payload,
        loadingActions: [
          ...state.loadingActions.filter((el) => el !== 'GET_VIDEO_EPISODES'),
        ],
      };
    case SET_FILTERED_VIDEOS:
      return {
        ...state,
        filteredVideos: action.payload,
      };
    case SET_VIDEOS_LIST_EDIT_MODE:
      return {
        ...state,
        videosListEditMode: action.payload.id,
      };
    case SET_SHARED_PLAYLIST:
      return {
        ...state,
        sharedPlaylist: {
          ...action.payload,
          episodes: action.payload.playlist_events,
          share_link: action.payload.share_link,
        },
      };
    case SET_ACTIVE_VIDEO:
      return {
        ...state,
        activeVideo: action.payload,
      };
    case SET_IS_PLAYING:
      return {
        ...state,
        isPlaying: action.payload,
      };
    case SET_PLAYER_COMMAND:
      return {
        ...state,
        playCommand: action.payload,
      };
    case SET_PLAY_STATE:
      return {
        ...state,
        playConsecutive: action.payload,
      };
    case SET_PLAYER_LOADING_VIDEO:
      return {
        ...state,
        isLoading: action.payload,
      };
    // case SET_PLAYED_EPISODE:
    //   return {
    //     ...state,
    //     playedEpisode: action.payload.episode,
    //     playedVideoSet: action.payload.videoSet,
    //     // isLoading: false
    //   };
    case GET_PLAYLISTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SET_PLAYLIST:
      return {
        ...state,
        playlists: action.payload,
        isLoading: false,
      };
    case SET_PLAYER_MODE:
      return {
        ...state,
        playerMode: action.payload,
      };
    case SET_SELECTED_GAMES:
      return {
        ...state,
        selectedGames: [...action.payload],
      };
    case SET_APPLIED_SELECTED_GAMES:
      return {
        ...state,
        appliedSelectedGames: action.payload,
        selectedGames: [],
      };
    case SET_SELECTED_TRAININGS:
      return {
        ...state,
        selectedTrainings: [...action.payload],
      };
    case SET_APPLIED_SELECTED_TRAININGS:
      return {
        ...state,
        selectedTrainings: [],
        appliedSelectedTrainings: action.payload,
      };
    case TOGGLE_VIDEO_COLLAPSED:
      if (state.playerMode === 'episodes') {
        const { element, index } = filterAndIndex(
          state.filteredVideos,
          action.payload,
          'id',
        );
        return {
          ...state,
          filteredVideos: [
            ...state.filteredVideos.slice(0, index),
            {
              ...element,
              // episodes: element?.isExpanded ? [] : element?.episodes,
              isExpanded:
                element?.isExpanded !== undefined ? !element?.isExpanded : true,
            },
            ...state.filteredVideos.slice(index + 1),
          ],
        };
      } else {
        const { element, index } = filterAndIndex(
          state.playlists.playlists,
          action.payload,
          'id',
        );
        return {
          ...state,
          playlists: {
            playlists: [
              ...state.playlists.playlists.slice(0, index),
              {
                ...element,
                isExpanded:
                  element?.isExpanded !== undefined
                    ? !element?.isExpanded
                    : true,
              },
              ...state.playlists.playlists.slice(index + 1),
            ],
          },
        };
      }
    case ADD_EVENTS_TO_EXPANDED_VIDEO:
      if (state.playerMode === 'episodes') {
        const { element, index } = filterAndIndex(
          state.filteredVideos,
          action.payload.videoId,
          'id',
        );
        const existingEpisodes = element?.episodes || [];
        console.log('ACTION');
        console.log(element);
        console.log(action.payload);
        return {
          ...state,
          filteredVideos: [
            ...state.filteredVideos.slice(0, index),
            {
              ...element,
              episodes: [...existingEpisodes, ...action.payload.episodes],
            },
            ...state.filteredVideos.slice(index + 1),
          ],
        };
      } else {
        const { element, index } = filterAndIndex(
          state.playlists.playlists,
          action.payload.videoId,
          'id',
        );
        const existingEpisodes = element?.episodes || [];

        return {
          ...state,
          playlists: {
            playlists: [
              ...state.playlists.playlists.slice(0, index),
              {
                ...element,
                episodes: [...existingEpisodes, ...action.payload.episodes],
              },
              ...state.playlists.playlists.slice(index + 1),
            ],
          },
        };
      }
    case RENAME_PLAYLIST:
      // eslint-disable-next-line no-case-declarations
      const obj = filterAndIndex(
        state.playlists.playlists,
        action.payload.videoId,
        'id',
      );
      // const existingEpisodes = obj.element?.episodes || [];
      return {
        ...state,
        playlists: {
          playlists: [
            ...state.playlists.playlists.slice(0, obj.index),
            {
              ...obj.element,
              name: action.payload.newName,
            },
            ...state.playlists.playlists.slice(obj.index + 1),
          ],
        },
      };
    case ADD_USER_EVENTS_TO_EXPANDED_VIDEO:
      if (state.playerMode === 'episodes') {
        const { element, index } = filterAndIndex(
          state.filteredVideos,
          action.payload.videoId,
          'id',
        );
        const existingEpisodes = element.user_episodes || [];
        return {
          ...state,
          filteredVideos: [
            ...state.filteredVideos.slice(0, index),
            {
              ...element,
              user_episodes: [
                ...existingEpisodes,
                ...action.payload.userEvents,
              ],
            },
            ...state.filteredVideos.slice(index + 1),
          ],
        };
      } else {
        return { ...state };
      }
    case REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO:
      if (state.playerMode === 'episodes') {
        const { element, index } = filterAndIndex(
          state.filteredVideos,
          action.payload.videoId,
          'id',
        );
        return {
          ...state,
          filteredVideos: [
            ...state.filteredVideos.slice(0, index),
            {
              ...element,
              user_episodes: [...action.payload.userEvents],
            },
            ...state.filteredVideos.slice(index + 1),
          ],
        };
      } else {
        return { ...state };
      }

    case INIT_TEAM_TOURNAMENT_DATES_REQUEST:
      return {
        ...state,
        loadingActions: [
          ...state.loadingActions,
          'INIT_TEAM_TOURNAMENT_DATES_REQUEST',
        ],
      };
    case INIT_TEAM_TOURNAMENT_DATES_ERROR:
      return {
        ...state,
        loadingActions: [
          ...state.loadingActions.filter(
            (el) => el !== 'INIT_TEAM_TOURNAMENT_DATES_REQUEST',
          ),
        ],
      };
    case INIT_TEAM_TOURNAMENT_DATES_SUCCESS:
      return {
        ...state,
        loadingActions: [
          ...state.loadingActions.filter(
            (el) => el !== 'INIT_TEAM_TOURNAMENT_DATES_REQUEST',
          ),
        ],
        currentTeamDateLimits: action.payload.dates,
        currentTeamTournaments: action.payload.tournaments,
        prefilteredGames: [],
      };
    case CLEAR_EVENTS_FILTERS:
      return {
        ...state,
        clearEventsFilters: action.payload,
      };
    case CLEAR_TRAININGS_FILTERS:
      return {
        ...state,
        clearTrainingsFilter: action.payload,
      };
    case OPEN_FILTERS_PANEL_STATE:
      return {
        ...state,
        filterPanelState: action.payload,
      };
    case SET_USER_EDITED_EPISODES:
      return {
        ...state,
        userEditedEpisode: action.payload,
      };
    case SET_USER_EDITED_TEMP_EPISODES:
      return {
        ...state,
        userEditedEpisodeTemporary: action.payload,
      };
    case SET_PLAYER_PAUSED_AT:
      return {
        ...state,
        playerPausedAt: action.payload,
      };
    case SET_USER_EDITED_EPISODE_DRAGGED:
      return {
        ...state,
        userEditedEpisodeDragged: action.payload,
      };
    case SET_VIDEO_TIME_ZOOM:
      return {
        ...state,
        videoTimeZoom: action.payload,
      };
    case SHOW_DOWNLOAD_EPISODES_MODAL:
      return {
        ...state,
        downloadEpisodesModalMode: action.payload,
      };
    case SET_PLAYER_HOTKEYS_ALLOWED:
      return {
        ...state,
        playerHotkeysAllowed: action.payload,
      };
    case SET_VISIBLE_RANGE:
      return {
        ...state,
        visibleRange: action.payload,
      };
    case SET_ALLOW_PLAY_FIRST:
      return {
        ...state,
        allowPlayFirst: action.payload,
      };
    default:
      return state;
  }
};

export const playerReducerV2 = createSlice({
  name: 'playerReducer',
  initialState: initialState,
  reducers: {
    setVideoDimentions(state, action) {
      state.videoDimensions = action.payload;
    },
    setCreateEpisodeMode(state, action) {
      state.createEpisodeMode = action.payload;
    },
    setPlayedEpsiodeAction(state, action) {
      state.playedEpisode = action.payload;
    },
    setPlayedVideoSet(state, action) {
      state.playedVideoSet = action.payload;
    },
    resetEditMode(state) {
      state.videosListEditMode = null;
      state.editedFileEpisodeRange = [];
      state.editedEpisodeRange = [];
    },
    setVideoListEditMode(state, action) {
      state.videosListEditMode = action.payload;
    },
    setEditedEpisodeFileRange(state, action) {
      state.editedFileEpisodeRange = action.payload;
    },
    setEditedEpisodeRange(state, action) {
      state.editedEpisodeRange = action.payload;
    },
    setEditedFileEpisodeFrom(state, action) {
      state.editedFileEpisodeRange[0] = action.payload;
    },
    setEditedFileEpisodeTo(state, action) {
      state.editedFileEpisodeRange[1] = action.payload;
    },
    setEditedEpisodeFrom(state, action) {
      state.editedEpisodeRange[0] = action.payload;
    },
    setEditedEpisodeTo(state, action) {
      state.editedEpisodeRange[1] = action.payload;
    },
    toggleWideScreenMode(state) {
      state.wideScreenMode = !state.wideScreenMode;
    },
  },
});
