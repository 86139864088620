import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SetViewCalendarEvent } from 'actions/calendar.actions';
import BlueDot from 'assets/img/BlueDot.svg';
import GreenDot from 'assets/img/GreenDot.svg';
import WhiteDot from 'assets/img/WhiteDot.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { MONTHS_ENDING } from 'types/constants';
import { CalendarDayEventType, EventType } from 'types/types';

import './index.css';

interface EventsListModalInterface {
  modalEvents: Array<CalendarDayEventType>;
  setModalEvents: any;
  referencedDay: any;
}

const EventsListModal: FC<EventsListModalInterface> = ({
  modalEvents,
  setModalEvents,
  referencedDay,
}) => {
  const eventsListRef = useRef<any>(null);
  const { activeDate, viewedEvent } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const [leftPosition, setLeftPosition] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  useLayoutEffect(() => {
    const w = eventsListRef?.current?.offsetHeight / 2;
    if (
      modalEvents.length > 0 &&
      eventsListRef?.current &&
      w &&
      referencedDay
    ) {
      setLeftPosition(referencedDay.left);
    }
  }, [modalEvents]);
  const eventsRefs = useRef<
    Array<CalendarDayEventType | HTMLDivElement | null>
  >([]);
  const dispatch = useAppDispatch();
  const openEventForEdit = (evt: CalendarDayEventType, reference: any) => {
    dispatch(
      SetViewCalendarEvent(evt, reference.getBoundingClientRect().toJSON()),
    );
    // dispatch(SetCurrentReference(reference.getBoundingClientRect()))
  };
  const [t] = useTranslation();
  return (
    <>
      {
        <div
          className="dailyEventsListModal"
          ref={eventsListRef}
          style={{
            top: `${referencedDay.top - 10}px`,
            left: `${leftPosition}px`,
          }}
        >
          <div>
            <span>
              {modalEvents[0]?.date.day} {MONTHS_ENDING[activeDate?.month || 0]}
            </span>
            <span>
              {modalEvents?.length} {t('events')}
            </span>
          </div>
          {modalEvents?.map((evt: CalendarDayEventType, index: number) => {
            return (
              <div
                className={`flex-row dayEventsContainer
                ${evt.id === viewedEvent?.id ? 'viewedEvent' : ''}`}
                ref={(el) => eventsRefs.current.push(el)}
                key={index}
                onClick={() => openEventForEdit(evt, eventsRefs.current[index])}
              >
                <img
                  src={
                    evt.type === EventType.game
                      ? GreenDot
                      : evt.id === viewedEvent?.id
                        ? WhiteDot
                        : BlueDot
                  }
                />
                <div>
                  {evt.date.startHour}:
                  {evt.date.startMinute.toString().padStart(2, '0')}
                </div>
                <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {evt.name}
                </div>
                <div className="gradientRight" />
              </div>
            );
          })}
          <div
            className={'enabled enabledClickable flex-row j-ce'}
            style={{ width: '100%' }}
            onClick={() => setModalEvents(false)}
          >
            {t('Hide')}
          </div>
        </div>
      }
    </>
  );
};
export default EventsListModal;
