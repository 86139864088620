import React, { FC, useEffect, useRef, useState } from 'react';

import './index.css';
import UserIcon from '../../assets/img/UserIcon.svg';
import { CityType, TeamType } from '../../types/types';
import InputWithClear from '../InputWithClear';

interface Interface {
  fieldName: string;
  placeholder: string;
  valueController: { value: any; setValue: any };
  searchElementsController: { searchElements: any; setSearchElements: any };
  maxLength: number;
  isRequired?: boolean;
  searchValuesController?: { values: Array<any>; getValues: any };
  conditionalDisplay?: boolean;
  allowNonDropDown?: boolean;
}

const DropDownSearch: FC<Interface> = ({
  fieldName,
  placeholder,
  valueController,
  maxLength,
  searchElementsController,
  isRequired = false,
  conditionalDisplay = false,
}) => {
  const searchRef = useRef<any>(null);

  const { value, setValue } = valueController;
  const dropDownControlledSetValue = (value: any) => {
    setValue(value);
  };
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [fieldIsOnFocus, setFieldIsOnFocus] = useState(false);
  const { searchElements, setSearchElements } = searchElementsController;

  useEffect(() => {
    if (value) {
      setDropdownVisible(value?.length > 0 && fieldIsOnFocus);
      setSearchElements(value);
    } else {
      setSearchElements(value);
    }
  }, [value]);

  useEffect(() => {
    // console.log('focusChanged')
    // console.log(fieldIsOnFocus)
    setDropdownVisible(fieldIsOnFocus);
  }, [fieldIsOnFocus]);

  const getTopOffset = (fieldRef: any) => {
    const scrollH = fieldRef?.current?.scrollHeight + 4;
    // console.log(conditionalDisplay)
    return conditionalDisplay
      ? scrollH
      : fieldRef?.current?.offsetTop + scrollH;
  };
  const getLeftOffset = (fieldRef: any) => {
    return fieldRef?.current?.offsetLeft;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const onOptionClick = (value: any) => {
    if (typeof value === 'function') {
      setFieldIsOnFocus(value);
    } else {
      dropDownControlledSetValue(value);
      setFieldIsOnFocus(false);
    }
  };

  // const focusTracker = (v: any) => {
  //   setFieldIsOnFocus((prev) => v);
  // };

  return (
    <div className="dropDownSearchContainer">
      <InputWithClear
        placeholder={placeholder}
        fieldType={'text'}
        fieldName={fieldName}
        isRequired={isRequired}
        valueController={{ value: value, setValue: setValue }}
        maxLength={maxLength}
        ref={searchRef}
        focusTracker={onOptionClick}
      />
      {dropdownVisible && value && searchElements.length > 0 && (
        <div
          style={{
            top: `${getTopOffset(searchRef)}px`,
            left: `${getLeftOffset(searchRef)}px`,
            width: `${searchRef?.current?.scrollWidth - 16}px`,
          }}
          className="dropdownListContainer display-flex j-sb"
        >
          <div className="innerDropdownListContainer flex-column j-sb">
            {searchElements.map((team: TeamType | CityType) => {
              return (
                <div
                  className="teamDDListElement"
                  onMouseDown={() => {
                    onOptionClick(team.name);
                  }}
                >
                  <div>
                    <input value={team.id} type="radio" />
                  </div>
                  <img src={UserIcon} />
                  <label>{team.name}</label>
                </div>
              );
            })}
          </div>
          {/*<div className={"bottomFilter"} />*/}
        </div>
      )}
    </div>
  );
};
export default DropDownSearch;
