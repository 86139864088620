import { createSlice } from '@reduxjs/toolkit';

import { NotificationType } from 'types/types';

type InitialStateType = {
  notificationIsOpen: boolean;
  notificationParameters: any[];
  notificatons: NotificationType[];
};
const initialState: InitialStateType = {
  notificationIsOpen: false,
  notificationParameters: [],
  notificatons: [],
};
export const notificationsReducer = createSlice({
  name: 'taskReducer',
  initialState: initialState,
  reducers: {
    setNotifications(state, action) {
      const newNotifications = action.payload.map((notification: any) => {
        return {
          ...notification,
          shown: true,
        };
      });
      state.notificatons = newNotifications;
    },
    removeNotification(state, action) {
      state.notificatons = state.notificatons.filter(
        (notification) => notification.id !== action.payload.id,
      );
    },
    markNotificationAsRead(state, action) {
      state.notificatons = state.notificatons.map((notification) =>
        action.payload.ids.includes(notification.id)
          ? { ...notification, is_read: true }
          : notification,
      );
    },
    showNotification(state, action) {
      state.notificationIsOpen = true;
      state.notificationParameters = action.payload.notificationParameters;
    },
    closeNotification(state, action) {
      state.notificationParameters = state.notificationParameters.filter(
        (notification) => action.payload.id !== notification.id,
      );
      state.notificationIsOpen = state.notificationParameters.length > 0;
    },
  },
});
