import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  metricsRegistry: any[];
};
const initialState: InitialStateType = {
  metricsRegistry: [],
};

export const metricsReducer = createSlice({
  name: 'metricsReducer',
  initialState: initialState,
  reducers: {
    setMetricsRegistry(state, action) {
      state.metricsRegistry = action.payload;
    },
  },
});
