import { ThunkAction } from 'redux-thunk';

import { ClubsAPI } from 'api/clubs';
import { AppStateType } from 'reducers';
import {
  SET_CURRENT_CLUB,
  SET_CURRENT_CLUB_ERROR,
  SET_CURRENT_TEAM_EVENT,
  SET_CURRENT_TEAM_EVENT_ERROR,
  SET_TEAMS,
  SET_TEAMS_ERROR,
  SET_TEAMS_REQUEST,
} from 'types/action.types';
import { ClubType, TeamType } from 'types/types';

export type ClubsActionsTypes =
  | SetCurrentClubActionType
  | SetCurrentClubErrorActionType
  | SetCurrentTeamType
  | SetCurrentTeamErrorType
  | SetTeamsType
  | SetTeamsTypeError
  | SetTeamsRequestType;

type SetCurrentClubActionType = {
  type: typeof SET_CURRENT_CLUB;
  payload: ClubType;
};
export const setCurrentClubAction = (
  clubData: ClubType,
): SetCurrentClubActionType => {
  return {
    type: SET_CURRENT_CLUB,
    payload: clubData,
  };
};
type SetCurrentClubErrorActionType = {
  type: typeof SET_CURRENT_CLUB_ERROR;
  payload: any;
};
export const setCurrentClubErrorAction = (
  error: any,
): SetCurrentClubErrorActionType => {
  return {
    type: SET_CURRENT_CLUB_ERROR,
    payload: error,
  };
};

type SetCurrentTeamType = {
  type: typeof SET_CURRENT_TEAM_EVENT;
  payload: TeamType | null;
};
type SetCurrentTeamErrorType = {
  type: typeof SET_CURRENT_TEAM_EVENT_ERROR;
  payload: any;
};
export const AC_SetCurrentSelectedTeamEvent = (
  newSelectedTeam: TeamType | null,
): SetCurrentTeamType => {
  return {
    type: SET_CURRENT_TEAM_EVENT,
    payload: newSelectedTeam,
  };
};
export const AC_SetCurrentSelectedTeamEventError = (
  error: any,
): SetCurrentTeamErrorType => {
  return {
    type: SET_CURRENT_TEAM_EVENT_ERROR,
    payload: error,
  };
};

export const SetCurrentUserTeamAction =
  (
    team: TeamType,
  ): ThunkAction<Promise<void>, AppStateType, undefined, ClubsActionsTypes> =>
  async (dispatch) => {
    try {
      const teamSetResponse = await ClubsAPI.setCurrentUserTeam(
        team.club,
        team.id,
      );
      if (teamSetResponse.status === 204) {
        dispatch(AC_SetCurrentSelectedTeamEvent(team));
      } else {
        dispatch(AC_SetCurrentSelectedTeamEventError(teamSetResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const SetCurrentUserClubAction =
  (
    clubId: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, ClubsActionsTypes> =>
  async (dispatch) => {
    try {
      const clubResponse = await ClubsAPI.getClub(clubId);
      if (clubResponse.status === 200) {
        //@ts-ignore
        dispatch(setCurrentClubAction(clubResponse.data));
      } else {
        //@ts-ignore
        dispatch(setCurrentClubErrorAction(clubResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
type SetTeamsRequestType = {
  type: typeof SET_TEAMS_REQUEST;
};
const AC_SetTeamsRequestAction = (): SetTeamsRequestType => {
  return {
    type: SET_TEAMS_REQUEST,
  };
};
type SetTeamsType = {
  type: typeof SET_TEAMS;
  payload: Array<TeamType>;
};
const AC_SetTeamsAction = (teams: Array<TeamType>): SetTeamsType => {
  return {
    type: SET_TEAMS,
    payload: teams,
  };
};
type SetTeamsTypeError = {
  type: typeof SET_TEAMS_ERROR;
  payload: any;
};
const AC_SetTeamsActionError = (error: any): SetTeamsTypeError => {
  return {
    type: SET_TEAMS_ERROR,
    payload: error,
  };
};

export const GetTeamsAction =
  (
    clubId?: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, ClubsActionsTypes> =>
  async (dispatch, getState) => {
    if (clubId === undefined) {
      clubId = getState().authReducer.currentUser?.clubs[0];
    }
    if (clubId) {
      try {
        dispatch(AC_SetTeamsRequestAction());
        // const clubResponse = await ClubsAPI.getClubTeams(clubId);
        const clubResponse = await ClubsAPI.getTeams();
        if (clubResponse.status === 200) {
          //@ts-ignore
          dispatch(AC_SetTeamsAction(clubResponse.data.results));
        } else {
          //@ts-ignore
          dispatch(AC_SetTeamsActionError(clubResponse.data));
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
