import React, { FC } from 'react';

import Draggable from 'react-draggable';
import './index.css';
// import { useSelector } from 'react-redux';

// import { AppStateType } from '../../../reducers';

interface ScrollerInterface {
  handleDrag: (e: any, data: any) => void;
  getCurrentProgressPosition: () => number;
  getRightBound: () => number;
  draggedRef: any;
  clickRef: any;
  getElementPosition?: any;
}

const VideoScroller: FC<ScrollerInterface> = ({
  handleDrag,
  getCurrentProgressPosition,
  getRightBound,
  draggedRef,
  clickRef,
}) => {
  // const { playerMode } = useSelector(
  //   (state: AppStateType) => state.playerReducer,
  // );
  // const leftRangeRef = useRef<any>(null);
  // const rightRangeRef = useRef<any>(null);
  return (
    <Draggable
      axis={'x'}
      positionOffset={{ x: -7, y: 0 }}
      onDrag={handleDrag}
      position={{ x: getCurrentProgressPosition(), y: 0 }}
      bounds={{ right: getRightBound(), left: 0 }}
    >
      <div
        ref={draggedRef}
        style={{
          left: `calc(${getCurrentProgressPosition()}-7)px`,
        }}
        onClick={() => {
          clickRef.current = false;
        }}
        className={'scroller'}
      >
        <div className={''}></div>
      </div>
    </Draggable>
  );
};
export default VideoScroller;
