import React from 'react';

import { Divider, Flex, Skeleton } from 'antd';

import ProtocolButton from 'components/PlayerPageController/PlayerMatchesBlock/ProtocolButton';

import { ReactComponent as ChevronRight } from '../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircleFilled } from '../../../assets/img/icons/faPlayCircleFilled.svg';

import './index.css';

const MatchSkeleton = () => {
  const renderBlock = (opacity: number) => {
    return (
      <div
        className={'team-match-wrapper'}
        style={{
          opacity: opacity,
        }}
      >
        <div>
          <div
            style={{
              width: 32,
              height: 32,
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexShrink: 0,
            }}
          >
            <ChevronRight fill="var(--colorLink, #1677FF)" />
          </div>
          <Flex className="match-details" justify="space-between">
            <Skeleton.Button
              active
              shape={'round'}
              style={{ width: 75, height: 14 }}
            ></Skeleton.Button>
            <Skeleton.Button
              active
              shape={'round'}
              style={{ width: 75, height: 14 }}
            ></Skeleton.Button>
            <Skeleton.Button
              active
              shape={'round'}
              style={{ width: 122, height: 14 }}
            ></Skeleton.Button>
          </Flex>
          <div className="match-participants-container">
            <Skeleton.Button
              active
              shape={'round'}
              style={{ width: 168, height: 18, textAlign: 'end', flex: 1 }}
            ></Skeleton.Button>
            <div
              className="score"
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Skeleton.Button
                active
                style={{
                  width: 28,
                  height: 24,
                }}
              ></Skeleton.Button>
              :
              <Skeleton.Button
                active
                style={{
                  width: 28,
                  height: 24,
                }}
              ></Skeleton.Button>
            </div>
            <Skeleton.Button
              active
              shape={'round'}
              style={{ width: 168, height: 18, textAlign: 'start', flex: 1 }}
            ></Skeleton.Button>
          </div>
          <Flex align="center">
            <Flex
              // vertical
              gap={6}
              style={{ height: '96px' }}
              justify="flex-end"
            >
              <ProtocolButton
                playedTime={undefined}
                role={undefined}
                roleStats={[]}
              />
            </Flex>
            <Divider type="vertical" style={{ height: 80 }} />
            <Flex align="center" className="buttons-controller">
              <div
                style={{
                  height: 32,
                }}
                className="enabledClickable"
              >
                <PlayCircleFilled />
              </div>
            </Flex>
          </Flex>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderBlock(1)}
      {renderBlock(0.7)}
      {renderBlock(0.4)}
    </>
  );
};
export default MatchSkeleton;
