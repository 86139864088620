import React, { FC, forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import CameraIcon from 'assets/img/CameraIcon.svg';
import PauseIcon from 'assets/img/PauseIcon.svg';
import StopIcon from 'assets/img/StopIcon.svg';
import './index.css';
import { CameraType } from 'types/types';

interface CameraControlButtonInterface {
  activeCamera: CameraType;
  isEnabled: boolean;
  fieldRef?: any;
  startRecordCallback?: any;
  stopRecordCallback?: any;
  pauseRecordCallback?: any;
  eventId?: string;
}

const CameraControlButton: FC<CameraControlButtonInterface> = forwardRef(
  (
    {
      activeCamera,
      isEnabled,
      fieldRef,
      startRecordCallback,
      stopRecordCallback,
      pauseRecordCallback,
      eventId,
    },
    // ref: any,
  ) => {
    const blockIsDisabled = () => {
      // console.log('BUTTON', activeCamera, isEnabled, recordsCurrentEvent(), eventId )
      // console.log('BUTTON', (activeCamera === undefined && !isEnabled && !recordsCurrentEvent()) )
      if (activeCamera === undefined) {
        // console.log('Button 1')
        return true;
      }
      if (!isEnabled) {
        // console.log('Button 2')
        return true;
      }
      return !recordsCurrentEvent();
      // return activeCamera === undefined && !isEnabled && !recordsCurrentEvent();
    };
    const recordsCurrentEvent = () => {
      if (activeCamera && activeCamera.status?.isRecording) {
        if (eventId !== undefined) {
          return eventId === activeCamera?.status?.recordedEventId;
        } else {
          return false;
        }
      }
      return true;
    };
    const [t] = useTranslation();
    return (
      <div
        ref={fieldRef}
        className={`cameraControlButtonContainer ${blockIsDisabled() ? 'disabled' : ''}`}
      >
        {activeCamera?.status?.readyToRecord &&
          !activeCamera?.status?.isRecording && (
            <button
              onClick={() => {
                !blockIsDisabled() && startRecordCallback();
              }}
            >
              <img src={CameraIcon} />
              <div className={'readyToRecord'} />
              {t('Start recording')}
            </button>
          )}
        {activeCamera?.status?.isRecording && (
          <>
            <button>
              <img src={CameraIcon} />
              <div className={'recording'} />
              {activeCamera?.status?.recordingTime}
            </button>
            <div
              onClick={() => !blockIsDisabled() && pauseRecordCallback()}
              className={'pauseButton'}
            >
              <img src={PauseIcon} />
            </div>
            <div
              onClick={() => !blockIsDisabled() && stopRecordCallback()}
              className={'stopButton'}
            >
              <img src={StopIcon} />
            </div>
          </>
        )}
      </div>
    );
  },
);
export default CameraControlButton;
