export const NEW_ACTIVE_DATE_TYPE = 'NEW_ACTIVE_DATE_TYPE';

export const SET_CALENDAR_EVENTS_TYPE = 'SET_CALENDAR_EVENTS_TYPE';
export const SET_CALENDAR_EVENTS_SUCCESS = 'SET_CALENDAR_EVENTS_SUCCESS';
export const SET_CALENDAR_EVENTS_ERROR = 'SET_CALENDAR_EVENTS_ERROR';

export const SET_EDITED_CALENDAR_EVENT = 'SET_EDITED_CALENDAR_EVENT';

export const ADD_NEW_CALENDAR_EVENT = 'ADD_NEW_CALENDAR_EVENT';
export const ADD_NEW_CALENDAR_EVENT_SUCCESS = 'ADD_NEW_CALENDAR_EVENT_SUCCESS';
export const ADD_NEW_CALENDAR_EVENT_ERROR = 'ADD_NEW_CALENDAR_EVENT_ERROR';

export const SET_CURRENT_TEAM_EVENT = 'SET_CURRENT_TEAM_EVENT';
export const SET_CURRENT_TEAM_EVENT_ERROR = 'SET_CURRENT_TEAM_EVENT_ERROR';
export const SET_VIEW_CALENDAR_EVENT = 'SET_VIEW_CALENDAR_EVENT';

export const SET_TEAMS_REQUEST = 'SET_TEAMS_REQUEST';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAMS_ERROR = 'SET_TEAMS_ERROR';

export const DELETE_CALENDAR_EVENT = 'DELETE_CALENDAR_EVENT';
export const DELETE_CALENDAR_EVENT_ERROR = 'DELETE_CALENDAR_EVENT_ERROR';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';

export const SET_CALENDAR_SIDE_FORM_MODE = 'SET_CALENDAR_SIDE_FORM_MODE';

export const UPDATE_CALENDAR_EVENT = 'UPDATE_CALENDAR_EVENT';
export const UPDATE_CALENDAR_ERROR = 'UPDATE_CALENDAR_ERROR';
export const UPDATE_CALENDAR_SUCCESS = 'UPDATE_CALENDAR_SUCCESS';

export const UPDATE_CAMERA_REQUEST = 'UPDATE_CAMERA_REQUEST';
export const UPDATE_CAMERA_REQUEST_ERROR = 'UPDATE_CAMERA_REQUEST_ERROR';
export const UPDATE_CAMERA_REQUEST_SUCCESS = 'UPDATE_CAMERA_REQUEST_SUCCESS';

export const DELETE_CAMERA_REQUEST = 'DELETE_CAMERA_REQUEST';
export const DELETE_CAMERA_REQUEST_ERROR = 'DELETE_CAMERA_REQUEST_ERROR';
export const DELETE_CAMERA_REQUEST_SUCCESS = 'DELETE_CAMERA_REQUEST_SUCCESS';

export const SET_CURRENT_CAMERA = 'SET_CURRENT_CAMERA';

export const GET_CAMERAS_LIST_REQUEST_ERROR = 'GET_CAMERAS_LIST_REQUEST_ERROR';
export const GET_CAMERAS_LIST_REQUEST_SUCCESS =
  'GET_CAMERAS_LIST_REQUEST_SUCCESS';
export const GET_CAMERAS_LIST_REQUEST = 'GET_CAMERAS_LIST_REQUEST';

export const SEND_LOGIN_ACTION = 'SEND_LOGIN_ACTION';
export const LOGIN_ACTION_SUCCESS = 'LOGIN_ACTION_SUCCESS';
export const LOGIN_ACTION_ERROR = 'LOGIN_ACTION_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const RECOVER_PASSWORD_ACTION_SUCCESS =
  'RECOVER_PASSWORD_ACTION_SUCCESS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_ERROR = 'SET_CURRENT_USER_ERROR';
export const SET_CURRENT_CLUB_ERROR = 'SET_CURRENT_CLUB_ERROR';
export const SET_CURRENT_CLUB = 'SET_CURRENT_CLUB';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_REQUEST_ERROR = 'UPDATE_USER_REQUEST_ERROR';
export const UPDATE_USER_REQUEST_SUCCESS = 'UPDATE_USER_REQUEST_SUCCESS';

export const SET_STYLE_MODE_ACTION = 'SET_STYLE_MODE_ACTION';
export const SET_LANGUAGE = 'SET_LANGUAGE';

export const SET_PLAYER_VIDEO = 'SET_PLAYER_VIDEO';
export const SET_VIDEOS_LIST_EDIT_MODE = 'SET_VIDEOS_LIST_EDIT_MODE';

export const SET_EPISODE_ATTRIBUTES_SUCCESS = 'SET_EPISODE_ATTRIBUTES_SUCCESS';
export const SET_EPISODE_ATTRIBUTES_REQUEST = 'SET_EPISODE_ATTRIBUTES_REQUEST';
export const SET_EPISODE_ATTRIBUTES_ERROR = 'SET_EPISODE_ATTRIBUTES_ERROR';

export const SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS';
export const SET_DISPLAYED_FILTERS = 'SET_DISPLAYED_FILTERS';
export const APPEND_DISPLAYED_FILTERS = 'APPEND_DISPLAYED_FILTERS';

export const SET_SHARED_PLAYLIST = 'SET_SHARED_PLAYLIST';
export const SET_ACTIVE_VIDEO = 'SET_ACTIVE_VIDEO';
export const SET_IS_PLAYING = 'SET_IS_PLAYING';
export const SET_PLAYER_COMMAND = 'SET_PLAYER_COMMAND';
export const SET_PLAY_STATE = 'SET_PLAY_STATE';
export const SET_PLAYER_LOADING_VIDEO = 'SET_PLAYER_LOADING_VIDEO';
export const SET_PLAYLIST = 'SET_PLAYLIST';
export const GET_PLAYLISTS_REQUEST = 'GET_PLAYLISTS_REQUEST';
export const SET_PLAYER_MODE = 'SET_PLAYER_MODE';
export const TOGGLE_VIDEO_COLLAPSED = 'TOGGLE_VIDEO_COLLAPSED';
export const ADD_EVENTS_TO_EXPANDED_VIDEO = 'ADD_EVENTS_TO_EXPANDED_VIDEO';
export const RENAME_PLAYLIST = 'RENAME_PLAYLIST';
export const ADD_USER_EVENTS_TO_EXPANDED_VIDEO =
  'ADD_USER_EVENTS_TO_EXPANDED_VIDEO';
export const REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO =
  'REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO';
export const SET_SELECTED_GAMES = 'SET_SELECTED_GAMES';
export const SET_APPLIED_SELECTED_GAMES = 'SET_APPLIED_SELECTED_GAMES';
export const SET_SELECTED_TRAININGS = 'SET_SELECTED_TRAININGS';
export const SET_APPLIED_SELECTED_TRAININGS = 'SET_APPLIED_SELECTED_TRAININGS';

export const GET_VIDEO_EPISODES_REQUEST = 'GET_VIDEO_EPISODES_REQUEST';
export const GET_VIDEO_EPISODES_SUCCESS = 'GET_VIDEO_EPISODES_SUCCESS';
export const GET_VIDEO_EPISODES_ERROR = 'GET_VIDEO_EPISODES_ERROR';
export const SET_FILTERED_VIDEOS = 'SET_FILTERED_VIDEOS';

export const PREFILTER_REQUEST = 'PREFILTER_REQUEST';
export const PREFILTER_REQUEST_SUCCESS = 'PREFILTER_REQUEST_SUCCESS';
export const PREFILTER_REQUEST_ERROR = 'PREFILTER_REQUEST_ERROR';

export const INIT_TEAM_TOURNAMENT_DATES_REQUEST =
  'INIT_TEAM_TOURNAMENT_DATES_REQUEST';
export const INIT_TEAM_TOURNAMENT_DATES_ERROR =
  'INIT_TEAM_TOURNAMENT_DATES_ERROR';
export const INIT_TEAM_TOURNAMENT_DATES_SUCCESS =
  'INIT_TEAM_TOURNAMENT_DATES_SUCCESS';

export const CLEAR_EVENTS_FILTERS = 'CLEAR_EVENTS_FILTERS';
export const CLEAR_TRAININGS_FILTERS = 'CLEAR_TRAININGS_FILTERS';

export const OPEN_FILTERS_PANEL_STATE = 'OPEN_FILTERS_PANEL_STATE';

export const CLEAR_EDITED_CALENDAR_EVENT_STATE =
  'CLEAR_EDITED_CALENDAR_EVENT_STATE';
export const CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH =
  'CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH';

export const FILE_UPLOAD_IN_PROGRESS = 'FILE_UPLOAD_IN_PROGRESS';
export const SET_USER_EDITED_EPISODES = 'SET_USER_EDITED_EPISODES';
export const SET_USER_EDITED_TEMP_EPISODES = 'SET_USER_EDITED_TEMP_EPISODES';

export const NEW_USER_EPISODE_REQUEST = 'NEW_USER_EPISODE_REQUEST';
export const NEW_USER_EPISODE_REQUEST_SUCCESS =
  'NEW_USER_EPISODE_REQUEST_SUCCESS';
export const NEW_USER_EPISODE_REQUEST_ERROR = 'NEW_USER_EPISODE_REQUEST_ERROR';

export const SET_PLAYER_PAUSED_AT = 'SET_PLAYER_PAUSED_AT';
export const SET_USER_EDITED_EPISODE_DRAGGED =
  'SET_USER_EDITED_EPISODE_DRAGGED';
export const SET_VIDEO_TIME_ZOOM = 'SET_VIDEO_TIME_ZOOM';

export const OPEN_FILTER = 'OPEN_FILTER';
export const SET_ACTIVE_TEAM_FILTER = 'SET_ACTIVE_TEAM_FILTER';
export const SET_VISIBLE_PLAYERS_TYPE = 'SET_VISIBLE_PLAYERS_TYPE';
export const SET_SELECTED_PLAYERS = 'SET_SELECTED_PLAYERS';
export const SET_SELECTED_MATCHES = 'SET_SELECTED_MATCHES';
export const SET_CURRENT_TEAM_TOURNAMENTS = 'SET_CURRENT_TEAM_TOURNAMENTS';
export const ADD_SELECTED_TOURNAMENT = 'ADD_SELECTED_TOURNAMENT';
export const ADD_ACTIVE_TEAM_DATE_LIMITS = 'ADD_ACTIVE_TEAM_DATE_LIMITS';
export const SET_LAST_X_FILTER = 'SET_LAST_X_FILTER';
export const SET_SELECTED_DATE_LIMITS_FILTER =
  'SET_SELECTED_DATE_LIMITS_FILTER';
export const SET_SELECTED_ATTRIBUTES = 'SET_SELECTED_ATTRIBUTES';
export const SET_VISIBLE_FILTER_ATTRIBUTES = 'SET_VISIBLE_FILTER_ATTRIBUTES';
export const SET_SELECTED_TIME_INTERVALS = 'SET_SELECTED_TIME_INTERVALS';
export const SET_SHOW_MY_EPISODES_FILTER = 'SET_SHOW_MY_EPISODES_FILTER';
export const SET_SHOW_PLAYER_INTERVALS_FILTER =
  'SET_SHOW_PLAYER_INTERVALS_FILTER';
export const SET_SHOW_PLAYER_GAMES_FILTER = 'SET_SHOW_PLAYER_GAMES_FILTER';

export const GET_FOLDERS_LIST = 'GET_FOLDERS_LIST';
export const SET_GAME_PROTOCOL_ID = 'SET_GAME_PROTOCOL_ID';
export const SET_STATS_CUSTOM_GAME_LIST = 'SET_STATS_CUSTOM_GAME_LIST';

export const GET_ROOT_PLAYLISTS = 'GET_ROOT_PLAYLISTS';
export const SET_OPENED_PLAYLIST = 'SET_OPENED_PLAYLIST';
export const SET_OPENED_PLAYLIST_ID = 'SET_OPENED_PLAYLIST_ID';

export const SHOW_DOWNLOAD_EPISODES_MODAL = 'SHOW_DOWNLOAD_EPISODES_MODAL';

export const SET_INTERMEDIATE_ACTIVITIES = 'SET_INTERMEDIATE_ACTIVITIES';
export const TOGGLE_PLAYLIST_VIDEO_COLLAPSED =
  'TOGGLE_PLAYLIST_VIDEO_COLLAPSED';

export const SET_PLAYLIST_LOADING = 'SET_PLAYLIST_LOADING';

export const SET_PLAYER_HOTKEYS_ALLOWED = 'SET_PLAYER_HOTKEYS_ALLOWED';
export const SET_VISIBLE_RANGE = 'SET_VISIBLE_RANGE';
export const SET_ALLOW_PLAY_FIRST = 'SET_ALLOW_PLAY_FIRST';
