import React, { useEffect } from 'react';

import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

import PlayerDataBlock from './PlayerDataBlock';
import PlayerInfoBlock from './PlayerInfoBlock';

import './index.css';

// import GameProtocolSidePanel from '../GameProtocolSidePanel';
const PlayerPageController = () => {
  const dispatch = useAppDispatch();
  const { setTopPlayerRole } = teamPlayerReducer.actions;
  useEffect(() => {
    dispatch(setTopPlayerRole(null));
  }, []);
  return (
    <div className="player-page-controller">
      <PlayerInfoBlock />
      <PlayerDataBlock />
      <GameProtocolSidePanel />
    </div>
  );
};
export default PlayerPageController;
