import React, { useEffect, useRef, useState } from 'react';

import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

import FieldFootballPlayer from '../../../../assets/img/field_football_player.png';
import { ReactComponent as ArrowsRotating } from '../../../../assets/img/icons/faArrowsRotating.svg';

import './index.css';

const MatchFieldEventsField = ({
  gameId,
  showClearFilterButton,
  clearAllFiltersCallback,
  updateDots,
}: {
  gameId: string;
  showClearFilterButton: boolean;
  clearAllFiltersCallback: any;
  updateDots: boolean;
}) => {
  const { gameEventCoordinates } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const [t] = useTranslation();
  const imgRef = useRef<any>(null);
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const game = gameEventCoordinates[gameId] || {};
    const evts: any = [];
    if (Object.keys(game).length > 0 && updateDots) {
      Object.keys(game)?.map((metric: any) => {
        game[metric]['success']
          ?.filter((evt: any) => evt.visible)
          .map((evt: any) =>
            evts.push(
              <Tooltip
                title={
                  metricsRegistry.filter(
                    (metric: any) =>
                      metric.key === evt.key ||
                      `${metric.key}_acc` == evt.key ||
                      `${metric.key}_inac` == evt.key ||
                      `${metric.key}_success` == evt.key ||
                      `${metric.key}_fail` == evt.key ||
                      `${metric.key}s_on_goal` == evt.key ||
                      `${metric.key}s_off_goal` == evt.key,
                  )[0]?.name
                }
                placement="top"
              >
                <div
                  className="event-dot success enabledClickable"
                  style={{
                    left:
                      evt.x * imgRef.current?.getBoundingClientRect().width - 5,
                    top:
                      evt.y * imgRef.current?.getBoundingClientRect().height -
                      5,
                  }}
                />
              </Tooltip>,
            ),
          );
        game[metric]['fail']
          ?.filter((evt: any) => evt.visible)
          ?.map((evt: any) =>
            evts.push(
              <Tooltip
                title={
                  metricsRegistry.filter(
                    (metric: any) =>
                      metric.key === evt.key ||
                      `${metric.key}_acc` == evt.key ||
                      `${metric.key}_inac` == evt.key ||
                      `${metric.key}_success` == evt.key ||
                      `${metric.key}_fail` == evt.key ||
                      `${metric.key}s_on_goal` == evt.key ||
                      `${metric.key}s_off_goal` == evt.key,
                  )[0]?.name
                }
                placement="top"
              >
                <div
                  className="event-dot fail enabledClickable"
                  style={{
                    left:
                      evt.x * imgRef.current?.getBoundingClientRect().width - 5,
                    top:
                      evt.y * imgRef.current?.getBoundingClientRect().height -
                      5,
                  }}
                />
              </Tooltip>,
            ),
          );
        game[metric]['all']
          ?.filter((evt: any) => evt.visible)
          ?.map((evt: any) =>
            evts.push(
              <Tooltip
                title={
                  metricsRegistry.filter(
                    (metric: any) =>
                      metric.key === evt.key ||
                      `${metric.key}_acc` == evt.key ||
                      `${metric.key}_inac` == evt.key ||
                      `${metric.key}_success` == evt.key ||
                      `${metric.key}_fail` == evt.key ||
                      `${metric.key}s_on_goal` == evt.key ||
                      `${metric.key}s_off_goal` == evt.key,
                  )[0]?.name
                }
                placement="top"
              >
                <div
                  className="event-dot all enabledClickable"
                  style={{
                    left:
                      evt.x * imgRef.current?.getBoundingClientRect().width - 5,
                    top:
                      evt.y * imgRef.current?.getBoundingClientRect().height -
                      5,
                  }}
                />
              </Tooltip>,
            ),
          );
      });
    }
    setEvents(evts);
  }, [imgRef, updateDots, gameEventCoordinates]);
  return (
    <Flex
      vertical
      justify="center"
      align="center"
      className="h100"
      style={{ position: 'relative' }}
    >
      <div
        style={{
          height: 219,
          width: 337,
          position: 'relative',
          backgroundImage: `url(${FieldFootballPlayer})`,
        }}
        ref={imgRef}
      >
        {events}
      </div>
      {showClearFilterButton && (
        <Flex
          className="enabledClickable"
          style={{
            position: 'absolute',
            zIndex: 5,
            left: 0,
            bottom: -5,
            color: 'var(--colorPrimaryBase)',
          }}
          gap={4}
          align="center"
          onClick={clearAllFiltersCallback}
        >
          <ArrowsRotating />
          {t('Show all actions')}
        </Flex>
      )}
    </Flex>
  );
};
export default MatchFieldEventsField;
