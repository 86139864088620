import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import mainReducer from 'reducers';

const store = configureStore({ reducer: mainReducer });
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
