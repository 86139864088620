import { createSlice } from '@reduxjs/toolkit';

import { Download } from 'types/types';

type InitialStateType = {
  downloads: Download[];
  goBackToPage: string;
};
const initialState: InitialStateType = {
  downloads: [],
  goBackToPage: '',
};
export const downloadsReducer = createSlice({
  name: 'downloadsReducer',
  initialState: initialState,
  reducers: {
    setGoBackToPage(state, action) {
      state.goBackToPage = action.payload;
    },
    setDownloads(state, action) {
      state.downloads = action.payload;
    },
    updateDownloadState(state, action) {
      const download = state.downloads.filter(
        (download) => download.id === action.payload.id,
      );
      download[0].status = action.payload.status;
      download[0].progress = action.payload.progress;
    },
    removeDownloads(state, action) {
      state.downloads = state.downloads.filter(
        (download) => !action.payload.ids.includes(download.id),
      );
    },
  },
});
