import { API_ENDPOINT } from 'types/constants';
import { CameraType } from 'types/types';
import myAxios from 'utils/axios';

const updateCamera = async (cameraId: string, cameraData: CameraType) => {
  const url = `${API_ENDPOINT}api/data/events/${cameraId}/`;
  try {
    return await myAxios.PATCH(url, cameraData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const deleteCamera = async (cameraId: string) => {
  const url = `${API_ENDPOINT}api/data/equipment/cameras/${cameraId}/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export type CameraListFilterParams = {
  isRecording?: string;
  readyToRecord?: string;
};
const getCamerasList = async (params?: CameraListFilterParams) => {
  const url = `${API_ENDPOINT}api/equipment/cameras/`;
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getCamera = async (cameraId: string) => {
  const url = `${API_ENDPOINT}api/equipment/cameras/${cameraId}/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const checkCameraIsAvailable = async (cameraId: string, dateTime: string) => {
  const url = `${API_ENDPOINT}api/equipment/cameras/${cameraId}/check_available/`;
  const params = new URLSearchParams();
  params.append('datetime', dateTime);
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const EquipmentAPI = {
  getCamerasList,
  getCamera,
  updateCamera,
  deleteCamera,
  checkCameraIsAvailable,
};
