import React from 'react';

import { WINDOW_MODE_WIDTH } from 'types/constants';
// import { formatMillisecondsToTime } from 'types/functions';

import { EpisodeTypes, MenuItemType } from '../types/types';

export function getItem(
  key: string,
  text: string,
  icon?: React.ReactNode,
  onClick?: (mode: any) => void,
  type?: 'icon' | 'label',
  label?: React.ReactNode,
  style?: any,
  className?: string,
): MenuItemType {
  return {
    key,
    text,
    icon,
    onClick,
    type,
    label,
    style,
    className,
  } as unknown as MenuItemType;
}

export const getValueFromString = (value: any, type: any): any => {
  return Object.values(type).find((t) => t === value);
};
export const formatScore = (score: string | null | undefined) => {
  if (score && score.includes('-')) {
    return score.replace('-', ':');
  }
  return score;
};
export const initTimer = (
  episode: any,
  timerType: string,
  useGameTimer = false,
  useFileTimer = false,
  // withFormat = true,
) => {
  // console.log(props.episode.user_timer);
  let timer = 0;
  if (useFileTimer) {
    if (episode?.user_timer && episode.user_timer[timerType] >= 0) {
      timer = episode.user_timer[timerType];
      return timer;
    } else {
      timer = episode.file_timer[timerType];
    }
  } else {
    if (useGameTimer) {
      timer = episode.game_timer[timerType];
    } else if (episode.file_timer && episode.file_timer[timerType] >= 0) {
      timer = episode.file_timer[timerType];
    }
  }
  let windowModeOffset = WINDOW_MODE_WIDTH;
  if (episode.episode_type === EpisodeTypes.period) {
    windowModeOffset = 0;
  }
  if (episode.user_timer || episode.keyframes?.length > 0) {
    windowModeOffset = 0;
  }
  if (timerType === 'started_at') {
    if (timer >= windowModeOffset * 1000) {
      timer -= windowModeOffset * 1000;
    } else {
      timer = 0;
    }
  } else {
    if (
      Number(episode.video.meta.video_length) * 1000 - timer >
      windowModeOffset * 1000
    ) {
      timer += windowModeOffset * 1000;
    } else {
      timer = Number(episode.video.meta.video_length) * 1000;
    }
  }
  return timer;
};
export const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;
  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'MacOS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }
  return os;
};
