import React, { FC } from 'react';

import { Button, ConfigProvider, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckboxFilled } from 'assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { NEW_PLAYLIST_TEXT } from 'types/constants';

const AddNewPlaylistNavbarForm: FC<any> = ({
  inputRefPlaylist,
  inputStatus,
  setInputStatus,
  newPlaylistName,
  setNewPlaylistName,
  addNewPlaylistHanlder,
  closeAddPlaylist,
}: {
  inputRefPlaylist: any;
  inputStatus: any;
  setInputStatus: any;
  newPlaylistName: any;
  setNewPlaylistName: any;
  addNewPlaylistHanlder: any;
  closeAddPlaylist: any;
}) => {
  const [t] = useTranslation();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: 'var(--colorTextPlaceholder)',
          fontFamily: 'SFProTextRegular',
          fontSize: 14,
        },
        components: {
          Input: {
            colorBorder: 'var(--colorPrimaryHover)',
          },
        },
      }}
    >
      <Input
        ref={inputRefPlaylist}
        status={inputStatus}
        className="new-folder-input"
        type="text"
        name="newPlaylistName"
        onChange={(event: any) => {
          setInputStatus('');
          setNewPlaylistName(event.target.value);
        }}
        autoFocus={true}
        max={100}
        placeholder={t(NEW_PLAYLIST_TEXT)}
        defaultValue={newPlaylistName}
        style={{
          background: 'var(--colorBgContainer)',
          color: 'var(--colorText)',
          marginLeft: 22,
        }}
      ></Input>
      <Button
        style={{
          border: '1px solid var(--colorIcon)',
          background: 'transparent',
          height: 24,
          width: 32,
        }}
        onClick={closeAddPlaylist}
        icon={<CloseCross />}
      />
      <Button
        style={{
          height: 24,
          width: 32,
          border: '1px solid var(--colorPrimaryBase)',
          background: 'rgba(22, 104, 220, 0.4)',
        }}
        onClick={addNewPlaylistHanlder}
        icon={<CheckboxFilled />}
        type="default"
      />
    </ConfigProvider>
  );
};
export default AddNewPlaylistNavbarForm;
