import React, { useEffect, useState } from 'react';

import './index.css';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';

import { AC_setIntermediateActivitiesAction } from '../../../actions/filter.actions';
import { ClubsAPI } from '../../../api/clubs';
import { ReactComponent as Chevron } from '../../../assets/img/icons/faChevronRight.svg';
import { ReactComponent as PlayCircle } from '../../../assets/img/icons/faPlayCircle.svg';
import { useAppDispatch } from '../../../store';
import dayjs from '../../../utils/dayjsLocal';

const StatisticsBlock = () => {
  const [t] = useTranslation();
  const { teamId } = useParams();
  const [stats, setStats] = useState<any>([]);
  const [seasons, setSeasons] = useState<any>([]);
  const [fullSeasonsList, setFullSeasonsList] = useState<any>([]);
  const {
    setSelectedSeason,
    setManualGamesSelectionSection,
    setSelectedMatchesStats,
    setShowGameSelectionModal,
  } = teamReducer.actions;
  const { selectedMatchesStats } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { selectedSeason } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  useEffect(() => {
    if (teamId) {
      setStats([]);
      setSeasons([]);
      ClubsAPI.getTeamSeasons(teamId).then((seasonsResponse) => {
        const seasonsList = seasonsResponse.data;
        setFullSeasonsList(seasonsList);
        if (seasonsList !== undefined) {
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return {
              label: label,
              value: season.id,
              id: season.id,
            };
          });
          setSeasons(s);
          if (selectedSeason === null && s.length) {
            dispatch(setSelectedSeason(s[0]));
            dispatch(setSelectedMatchesStats([]));
          } else if (s.length === 0) {
            dispatch(setSelectedSeason('last10'));
          }
        }
      });
    }
  }, [teamId]);
  useEffect(() => {
    if (teamId !== undefined && selectedSeason) {
      dispatch(setStatsCustomGameList(selectedMatchesStats));
      if (
        selectedSeason !== 'manual' ||
        (selectedSeason === 'manual' && selectedMatchesStats.length > 0)
      ) {
        ClubsAPI.getTeamStats(
          teamId,
          selectedSeason.id || selectedSeason,
          selectedMatchesStats,
        ).then((teamResponse) => {
          // setStats([]);
          if (teamResponse.data !== undefined) {
            setStats(teamResponse.data);
          }
        });
      } else {
        // setSelectedMatches([]);
      }
    }
  }, [selectedSeason, selectedMatchesStats]);
  const { setStatsCustomGameList } = teamReducer.actions;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const getStatVideoLink = (
    metric: string,
    opposite = false,
    metricId: number | undefined = undefined,
  ) => {
    let link = `/player?teamId=${teamId}&m=${metric}&o=${opposite}`;
    if (selectedSeason === 'last5') {
      link = `${link}&lX=5`;
    } else if (selectedSeason === 'last10') {
      link = `${link}&lX=10`;
    } else if (selectedSeason.id) {
      const s = fullSeasonsList.filter(
        (el: any) => (el.id = selectedSeason.id),
      )[0];
      link = `${link}&s=${s?.id}&t=${s.tournament.id}`;
    } else if (selectedSeason) {
      const s = fullSeasonsList.filter(
        (el: any) => (el.id = selectedSeason),
      )[0];
      link = `${link}&s=${s?.id}&t=${s.tournament.id}`;
    }
    if (metric !== 'gound_duel') {
      // TODO MAGIC NUMBER
      link = `${link}&tid=${teamId}`;
    }
    if (selectedMatchesStats.length > 0 && selectedMatchesStats[0] !== null) {
      dispatch(AC_setIntermediateActivitiesAction(selectedMatchesStats));
      dispatch(setStatsCustomGameList(selectedMatchesStats));
    }
    if (metricId) {
      link = `${link}&mid=${metricId}`;
    }
    return link;
  };
  return (
    <div className="block-wrapper left stats">
      <div className="flex-column w100 f-ga-8">
        <div className="block-title">
          <div>{t('Statistics')}</div>
          <Select
            // style={{minWidth: 130}}
            variant="borderless"
            value={selectedSeason}
            onSelect={(value) => {
              if (value === 'manual') {
                dispatch(setManualGamesSelectionSection('statistics'));
                dispatch(setShowGameSelectionModal(true));
              } else {
                dispatch(setSelectedMatchesStats([]));
                dispatch(setSelectedSeason(value));
              }
            }}
            popupMatchSelectWidth={false}
          >
            {seasons.map((season: any, index: number) => (
              <Select.Option
                key={index}
                label={season.value}
                value={season.value}
              >
                {season.label}
              </Select.Option>
            ))}
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderTop: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
              }}
              value={'last10'}
            >
              {t('10 last games')}
            </Select.Option>
            <Select.Option label={'last5'} value={'last5'}>
              {t('5 last games')}
            </Select.Option>
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderTop: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
              }}
              value={'manual'}
            >
              <div>{t('Select matches')}</div>
            </Select.Option>
          </Select>
        </div>
        <div className="block-content">
          <div className="block-column" style={{ minHeight: '298px' }}>
            <div className="block-column-title">
              <div className="team-name">{stats[0]?.teamName}</div>
              <div className="stats-header">{t('per 90 min')}</div>
              <div className="stats-header">{t('Total')}</div>
            </div>
            {stats[0]?.stats.map((statElement: any, index: number) => (
              <div className="block-column-row" key={index}>
                <div className="block-column-ttd">{t(statElement.name)}</div>
                <div
                  className={`block-column-avg ${statElement.average === null ? 'na-stat' : ''}`}
                  style={{ width: 90 }}
                >
                  {statElement.average !== null ? (
                    <>
                      <div>{statElement.average}</div>{' '}
                      <div className="">
                        {' '}
                        {/* {statElement.percent ? (
                          <div className="percent-value">
                            {' '}
                            {statElement.percent}%
                          </div>
                        ) : (
                          ''
                        )} */}
                      </div>
                    </>
                  ) : (
                    t('n/a')
                  )}
                </div>

                <div
                  className={`${statElement.total === null ? 'na-stat' : statElement.total > 0 ? 'block-column-total  enabledClickable' : 'block-column-avg'}`}
                  style={{
                    width: 70,
                  }}
                  onClick={() => {
                    if (statElement.total !== null && statElement.total > 0) {
                      navigate(
                        getStatVideoLink(
                          statElement.key,
                          false,
                          statElement.metric_id,
                        ),
                      );
                    }
                  }}
                >
                  <div>
                    {statElement.total !== null && statElement.total > 0 ? (
                      <div className="block-column-total-icon">
                        <PlayCircle />
                      </div>
                    ) : statElement.total === null ? (
                      <>{t('n/a')}</>
                    ) : (
                      statElement.total
                    )}
                  </div>
                  {statElement.total ? statElement.total : ''}
                </div>
              </div>
            ))}
          </div>
          <div className="block-column" style={{ flex: 1, minHeight: '298px' }}>
            <div className="block-column-title">
              <div className="team-name">{t('Opponents')}</div>
              <div className="stats-header">{t('per 90 min')}</div>
              <div className="stats-header">{t('Total')}</div>
            </div>
            {stats[1]?.stats.map((statElement: any, indexStat: number) => (
              <div className="block-column-row" key={indexStat}>
                <div className="block-column-ttd">{t(statElement.name)}</div>
                <div
                  className={`block-column-avg ${statElement.average === null ? 'na-stat' : ''}`}
                  style={{ width: 90 }}
                >
                  {statElement.average !== null ? (
                    <>
                      <div>{statElement.average}</div>{' '}
                      <div className="">
                        {' '}
                        {/* {statElement.percent ? (
                          <div className="percent-value">
                            {statElement.percent}%
                          </div>
                        ) : (
                          ''
                        )} */}
                      </div>
                    </>
                  ) : (
                    t('n/a')
                  )}
                </div>
                <div
                  className={`${statElement.total === null ? 'na-stat' : statElement.total > 0 ? 'block-column-total  enabledClickable' : 'block-column-avg'}`}
                  style={{
                    width: 70,
                  }}
                  onClick={() => {
                    if (statElement.total !== null && statElement.total > 0) {
                      {
                        const link = getStatVideoLink(
                          statElement.key,
                          true,
                          statElement.metric_id,
                        );
                        localStorage.setItem('openPlayerLink', link);
                        navigate(link);
                      }
                    }
                  }}
                >
                  <div>
                    {statElement.total !== null && statElement.total > 0 ? (
                      <div className="block-column-total-icon">
                        <PlayCircle />
                      </div>
                    ) : statElement.total === null ? (
                      <>{t('n/a')}</>
                    ) : (
                      statElement.total
                    )}
                  </div>
                  {statElement.total ? statElement.total : ''}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {stats.length > 5 && (
        <div
          className="team-header-player flex-row see-more enabledClickable"
          style={{ paddingTop: 8, paddingBottom: 8 }}
        >
          <Chevron
            style={{
              transform: 'rotate(90deg)',
              width: 16,
              height: 16,
              marginLeft: 12,
            }}
          />
          <div
            className={'elipsis-text'}
            style={{ textWrap: 'nowrap', overflow: 'hidden' }}
          >
            {t('Show all stats')}
          </div>
        </div>
      )}
    </div>
  );
};
export default StatisticsBlock;
