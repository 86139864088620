import { createSlice } from '@reduxjs/toolkit';

import { KeyFrameType, ReelFramePositionType } from 'types/types';

type InitialStateType = {
  addKeyframesIsEnabled: boolean;
  reelsModeOn: boolean;
  reelsFormat: string;
  keyframes: KeyFrameType[];
  selectedReelsDurationWarning: boolean;
  reelEditorPosition: ReelFramePositionType | null;
  toggleFrameState: string;
};
const initialState: InitialStateType = {
  addKeyframesIsEnabled: false,
  reelsModeOn: false,
  keyframes: [],
  reelsFormat: '9:16',
  selectedReelsDurationWarning: false,
  reelEditorPosition: null,
  toggleFrameState: '',
};
export const reelsReducer = createSlice({
  name: 'reelsReducer',
  initialState: initialState,
  reducers: {
    setSelectedReelsDurationWarning(state, action) {
      state.selectedReelsDurationWarning = action.payload;
    },
    setToggleFrameState(state, action) {
      state.toggleFrameState = action.payload;
    },
    setAddKeyframesIsEnabled(state, action) {
      state.addKeyframesIsEnabled = action.payload;
    },
    setReelsFormat(state, action) {
      state.reelsFormat = action.payload;
    },
    resetReelsState(state) {
      state.reelsModeOn = false;
      state.keyframes = [];
      state.reelEditorPosition = null;
      state.addKeyframesIsEnabled = false;
    },
    toggleReelsMode(state) {
      state.reelsModeOn = !state.reelsModeOn;
    },
    setReelsMode(state, action) {
      state.reelsModeOn = action.payload;
    },
    setKeyframes(state, action) {
      state.keyframes = action.payload;
    },
    setReelEditorPosition(state, action) {
      state.reelEditorPosition = action.payload;
    },
    insertKeyFrame(state, action) {
      state.keyframes.push(action.payload);
      state.keyframes.sort((a, b) => a.time - b.time);
    },
    updateKeyframe(state, action) {
      const keyframeIndex = state.keyframes.findIndex(
        (keyframe) => keyframe.time === action.payload.time,
      );
      state.keyframes[keyframeIndex] = action.payload;
    },
    removeKeyFrame(state, action) {
      state.keyframes = state.keyframes.filter(
        (frame: KeyFrameType) => frame.time !== action.payload.time,
      );
    },
  },
});
