import React, { FC, useState } from 'react';

import { Divider, Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  AC_SetPlayerCommandAction,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { ReactComponent as NarrowScreenMode } from 'assets/img/icons/faCloseWidescreenMode.svg';
import { ReactComponent as FullScreeExpand } from 'assets/img/icons/faExpandFullScreenIcon.svg';
import { ReactComponent as Reels } from 'assets/img/icons/faReelsIcon.svg';
import { ReactComponent as Scissors } from 'assets/img/icons/faScisorsIcon.svg';
import { ReactComponent as SoundIcon } from 'assets/img/icons/faSoundIcon.svg';
import { ReactComponent as SoundZeroIcon } from 'assets/img/icons/faSoundZeroIcon.svg';
import './index.css';
import VideoSizeSelectorContext from 'components/contexts/VideoSizeSelectorContext';
import VideoSoundContext from 'components/contexts/VideoSoundControllerContext';
import VideoSpeedContext from 'components/contexts/VideoSpeedContext';
import { AppStateType } from 'reducers';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { PlayCommandType } from 'types/types';
import { initTimer } from 'utils/functions';

interface HelperPlayerButtonsInterface {
  isNarrowScreenMode: boolean;
  toggleFullScreen: any;
  playbackRate: any;
  setPlaybackRate: any;
  volume: number;
  setVolume: any;
  setIsMuted: any;
  playerState: any;
}

const HelperPlayerButtons: FC<HelperPlayerButtonsInterface> = ({
  isNarrowScreenMode,
  toggleFullScreen,
  playbackRate,
  setPlaybackRate,
  volume,
  setVolume,
  setIsMuted,
  playerState,
}) => {
  //  const [t] = useTranslation();
  const [dimensions, setDimensions] = useState('FHD');
  const [savedVolume, setSavedVolume] = useState(0.5);
  const setVolumeCallback = (newVolume: number) => {
    setIsMuted(false);
    setVolume(newVolume);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reelsModeOn } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const { toggleWideScreenMode } = playerReducerV2.actions;
  const dispatch = useDispatch();
  const { activeVideo } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { createEpisodeMode, videosListEditMode, playedEpisode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { toggleReelsMode, resetReelsState } = reelsReducer.actions;
  const { setCreateEpisodeMode } = playerReducerV2.actions;

  const {
    setEditedEpisodeRange,
    setEditedEpisodeFileRange,
    setVideoListEditMode,
    resetEditMode,
  } = playerReducerV2.actions;
  const { isLoading } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const turnEditMode = () => {
    const episode = playedEpisode;
    if (episode && episode.episode_type !== 'period') {
      const eventId = episode.id;
      if (!videosListEditMode) {
        const timerStart = initTimer(episode, 'started_at', true, false);
        const timerFinish = initTimer(episode, 'finished_at', true, false);
        const range = [timerStart, timerFinish];

        const fileRange = [
          initTimer(episode, 'started_at', false, true),
          initTimer(episode, 'finished_at', false, true),
        ];
        dispatch(setEditedEpisodeRange(range));
        dispatch(setEditedEpisodeFileRange(fileRange));
        dispatch(setVideoListEditMode(eventId));
        dispatch(AC_SetPlayerCommandAction(PlayCommandType.scrollTo));
      }
    }
  };
  return (
    <div className={'flex-row f-ga-4 ai-c'} style={{ marginRight: '0px' }}>
      {playedEpisode?.episode_type !== 'period' &&
        currentUser?.custom_permissions.includes('can_cut_reels') && (
          <Flex style={{ width: 40 }} align="center">
            <Reels
              width={30}
              height={30}
              style={{ padding: 5 }}
              onClick={() => {
                if (playerState && !isLoading) {
                  dispatch(toggleReelsMode());
                  if (reelsModeOn) {
                    dispatch(resetEditMode());
                    dispatch(AC_SetVisibleRangeAction([]));
                    dispatch(resetReelsState());
                  } else {
                    turnEditMode();
                  }
                  if (createEpisodeMode) {
                    dispatch(setCreateEpisodeMode(false));
                  }
                }
              }}
              className={`enabledClickable ${reelsModeOn ? 'activeButton' : ''}`}
            />
          </Flex>
        )}
      <Flex style={{ width: 40 }}>
        <Scissors
          width={30}
          height={30}
          style={{ padding: 5 }}
          onClick={() => {
            if (
              !(
                activeVideo === null ||
                !!videosListEditMode ||
                createEpisodeMode
              )
            ) {
              dispatch(setCreateEpisodeMode(true));
            } else if (createEpisodeMode) {
              dispatch(setCreateEpisodeMode(false));
              dispatch(resetEditMode());
            }
          }}
          className={`enabledClickable ${createEpisodeMode ? 'activeButton' : ''}`}
        />
      </Flex>
      <Divider
        type="vertical"
        style={{ height: 24, margin: 0, width: 1, background: '#424242' }}
      />
      <VideoSoundContext setVolumeCallback={setVolumeCallback} volume={volume}>
        <div
          onClick={() => {
            if (volume) {
              setSavedVolume(volume);
              setVolume(0);
              setIsMuted(true);
            } else {
              setIsMuted(false);
              setVolume(savedVolume);
            }
          }}
          className={'enabledClickable flex-row j-ce ai-c'}
          style={{ width: 40, height: 40 }}
        >
          {volume > 0 ? <SoundIcon /> : <SoundZeroIcon />}
        </div>
      </VideoSoundContext>
      <VideoSpeedContext speedSetCallback={setPlaybackRate}>
        <div
          className={'enabledClickable helper-button-text flex-row j-ce'}
          style={{ width: 40 }}
        >
          x{playbackRate}
        </div>
      </VideoSpeedContext>
      <VideoSizeSelectorContext videoSizeSetCallable={setDimensions}>
        <div
          className={'enabledClickable helper-button-text flex-row j-ce'}
          style={{ width: 40 }}
        >
          {dimensions}
        </div>
      </VideoSizeSelectorContext>
      <div
        // className={`${false ? 'disabledClickable' : 'enabledClickable'} flex-row j-ce`}
        className={'enabledClickable flex-row j-ce'}
        style={{ width: 40 }}
        onClick={() => {
          toggleFullScreen((prev: boolean) => !prev);
          dispatch(toggleWideScreenMode());
        }}
      >
        {isNarrowScreenMode ? <NarrowScreenMode /> : <FullScreeExpand />}
      </div>
    </div>
  );
};
export default HelperPlayerButtons;
