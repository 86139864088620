import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ClubsAPI } from 'api/clubs';
import Loader from 'components/Loader';
import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';
import dayjs from 'utils/dayjsLocal';

const BestPlayersBlock = () => {
  const {
    selectedMatchesBestPlayers,
    selectedBestPlayerSeason,
    bestPlayerStat,
  } = useSelector((state: AppStateType) => state.teamReducer);
  const {
    setSelectedMatchesBestPlayers,
    setManualGamesSelectionSection,
    setShowGameSelectionModal,
    setSelectedBestPlayerSeason,
    setBestPlayersStat,
  } = teamReducer.actions;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const options = [
    { value: 'goals', label: t('Goals') },
    { value: 'assists', label: t('Assists') },
    { value: 'gp', label: t('Goals + Assists') },
  ];
  const { teamId } = useParams();

  const [seasons, setSeasons] = useState<any>([]);
  const [bestPlayers, setBestPlayers] = useState<any>(null);
  // const [selectedStat, setSelectedStat] = useState<string>('goals');
  const [bestPlayersLoading, setBestPlayersLoading] = useState(false);
  useEffect(() => {
    const getLinup = async () => {
      if (teamId) {
        setBestPlayers(null);
        setSeasons([]);
        const seasonsResponse = await ClubsAPI.getTeamSeasons(teamId, true);
        const seasonsList = seasonsResponse.data;
        if (seasonsList !== undefined) {
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return {
              label: label,
              value: season.id,
              id: season.id,
            };
          });
          setSeasons(s);

          if (selectedBestPlayerSeason === null && s.length) {
            dispatch(setSelectedBestPlayerSeason(s[0]));
            dispatch(setSelectedMatchesBestPlayers([]));
          } else if (s.length === 0) {
            dispatch(setSelectedBestPlayerSeason('last10'));
          }
        }
      }
    };
    getLinup().then();
  }, [teamId]);
  useEffect(() => {
    if (
      teamId !== undefined &&
      selectedBestPlayerSeason &&
      bestPlayerStat !== null &&
      (selectedBestPlayerSeason !== 'manual' ||
        (selectedBestPlayerSeason === 'manual' &&
          selectedMatchesBestPlayers.length > 0))
    ) {
      setBestPlayersLoading(true);
      setBestPlayers(null);
      ClubsAPI.getBestPlayers(
        teamId,
        selectedBestPlayerSeason.id || selectedBestPlayerSeason,
        bestPlayerStat,
        selectedMatchesBestPlayers,
      )
        .then((playersResponse) => {
          if (playersResponse.data !== undefined) {
            setBestPlayers(playersResponse.data.slice(0, 5));
          }
          setBestPlayersLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setBestPlayers(null);
          setBestPlayersLoading(false);
        });
    }
  }, [selectedBestPlayerSeason, bestPlayerStat, selectedMatchesBestPlayers]);

  return (
    <div className="block-wrapper right best-players">
      <div className="block-title">
        <div style={{ textWrap: 'nowrap' }}>{t('Best players')}</div>
        <Select
          defaultValue={bestPlayerStat}
          variant="borderless"
          onChange={(value) => {
            dispatch(setBestPlayersStat(value));
          }}
          className="small-opt-group"
          popupMatchSelectWidth={false}
        >
          {options.map((option: any, index: number) => {
            return (
              <Select.Option
                key={index}
                label={option.label}
                value={option.value}
              >
                {option.label}
              </Select.Option>
            );
          })}
        </Select>
        <Select
          // style={{minWidth: 130}}
          variant="borderless"
          value={selectedBestPlayerSeason}
          onSelect={(value) => {
            if (value === 'manual') {
              dispatch(setManualGamesSelectionSection('bestPlayers'));
              dispatch(setShowGameSelectionModal(true));
            } else {
              dispatch(setSelectedMatchesBestPlayers([]));
              dispatch(setSelectedBestPlayerSeason(value));
            }
          }}
          popupMatchSelectWidth={false}
        >
          {seasons.map((season: any, index: number) => (
            <Select.Option
              key={index}
              label={season.value}
              value={season.value}
            >
              {season.label}
            </Select.Option>
          ))}
          <Select.Option
            style={{
              mpaddingTop: 10,
              borderTop: '0.5px solid rgba(0,0,0,0.1)',
              borderRaius: 0,
            }}
            label={'last10'}
            value={'last10'}
          >
            {t('10 last games')}
          </Select.Option>
          <Select.Option label={'last5'} value={'last5'}>
            {t('5 last games')}
          </Select.Option>
          <Select.Option
            style={{
              mpaddingTop: 10,
              borderTop: '0.5px solid rgba(0,0,0,0.1)',
              borderRaius: 0,
            }}
            label={'manual'}
            value={'manual'}
          >
            {t('Select matches')}
          </Select.Option>
        </Select>
      </div>
      <div className="block-content flex-column" style={{ flex: 1 }}>
        <div
          className={`best-players-wrapper ${(bestPlayers === null || bestPlayers.length === 0) && 'j-ce'}`}
        >
          {bestPlayers?.length === 0 && (
            <div className="best-players-no-data">
              {t(
                'List of top 5 players will be here as soon as the team starts scoring',
              )}
            </div>
          )}
          {bestPlayersLoading && <Loader color="black" />}
          {(bestPlayers === null || bestPlayers === undefined) &&
            !bestPlayersLoading && (
              <div className="best-players-no-data">
                {t(
                  'List of top 5 players will be here as soon as we load the data',
                )}
              </div>
            )}
          {bestPlayers?.map((player: any, index: number) => (
            <div className="best-player-row" key={index}>
              <div
                className="flex-row f-ga-8"
                style={{
                  alignItems: 'center',
                  gap: 8,
                  alignSelf: 'stretch',
                  flex: 1,
                  justifyContent: 'space-between',
                }}
              >
                <div className="best-player-number">{index + 1}</div>
                <div className="best-player-name">{player.name}</div>
                <div className="best-player-value">{player.value}</div>
              </div>
              {index < bestPlayers.length - 1 && (
                <div className="best-player-divider" />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default BestPlayersBlock;
