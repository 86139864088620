import { API_ENDPOINT } from 'types/constants';
import { NewPlayerType } from 'types/types';
import myAxios from 'utils/axios';

import { addParams } from './events';

export const getClubs = async (searchValue?: string) => {
  const url = `${API_ENDPOINT}api/clubs/`;
  let searchParams = {};
  if (searchValue) {
    searchParams = { search: searchValue };
  }
  try {
    return await myAxios.GET(url, searchParams);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getClub = async (clubId: number) => {
  const url = `${API_ENDPOINT}api/clubs/${clubId}/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getClubTeams = async (clubId: number) => {
  const url = `${API_ENDPOINT}api/clubs/${clubId}/teams/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getTeams = async () => {
  const url = `${API_ENDPOINT}api/clubs/teams/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const searchForTeams = async (searchString: string) => {
  const url = `${API_ENDPOINT}api/clubs/teams/`;
  let searchParams = {};
  if (searchString) {
    searchParams = { search: searchString };
  }
  try {
    const response = await myAxios.GET(url, searchParams);
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const searchForPlayers = async (searchString: string) => {
  const url = `${API_ENDPOINT}api/clubs/players/`;
  let searchParams = {};
  if (searchString) {
    searchParams = { search: searchString };
  }
  try {
    const response = await myAxios.GET(url, searchParams);
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const createNewTeam = async (clubId: number, name: string) => {
  const url = `${API_ENDPOINT}api/clubs/${clubId}/teams/`;
  try {
    return await myAxios.POST(url, { name: name });
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const setCurrentUserTeam = async (clubId: number, teamId: number) => {
  const url = `${API_ENDPOINT}api/clubs/${clubId}/teams/${teamId}/set-current/`;
  try {
    return await myAxios.POST(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

/*PLAYERS*/
export const getPlayers = async (params: any, searchValue?: string) => {
  const url = `${API_ENDPOINT}api/clubs/players/`;
  let searchParams = new URLSearchParams();
  if (searchValue) {
    searchParams.append('search', searchValue);
  }
  searchParams = addParams(params, searchParams);
  try {
    return await myAxios.GET(url, searchParams);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getPlayer = async (playerId: string) => {
  const url = `${API_ENDPOINT}api/clubs/players/${playerId}/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const createNewPlayer = async (playerData: NewPlayerType) => {
  const url = `${API_ENDPOINT}api/clubs/players/`;
  try {
    return await myAxios.POST(url, playerData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const updatePlayer = async (
  playerId: string,
  playerData: NewPlayerType,
) => {
  const url = `${API_ENDPOINT}api/clubs/players/${playerId}`;
  try {
    return await myAxios.PATCH(url, playerData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const deletePlayer = async (playerId: string) => {
  const url = `${API_ENDPOINT}api/clubs/players/${playerId}`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getLineup = async (
  teamId: string,
  event_date: any,
  params?: any,
) => {
  const url = `${API_ENDPOINT}api/clubs/teams/${teamId}/lineup/`;
  const searchParams = new URLSearchParams();
  if (event_date) {
    searchParams.append('event_date', event_date);
  }
  if (params) {
    searchParams.append('params', params);
  }
  try {
    return await myAxios.GET(url, searchParams);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getTeam = async (teamId: string) => {
  const url = `${API_ENDPOINT}api/clubs/teams/${teamId}/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getTeamSeasons = async (teamId: string, forStats = false) => {
  const url = `${API_ENDPOINT}api/clubs/teams/${teamId}/seasons/?for_stats=${forStats}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getTeamStats = async (
  teamId: string,
  season: string,
  matches: string[],
) => {
  const url = `${API_ENDPOINT}api/clubs/teams/${teamId}/stats/`;
  const searchParams = new URLSearchParams();
  searchParams.append('season', season);
  const payload: any = {};
  if (matches.length > 0) {
    payload.matches = matches;
  }
  try {
    return await myAxios.POST(url, payload, {}, searchParams);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getBestPlayers = async (
  teamId: string,
  season: string,
  parameter: string,
  matches: string[],
) => {
  const url = `${API_ENDPOINT}api/clubs/teams/${teamId}/best_players/`;
  const searchParams = new URLSearchParams();
  searchParams.append('season', season);
  searchParams.append('parameter', parameter);
  const payload: any = {};
  if (matches.length > 0) {
    payload.matches = matches;
  }
  try {
    return await myAxios.POST(url, payload, {}, searchParams);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const ClubsAPI = {
  getClub,
  getClubs,
  getClubTeams,
  getTeams,
  createNewTeam,
  setCurrentUserTeam,

  searchForTeams,
  searchForPlayers,
  getPlayers,
  getPlayer,
  createNewPlayer,
  updatePlayer,
  deletePlayer,
  getTeamSeasons,
  getTeamStats,
  getLineup,
  getTeam,
  getBestPlayers,
};
