import { ClubsActionsTypes } from 'actions/clubs.actions';
import {
  SET_CURRENT_TEAM_EVENT,
  SET_TEAMS,
  SET_TEAMS_ERROR,
  SET_TEAMS_REQUEST,
} from 'types/action.types';
import { ClubType, TeamType } from 'types/types';

type InitialStateType = {
  error: any;
  teams: Array<TeamType>;
  currentClub: ClubType | null;
  currentTeam: TeamType | null;
  isLoading: boolean;
  loadingActions: string[];
};
const initialState: InitialStateType = {
  error: null,
  teams: [],
  currentClub: null,
  currentTeam: null,
  isLoading: false,
  loadingActions: [],
};
const clubsReducer = (
  state = initialState,
  action: ClubsActionsTypes,
): InitialStateType => {
  switch (action.type) {
    case 'SET_CURRENT_CLUB_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_CURRENT_CLUB':
      return {
        ...state,
        currentClub: action.payload,
      };
    case SET_CURRENT_TEAM_EVENT:
      return {
        ...state,
        currentTeam: action.payload,
      };
    case 'SET_CURRENT_TEAM_EVENT_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case SET_TEAMS_REQUEST:
      return {
        ...state,
        isLoading: true,
        loadingActions: [...state.loadingActions, 'SET_TEAMS'],
      };
    case SET_TEAMS:
      return {
        ...state,
        teams: action.payload,
        isLoading: false,
        loadingActions: [
          ...state.loadingActions.filter((el) => el !== 'SET_TEAMS'),
        ],
      };
    case SET_TEAMS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        loadingActions: [
          ...state.loadingActions.filter((el) => el !== 'SET_TEAMS'),
        ],
      };
    default: {
      return state;
    }
  }
};
export default clubsReducer;
