import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
  ClearViewCalendarEvent,
  SetActiveDateAction,
  SetCalendarEventsAction,
  SetCalendarSideFormModeAction,
  SetEditedCalendarEvent,
} from 'actions/calendar.actions';
import LeftCalendarScroll from 'assets/img/LeftCalendarScroll.svg';
import RightCalendarScroll from 'assets/img/RightCalendarScroll.svg';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { MONTHS } from 'types/constants';
import {
  CalendarDayEventType,
  CalendarSideModeMode,
  DateType,
} from 'types/types';

import CalendarDropDown from './CalendarDropDown';
import DayBlock from './DayBlock';
import EventDetailsModal from './EventDetailsModal';
import EventsListModal from './EventsListModal';

import './index.css';
const CalendarEventsController = () => {
  const d = new Date();
  const todayDate = d.getDate();
  const [currentMonth, setCurrentMonth] = useState<number>(d.getMonth());
  const [currentYear, setCurrentYear] = useState<number>(d.getFullYear());
  const [displayDays, setDisplayDays] = useState<any>([]);
  const { activeDate } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const { currentTeam } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  const daysContainerRef = useRef<any>(null);
  const [dayHeight, setDayHeight] = useState<number>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  useEffect(() => {
    goToToday();
    const t: DateType = {
      month: currentMonth,
      year: currentYear,
      date: new Date().getDate(),
    };
    dispatch(SetActiveDateAction(t));
    populateDays(currentYear, currentMonth);
    currentTeam &&
      dispatch(
        SetCalendarEventsAction(currentTeam.id, currentYear, currentMonth + 1),
      );
  }, []);

  useLayoutEffect(() => {
    currentTeam &&
      dispatch(
        SetCalendarEventsAction(currentTeam.id, currentYear, currentMonth + 1),
      );
  }, [currentTeam, currentYear, currentMonth]);

  useLayoutEffect(() => {
    if (activeDate) {
      const cy = activeDate?.year;
      const cm = activeDate?.month;
      setCurrentYear(cy);
      setCurrentMonth(cm);
      populateDays(cy, cm);
    }
    if (viewedEvent) {
      dispatch(ClearViewCalendarEvent());
    }
  }, [activeDate]);

  const _oneMonthForward = (month: number, year: number) => {
    if (month < 11) {
      return [year, month + 1];
    } else {
      return [year + 1, 0];
    }
  };
  const populateDays = (inputYear: number, inputMonth: number) => {
    const weekDayStart = new Date(inputYear, inputMonth, 1).getDay(); // день недели начала месяца
    const missingStartBase = [6, 0, 1, 2, 3, 4, 5]; // кол-во дней для заполнения начала месяца
    const DAYS: Array<DateType> = []; // дни в календаре
    if (missingStartBase[weekDayStart] > 0) {
      // заполняем дни в начале месяца
      const prevM = dayjs(new Date(inputYear, inputMonth, 1)).subtract(
        1,
        'month',
      );
      const startDate =
        prevM.daysInMonth() - missingStartBase[weekDayStart] + 1;
      for (let i = 0; i < missingStartBase[weekDayStart]; i++) {
        DAYS.push({
          date: startDate + i,
          month: prevM.month(),
          year: prevM.year(),
        });
      }
    }
    let monthLength = 0;
    const lastMonthDay = dayjs(new Date(inputYear, inputMonth, 1));
    for (let i = 1; i <= lastMonthDay.daysInMonth(); i++) {
      DAYS.push({ date: i, month: inputMonth, year: lastMonthDay.year() });
      monthLength += 1;
    }
    const weekDayFinish = new Date(inputYear, inputMonth, monthLength).getDay();
    const missing = [0, 6, 5, 4, 3, 2, 1];
    const missingEndDays = missing[weekDayFinish];
    if (missingEndDays) {
      const [y, m] = _oneMonthForward(inputMonth, inputYear);
      for (let j = 1; j < missingEndDays + 1; j++) {
        DAYS.push({ date: j, month: m, year: y });
      }
    }
    setDisplayDays(DAYS);
  };

  const goToToday = () => {
    const d = new Date();
    setCurrentMonth(d.getMonth());
    setCurrentYear(d.getFullYear());
    populateDays(d.getFullYear(), d.getMonth());
    const newDate: DateType = {
      date: activeDate?.date || 0,
      month: d.getMonth() || 0,
      year: d.getFullYear() || 0,
    };
    dispatch(SetActiveDateAction(newDate));
  };
  const oneMonthBack = () => {
    let cy;
    let cm;
    if (currentMonth > 0) {
      cm = currentMonth - 1;
      setCurrentMonth((prev) => prev - 1);
      cy = currentYear;
    } else {
      cm = 11;
      cy = currentYear - 1;
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
    populateDays(cy, cm);
    const newDate: DateType = {
      date: activeDate?.date || 0,
      month: cm,
      year: cy || 0,
    };
    dispatch(SetActiveDateAction(newDate));
    if (viewedEvent) {
      dispatch(ClearViewCalendarEvent());
    }
  };
  const oneMonthForward = () => {
    let cy;
    let cm;
    if (currentMonth < 11) {
      cm = currentMonth + 1;
      cy = currentYear;
      setCurrentMonth((prev) => prev + 1);
    } else {
      cm = 0;
      cy = currentYear + 1;
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
    populateDays(cy, cm);
    const newDate: DateType = {
      date: activeDate?.date || 0,
      month: cm,
      year: cy || 0,
    };
    dispatch(SetActiveDateAction(newDate));
    if (viewedEvent) {
      dispatch(ClearViewCalendarEvent());
    }
  };
  const todaysMonth = dayjs().month();
  const todaysYear = dayjs().year();

  /* CONTROL EVENTS */
  const dispatch = useAppDispatch();
  const getWeekBackEvent = (date: DateType) => {
    const filteredGame = [...events].filter((evt: CalendarDayEventType) => {
      return (
        evt.date.day === date.date &&
        evt.date.month === date.month &&
        evt.date.year === date.year
      );
    });
    if (filteredGame.length > 0) {
      return { ...filteredGame[0] };
    }
    return null;
  };
  const setClickedDate = (value: DateType, doubleClick: boolean) => {
    if (mode === CalendarSideModeMode.edit) {
      return;
    }
    if (doubleClick) {
      const weekBackDate = dayjs(
        new Date(value.year, value.month, value.date),
      ).subtract(7, 'days');
      const weekBackEvent = getWeekBackEvent({
        date: weekBackDate.date(),
        month: weekBackDate.month(),
        year: weekBackDate.year(),
      });
      if (weekBackEvent) {
        const previousWeekEvent = {
          ...weekBackEvent,
          date: {
            ...weekBackEvent.date,
            day: value.date,
            month: value.month,
            year: value.year,
          },
        };
        dispatch(SetEditedCalendarEvent(previousWeekEvent));
      }
    }
    mode !== CalendarSideModeMode.create &&
      dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.create));
    dispatch(SetActiveDateAction(value));
    dispatch(ClearViewCalendarEvent());
  };

  useLayoutEffect(() => {
    if (displayDays?.length > 0) {
      const height = daysContainerRef?.current?.clientHeight;
      setDayHeight(height ? height / Math.ceil(displayDays.length / 7) : 100);
    }
  }, [displayDays]);
  const { events, viewedEvent, mode } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [openTT, setOpenTT] = useState(false);

  useLayoutEffect(() => {
    setOpenTT(viewedEvent !== null);
  }, [viewedEvent]);
  /* END CONTROL EVENTS */
  const [showCalendarDD, setShowCalendarDD] = useState(false);
  const [modalEvents, setModalEvents] = useState([]);
  const [referencedDay, setReferencedDay] = useState();
  const [t] = useTranslation();
  const { language } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  useEffect(() => {
    console.log();
  }, [language]);
  return (
    <div className="contentContainer">
      {/* <CalendarFilters /> */}
      <div className="calendar">
        <div className="monthSelector">
          <div onClick={() => oneMonthBack()}>
            <img src={LeftCalendarScroll} />
          </div>
          <div
            className="currentMonth"
            onClick={() => setShowCalendarDD(!showCalendarDD)}
          >
            {t(MONTHS[currentMonth])} {currentYear}
          </div>
          <div onClick={() => oneMonthForward()}>
            <img src={RightCalendarScroll} />
          </div>
          <div
            className={'enabledClickable'}
            style={{ color: '#307FF4', fontSize: 16 }}
            onClick={() => goToToday()}
          >
            {t('Today')}
          </div>
        </div>
        <div className="weekDays">
          <div>{t('Mo')}</div>
          <div>{t('Tu')}</div>
          <div>{t('We')}</div>
          <div>{t('Th')}</div>
          <div>{t('Fr')}</div>
          <div>{t('Sa')}</div>
          <div>{t('Su')}</div>
        </div>
        <div className="daysContainer" ref={daysContainerRef}>
          {displayDays?.map((day: any, index: number) => {
            return (
              <DayBlock
                key={index}
                day={day}
                weeksShown={displayDays.length / 7}
                currentMonth={currentMonth}
                actualMonth={todaysMonth}
                actualYear={todaysYear}
                dayHeight={dayHeight}
                todayDate={todayDate}
                weeks={Math.ceil(displayDays / 7)}
                onClick={(e: any) => {
                  if (e.detail === 2) {
                    setClickedDate(day, true);
                  } else {
                    setClickedDate(day, false);
                  }
                }}
                setModalEvents={setModalEvents}
                clearModalEvents={() =>
                  modalEvents.length > 0 && setModalEvents([])
                }
                setReferencedDay={setReferencedDay}
              />
            );
          })}
        </div>
        <EventDetailsModal
          closeCallback={() => {
            setModalEvents([]);
          }}
        />
        <Tooltip
          id={'evtTooltipName'}
          noArrow={true}
          className={'advancedTooltip'}
        />
        {modalEvents.length > 0 && (
          <EventsListModal
            setModalEvents={setModalEvents}
            modalEvents={modalEvents}
            referencedDay={referencedDay}
          />
        )}
        {showCalendarDD && (
          <CalendarDropDown
            callBack={() => setShowCalendarDD(!showCalendarDD)}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            callBackNoCollapse={() => {}}
          />
        )}
      </div>
    </div>
  );
};
export default CalendarEventsController;
