import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { GetCurrentUserAction, UpdateUserAction } from 'actions/auth.actions';
import { SetCurrentUserTeamAction } from 'actions/clubs.actions';
import { ClubsAPI } from 'api/clubs';
import SelectDownArrowBlue from 'assets/img/SelectDownArrowBlue.svg';
import SelectUpArrowBlue from 'assets/img/SelectUpArrowBlue.svg';
import TeamIconBlue from 'assets/img/TeamIconBlue.svg';
import TeamIconGrey from 'assets/img/TeamIconGrey.svg';
import './index.css';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { TeamType } from 'types/types';

import InputWithClear from '../InputWithClear';
import WarningModal from '../modals/WarningModal';

const TeamSelector = () => {
  const { currentClub, currentTeam } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  const { language } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [existingTeams, setExistingTeams] = useState<Array<TeamType>>([]);
  const [currentUserTeams, setCurrentUserTeams] = useState<Array<TeamType>>([]);
  const [currentUserTeamsIds, setCurrentUserTeamsIds] = useState<Array<number>>(
    [],
  );
  const [currentSelectedTeam, setCurrentSelectedTeam] =
    useState<TeamType | null>();
  const [selectNewTeam, setSelectNewTeam] = useState(false);
  const [showTeamDropDownSelector, setShowTeamDropDownSelector] =
    useState(false);
  const [newAddedTeam, setNewAddedTeam] = useState<string>('');
  const [warningVisible, setWarningVisible] = useState<boolean>(false);
  const selectorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const teamAddInputRef = useRef<any>(null);
  // useEffect(() => {
  //     if (currentUser && currentClub) {
  //         const userTeams = currentClub?.teams.filter((team: TeamType) => currentUser?.teams.includes(team.id) && team.id === currentUser.current_team);
  //         console.log('userteams', userTeams)
  //         if (userTeams?.length > 0){
  //             dispatch(SetCurrentUserTeamAction(userTeams[0]))
  //             // setCurrentSelectedTeam(userTeams[0]);
  //         }
  //     }
  // }, [])
  useEffect(() => {
    dispatch(GetCurrentUserAction());
  }, [language]);
  useEffect(() => {
    const ids: Array<number> = currentUserTeams.map((team) => team.id);
    if (ids.length) {
      setCurrentUserTeamsIds(ids);
    }
  }, [currentUserTeams]);

  useEffect(() => {
    setExistingTeams(currentClub?.teams || []);
    setCurrentUserTeamsIds(currentUser?.teams || []);
    currentClub &&
      setCurrentUserTeams(
        currentClub?.teams.filter((team: TeamType) =>
          currentUser?.teams.includes(team.id),
        ),
      );
  }, [currentClub]);

  useEffect(() => {
    setCurrentSelectedTeam(currentTeam);
  }, [currentTeam]);

  const updateCurrentUserTeams = (event: any, team: TeamType) => {
    if (event.target.checked) {
      setCurrentUserTeams((prev) => [...prev, team]);
    } else {
      setCurrentUserTeams((prev) =>
        Array.from(prev.filter((t) => t.id !== team.id)),
      );
    }
  };
  const saveTeamsSelection = async () => {
    // Тут сохраняем выбранные команды
    if (currentClub) {
      let newCreatedTeam: TeamType | undefined = undefined;
      if (newAddedTeam) {
        const newTeamResponse = await ClubsAPI.createNewTeam(
          currentClub.id,
          newAddedTeam,
        );
        newCreatedTeam = newTeamResponse.data;
        // if (selectNewTeam) {
        //     setCurrentSelectedTeam(prev => newCreatedTeam);
        //     setSelectNewTeam(false);
        // }
        setSelectNewTeam(false);
        setNewAddedTeam('');
      }
      const teamsCheckboxes = document.querySelectorAll(
        'input[name=teams]:checked',
      );
      let newUserTeamsIds = Array.from(teamsCheckboxes).map(
        (teamCheckbox: any) => {
          return Number(teamCheckbox.value);
        },
      );
      if (newCreatedTeam !== undefined && selectNewTeam) {
        newUserTeamsIds = [...newUserTeamsIds, newCreatedTeam.id];
      }
      currentUser &&
        dispatch(
          UpdateUserAction(currentUser?.id, {
            id: currentUser?.id,
            teams: newUserTeamsIds,
          }),
        );
      setModalIsOpen(false);
    } else {
      setWarningVisible(true);
      setTimeout(() => {
        setWarningVisible(false);
      }, 2000);
    }
  };
  const updatedSelectedTeam = (value: TeamType) => {
    dispatch(SetCurrentUserTeamAction(value));
  };
  const [t] = useTranslation();
  const additionalProps = (text: string | undefined) => {
    if (text && text.length > 19) {
      return {
        'data-tooltip-id': 'currentTeamNameTooltip',
        'data-tooltip-content': text,
        'data-tooltip-place': 'bottom',
        'data-tooltip-delay-hide': 1000,
      };
    }
  };
  return (
    <>
      <Tooltip
        variant={'dark'}
        id={'currentTeamNameTooltip'}
        noArrow={false}
        className={'videoAttributesTooltip'}
        place={'bottom-end'}
        style={{ zIndex: 100 }}
      />
      {existingTeams.length === 0 && !currentSelectedTeam ? (
        <div
          onClick={() => setModalIsOpen(true)}
          className="activeTeamSwitchContainer"
        >
          + {t('Select team')}
        </div>
      ) : (
        <div
          className={'activeTeamSelector'}
          ref={selectorRef}
          onClick={() => setShowTeamDropDownSelector((prev) => !prev)}
        >
          <div>
            <div
              {...(additionalProps(currentSelectedTeam?.name) as {
                [key: string]: string | number;
              })}
            >
              <img src={TeamIconBlue} />
              <span className={'activeTeamSelectorSpan elipsis-text'}>
                {currentSelectedTeam?.name}
              </span>
            </div>
            <img src={SelectDownArrowBlue} />
          </div>
        </div>
      )}
      {showTeamDropDownSelector && (
        <div
          className={'activeTeamSelector opened'}
          onClick={() => setShowTeamDropDownSelector((prev) => !prev)}
          style={{
            width: `${selectorRef.current?.offsetWidth - 34}px` || 'unset',
            top: `${selectorRef.current.offsetTop}px`,
          }}
        >
          <div>
            <div>
              <img src={TeamIconBlue} />
              <span className={'activeTeamSelectorSpan elipsis-text'}>
                {currentSelectedTeam?.name}
              </span>
            </div>
            <img src={SelectUpArrowBlue} />
          </div>
          {currentUserTeams.length > 0 && (
            <div className={'teamsSelectionList'}>
              {currentUserTeams
                .filter((team) => team.id !== currentSelectedTeam?.id)
                .map((filteredTeam, index) => {
                  return (
                    <div
                      key={index}
                      {...(additionalProps(filteredTeam?.name) as {
                        [key: string]: string | number;
                      })}
                      onClick={() => updatedSelectedTeam(filteredTeam)}
                    >
                      <img src={TeamIconGrey} />
                      <span className={'elipsis-text'}>
                        {filteredTeam?.name}
                      </span>
                    </div>
                  );
                })}
            </div>
          )}
          <div style={{ width: '100%' }} onClick={() => setModalIsOpen(true)}>
            + {t('Add new team')}
          </div>
        </div>
      )}
      <ReactModal
        // style={{overlay: {display: "flex"}}}
        overlayClassName="modalOverlay"
        className="teamsAddModal"
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen((prev) => !prev);
        }}
        shouldCloseOnEsc={true}
      >
        <div className="modalTitle">{t('Select a team or add new')}</div>
        <div className="teamsListContainer">
          {existingTeams.map((team, index) => {
            return (
              <div key={index}>
                <input
                  type="checkbox"
                  value={team.id}
                  defaultChecked={currentUserTeamsIds.includes(team.id)}
                  onClick={(event) => updateCurrentUserTeams(event, team)}
                  name="teams"
                  id={`team-${team.id}`}
                />
                <label
                  className={'enabledClickable'}
                  htmlFor={`team-${team.id}`}
                  style={{
                    color: `${currentUserTeamsIds.includes(team.id) ? '#212121' : ''}`,
                  }}
                >
                  {team.name}
                </label>
              </div>
            );
          })}
        </div>
        <InputWithClear
          ref={teamAddInputRef}
          placeholder={t('Enter team name')}
          fieldType={'text'}
          fieldName={'newTeam'}
          isRequired={false}
          valueController={{ value: newAddedTeam, setValue: setNewAddedTeam }}
          maxLength={500}
        />
        <input
          type="checkbox"
          name="newSelected"
          defaultChecked={selectNewTeam}
          onChange={() => {
            setSelectNewTeam((prev) => !prev);
          }}
          className="newTeamSelected"
        />
        <div className="modalControlButtons">
          <div onClick={() => setModalIsOpen(false)}>Cancel</div>
          <div onClick={saveTeamsSelection}>Apply</div>
        </div>
        {warningVisible && (
          <WarningModal
            title={t(
              'You do not have active club set. Please contact technical support.',
            )}
            referencedField={teamAddInputRef}
          />
        )}
      </ReactModal>
    </>
  );
};
export default TeamSelector;
