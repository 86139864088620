import React, { FC, useEffect, useLayoutEffect, useState } from 'react';

import './index.css';
import { Button, ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AC_OpenFilterAction } from '../../../../actions/filter.actions';
import { ReactComponent as IconPlayCircleTwoOne } from '../../../../assets/img/icons/faIconPlayCircleTwoOne.svg';
import { AppStateType } from '../../../../reducers';
import { useAppDispatch } from '../../../../store';
import { EpisodeFilterPanelTypes, MenuItemType } from '../../../../types/types';
import { getItem, getValueFromString } from '../../../../utils/functions';
import CustomTab from '../../../Library/Tab';

interface Interface {
  gap: number;
  closeCallback: any;
  backCallback?: any;
  clearCallback: any;
  applyCallback: any;
  nextCallback?: any;
  applyCount: number;
  children: any;
  type: EpisodeFilterPanelTypes;
  allowGrow?: boolean;
  preFilterLoading: boolean;
}
const FiltersContainer: FC<Interface> = ({
  // gap,
  // closeCallback,
  // backCallback,
  applyCallback,
  clearCallback,
  nextCallback,
  applyCount,
  children,
  type,
  // allowGrow = false,
  preFilterLoading,
}) => {
  const {
    activeFilter,
    selectedPlayers,
    selectedAttributes,
    selectedGames,
    showMyEpisdes,
    selectedTimingIntervals,
  } = useSelector((state: AppStateType) => state.filtersReducer);
  const [t] = useTranslation();
  const MATCHES_OTHER = 'matches-other';
  const EPISODES_OTHER = 'episodes-other';

  const getMatchWord = (num: string) => {
    const lastChar = num.slice(-1);
    if (lastChar === '1') {
      if (num.endsWith('11')) {
        return MATCHES_OTHER;
      } else {
        return 'matches-1';
      }
    } else if (lastChar === '2' || lastChar === '3' || lastChar === '4') {
      if (num.endsWith('12') || num.endsWith('13') || num.endsWith('14')) {
        return MATCHES_OTHER;
      } else {
        return 'matches-2';
      }
    }
    return MATCHES_OTHER;
  };
  const getEpisodeWord = (num: string) => {
    const lastChar = num.slice(-1);
    if (lastChar === '1') {
      if (num.endsWith('11')) {
        return EPISODES_OTHER;
      } else {
        return 'episodes-1';
      }
    } else if (lastChar === '2' || lastChar === '3' || lastChar === '4') {
      if (num.endsWith('12') || num.endsWith('13') || num.endsWith('14')) {
        return EPISODES_OTHER;
      } else {
        return 'episodes-2';
      }
    }
    return EPISODES_OTHER;
  };
  const applyText = () => {
    let text = 'Watch';
    if (
      applyCount !== null &&
      applyCount > 0 &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0)
    ) {
      text =
        t('Watch') +
        ' ' +
        applyCount +
        ' ' +
        t(getEpisodeWord(applyCount.toString()));
    } else if (selectedGames.length > 0) {
      text =
        t('Watch') +
        ' ' +
        selectedGames.length +
        ' ' +
        t(getMatchWord(selectedGames.length.toString()));
    }
    return t(text);
  };
  const [matchesSummaryText, setMatchesSummaryText] = useState(applyText());
  useEffect(() => {
    setMatchesSummaryText(applyText());
  }, [selectedGames, applyCount]);

  const items: MenuItemType[] = [
    getItem(
      EpisodeFilterPanelTypes.players,
      `${t(EpisodeFilterPanelTypes.players)} ${selectedPlayers.length > 0 ? ` (${selectedPlayers?.length})` : ''}`,
      <></>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'label',
      <>{t('Players')}</>,
      {},
      'filterButton',
    ),
    getItem(
      EpisodeFilterPanelTypes.matches,
      `${t(EpisodeFilterPanelTypes.matches)}${selectedGames.length > 0 ? ` (${selectedGames.length})` : ''}`,
      <></>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'label',
      <>{t('Matches')}</>,
      {},
      'filterButton',
    ),
    getItem(
      EpisodeFilterPanelTypes.metrics,
      `${t(EpisodeFilterPanelTypes.metrics)}${selectedAttributes.length + Number(showMyEpisdes) > 0 ? ` (${selectedAttributes.length + Number(showMyEpisdes)})` : ''}`,
      <></>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'label',
      <>{t('Events')}</>,
      {},
      'filterButton',
    ),
    getItem(
      EpisodeFilterPanelTypes.timing,
      `${t(EpisodeFilterPanelTypes.timing)}${Object.keys(selectedTimingIntervals).length > 0 ? ` (${Object.keys(selectedTimingIntervals).length})` : ''}`,
      <></>,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      'label',
      <>{t('Time')}</>,
      {},
      'filterButton',
    ),
  ];
  const dispatch = useAppDispatch();
  const setActiveTab = (tabKey: string | undefined) => {
    dispatch(
      AC_OpenFilterAction(getValueFromString(tabKey, EpisodeFilterPanelTypes)),
    );
  };

  const getNextButton = (nextCallback: any) => {
    if (activeFilter === EpisodeFilterPanelTypes.players) {
      return (
        <Button
          style={{ width: '125px' }}
          type="primary"
          onClick={() => nextCallback()}
        >
          {t('Next filter')}
        </Button>
      );
    } else {
      return (
        <ConfigProvider
          theme={{
            components: {
              Button: {
                defaultBg: 'transparent',
                defaultBorderColor: 'rgba(255, 255, 255, 0.65)',
                borderRadius: 8,
                defaultHoverBg: 'transparent',
                defaultActiveBg: 'transparent',
              },
            },
          }}
        >
          <Button
            style={{ width: '125px', color: 'var(--colorText)' }}
            onClick={() => nextCallback()}
          >
            {t('Next filter')}
          </Button>
        </ConfigProvider>
      );
    }
  };

  const getIsDisabled = () => {
    // console.log(
    //   !preFilterLoading,
    //   applyCount,
    //   selectedAttributes.length,
    //   selectedGames.length,
    //   Object.keys(selectedTimingIntervals).length,
    // );

    if (
      (applyCount === 0 || applyCount === null) &&
      selectedGames.length === 0
    ) {
      return true;
    }
    if (
      selectedGames.length > 0 &&
      selectedAttributes.length === 0 &&
      Object.keys(selectedTimingIntervals).length === 0
    ) {
      return false;
    }
    if (
      selectedGames.length === 0 &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0) &&
      applyCount > 0
    ) {
      return false;
    }
    if (
      selectedGames.length > 0 &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0) &&
      applyCount === 0
    ) {
      return true;
    }
    return !(
      selectedGames.length > 0 &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0) &&
      applyCount > 0
    );
  };
  const [isDisabled, setIsdisabled] = useState(false);
  useLayoutEffect(() => {
    setIsdisabled(getIsDisabled());
  }, [preFilterLoading, applyCount, selectedAttributes, selectedGames]);
  return (
    <div
      className={`filterContainer ${type} flex-column j-sb`}
      style={{ top: '45px' }}
      key={type}
    >
      <div className="filters_horizontal_menu">
        {items.map((item, index) => {
          return (
            <CustomTab
              key={index}
              //@ts-ignore
              type={item.data || ''}
              //@ts-ignore
              text={item.text}
              onClick={() => setActiveTab(item?.key?.toString())}
              //@ts-ignore
              chosen={activeFilter === item?.key}
              textAlign={'left'}
              selfAlign="horizontal"
            />
          );
        })}
      </div>
      {children}
      <div className={'bottomFilterController w-100'}>
        <Button
          style={{ color: 'var(--colorText)' }}
          onClick={() => clearCallback(type)}
          type="text"
        >
          {t('Clear')}
        </Button>
        <div className={'flex-row  f-ga-8'}>
          {nextCallback ? getNextButton(nextCallback) : <></>}
          <ConfigProvider
            theme={{
              token: {
                colorBorder: 'transparent',
                colorBgContainerDisabled: 'var(--colorBgContainerDisabled)',
                borderRadius: 8,
              },
            }}
          >
            <Button
              className={'enabledClickable filtersActive'}
              icon={<IconPlayCircleTwoOne />}
              onClick={applyCallback}
              disabled={isDisabled}
              style={{ width: '220px' }}
              type="primary"
            >
              {matchesSummaryText}
            </Button>
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};
export default FiltersContainer;
