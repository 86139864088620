import React, { FC } from 'react';

import './index.css';
import { useSelector } from 'react-redux';

import { AC_SetVideoTimeZoomAction } from 'actions/player.acitons';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { VIDEO_TIME_MAX_ZOOM } from 'types/constants';

const VideoTimelineZoomControl: FC<any> = () => {
  const dispatch = useAppDispatch();
  const { videoTimeZoom } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const handleZoom = (newValue: string) => {
    dispatch(AC_SetVideoTimeZoomAction(Number(newValue)));
  };
  return (
    <div className={'flex-row f-ga-24'} style={{ marginRight: '10px' }}>
      <input
        type={'range'}
        min={0}
        max={VIDEO_TIME_MAX_ZOOM}
        value={videoTimeZoom}
        onChange={(e) => handleZoom(e.target.value)}
        className={'zoomerBar'}
      />
    </div>
  );
};
export default VideoTimelineZoomControl;
