import { API_ENDPOINT } from 'types/constants';
import myAxios from 'utils/axios';

export const getPlayerSeasons = async (playerId: string, forStats = false) => {
  const url = `${API_ENDPOINT}api/clubs/players/${playerId}/seasons/?for_stats=${forStats}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getPlayerRoles = async (playerId: string) => {
  const url = `${API_ENDPOINT}api/data/placements/player_roles/?player_id=${playerId}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const PlayersAPI = {
  getPlayerSeasons,
  getPlayerRoles,
};
