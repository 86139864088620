import React, { useEffect, useMemo } from 'react';
import './index.css';

import { Button, ConfigProvider, Flex, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import Context from 'react-redux/es/components/Context';

import { ReactComponent as CloseCrossIcon } from '../../assets/img/icons/faBigCloseCross.svg';

const DownloadMassActionsController = ({
  selectedDownloads,
  massDeleteCallback,
  massDownloadCallback,
}: {
  selectedDownloads: React.Key[];
  massDeleteCallback: any;
  massDownloadCallback: any;
}) => {
  const [t] = useTranslation();
  const [api, contextHolder] = notification.useNotification({
    stack: false,
    bottom: 0,
  });
  const deleteCallable = () => {
    massDeleteCallback(selectedDownloads);
    api.destroy();
  };
  const donwloadCallable = () => {
    massDownloadCallback(selectedDownloads);
  };
  const openNotification = () => {
    api.info({
      message: '',
      key: 'downloads',
      duration: 0,
      className: 'link-create-notification',
      description: (
        <div className="flex-row ai-c f-ga-8 mass-download-modal">
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  // defaultBg: 'var(--colorPrimaryActive, rgba(21, 84, 173, 1))',
                  colorText: 'var(--white)',
                  colorBorder: 'var(--colorBorder)',
                  // defaultHoverBg: 'var(--colorBgContainer)',
                  // defaultActiveBg:
                  //   'var(--colorPrimaryActive, rgba(21, 84, 173, 1))',
                  paddingBlockLG: 12,
                },
              },
            }}
          >
            <Button type="text" onClick={() => deleteCallable()} block>
              {t('Delete')}
            </Button>
          </ConfigProvider>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  colorText: 'var(--white)',
                  colorBorder: 'var(--colorBorder)',
                  // defaultHoverBg: '#rgba(60, 137, 232, 1)',
                  paddingBlockLG: 12,
                },
              },
            }}
          >
            <Button
              type="primary"
              className="primary-button"
              onClick={() => donwloadCallable()}
              block
            >
              {t('Download')}
            </Button>
          </ConfigProvider>
          <Flex
            align="center"
            justify="center"
            onClick={() => api.destroy()}
            style={{ minWidth: 32, height: 32 }}
          >
            <CloseCrossIcon className="white-icon enabledClickable" />
          </Flex>
        </div>
      ),
      placement: 'bottomRight',
      icon: <></>,
      style: {
        padding: 12,
        background: 'var(--colorLink, rgba(22, 104, 220, 1))',
        borderRadius: '8px',
        marginInlineStart: 0,
      },
      closeIcon: null,
      onClose: () => api.destroy(),
    });
  };
  useEffect(() => {
    if (selectedDownloads.length > 0) {
      openNotification();
    } else {
      api.destroy();
    }
  }, [selectedDownloads]);
  const contextValue = useMemo(() => <></>, []);
  return (
    <Context.Provider
      //@ts-ignore
      value={contextValue}
    >
      <ConfigProvider
        theme={{
          token: {
            marginLG: 16,
          },
        }}
      >
        {contextHolder}
      </ConfigProvider>
    </Context.Provider>
  );
};
export default DownloadMassActionsController;
