import { EquipmentActionsTypes } from 'actions/equipment.actions';
import { filterAndIndex } from 'types/functions';
import { CameraType } from 'types/types';

type InitialStateType = {
  isLoading: boolean;
  error: null;
  cameras: Array<CameraType>;
  currentCamera: CameraType | null;
};
const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  cameras: [],
  currentCamera: null,
};

const equipmentReducer = (
  state = initialState,
  action: EquipmentActionsTypes,
): InitialStateType => {
  switch (action.type) {
    case 'GET_CAMERAS_LIST_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'GET_CAMERAS_LIST_REQUEST_SUCCESS':
      return {
        ...state,
        cameras: action.payload,
        isLoading: false,
      };
    case 'GET_CAMERAS_LIST_REQUEST_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case 'UPDATE_CAMERA_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'UPDATE_CAMERA_REQUEST_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'UPDATE_CAMERA_REQUEST_SUCCESS':
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const { element, index } = filterAndIndex(
        state.cameras,
        action.payload.id,
        'id',
      );
      return {
        ...state,
        isLoading: false,
        cameras: [
          ...state.cameras.slice(0, index),
          { ...action.payload },
          ...state.cameras.slice(index + 1),
        ],
        currentCamera:
          state.currentCamera?.id === action.payload.id
            ? { ...action.payload }
            : state.currentCamera,
      };
    case 'DELETE_CAMERA_REQUEST':
      return {
        ...state,
        isLoading: true,
      };
    case 'DELETE_CAMERA_REQUEST_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'DELETE_CAMERA_REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        cameras: [
          ...state.cameras.filter((camera) => camera.id != action.payload),
        ],
        currentCamera:
          state.currentCamera?.id !== action.payload
            ? state.currentCamera
            : null,
      };
    case 'SET_CURRENT_CAMERA':
      return {
        ...state,
        currentCamera: action.payload,
      };
    default:
      return state;
  }
};
export default equipmentReducer;
