import React, { useEffect, useState } from 'react';

import { Button, ConfigProvider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PlaylistsAPI } from 'api/playlists';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { ReactComponent as ElipsisVertical } from 'assets/img/icons/faElipsisV.svg';
import { ReactComponent as FolderIcon } from 'assets/img/icons/faFolderIcon.svg';
import './index.css';
import { AppStateType } from 'reducers';

const MoveToFolderModal = ({
  isOpen,
  handleOk,
  closeCallable,
  folderId,
  type,
}: {
  isOpen: boolean;
  handleOk: any;
  closeCallable: any;
  folderId: string | null;
  type: string;
}) => {
  const [t] = useTranslation();
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const [selectedFolder, setSelectedFolder] = useState<string | null>('');
  const [rootPlaylistsCount, setRootPlaylistsCount] = useState(0);
  const [folders, setFolders] = useState([]);
  useEffect(() => {
    const call = async () => {
      const response = await PlaylistsAPI.getFolders(
        null,
        null,
        currentUser?.playlist_sorting,
      );
      setFolders(response.data);
      const rootPlaylists = await PlaylistsAPI.getPlaylists({
        root_folder: true,
        sorting: currentUser?.playlist_sorting,
      });
      setRootPlaylistsCount(rootPlaylists.data.length);
    };
    if (isOpen) {
      call().then();
    }
  }, [isOpen]);
  const close = () => {
    setSelectedFolder('');
    closeCallable();
  };
  const submit = () => {
    handleOk(selectedFolder);
    setSelectedFolder('');
  };

  const FolderNode = ({
    folder,
    folders,
  }: {
    folder: any;
    folders: any;
    type: string;
  }) => {
    return (
      <>
        <div
          className={`enabledClickable flex-row ai-c f-ga-4 ${selectedFolder === folder.id ? 'selected-folder-to-move' : ''}`}
          style={{ height: 32, marginLeft: 28 * folder.level, padding: 0 }}
          onClick={() => setSelectedFolder(folder.id)}
        >
          <FolderIcon width={32} />
          <div style={{ flex: 1 }} className="folder-name">
            {folder.name}
          </div>
          <div className="folder-playlist-count">
            {folder.playlist_count ? folder.playlist_count : ''}
          </div>
          <ElipsisVertical width={24} />
        </div>
        {folders
          .filter((el: any) => {
            if (type === 'folder') {
              return el.parent === folder.id && el.id !== folderId;
            } else {
              return el.parent === folder.id;
            }
          })
          .map((el: any) => {
            return <FolderNode folder={el} folders={folders} type={type} />;
          })}
      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'var(--colorBgLayout)',
            headerBg: 'var(--colorBgLayout)',
            titleColor: 'var(--colorText)',
            colorText: 'var(--colorText)',
            paddingContentHorizontal: 12,
          },
        },
      }}
    >
      <Modal
        title={t('Move to folder')}
        open={isOpen}
        closable
        width={360}
        style={{ maxHeight: '400px' }}
        styles={{
          content: {
            width: 360,
            borderRadius: 18,
          },
        }}
        closeIcon={<CloseCross />}
        onCancel={close}
        footer={[
          <div className="flex-row f-ga-8">
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    defaultBg: 'var(--colorBgContainer)',
                    colorText: 'var(--colorText)',
                    colorBorder: 'var(--colorBorder)',
                    defaultHoverBg: 'var(--colorBgContainer)',
                    defaultActiveBg: 'var(--colorBgContainer)',
                  },
                },
              }}
            >
              <Button style={{ flex: 1 }} type="default" onClick={close}>
                {t('Cancel')}
              </Button>
            </ConfigProvider>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorTextDisabled: 'var(--colorTextDisabled)',
                    borderColorDisabled: 'var(--colorBorder)',
                  },
                },
              }}
            >
              <Button
                style={{ flex: 1 }}
                disabled={selectedFolder === ''}
                type="primary"
                onClick={submit}
              >
                {t('Move')}
              </Button>
            </ConfigProvider>
          </div>,
        ]}
      >
        <div
          className={`enabledClickable flex-row ai-c ${selectedFolder === null ? 'selected-folder-to-move' : ''}`}
          onClick={() => setSelectedFolder(null)}
          style={{ padding: '5px 0px 5px 8px', marginLeft: -8 }}
        >
          <div style={{ flex: 1 }}>{t('Move outside folders')}</div>
          <div className="folder-playlist-count">{rootPlaylistsCount}</div>
          <ElipsisVertical width={24} />
        </div>
        <div
          style={{
            width: '360px',
            height: '1px',
            margin: '4px 0 4px -24px',
            background: 'rgba(255, 255, 255, 0.08)',
            overflow: 'auto',
          }}
        ></div>
        <div style={{ maxHeight: 224, overflow: 'auto' }}>
          {folders
            .filter((folder: any) => {
              if (type === 'folder') {
                return folder.level === 0 && folder.id !== folderId;
              } else {
                return folder.level === 0;
              }
            })
            .map((rootFolder: any) => {
              return (
                <FolderNode folder={rootFolder} folders={folders} type={type} />
              );
            })}
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default MoveToFolderModal;
