import React from 'react';

import { Divider, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { protocolReducer } from 'reducers/protocol.reducer';

import AtyrauLogo from '../../assets/AtyrauLogo.png';
import { ReactComponent as CloseCrossIcon } from '../../assets/img/icons/faCloseCross.svg';
import { ReactComponent as GoalSmall } from '../../assets/img/icons/faGoalSmall.svg';
import { ReactComponent as RedCardSmall } from '../../assets/img/icons/faRedCardSmall.svg';
import { ReactComponent as SubstitutionIcon } from '../../assets/img/icons/faSubstitution.svg';
import { ReactComponent as YellowCardSmall } from '../../assets/img/icons/faYellowCardSmall.svg';
import KairatLogo from '../../assets/KairatLogo.png';
import { AppStateType } from '../../reducers';
import { useAppDispatch } from '../../store';
import './index.css';
const GameProtocolSidePanel = () => {
  const { protocolGameID } = useSelector(
    (state: AppStateType) => state.protocolReducer,
  );
  const dispatch = useAppDispatch();
  const { openGameProtocol } = protocolReducer.actions;
  const onClose = () => {
    dispatch(openGameProtocol(''));
  };
  const gameProtocol = {
    homeTeam: {
      name: 'Kairat',
      logo: KairatLogo,
      score: 2,
      main: [
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
            {
              type: 'substitution',
              time: 32,
              player: {
                number: 11,
                first_name: 'Name',
                last_name: 'Last',
              },
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'red_card',
              time: 11,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'goal',
              time: 12,
            },
          ],
        },
      ],
      substitution: [
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
            {
              type: 'substitution',
              time: 32,
              player: {
                number: 11,
                first_name: 'Name',
                last_name: 'Last',
              },
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'red_card',
              time: 32,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'goal',
              time: 12,
            },
          ],
        },
      ],
    },
    awayTeam: {
      name: 'Atyrau',
      logo: AtyrauLogo,
      score: 3,
      main: [
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
            {
              type: 'substitution',
              time: 32,
              player: {
                number: 11,
                first_name: 'Name',
                last_name: 'Last',
              },
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'red_card',
              time: 42,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'goal',
              time: 12,
            },
          ],
        },
      ],
      substitution: [
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
            {
              type: 'substitution',
              time: 32,
              player: {
                number: 11,
                first_name: 'Name',
                last_name: 'Last',
              },
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'yellow_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'red_card',
              time: 12,
            },
          ],
        },
        {
          id: 1,
          first_name: 'Name',
          last_name: 'Last Name',
          number: 12,
          events: [
            {
              type: 'goal',
              time: 12,
            },
          ],
        },
      ],
    },
    date: '23.01.2023',
    tournament: 'OLIMPBET-Чемпионат',
    country: 'Kazakhstan',
  };
  const Title = () => {
    return (
      <div className="game-protocol-title">
        <CloseCrossIcon
          className="enabledClickable"
          onClick={onClose}
          style={{ position: 'absolute', top: 16, right: 16 }}
        />
        <div className="game-protocol-team">
          <img
            className="game-protocol-logo"
            src={gameProtocol.homeTeam.logo}
            alt=""
          />
          <div className="game-protocol-team-name">
            {gameProtocol.homeTeam.name}
          </div>
        </div>
        <div className="game-protocol-details">
          <div className="date">{gameProtocol.date}</div>
          <div className="protocol-score">
            <div style={{ flex: 1, textAlign: 'right' }}>
              {gameProtocol.homeTeam.score}
            </div>
            :<div style={{ flex: 1 }}>{gameProtocol.awayTeam.score}</div>
          </div>
          <div className="game-protocol-tournament">
            {gameProtocol.tournament}
            <Divider type="vertical" />
            {/* <div className="divider"></div> */}
            {gameProtocol.country}
          </div>
        </div>
        <div className="game-protocol-team">
          <img
            className="game-protocol-logo"
            src={gameProtocol.awayTeam.logo}
            alt=""
          />
          <div className="game-protocol-team-name">
            {gameProtocol.awayTeam.name}
          </div>
        </div>
      </div>
    );
  };
  const [t] = useTranslation();
  const EVENT_ICONS: any = {
    red_card: <RedCardSmall />,
    yellow_card: <YellowCardSmall />,
    goal: <GoalSmall />,
  };
  const PlayerRow = (player: any) => {
    return (
      <div className="game-protocol-lineup-player-wrapper" key={player.id}>
        <div className="flex-row ai-c f-ga-8 j-sb">
          <div className="player-number">{player.number}</div>
          <div style={{ flex: 1 }}>
            {player.first_name} {player.last_name}
          </div>
          {player.events
            .filter((evt: any) => evt.type !== 'substitution')
            .map((evt: any, index: number) => (
              <div className="flex-row f-ga-4 ai-c" key={index}>
                <div className="timing">{evt.time}'</div>
                {EVENT_ICONS[evt.type]}
              </div>
            ))}
        </div>
        {player.events
          .filter((evt: any) => evt.type === 'substitution')
          .map((evt: any, index: number) => (
            <div className="flex-row f-ga-8 ai-c" key={index}>
              <div className="timing">{evt.time}'</div>
              <SubstitutionIcon />
              <div className="player-number">{evt.player.number}</div>
              <div className="substitution-name">
                {evt.player.first_name} {evt.player.last_name}
              </div>
            </div>
          ))}
      </div>
    );
  };
  return (
    <Drawer
      width={640}
      closable={false}
      styles={{
        header: {
          padding: 0,
        },
        body: {
          padding: 0,
        },
        content: {
          borderRadius: '12px 0px 0px 12px',
        },
      }}
      onClose={onClose}
      open={protocolGameID !== ''}
      title={<Title />}
    >
      <div className="game-protocol-lineup-title">{t('Start lineup')}</div>
      <div className="game-protocol-lineup-container">
        <div>
          {gameProtocol.homeTeam.main.map((player: any) => PlayerRow(player))}
        </div>
        <div>
          {gameProtocol.awayTeam.main.map((player: any) => PlayerRow(player))}
        </div>
      </div>
      <div className="game-protocol-lineup-title">
        {t('Substitution lineup')}
      </div>
      <div className="game-protocol-lineup-container">
        <div>
          {gameProtocol.homeTeam.substitution.map((player: any) =>
            PlayerRow(player),
          )}
        </div>
        <div>
          {gameProtocol.awayTeam.substitution.map((player: any) =>
            PlayerRow(player),
          )}
        </div>
      </div>
    </Drawer>
  );
};
export default GameProtocolSidePanel;
