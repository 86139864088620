import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import EventsFilter from './EventsFilter';
import MetricsFilter from './MetricsFilter';
import PlayersFilter from './PlayersFilter';
import TimingFilter from './TImingFilter';
import { AC_OpenFilterAction } from '../../../actions/filter.actions';
import { ApplyEpisodesFilters } from '../../../actions/player.acitons';
import { EventsAPI } from '../../../api/events';
import { AppStateType } from '../../../reducers';
import {
  AppliedFilters,
  playerReducerV2,
} from '../../../reducers/player.reducer';
import { useAppDispatch } from '../../../store';
import {
  EpisodeFilterPanelTypes,
  EpisodeFilterTypes,
} from '../../../types/types';

const Filters = ({ setSelectedEpisodes }: { setSelectedEpisodes: any }) => {
  const { activeFilter } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  // const { displayedFilters } = useSelector(
  //   (state: AppStateType) => state.playerReducer,
  // );
  const [currentDisplayedFilters, setCurrentDisplayedFilters] =
    useState<AppliedFilters | null>(null);

  const {
    activeTeam,
    selectedTournaments,
    selectedDateLimits,
    selectedPlayers,
    lastXFilter,
    selectedAttributes,
    selectedGames,
    selectedTimingIntervals,
    showMyEpisdes,
    showPlayerGames,
    showPlayerIntervals,
  } = useSelector((state: AppStateType) => state.filtersReducer);

  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const openFilter = (filterName: EpisodeFilterPanelTypes | string) => {
    dispatch(AC_OpenFilterAction(filterName));
  };
  const { resetEditMode } = playerReducerV2.actions;
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const filterVideoEvents = () => {
    dispatch(ApplyEpisodesFilters());
    openFilter('');
    dispatch(resetEditMode());
    dispatch(setCreateEpisodeMode(false));
    setSelectedEpisodes({});
  };

  const changeCurrentFilters = (
    filterType: EpisodeFilterTypes,
    updatedFilters: any,
  ) => {
    // console.log('UPDATED FILTERS updatedFilters', updatedFilters)
    const newFilters: any = {
      ...currentDisplayedFilters,
    };
    newFilters[filterType] = updatedFilters;
    setCurrentDisplayedFilters(newFilters);
    // console.log('UPDATED FILTERS', newFilters)
  };
  const [preFilterEpisodesCount, setPrefilterEpisodesCount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (
      activeTeam &&
      (selectedAttributes.length > 0 ||
        Object.keys(selectedTimingIntervals).length > 0)
    ) {
      newFiltersV2.games[activeTeam] = {};
      if (selectedTournaments.length > 0) {
        newFiltersV2.games[activeTeam]['tournaments'] = selectedTournaments.map(
          (el) => {
            if (el.id) {
              return el.id;
            }
            return el;
          },
        );
      }
      if (Object.keys(selectedDateLimits).length > 0) {
        const shiftedDateLimits: any = {};
        for (const year of Object.keys(selectedDateLimits)) {
          shiftedDateLimits[year] = [
            selectedDateLimits[year][0] + 1,
            selectedDateLimits[year][1],
          ];
        }
        newFiltersV2.games[activeTeam]['date_limits'] = {
          ...shiftedDateLimits,
        };
      }
      if (lastXFilter) {
        newFiltersV2.games[activeTeam]['lastX'] = lastXFilter;
      }
      if (selectedGames) {
        newFiltersV2.games[activeTeam]['match'] = selectedGames;
      }
      if (Object.keys(selectedTimingIntervals).length > 0) {
        newFiltersV2['match_part'] = selectedTimingIntervals;
      }
      if (showMyEpisdes) {
        newFiltersV2['show_my_episodes'] = showMyEpisdes;
      }
      if (showPlayerGames) {
        newFiltersV2['show_player_games'] = showPlayerGames;
      }
      if (showPlayerIntervals) {
        newFiltersV2['show_player_intervals'] = showPlayerIntervals;
      }
      if (selectedPlayers.length > 0) {
        newFiltersV2.players = {};
        newFiltersV2.players[activeTeam] = selectedPlayers;
      }
      if (selectedAttributes.length > 0) {
        newFiltersV2.attributes = selectedAttributes;
      }
      newFiltersV2['count'] = true;
      try {
        setLoading(true);
        EventsAPI.getEpisodesCount(newFiltersV2).then((response: any) => {
          const count = response?.data?.episodes_count || 0;
          setPrefilterEpisodesCount(count);
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
      }
    } else {
      setPrefilterEpisodesCount(0);
    }
  }, [selectedAttributes, selectedTimingIntervals]);

  return (
    <>
      {activeFilter === EpisodeFilterPanelTypes.players && (
        <PlayersFilter
          hideCallback={() => {
            openFilter('');
          }}
          nextCallback={() => openFilter(EpisodeFilterPanelTypes.matches)}
          applyFilter={filterVideoEvents}
          filtersControls={{
            filters:
              (currentDisplayedFilters &&
                currentDisplayedFilters[EpisodeFilterPanelTypes.players]) ||
              [],
            updateFilters: changeCurrentFilters,
          }}
          preFilterEpisodesCount={preFilterEpisodesCount}
          preFilterLoading={isLoading}
        />
      )}
      {activeFilter === EpisodeFilterPanelTypes.matches && (
        <EventsFilter
          hideCallback={() => {
            openFilter('');
          }}
          nextCallback={() => openFilter(EpisodeFilterPanelTypes.metrics)}
          applyFilter={filterVideoEvents}
          filtersControls={{
            filters: [],
            updateFilters: changeCurrentFilters,
          }}
          preFilterEpisodesCount={preFilterEpisodesCount}
          preFilterLoading={isLoading}
        />
      )}
      {activeFilter === EpisodeFilterPanelTypes.metrics && (
        <MetricsFilter
          hideCallback={() => {
            openFilter('');
          }}
          nextCallback={() => openFilter(EpisodeFilterPanelTypes.timing)}
          applyFilter={filterVideoEvents}
          filtersControls={{
            filters: [],
            updateFilters: changeCurrentFilters,
          }}
          preFilterEpisodesCount={preFilterEpisodesCount}
          preFilterLoading={isLoading}
        />
      )}
      {activeFilter === EpisodeFilterPanelTypes.timing && (
        <TimingFilter
          hideCallback={() => {
            openFilter('');
          }}
          applyFilter={filterVideoEvents}
          filtersControls={{
            filters: [],
            updateFilters: changeCurrentFilters,
          }}
          preFilterEpisodesCount={preFilterEpisodesCount}
          preFilterLoading={isLoading}
        />
      )}
    </>
  );
};
export default Filters;
