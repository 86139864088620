import { ThunkAction } from 'redux-thunk';

import { PrefilterGamesAction } from './filter.actions';
import { EventsAPI } from '../api/events';
import { PlaylistsAPI } from '../api/playlists';
import { RegistriesAPI } from '../api/registries';
import { AppStateType } from '../reducers';
import {
  ADD_EVENTS_TO_EXPANDED_VIDEO,
  ADD_USER_EVENTS_TO_EXPANDED_VIDEO,
  APPEND_DISPLAYED_FILTERS,
  CLEAR_EVENTS_FILTERS,
  CLEAR_TRAININGS_FILTERS,
  GET_PLAYLISTS_REQUEST,
  GET_VIDEO_EPISODES_ERROR,
  GET_VIDEO_EPISODES_REQUEST,
  GET_VIDEO_EPISODES_SUCCESS,
  INIT_TEAM_TOURNAMENT_DATES_ERROR,
  INIT_TEAM_TOURNAMENT_DATES_REQUEST,
  INIT_TEAM_TOURNAMENT_DATES_SUCCESS,
  NEW_USER_EPISODE_REQUEST,
  NEW_USER_EPISODE_REQUEST_ERROR,
  NEW_USER_EPISODE_REQUEST_SUCCESS,
  OPEN_FILTERS_PANEL_STATE,
  RENAME_PLAYLIST,
  REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO,
  SET_ACTIVE_VIDEO,
  SET_ALLOW_PLAY_FIRST,
  SET_APPLIED_FILTERS,
  SET_APPLIED_SELECTED_GAMES,
  SET_APPLIED_SELECTED_TRAININGS,
  SET_DISPLAYED_FILTERS,
  SET_FILTERED_VIDEOS,
  SET_IS_PLAYING,
  SET_PLAY_STATE,
  SET_PLAYER_COMMAND,
  SET_PLAYER_HOTKEYS_ALLOWED,
  SET_PLAYER_LOADING_VIDEO,
  SET_PLAYER_MODE,
  SET_PLAYER_PAUSED_AT,
  SET_PLAYER_VIDEO,
  SET_PLAYLIST,
  SET_SELECTED_GAMES,
  SET_SELECTED_TRAININGS,
  SET_SHARED_PLAYLIST,
  SET_USER_EDITED_EPISODE_DRAGGED,
  SET_USER_EDITED_EPISODES,
  SET_USER_EDITED_TEMP_EPISODES,
  SET_VIDEO_TIME_ZOOM,
  SET_VIDEOS_LIST_EDIT_MODE,
  SET_VISIBLE_RANGE,
  SHOW_DOWNLOAD_EPISODES_MODAL,
  TOGGLE_VIDEO_COLLAPSED,
} from '../types/action.types';
import { filterAndIndex } from '../types/functions';
import { GameVideoType, VideoFileType } from '../types/types';

export type PlayerActionTypes =
  | PlayerVideoType
  | GetVideoEpisodesRequestType
  | GetVideoEpisodesRequestSuccessType
  | GetVideoEpisodesRequestErrorType
  | AppliedFiltersType
  | DisplayedFiltersType
  | AppendDisplayedFiltersType
  | SetVideosListModeType
  | SetSharedPlaylistActionType
  | SetActiveVideoType
  | SetIsPlayingVideoType
  | SetConsecutivePlayStateType
  | SetPlayerLoadingType
  | SetPlayListType
  | GetPlayListRequestType
  | SetPlayerModeType
  | ToggleIsCollapsedType
  | AddEventsToExpandedVideoType
  | RenamePlaylistActionType
  | SetSelectedGamesType
  | SetAppliedSelectedGamesType
  | SetPlayerCommandType
  | InitializeTeamTournamentsAndDateLimitsRequestType
  | InitializeTeamTournamentsAndDateLimitsErrorType
  | InitializeTeamTournamentsAndDateLimitsSuccessType
  | ClearEventsFiltersCommandType
  | SetSelectedTrainingsType
  | SetAppliedSelectedTrainingsType
  | ClearTrainingsFiltersCommandType
  | OpenFiltersPanelType
  | SetUserEditedEpisodeType
  | NewUserEpisodeRequestType
  | NewUserEpisodeRequestSuccessType
  | NewUserEpisodeRequestErrorType
  | AddUserEventsToExpandedVideoType
  | SetPlayerPausedAtActionType
  | SetUserEditedEpisodeDraggedActionType
  | SetVideoTimeZoomActionType
  | SetUserEditedEpisodeTempType
  | SetDownloadEpisodesType
  | SetFilteredVideosType
  | SetPlayerHotkeysAllowedType
  | SetVisibleRangeType
  | SetAllowPlayFirstType
  | ReplaceUserEventsToExpandedVideoType;

type PlayerVideoType = {
  type: typeof SET_PLAYER_VIDEO;
};

type AppliedFiltersType = {
  type: typeof SET_APPLIED_FILTERS;
  payload: any;
};
const AC_ApplyFilters = (filters: any): AppliedFiltersType => {
  return {
    type: SET_APPLIED_FILTERS,
    payload: filters,
  };
};
type DisplayedFiltersType = {
  type: typeof SET_DISPLAYED_FILTERS;
  payload: any;
};
export const AC_DisplayFilters = (filters: any): DisplayedFiltersType => {
  return {
    type: SET_DISPLAYED_FILTERS,
    payload: filters,
  };
};

export const UpdateDisplayedFilters =
  (
    filterName: string,
    filterValue: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch, getState) => {
    const { displayedFilters } = getState().playerReducer;
    const updatedFilters = {
      ...displayedFilters,
      [filterName]: filterValue,
    };
    dispatch(AC_ApplyFilters(updatedFilters));
  };

type AppendDisplayedFiltersType = {
  type: typeof APPEND_DISPLAYED_FILTERS;
  payload: any;
};
export const AC_AppendDisplayFilters = (
  filters: any,
): AppendDisplayedFiltersType => {
  return {
    type: APPEND_DISPLAYED_FILTERS,
    payload: filters,
  };
};
type GetVideoEpisodesRequestType = {
  type: typeof GET_VIDEO_EPISODES_REQUEST;
};
export const AC_GetVideoEpisodesRequest = (): GetVideoEpisodesRequestType => {
  return {
    type: GET_VIDEO_EPISODES_REQUEST,
  };
};
type GetVideoEpisodesRequestSuccessType = {
  type: typeof GET_VIDEO_EPISODES_SUCCESS;
  payload: Array<any>;
};
export const AC_GetVideoEpisodesSuccess = (
  episodes: Array<any>,
): GetVideoEpisodesRequestSuccessType => {
  return {
    type: GET_VIDEO_EPISODES_SUCCESS,
    payload: episodes,
  };
};
type SetFilteredVideosType = {
  type: typeof SET_FILTERED_VIDEOS;
  payload: any;
};
export const AC_SetFilteredVideosAction = (
  filteredVideos: any,
): SetFilteredVideosType => {
  return {
    type: SET_FILTERED_VIDEOS,
    payload: filteredVideos,
  };
};

type GetVideoEpisodesRequestErrorType = {
  type: typeof GET_VIDEO_EPISODES_ERROR;
  payload: any;
};
export const AC_GetVideoEpisodesError = (
  error: any,
): GetVideoEpisodesRequestErrorType => {
  return {
    type: GET_VIDEO_EPISODES_ERROR,
    payload: error,
  };
};
type SetAppliedSelectedGamesType = {
  type: typeof SET_APPLIED_SELECTED_GAMES;
  payload: string[];
};
export const AC_SetAppliedSelectedGames = (
  gamesByTeam: string[],
): SetAppliedSelectedGamesType => {
  return {
    type: SET_APPLIED_SELECTED_GAMES,
    payload: gamesByTeam,
  };
};
type SetAppliedSelectedTrainingsType = {
  type: typeof SET_APPLIED_SELECTED_TRAININGS;
  payload: string[];
};
export const AC_SetAppliedSelectedTrainings = (
  trainingsByTeam: string[],
): SetAppliedSelectedTrainingsType => {
  return {
    type: SET_APPLIED_SELECTED_TRAININGS,
    payload: trainingsByTeam,
  };
};

type SetVideosListModeType = {
  type: typeof SET_VIDEOS_LIST_EDIT_MODE;
  payload: {
    id: string | null;
    range: number[];
    fileRange: number[];
  };
};
export const AC_setVideosListModeAction = (
  editedEpisodeId: string | null,
  range: number[],
  fileRange: number[],
) => {
  return {
    type: SET_VIDEOS_LIST_EDIT_MODE,
    payload: { id: editedEpisodeId, range: range, fileRange: fileRange },
  };
};
type SetSharedPlaylistActionType = {
  type: typeof SET_SHARED_PLAYLIST;
  payload: any;
};

type SetActiveVideoType = {
  type: typeof SET_ACTIVE_VIDEO;
  payload: any;
};
export const AC_SetActiveVideoAction = (
  activeVideo: VideoFileType | null,
): SetActiveVideoType => {
  return {
    type: SET_ACTIVE_VIDEO,
    payload: activeVideo,
  };
};
type SetIsPlayingVideoType = {
  type: typeof SET_IS_PLAYING;
  payload: any;
};
export const AC_SetIsPlayingVideoAction = (
  isPlaying: boolean,
): SetIsPlayingVideoType => {
  return {
    type: SET_IS_PLAYING,
    payload: isPlaying,
  };
};
type SetPlayerCommandType = {
  type: typeof SET_PLAYER_COMMAND;
  payload: any;
};
export const AC_SetPlayerCommandAction = (
  command: string,
): SetPlayerCommandType => {
  return {
    type: SET_PLAYER_COMMAND,
    payload: command,
  };
};
type SetConsecutivePlayStateType = {
  type: typeof SET_PLAY_STATE;
  payload: any;
};
export const AC_SetConsecutivePlayStateAction = (
  state: boolean,
): SetConsecutivePlayStateType => {
  return {
    type: SET_PLAY_STATE,
    payload: state,
  };
};
type SetPlayerLoadingType = {
  type: typeof SET_PLAYER_LOADING_VIDEO;
  payload: any;
};
export const AC_SetPlayerLoadingAction = (
  isLoading: boolean,
): SetPlayerLoadingType => {
  return {
    type: SET_PLAYER_LOADING_VIDEO,
    payload: isLoading,
  };
};
// type SetPlayedEpisodeType = {
//   type: typeof SET_PLAYED_EPISODE;
//   payload: any;
// };
// export const AC_SetPlayedEpisodeAction = (
//   episode: any,
//   videoSetId: GameVideoType | PlaylistType | null,
// ): SetPlayedEpisodeType => {
//   return {
//     type: SET_PLAYED_EPISODE,
//     payload: {
//       episode: episode,
//       videoSet: videoSetId,
//     },
//   };
// };
type SetPlayListType = {
  type: typeof SET_PLAYLIST;
  payload: { playlists: Array<GameVideoType> };
};
export const AC_SetPlayList = (
  playlists: Array<GameVideoType>,
): SetPlayListType => {
  return {
    type: SET_PLAYLIST,
    payload: {
      playlists: playlists,
    },
  };
};
type GetPlayListRequestType = {
  type: typeof GET_PLAYLISTS_REQUEST;
};
const AC_getPlaylistsRequest = (): GetPlayListRequestType => {
  return {
    type: GET_PLAYLISTS_REQUEST,
  };
};
export const getPlaylistsAction =
  (): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_getPlaylistsRequest);
      const response = await PlaylistsAPI.getPlaylists({});
      dispatch(AC_SetPlayList(response.data.results));
    } catch (e) {
      console.log(e);
    }
  };
export const popPlaylist =
  (
    playlistId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch, getState) => {
    const { playlists } = getState().playerReducer;
    const pl = filterAndIndex(playlists.playlists, playlistId, 'id');
    const newPlaylists: Array<GameVideoType> = [
      ...playlists.playlists.slice(0, pl.index),
      ...playlists.playlists.slice(pl.index + 1),
    ];
    dispatch(AC_SetPlayList(newPlaylists));
  };

export const AppendNewPlaylist =
  (
    newPlaylist: GameVideoType,
  ): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch, getState) => {
    const { playlists } = getState().playerReducer;
    const newSetOfPlaylists: Array<GameVideoType> = [
      ...playlists.playlists,
      newPlaylist,
    ];
    dispatch(AC_SetPlayList(newSetOfPlaylists));
  };
type SetPlayerModeType = {
  type: typeof SET_PLAYER_MODE;
  payload: string;
};
export const AC_setPlayerMode = (mode: string): SetPlayerModeType => {
  return {
    type: SET_PLAYER_MODE,
    payload: mode,
  };
};
type ToggleIsCollapsedType = {
  type: typeof TOGGLE_VIDEO_COLLAPSED;
  payload: string;
};
export const AC_ToggleIsCollapsed = (
  videoId: string,
): ToggleIsCollapsedType => {
  return {
    type: TOGGLE_VIDEO_COLLAPSED,
    payload: videoId,
  };
};
type AddEventsToExpandedVideoType = {
  type: typeof ADD_EVENTS_TO_EXPANDED_VIDEO;
  payload: {
    videoId: string;
    episodes: any;
  };
};
export const AC_AddEventsToExpandedVideo = (
  events: any,
  videoId: string,
): AddEventsToExpandedVideoType => {
  return {
    type: ADD_EVENTS_TO_EXPANDED_VIDEO,
    payload: {
      videoId: videoId,
      episodes: events,
    },
  };
};
type RenamePlaylistActionType = {
  type: typeof RENAME_PLAYLIST;
  payload: {
    videoId: string;
    newName: string;
  };
};
export const AC_RenamePlaylistAction = (
  videoId: string,
  newName: string,
): RenamePlaylistActionType => {
  return {
    type: RENAME_PLAYLIST,
    payload: {
      videoId: videoId,
      newName: newName,
    },
  };
};
type AddUserEventsToExpandedVideoType = {
  type: typeof ADD_USER_EVENTS_TO_EXPANDED_VIDEO;
  payload: {
    videoId: string;
    userEvents: any;
  };
};
export const AC_AddUserEventsToExpandedVideo = (
  userEvents: any,
  videoId: string,
): AddUserEventsToExpandedVideoType => {
  return {
    type: ADD_USER_EVENTS_TO_EXPANDED_VIDEO,
    payload: {
      videoId: videoId,
      userEvents: userEvents,
    },
  };
};
type ReplaceUserEventsToExpandedVideoType = {
  type: typeof REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO;
  payload: {
    videoId: string;
    userEvents: any;
  };
};
export const AC_ReplaceUserEventsToExpandedVideo = (
  userEvents: any,
  videoId: string,
): ReplaceUserEventsToExpandedVideoType => {
  return {
    type: REPLACE_USER_EVENTS_TO_EXPANDED_VIDEO,
    payload: {
      videoId: videoId,
      userEvents: userEvents,
    },
  };
};
type SetSelectedGamesType = {
  type: typeof SET_SELECTED_GAMES;
  payload: string[];
};
export const AC_SetSelectedGames = (
  gamesByTeam: string[],
): SetSelectedGamesType => {
  return {
    type: SET_SELECTED_GAMES,
    payload: gamesByTeam,
  };
};
type SetSelectedTrainingsType = {
  type: typeof SET_SELECTED_TRAININGS;
  payload: string[];
};
export const AC_SetSelectedTrainings = (
  trainingsByTeam: string[],
): SetSelectedTrainingsType => {
  return {
    type: SET_SELECTED_TRAININGS,
    payload: trainingsByTeam,
  };
};

type InitializeTeamTournamentsAndDateLimitsRequestType = {
  type: typeof INIT_TEAM_TOURNAMENT_DATES_REQUEST;
};
type InitializeTeamTournamentsAndDateLimitsErrorType = {
  type: typeof INIT_TEAM_TOURNAMENT_DATES_ERROR;
};
type InitializeTeamTournamentsAndDateLimitsSuccessType = {
  type: typeof INIT_TEAM_TOURNAMENT_DATES_SUCCESS;
  payload: {
    dates: any[];
    tournaments: any[];
  };
};
const AC_InitializeTeamTournamentsAndDateLimitsRequest =
  (): InitializeTeamTournamentsAndDateLimitsRequestType => {
    return {
      type: INIT_TEAM_TOURNAMENT_DATES_REQUEST,
    };
  };
const AC_InitializeTeamTournamentsAndDateLimitsError =
  (): InitializeTeamTournamentsAndDateLimitsErrorType => {
    return {
      type: INIT_TEAM_TOURNAMENT_DATES_ERROR,
    };
  };
const AC_InitializeTeamTournamentsAndDateLimitsSuccess = (
  dates: any[],
  tournaments: any[],
): InitializeTeamTournamentsAndDateLimitsSuccessType => {
  return {
    type: INIT_TEAM_TOURNAMENT_DATES_SUCCESS,
    payload: {
      dates: dates,
      tournaments: tournaments,
    },
  };
};
export const InitializeTeamTournamentsAndDateLimits =
  (
    teamId: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch, getState) => {
    try {
      const { appliedFilters, displayedFilters, filterPanelState } =
        getState().playerReducer;
      dispatch(AC_InitializeTeamTournamentsAndDateLimitsRequest());
      // dispatch(AC_PrefilterRequestSuccess([]));
      const eventsDateLimitsResponse =
        await EventsAPI.getEventsDateLimits(teamId);
      const tournamentsResponse = await RegistriesAPI.getTournaments({
        my: true,
        team: teamId,
      });

      dispatch(
        AC_InitializeTeamTournamentsAndDateLimitsSuccess(
          eventsDateLimitsResponse.data,
          tournamentsResponse.data.results,
        ),
      );
      if (filterPanelState === 'init') {
        dispatch(AC_DisplayFilters(appliedFilters));
      } else {
        let newFilters: any;
        if (
          appliedFilters &&
          (!displayedFilters || Object.keys(displayedFilters).length === 0)
        ) {
          newFilters = {
            ...appliedFilters,
            games: { ...appliedFilters.games },
          };
        } else if (
          displayedFilters !== null &&
          Object.keys(displayedFilters).length > 0
        ) {
          newFilters = {
            ...displayedFilters,
            games: { ...displayedFilters.games },
          };
        } else {
          newFilters = { games: {} };
        }
        newFilters.games[teamId] = {};
        const eventsFilters = {
          games: { ...newFilters.games },
          // players: {...newFilters?.players},
          type: 'game',
        };
        const newGames: any = {};
        newGames[teamId.toString()] = {};
        if (eventsFilters.games) {
          const team = teamId.toString();
          newGames[team] = { ...eventsFilters.games[team] };
          if (newGames && Object.keys(newGames[team]).includes('match')) {
            delete newGames[team].match;
          }
          if (
            newGames &&
            newGames[team] &&
            Object.keys(newGames[team]).includes('date_limits') &&
            newGames[team].date_limits &&
            Object.keys(newGames[team].date_limits)?.length === 0
          ) {
            delete newGames[team].date_limits;
          }
          if (
            newGames &&
            newGames[team] &&
            Object.keys(newGames[team]).includes('tournament') &&
            newGames[team].tournament &&
            Object.keys(newGames[team].tournament)?.length === 0
          ) {
            delete newGames[team].tournament;
          }
        }
        eventsFilters.games = { ...newGames };
        // console.log('111', eventsFilters)
        dispatch(PrefilterGamesAction());
      }
    } catch (e) {
      dispatch(AC_InitializeTeamTournamentsAndDateLimitsError());
    }
  };
type ClearEventsFiltersCommandType = {
  type: typeof CLEAR_EVENTS_FILTERS;
  payload: boolean;
};
export const AC_ClearEventsFiltersCommand = (
  command: boolean,
): ClearEventsFiltersCommandType => {
  return {
    type: CLEAR_EVENTS_FILTERS,
    payload: command,
  };
};
type ClearTrainingsFiltersCommandType = {
  type: typeof CLEAR_TRAININGS_FILTERS;
  payload: boolean;
};
export const AC_ClearTrainingsFiltersCommand = (
  command: boolean,
): ClearTrainingsFiltersCommandType => {
  return {
    type: CLEAR_TRAININGS_FILTERS,
    payload: command,
  };
};

type OpenFiltersPanelType = {
  type: typeof OPEN_FILTERS_PANEL_STATE;
  payload: string;
};
export const AC_OpenFiltersPanelAction = (
  state: string,
): OpenFiltersPanelType => {
  return {
    type: OPEN_FILTERS_PANEL_STATE,
    payload: state,
  };
};
type SetUserEditedEpisodeType = {
  type: typeof SET_USER_EDITED_EPISODES;
  payload: any;
};
export const AC_SetUserEditedEpisode = (
  episode: any,
): SetUserEditedEpisodeType => {
  return {
    type: SET_USER_EDITED_EPISODES,
    payload: episode,
  };
};
type SetUserEditedEpisodeTempType = {
  type: typeof SET_USER_EDITED_TEMP_EPISODES;
  payload: any;
};
export const AC_SetUserEditedTempEpisode = (
  episode: any,
): SetUserEditedEpisodeTempType => {
  return {
    type: SET_USER_EDITED_TEMP_EPISODES,
    payload: episode,
  };
};
type NewUserEpisodeRequestType = {
  type: typeof NEW_USER_EPISODE_REQUEST;
};
const AC_NewUserEpisodeRequest = (): NewUserEpisodeRequestType => {
  return {
    type: NEW_USER_EPISODE_REQUEST,
  };
};
type NewUserEpisodeRequestSuccessType = {
  type: typeof NEW_USER_EPISODE_REQUEST_SUCCESS;
};
const AC_NewUserEpisodeRequestSuccess =
  (): NewUserEpisodeRequestSuccessType => {
    return {
      type: NEW_USER_EPISODE_REQUEST_SUCCESS,
    };
  };
type NewUserEpisodeRequestErrorType = {
  type: typeof NEW_USER_EPISODE_REQUEST_ERROR;
};
const AC_NewUserEpisodeRequestError = (): NewUserEpisodeRequestErrorType => {
  return {
    type: NEW_USER_EPISODE_REQUEST_ERROR,
  };
};
export const AddUserPlayerEpisode =
  (
    episode: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_NewUserEpisodeRequest());
      const response = await EventsAPI.addNewUserEpisode(episode);
      dispatch(AC_NewUserEpisodeRequestSuccess());
      return response;
    } catch (e) {
      console.log(e);
      dispatch(AC_NewUserEpisodeRequestError());
    }
  };

type SetPlayerPausedAtActionType = {
  type: typeof SET_PLAYER_PAUSED_AT;
  payload: number | null | undefined;
};
export const AC_SetPlayerPausedAtAction = (
  timer: number | null | undefined,
): SetPlayerPausedAtActionType => {
  return {
    type: SET_PLAYER_PAUSED_AT,
    payload: timer,
  };
};
type SetUserEditedEpisodeDraggedActionType = {
  type: typeof SET_USER_EDITED_EPISODE_DRAGGED;
  payload: boolean;
};
export const AC_SetUserEditedEpisodeDraggedAction = (
  dragged: boolean,
): SetUserEditedEpisodeDraggedActionType => {
  return {
    type: SET_USER_EDITED_EPISODE_DRAGGED,
    payload: dragged,
  };
};
type SetVideoTimeZoomActionType = {
  type: typeof SET_VIDEO_TIME_ZOOM;
  payload: number;
};
export const AC_SetVideoTimeZoomAction = (
  zoom: number,
): SetVideoTimeZoomActionType => {
  return {
    type: SET_VIDEO_TIME_ZOOM,
    payload: zoom,
  };
};

type SetDownloadEpisodesType = {
  type: typeof SHOW_DOWNLOAD_EPISODES_MODAL;
  payload: string | null;
};
export const AC_SetDownloadEpisodesAction = (
  openMode: string | null,
): SetDownloadEpisodesType => {
  return {
    type: SHOW_DOWNLOAD_EPISODES_MODAL,
    payload: openMode,
  };
};

export const ApplyEpisodesFilters =
  (): ThunkAction<Promise<void>, AppStateType, undefined, PlayerActionTypes> =>
  async (dispatch, getState) => {
    const { displayedFilters, selectedTrainings } = getState().playerReducer;
    const {
      activeTeam,
      selectedTournaments,
      selectedDateLimits,
      selectedPlayers,
      lastXFilter,
      selectedAttributes,
      selectedGames,
      selectedTimingIntervals,
      showMyEpisdes,
      showPlayerGames,
      showPlayerIntervals,
    } = getState().filtersReducer;

    const newFiltersV2: any = {
      type: 'game',
      games: {},
    };
    if (activeTeam) {
      newFiltersV2.games[activeTeam] = {};
      if (selectedTournaments.length > 0) {
        newFiltersV2.games[activeTeam].tournaments = selectedTournaments.map(
          (el) => {
            if (el.id) {
              return el.id;
            }
            return el;
          },
        );
      }
      if (Object.keys(selectedDateLimits).length > 0) {
        const shiftedDateLimits: any = {};
        for (const year of Object.keys(selectedDateLimits)) {
          shiftedDateLimits[year] = [
            selectedDateLimits[year][0] + 1,
            selectedDateLimits[year][1],
          ];
        }
        newFiltersV2.games[activeTeam].date_limits = {
          ...shiftedDateLimits,
        };
      }
      if (lastXFilter) {
        newFiltersV2.games[activeTeam].lastX = lastXFilter;
      }
      if (selectedGames) {
        newFiltersV2.games[activeTeam].match = selectedGames;
      }
      if (Object.keys(selectedTimingIntervals).length > 0) {
        newFiltersV2.match_part = selectedTimingIntervals;
      }
      if (showMyEpisdes) {
        newFiltersV2.show_my_episodes = showMyEpisdes;
      }
      if (showPlayerGames) {
        newFiltersV2.show_player_games = showPlayerGames;
      }
      if (showPlayerIntervals) {
        newFiltersV2.show_player_intervals = showPlayerIntervals;
      }
      if (selectedPlayers.length > 0) {
        newFiltersV2.players = {};
        newFiltersV2.players[activeTeam] = selectedPlayers;
      }
      if (selectedAttributes.length > 0) {
        newFiltersV2.attributes = selectedAttributes;
      }
    }
    try {
      // console.log('selectedPlayers', newFiltersV2);
      dispatch(AC_GetVideoEpisodesRequest());
      const response = await EventsAPI.getEpisodes(newFiltersV2);
      dispatch(AC_GetVideoEpisodesSuccess(response.data.results));
      dispatch(AC_ApplyFilters(displayedFilters));
      dispatch(AC_SetAppliedSelectedGames(selectedGames));
      dispatch(AC_SetAppliedSelectedTrainings(selectedTrainings));
      dispatch(AC_DisplayFilters(null));
      for (const game of response.data.results) {
        dispatch(AC_ToggleIsCollapsed(game.id));
        dispatch(AC_AddEventsToExpandedVideo([], game.id));
      }
    } catch (e) {
      console.log(e);
      dispatch(AC_GetVideoEpisodesError(e));
    }
  };
type SetPlayerHotkeysAllowedType = {
  type: typeof SET_PLAYER_HOTKEYS_ALLOWED;
  payload: any;
};
export const AC_SetPlayerHotkeysAllowedAction = (
  listens: boolean,
): SetPlayerHotkeysAllowedType => {
  return {
    type: SET_PLAYER_HOTKEYS_ALLOWED,
    payload: listens,
  };
};
type SetVisibleRangeType = {
  type: typeof SET_VISIBLE_RANGE;
  payload: number[];
};
export const AC_SetVisibleRangeAction = (
  range: number[],
): SetVisibleRangeType => {
  return {
    type: SET_VISIBLE_RANGE,
    payload: range,
  };
};
type SetAllowPlayFirstType = {
  type: typeof SET_ALLOW_PLAY_FIRST;
  payload: boolean;
};
export const AC_SetAllowPlayFirstAction = (
  allow: boolean,
): SetAllowPlayFirstType => {
  return {
    type: SET_ALLOW_PLAY_FIRST,
    payload: allow,
  };
};
