import React, { useState } from 'react';

import { ConfigProvider, Dropdown, Flex, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UpdateUserAction } from 'actions/auth.actions';
import { ReactComponent as ArrowUpArrowDown } from 'assets/img/icons/faArrowUpArrowDown.svg';
import { ReactComponent as Check } from 'assets/img/icons/faCheck.svg';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { useAppDispatch } from 'store';
import { generateUUID } from 'types/crypto';

const PlaylistSortingContext = ({
  sortingToggleCallback,
}: {
  playlist: any;
  sortingToggleCallback: any;
}) => {
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const [t] = useTranslation();
  const items: MenuProps['items'] = [
    {
      key: 'manual',
      label: (
        <div onClick={() => sortingToggleCallback('manual')}>
          {t('Manual ordering')}
        </div>
      ),
      disabled: false,
      style: {
        padding: '5px 8px',
      },
      itemIcon:
        currentUser?.playlist_sorting === 'manual' ? <Check></Check> : null,
    },
    {
      key: '-created_at',
      style: {
        padding: '5px 8px',
      },
      itemIcon:
        currentUser?.playlist_sorting === '-created_at' ? (
          <Check></Check>
        ) : null,
      label: (
        <div onClick={() => sortingToggleCallback('-created_at')}>
          {t('New first')}
        </div>
      ),
      disabled: true,
    },
  ];
  const dispatch = useAppDispatch();
  const [orderingContextOpen, setOrderingContextOpen] = useState(false);
  const { showNotification } = notificationsReducer.actions;
  const SORTING_NAMES: any = {
    manual: 'Manual',
    '-created_at': 'New first',
  };
  const updateSortingRule = (rule: string) => {
    currentUser &&
      dispatch(
        UpdateUserAction(currentUser?.id, {
          id: currentUser?.id,
          playlist_sorting: rule,
        }),
      );
    dispatch(
      showNotification({
        notificationParameters: [
          {
            id: generateUUID(),
            text: t(`Sorting updated to "${SORTING_NAMES[rule]}"`),
          },
        ],
      }),
    );
  };
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgElevated: 'var(--colorBgSpotlight)',
            colorText: 'var(--colorText)',
          },
          components: {
            Menu: {
              dropdownWidth: 170,
              itemSelectedBg: 'red',
              itemSelectedColor: 'var(--colorText)',
            },
          },
        }}
      >
        <Dropdown
          className="enabledClickable"
          menu={{
            items,
            onClick: (value) => {
              // console.log('sdd')
              updateSortingRule(value.key);
            },
          }}
          onOpenChange={() => setOrderingContextOpen((prev: boolean) => !prev)}
          trigger={['hover']}
          overlayStyle={{ width: '170px', maxWidth: 170, padding: 0 }}
          placement="bottomRight"
        >
          <Flex
            style={{ width: 32, height: 32 }}
            className={orderingContextOpen ? 'activeButton' : ''}
            align="center"
            justify="center"
          >
            <ArrowUpArrowDown style={{ display: 'block', margin: '4 2 4 2' }} />
          </Flex>
        </Dropdown>
      </ConfigProvider>
    </>
  );
};
export default PlaylistSortingContext;
