import React, { FC, useState } from 'react';

import './index.css';
import { Checkbox, ConfigProvider, Slider } from 'antd';
import { useTranslation } from 'react-i18next';

import { MONTHS_ENDING } from 'types/constants';

import dayjs from '../../utils/dayjsLocal';

interface Interface {
  name: string;
  label: string;
  rangeType: string;
  range?: [number, number];
  valuesController: { values: any; setValues: any };
  disabledTracks?: boolean;
}
const CheckBoxWithRange: FC<Interface> = ({
  name,
  label,
  rangeType,
  range,
  valuesController,
  disabledTracks = false,
}) => {
  const [showSlider, setShowSlider] = useState<boolean>(
    valuesController.values &&
      Object.keys(valuesController.values).includes(name),
  );

  const getRange = (): [number, number] => {
    if (rangeType === 'numbers' && range) {
      if (valuesController.values.length) {
        return valuesController.values[name];
      }
      return range;
    }
    if (
      Object.keys(valuesController?.values).length > 0 &&
      Object.keys(valuesController.values).includes(name)
    ) {
      // console.log('valuesController', valuesController)
      return valuesController.values[name];
    }
    return [0, 12];
  };
  const getBaseRange = (): [number, number] => {
    if (rangeType === 'numbers' && range) {
      return range;
    }
    return [0, 12];
  };
  const getLabelValue = (value: number, type: string) => {
    if (rangeType === 'numbers') {
      return `${[31, 46, 91].includes(value) ? value - 1 : value}${[31, 46, 91].includes(value) ? '+' : ''} мин`;
    }
    if (type === 'end') {
      const v = value - 1;
      const numberOfDays = dayjs().month(v).year(Number(label)).daysInMonth();
      const lastDayOfMonth = dayjs()
        .month(v)
        .year(Number(label))
        .date(numberOfDays);
      return lastDayOfMonth.format('DD.MM');
    }
    const v = value;
    return MONTHS_ENDING[v];
  };
  const initLocalValues = () => {
    if (
      Object.keys(valuesController?.values).length > 0 &&
      Object.keys(valuesController?.values).includes(name)
    ) {
      return valuesController.values[name];
    }
    return getRange();
  };
  const [localValues, setLocalValues] =
    useState<[number, number]>(initLocalValues());

  const toggleVisible = () => {
    if (!showSlider) {
      updateValues(localValues);
    } else {
      setLocalValues(getRange());
      const newValue: any = { ...valuesController.values };
      if (Object.keys(newValue).includes(name)) {
        delete newValue[name];
      }
      valuesController.setValues(newValue);
    }
    setShowSlider((prev: any) => !prev);
  };
  const updateValues = (value: any) => {
    setLocalValues(value);
    const newValue: any = { ...valuesController.values };
    newValue[name] = value;
    valuesController.setValues(newValue);
  };
  const [t] = useTranslation();
  return (
    <div className={'flex-column f-ga-12 w100 checkboxWithRange'}>
      <Checkbox
        className="year_checkbox"
        onChange={toggleVisible}
        name={name}
        checked={
          valuesController.values &&
          Object.keys(valuesController.values).includes(name)
        }
      >
        {label}
      </Checkbox>
      {valuesController.values &&
        Object.keys(valuesController.values).includes(name) && (
          <div className={'flex-column f-ga-12'}>
            <ConfigProvider
              theme={{
                components: {
                  Slider: {
                    handleActiveColor: 'var(--Primary-5, #40A9FF)',
                    handleColor: 'var(--Primary-5, #40A9FF)',
                    handleSizeHover: 10,
                    handleSize: 10,
                    handleLineWidthHover: 2,
                    handleLineWidth: 2,
                    colorPrimaryBorderHover: 'var(--Primary-5, #40A9FF)',
                    colorFillContentHover: 'var(--Primary-5, #40A9FF)',
                    handleColorDisabled: 'grey',
                    trackBgDisabled: 'grey',
                    colorTextDisabled: 'grey',
                    colorBgContainerDisabled: 'grey',
                    controlItemBgActiveDisabled: 'grey',
                  },
                },
              }}
            >
              <Slider
                // dots={true}
                disabled={disabledTracks}
                range
                className="year_slider"
                defaultValue={getBaseRange()}
                value={localValues}
                tooltip={{ open: false }}
                min={getBaseRange()[0]}
                max={getBaseRange()[1]}
                onChangeComplete={(value: number[]) => {
                  if (value[0] < value[1] && value[0] <= 11) {
                    updateValues(value);
                  }
                }}
                onChange={(value: any) => {
                  if (value[0] < value[1] && value[0] <= 11) {
                    setLocalValues(value);
                  }
                }}
                step={1}
                styles={{
                  track: {
                    background:
                      'var(--colorFillContent, rgba(255, 255, 255, 0.12))',
                    backgroundColor: 'var(--Primary-5, #40A9FF)',
                  },
                  tracks: {
                    background: 'var(--Primary-5, #40A9FF)',
                  },
                  handle: {
                    // backgroundColor: "grey",
                    // backgroundColor: "var(--Primary-5, #40A9FF)",
                    // fill: "var(--Primary-5, #40A9FF)",
                    fill: 'grey',
                    background: 'grey',
                    // background:  "var(--Primary-5, #40A9FF)",
                    boxShadow: '0px 0px grey',
                    // boxShadow: "var(--Primary-5, #40A9FF)",
                  },
                }}
              />
            </ConfigProvider>
            <div className={'flex-row j-sb'}>
              <div>
                <span className="dark_text">{t('from')}</span>
                <span className="light_text">
                  {' '}
                  {getLabelValue(localValues[0], 'start')}
                </span>
              </div>
              <div>
                <span className="dark_text">{t('to')}</span>
                <span className="light_text">
                  {' '}
                  {getLabelValue(localValues[1], 'end')}
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
export default CheckBoxWithRange;
