import { useState } from 'react';
import React from 'react';

import { ConfigProvider, Divider, Flex, Tag } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';

const PersonalInfo = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [years, setYears] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  const [t] = useTranslation();
  const { openedTeamPlayer, topPlayerRole } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const translateYears = () => {
    if (years === 0 || (years > 10 && years.toString().endsWith('1'))) {
      return t('year');
    }
    if ([10, 11, 12, 13, 14].includes(years)) {
      return t('years_many');
    }
    if (
      years.toString().endsWith('2') ||
      years.toString().endsWith('3') ||
      years.toString().endsWith('4')
    ) {
      return t('years_two');
    }
    return t('years_many');
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Tag: {
            defaultColor: 'var(--blue- 9, #002C8C)',
            defaultBg: 'var(--blue-1, #E6F4FF)',
          },
        },
        token: {
          colorText: 'var(--blue- 9, #002C8C)',
        },
      }}
    >
      <Flex className="team-player-stats-container" gap={12}>
        <Flex className="team-player-stats-block">
          <Flex
            gap={16}
            vertical
            className="f-grow f-ga-4"
            justify="space-around"
          >
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Birthday')} </span>
              {openedTeamPlayer?.birthday ? (
                <Tag color="blue" bordered={false}>
                  {dayjs(openedTeamPlayer?.birthday).format('DD.MM.YYYY')}
                </Tag>
              ) : (
                <></>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Age')} </span>
              {years ? (
                <Tag
                  color="blue"
                  bordered={false}
                >{`${years} ${translateYears()}`}</Tag>
              ) : (
                <span className="no-data-span-label">
                  {t('No data about age')}
                </span>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex
            gap={16}
            vertical
            className="f-grow f-ga-4"
            justify="space-around"
          >
            <Flex justify="space-between" align="center" gap={4}>
              <span className="span-label">{t('Position')}</span>
              {topPlayerRole ? (
                <div className="capitalize" style={{ fontSize: 16 }}>
                  {topPlayerRole.map((role: string) => (
                    <Tag bordered={false} color="blue">
                      {t(role)}
                    </Tag>
                  ))}
                </div>
              ) : (
                <span className="no-data-span-label"> {t('no data')}</span>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Leg')} </span>
              {openedTeamPlayer?.main_leg &&
              openedTeamPlayer.main_leg !== 'unset' ? (
                <span className="capitalize" style={{ fontSize: 16 }}>
                  <Tag bordered={false} color="blue">
                    {t(`${openedTeamPlayer.main_leg} leg`)}
                  </Tag>
                </span>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex
            gap={16}
            vertical
            className="f-grow f-ga-4"
            justify="space-around"
          >
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Height')} </span>
              {openedTeamPlayer?.height ? (
                <Tag
                  bordered={false}
                  color="blue"
                >{`${openedTeamPlayer?.height} ${t('cm')}`}</Tag>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Weight')} </span>
              {openedTeamPlayer?.weight ? (
                <Tag
                  bordered={false}
                  color="blue"
                >{`${openedTeamPlayer?.weight} ${t('kg')}`}</Tag>
              ) : (
                <span className="no-data-span-label">{t('no data')}</span>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex className="team-player-stats-block">
          <Flex
            gap={16}
            vertical
            className="f-grow f-ga-4"
            justify="space-around"
          >
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('National team')} </span>
              {openedTeamPlayer !== null &&
              openedTeamPlayer.national_team?.id !== 52 ? (
                <Tag bordered={false} color="blue">
                  {openedTeamPlayer?.national_team?.name}
                </Tag>
              ) : openedTeamPlayer?.national_team?.id === 52 ? (
                <Tag bordered={false} color="blue">
                  {t('not called up')}
                </Tag>
              ) : (
                <Tag bordered={false} color="blue">
                  {t('no data')}
                </Tag>
              )}
            </Flex>
            <Flex
              justify="space-between"
              className={openedTeamPlayer?.birthday ? '' : 'no-data-span-label'}
              style={{ fontSize: 16 }}
            >
              <span className="span-label">{t('Contract expires')} </span>
              {openedTeamPlayer?.contract_untill ? (
                <span style={{ fontSize: 16 }}>
                  <Tag bordered={false} color="blue">
                    {dayjs(openedTeamPlayer?.contract_untill).format(
                      'DD.MM.YYYY',
                    )}
                  </Tag>
                </span>
              ) : (
                <Tag bordered={false} color="blue">
                  {t('no data')}
                </Tag>
              )}
            </Flex>
          </Flex>
          <Divider type="vertical" />
        </Flex>
      </Flex>
    </ConfigProvider>
  );
};
export default PersonalInfo;
