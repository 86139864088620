import React, { FC, useEffect } from 'react';

import { Button, ConfigProvider, message, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import {
  AC_SetIsPlayingVideoAction,
  AC_SetPlayerHotkeysAllowedAction,
} from 'actions/player.acitons';
import { NotificationsAPI } from 'api/notifications';
import { ReactComponent as ErrorIcon } from 'assets/img/icons/faColorCircleCrossError.svg';
import { ReactComponent as SuccessIcon } from 'assets/img/icons/faSuccessGreemCheckIcon.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as WarningIcon } from 'assets/img/icons/faWarningToasIcon.svg';
import { AppStateType } from 'reducers';
import './style.css';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { videosListReducer } from 'reducers/videosList.reducer';
import { useAppDispatch } from 'store';

export const Toast: FC<any> = () => {
  const { notificationIsOpen, notificationParameters } = useSelector(
    (state: AppStateType) => state.notificationsReducer,
  );
  // const [messageApi, contextHolder] = message.useMessage();
  const [messageApi, contextHolder] = notification.useNotification();
  message.config({
    prefixCls: 'notification-toast',
  });
  const { closeNotification } = notificationsReducer.actions;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (notificationIsOpen) {
      notificationParameters.forEach((parameter: any) => {
        if (parameter?.type === 'error') {
          messageApi.error({
            message: <></>,
            description: renderToast(parameter),
            duration: 3,
            className: 'notification-toast error',
            icon: <ErrorIcon />,
            placement: 'bottomRight',
            closeIcon: null,
          });
        } else if (parameter?.type === 'warning') {
          messageApi.error({
            message: <></>,
            description: renderToast(parameter),
            duration: 3,
            className: 'notification-toast warning',
            icon: <WarningIcon />,
            placement: 'bottomRight',
            closeIcon: null,
          });
        } else {
          messageApi.success({
            message: <></>,
            description: renderToast(parameter),
            duration: 3,
            className: 'notification-toast',
            placement: 'bottomRight',
            closeIcon: null,
            icon: <SuccessIcon />,
          });
        }
        dispatch(closeNotification({ id: parameter.id }));
      });
    }
  }, [notificationIsOpen, notificationParameters]);

  const [t] = useTranslation();
  const navigate = useNavigate();
  const { setEpisodeIdsToAddToPlaylist } = videosListReducer.actions;
  const CALLBACK_ACTIONS: any = {
    removeFromPlaylists: {
      // label: t('Remove from Playlists'),
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      callback: (parameter: any) => {},
    },
    addToPlaylist: {
      label: t('Add to playlist'),
      callback: (parameter: any) => {
        dispatch(
          setEpisodeIdsToAddToPlaylist({
            episodeIds: [parameter?.userEpsiodeId],
          }),
        );
        dispatch(AC_SetIsPlayingVideoAction(false));
        dispatch(AC_SetPlayerHotkeysAllowedAction(true));
      },
    },
    downloadFile: {
      label: t('Download'),
      callback: (parameter: any) => {
        // console.log(parameter);
        window.open(parameter.url, '_blank');
        NotificationsAPI.readNotification(parameter.id).then();
      },
    },
    openDownloads: {
      label: t('Go to downloads'),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      callback: (parameter: any) => {
        navigate('/downloads');
      },
    },
    addPlaylistEpisodeToAnotherPlaylist: {
      label: t('Add to another playlist'),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      callback: (parameter: any) => {
        dispatch(
          setEpisodeIdsToAddToPlaylist({
            episodeIds: [parameter?.userEpsiodeId],
          }),
        );
        dispatch(AC_SetIsPlayingVideoAction(false));
        dispatch(AC_SetPlayerHotkeysAllowedAction(true));
      },
    },
    removeFolder: {
      // label: 'Remove folder',
      // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
      callback: (parameter: any) => {},
    },
  };
  const renderToast = (parameter: any) => {
    return (
      <div className="toast-content">
        <div>{parameter.text}</div>
        {parameter.callbackName && (
          <Button
            onClick={() =>
              CALLBACK_ACTIONS[parameter.callbackName]['callback'](parameter)
            }
            type="primary"
          >
            {CALLBACK_ACTIONS[parameter.callbackName].label}
          </Button>
        )}
      </div>
    );
  };
  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Notification: {
              width: 510,
            },
          },
          token: {
            paddingMD: 12,
            paddingContentHorizontalLG: 16,
          },
        }}
      >
        {contextHolder}
      </ConfigProvider>
    </>
  );
};
