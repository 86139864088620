/* eslint-disable import/no-extraneous-dependencies */
import _toConsumableArray from '@babel/runtime/helpers/esm/toConsumableArray';
import { fillFieldNames } from 'rc-tree/es/utils/treeUtil';
const RECORD_NONE = 0;
const RECORD_START = 1;
const RECORD_END = 2;
function traverseNodesKey(treeData, callback, fieldNames) {
  const { key: fieldKey, children: fieldChildren } = fieldNames;
  function processNode(dataNode) {
    const key = dataNode[fieldKey];
    const children = dataNode[fieldChildren];
    if (callback(key, dataNode) !== false) {
      traverseNodesKey(children || [], callback, fieldNames);
    }
  }
  treeData.forEach(processNode);
}
/** 计算选中范围，只考虑expanded情况以优化性能 */
export function calcRangeKeys(_ref) {
  let { treeData, expandedKeys, startKey, endKey, fieldNames } = _ref;
  const keys = [];
  let record = RECORD_NONE;
  if (startKey && startKey === endKey) {
    return [startKey];
  }
  if (!startKey || !endKey) {
    return [];
  }
  function matchKey(key) {
    return key === startKey || key === endKey;
  }
  traverseNodesKey(
    treeData,
    (key) => {
      if (record === RECORD_END) {
        return false;
      }
      if (matchKey(key)) {
        // Match test
        keys.push(key);
        if (record === RECORD_NONE) {
          record = RECORD_START;
        } else if (record === RECORD_START) {
          record = RECORD_END;
          return false;
        }
      } else if (record === RECORD_START) {
        // Append selection
        keys.push(key);
      }
      return expandedKeys.includes(key);
    },
    fillFieldNames(fieldNames),
  );
  return keys;
}
export function convertDirectoryKeysToNodes(treeData, keys, fieldNames) {
  const restKeys = _toConsumableArray(keys);
  const nodes = [];
  traverseNodesKey(
    treeData,
    (key, node) => {
      const index = restKeys.indexOf(key);
      if (index !== -1) {
        nodes.push(node);
        restKeys.splice(index, 1);
      }
      return !!restKeys.length;
    },
    fillFieldNames(fieldNames),
  );
  return nodes;
}
