import React, { FC, useEffect, useState } from 'react';

import './index.css';
import 'react-day-picker/dist/style.css';
// import { Checkbox, ConfigProvider, Tooltip } from 'antd';
// import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import GameSelectorComponent from './GameSelectorComponent';
import GamesFilter from './GamesFilter';
import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectTimingIntervalFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetSelectedAttributes,
  AC_SetSelectedDateLimitsFilterAction,
  AC_SetShowMyEpisodesFilterAction,
  AC_SetShowPlayerGamesFilterAction,
  AC_SetShowPlayerIntervalsFilterAction,
  setLastXFilterAction,
} from '../../../../actions/filter.actions';
import { AppStateType } from '../../../../reducers';
import { useAppDispatch } from '../../../../store';
import { EpisodeFilterPanelTypes, TeamType } from '../../../../types/types';
import FiltersContainer from '../FiltersContainer';

interface FilterInterface {
  hideCallback: any;
  nextCallback: any;
  applyFilter: any;
  filtersControls: { filters: any; updateFilters: any };
  preFilterEpisodesCount: number | null;
  preFilterLoading: boolean;
}
const customSelector = createSelector(
  (state: any) => state.filtersReducer,
  (activeTeam: number | null) => {
    return activeTeam;
  },
  (showPlayerGames: boolean) => {
    return showPlayerGames;
  },
  (selectedPlayers: any) => {
    return selectedPlayers;
  },
  (selectedGames: any) => {
    return selectedGames;
  },
);

const EventsFilter: FC<FilterInterface> = ({
  hideCallback,
  nextCallback,
  applyFilter,
  // filtersControls,
  preFilterEpisodesCount,
  preFilterLoading,
}) => {
  const { teams } = useSelector((state: AppStateType) => state.clubsReducer);
  // const { selectedPlayers } = useSelector(customSelector);
  const { selectedGames } = useSelector(customSelector);

  const { selectedDateLimits } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeFilterTeam, setActiveFilterTeam] = useState<TeamType | null>(
    teams.length > 0 ? teams[0] : null,
  );

  // const MODES = {
  //   match: 'match',
  //   training: 'training',
  // };
  // const [panelMode, setPanelMode] = useState(MODES.match);
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [selectedDateLimits]);
  const dispatch = useAppDispatch();
  const clearFilter = async (type: string) => {
    if (type === 'matches') {
      dispatch(AC_SelectedMatchesFilterAction([]));
      dispatch(AC_AddSelectedTournamentFilterAction([]));
      dispatch(AC_SetSelectedDateLimitsFilterAction({}));
      dispatch(AC_SetShowPlayerGamesFilterAction(false));
      dispatch(setLastXFilterAction(null));
      dispatch(AC_SelectTimingIntervalFilterAction({}));
      dispatch(AC_SetShowPlayerIntervalsFilterAction(false));
      dispatch(AC_SetSelectedAttributes([]));
      dispatch(AC_SetShowMyEpisodesFilterAction(false));
    }
    if (type === 'training') {
      // dispatch(AC_ClearTrainingsFiltersCommand(true));
    }
  };

  // const [t] = useTranslation();
  const [localSelectedGames, setLocalSelectedGames] = useState<{
    [key: string]: string[];
  }>({});

  const actualSetSelectedTeams = (newTeams: any) => {
    setLocalSelectedGames(newTeams);
  };
  // const toggleShowPlayerGames = () => {};

  return (
    <FiltersContainer
      gap={24}
      type={EpisodeFilterPanelTypes.matches}
      closeCallback={hideCallback}
      clearCallback={clearFilter}
      applyCallback={applyFilter}
      nextCallback={nextCallback}
      applyCount={
        preFilterEpisodesCount !== null
          ? preFilterEpisodesCount
          : selectedGames.length
      }
      preFilterLoading={preFilterLoading}
    >
      <div className={'games_filter_content_wrapper'}>
        <div className="games_filter_separate_filters_wrapper">
          <div className="games_filter_columns_wrapper">
            <GameSelectorComponent activeFilterTeam={activeFilterTeam} />
          </div>
          <div className="games_filter_other_filters">
            {/* temporarily removed */}
            {/* {false && (
              <>
                <div
                  style={{
                    height: 1,
                    background: 'rgba(255, 255, 255, 0.12)',
                    width: '100%',
                  }}
                ></div>
                <Tooltip
                  title={t(
                    'This metric calculation in planned for future releases',
                  )}
                >
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: '#141414',
                        colorBorder: '#424242',
                        lineWidth: 1,
                      },
                    }}
                  >
                    <Checkbox
                      disabled
                      checked={showPlayerGames}
                      onChange={() =>
                        dispatch(
                          AC_SetShowPlayerGamesFilterAction(!showPlayerGames),
                        )
                      }
                      type="checkbox"
                    >
                      {t('Показать только сыгранное игроком время матча ')}
                    </Checkbox>
                  </ConfigProvider>
                </Tooltip>
              </>
            )} */}
          </div>
        </div>
        <div className="games_filter_matches_wrapper">
          <GamesFilter
            filterType={'game'}
            selectedGamesController={{
              selectedGames: localSelectedGames,
              setSelectedGames: actualSetSelectedTeams,
            }}
            // gameListLoading={prefilteredGamesLoading}
            // gamesList={prefilteredGames}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};
export default EventsFilter;
