import React, { useRef } from 'react';

import { Button, ConfigProvider, Input } from 'antd';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { useAppDispatch } from 'store';

import { ReactComponent as CheckboxFilled } from '../../../assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as CloseCross } from '../../../assets/img/icons/faCloseCrossWhite.svg';

const RenameController = ({
  setNewEpisodeName,
  newEpisodeName,
  setRenameMode,
  renameEpisodeHandler,
}: {
  setNewEpisodeName: any;
  newEpisodeName: string;
  setRenameMode: any;
  renameEpisodeHandler: any;
}) => {
  const inputRef = useRef(null);
  const dispatch = useAppDispatch();
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBorder: 'var(--Primary-5)',
          },
        },
        token: {
          colorBgBase: 'transparent',
        },
      }}
    >
      <Input
        ref={inputRef}
        className="episode-rename-input"
        type="text"
        name="newEpisodeName"
        onChange={(event: any) => {
          setNewEpisodeName(event.target.value);
        }}
        autoFocus={true}
        onFocus={() => {
          dispatch(AC_SetPlayerHotkeysAllowedAction(false));
        }}
        onBlur={() => {
          dispatch(AC_SetPlayerHotkeysAllowedAction(true));
        }}
        defaultValue={newEpisodeName}
        placeholder={newEpisodeName}
        maxLength={100}
        style={{
          background: 'transparent',
          color: 'var(--colorText)',
          border: '1px solid var(--Primary-5, #40A9FF)',
          borderRadius: '8px',
        }}
      ></Input>
      <Button
        style={{
          border: '1px solid var(--colorIcon)',
          background: 'transparent',
          height: 24,
          width: 32,
        }}
        onClick={() => {
          setNewEpisodeName(null);
          setRenameMode(false);
          dispatch(AC_SetPlayerHotkeysAllowedAction(true));
        }}
        icon={<CloseCross />}
      />
      <Button
        style={{
          height: 24,
          width: 32,
          border: '1px solid var(--colorPrimaryBase)',
          background: 'rgba(22, 104, 220, 0.4)',
        }}
        onClick={() => renameEpisodeHandler()}
        icon={<CheckboxFilled />}
        type="default"
      />
    </ConfigProvider>
  );
};
export default RenameController;
