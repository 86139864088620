import axios from 'axios';

import { API_ENDPOINT } from '../types/constants';
import {
  CalendarDayEventType,
  CalendarEventTypeAPI,
  CalendarEventUpdateTypeAPI,
} from '../types/types';
import myAxios from '../utils/axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require('js-file-download');

const paginate = (queryParams: any) => {
  const params = new URLSearchParams();
  if ('page' in queryParams && queryParams.page) {
    params.append('page', queryParams.page?.toString());
  }
  return params;
};
export const addParams = (json: any, searchParams: URLSearchParams) => {
  for (const key in json) {
    // eslint-disable-next-line no-prototype-builtins
    if (json.hasOwnProperty(key)) {
      const value = json[key];
      searchParams.append(key, value);
    }
  }
  return searchParams;
};
const addNewActivity = async (
  clubId: number,
  eventData: CalendarEventTypeAPI,
) => {
  const url = `${API_ENDPOINT}api/data/activities/`;
  eventData.activity_videos = eventData.event_videos;
  try {
    return await myAxios.POST(url, eventData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const addNewActivityByCamera = async (eventData: CalendarEventTypeAPI) => {
  const url = `${API_ENDPOINT}api/data/activities/start_by_record/`;
  try {
    return await myAxios.POST(url, eventData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const stopActivityByCamera = async (eventId: string) => {
  const url = `${API_ENDPOINT}api/data/activities/${eventId}/stop_by_record/`;
  try {
    return await myAxios.PATCH(url, eventId);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const updateActivity = async (
  eventId: string,
  eventData:
    | CalendarEventTypeAPI
    | CalendarDayEventType
    | CalendarEventUpdateTypeAPI,
) => {
  const url = `${API_ENDPOINT}api/data/activities/${eventId}/`;
  try {
    return await myAxios.PATCH(url, eventData);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const deleteActivity = async (eventId: string) => {
  const url = `${API_ENDPOINT}api/data/activities/${eventId}/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const getListOfActivities = async (
  teamId: number,
  year: number,
  month: number,
) => {
  const url = `${API_ENDPOINT}api/data/activities/`;
  const params = new URLSearchParams();
  params.append('teams', teamId.toString());
  if (year) {
    params.append('year', year.toString());
  }
  if (month) {
    params.append('month', month.toString());
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getSingleActivity = async (eventId: string) => {
  const url = `${API_ENDPOINT}api/data/activities/${eventId}`;

  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const uploadVideoFile = async (
  file: any,
  progressCallback: any,
  abortController: any,
) => {
  const url = `${API_ENDPOINT}videos/upload/`;
  try {
    return await myAxios.POST(
      url,
      { file: file },
      {},
      {},
      'json',
      'multipart/form-data',
      null,
      {
        onUploadProgress: (progressEvent: any) => {
          progressCallback(file, progressEvent);
        },
        onDownloadProgress: (progressEvent: any) => {
          const progress =
            100 + (progressEvent.loaded / progressEvent.total) * 100;
          file.progress = progress;
          progressCallback(progress);
        },
        signal: abortController.signal,
        // cancelToken: new axios.CancelToken((cancel: any) => source.current = cancel),
      },
    );
  } catch (e: any) {
    console.log('ERROR IN API', e);
    if (axios.isCancel(e)) {
      progressCallback(file, undefined, 0);
    }
    throw e;
  }
  return {};
};
/* PLAYLISTS */

/* END PLAYLISTS */
/*ATTRIBUTES*/
const getListOfAttributes = async (queryParams: any) => {
  const url = `${API_ENDPOINT}api/data/attributes/?with_events=True`;
  try {
    // console.log('params', queryParams);
    return await myAxios.POST(url, queryParams, {});
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

/*EPISODES*/
const getEpisodes = async (episodeFilters: any) => {
  const url = `${API_ENDPOINT}api/data/events/by_activity/`;
  // const queryParams = paginate(episodeFilters);
  // let params = paginate(queryParams);
  // params = addParams(episodeFilters, params);
  try {
    return await myAxios.POST(url, episodeFilters);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getEpisodesCount = async (episodeFilters: any) => {
  console.log();
  const url = `${API_ENDPOINT}api/data/events/by_activity/`;
  try {
    return await myAxios.POST(url, episodeFilters);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getEpisodesByIds = async (
  episodeIds: Array<string>,
  page?: number,
  cancelToken?: any,
) => {
  const url = `${API_ENDPOINT}api/data/events/by_ids/`;
  const queryParams = paginate({ page: page });
  try {
    return await myAxios.POST(
      url,
      { ids: episodeIds },
      {},
      queryParams,
      'json',
      'application/json',
      cancelToken,
    );
  } catch (e: any) {
    console.log('e', e);
    return { data: {} };
  }
  return {};
};
const updateEpisodeByUserTimer = async (eventId: string, timers: any) => {
  const url = `${API_ENDPOINT}api/data/events/${eventId}/user_timer/`;
  // const queryParams = paginate(episodeFilters);
  // let params = paginate(queryParams);
  // params = addParams(episodeFilters, params);
  try {
    return await myAxios.PATCH(url, timers);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const removeUserTimer = async (eventId: string) => {
  const url = `${API_ENDPOINT}api/data/events/${eventId}/user_timer/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const deletePlaylistLink = async (playlistId: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/link/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const createDownloadLink = async (
  eventIds: string[],
  fullVideosIds: string[],
  merge: boolean,
  filters: any,
  add_highlights = false,
  add_standards = false,
  as_reels = false,
) => {
  const url = `${API_ENDPOINT}api/data/downloads/`;
  try {
    return await myAxios.POST(url, {
      events: eventIds,
      full_videos: fullVideosIds,
      merge: merge,
      add_highlights: add_highlights,
      add_standards: add_standards,
      as_reels: as_reels,
      filters: filters,
    });
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const getEventsDateLimits = async (team?: number) => {
  const params = new URLSearchParams();
  if (team) {
    params.append('team', team.toString());
  }
  const url = `${API_ENDPOINT}api/data/activities/date_limits/`;
  // const params = paginate(queryParams);
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPreFilterGames = async (activitFilters: any) => {
  const url = `${API_ENDPOINT}api/data/activities/pre_filter/`;
  // const params = paginate(queryParams);
  try {
    return await myAxios.POST(url, activitFilters);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const downloadArchive = async (archiveId: string) => {
  const url = `${API_ENDPOINT}api/data/downloads/${archiveId}/download_file/`;
  // const params = paginate(queryParams);
  try {
    const response = await myAxios.GET(url, {}, {}, 'blob');
    FileDownload(response.data, 'videos.zip');
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getUserEpisodeNames = async (episodeName: string) => {
  const params = new URLSearchParams();
  params.append('search', episodeName.toString());
  const url = `${API_ENDPOINT}api/data/user_events/types/`;
  try {
    const response = await myAxios.GET(url, params);
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    return [];
  }
};
const addNewUserEpisode = async (episode: any) => {
  const url = `${API_ENDPOINT}api/data/user_events/`;
  // const params = paginate(queryParams);
  try {
    return await myAxios.POST(url, episode);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getUserEpisodesByIds = async (
  episodeIds: Array<string>,
  page?: number,
  cancelToken?: any,
) => {
  const url = `${API_ENDPOINT}api/data/user_events/by_ids/`;
  const queryParams = paginate({ page: page });
  try {
    return await myAxios.POST(
      url,
      { ids: episodeIds },
      {},
      queryParams,
      'json',
      'application/json',
      cancelToken,
    );
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const getGameStatsBySeason = async (season: string | null, teamId: string) => {
  const url = `${API_ENDPOINT}api/data/events/season_stats/`;
  const params = new URLSearchParams();
  if (season) {
    params.append('season', season);
  }
  params.append('teamId', teamId);
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPlayerGamesStats = async (
  season: string | null,
  page: number | null,
  limit: number | null,
  selectedMinutes: string | null,
  selectedPlayedSchemas: string | null,
  selectedPositions: string | null,
  playerId: string,
) => {
  const url = `${API_ENDPOINT}api/data/events/player_stats/`;
  const params = new URLSearchParams();
  if (season) {
    params.append('season', season);
  }
  if (page) {
    params.append('page', page.toString());
  }
  if (limit) {
    params.append('limit', limit.toString());
  }
  params.append('playerId', playerId);
  if (selectedMinutes) {
    params.append('minutes', selectedMinutes);
  }
  if (selectedPlayedSchemas) {
    params.append('schemas', selectedPlayedSchemas);
  }
  if (selectedPositions) {
    params.append('positions', selectedPositions);
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPlayerGameStatsBySeason = async (
  seasonId: string | null,
  playerId: string,
  selectedPositions: string,
  games: string[] = [],
  getPth = false,
) => {
  const url = `${API_ENDPOINT}api/data/events/player_season_stats/`;
  const params = new URLSearchParams();
  if (seasonId) {
    params.append('season', seasonId);
  }
  if (games) {
    games.forEach((game) => params.append('games', game));
  }
  params.append('selectedPositions', selectedPositions);
  params.append('playerId', playerId);
  params.append('getPth', getPth.toString());
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPlayerGameEventCoordinates = async (
  matchId: string,
  metric: string[],
  playerId: string,
  results: string[],
  lastX = 0,
) => {
  const url = `${API_ENDPOINT}api/data/events/player_event_details/`;
  const params = new URLSearchParams();
  params.append('matchId', matchId);
  params.append('playerId', playerId);
  metric.forEach((metric) => params.append('metrics', metric));
  params.append('coords', 'true');
  params.append('timing', 'false');
  if (lastX) {
    params.append('lastX', lastX.toString());
  }
  results.forEach((result) => params.append('result', result));
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPlayerEventsCloud = async (playerId: string, lastX = 0) => {
  const url = `${API_ENDPOINT}api/data/events/player_events_cloud/`;
  const params = new URLSearchParams();
  params.append('playerId', playerId);
  if (lastX) {
    params.append('lastX', lastX.toString());
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getPlayerGameEventDetails = async (
  matchId: string,
  metrics: string[],
  playerId: string,
  results: string[],
) => {
  const url = `${API_ENDPOINT}api/data/events/player_event_details/`;
  const params = new URLSearchParams();
  params.append('matchId', matchId);
  params.append('playerId', playerId);
  metrics.forEach((metric: string) => params.append('metrics', metric));
  params.append('coords', 'false');
  params.append('timing', 'true');
  results.forEach((result) => params.append('result', result));
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const getMetricsRegistry = async (
  calculateFor: string | null,
  viewedIn: string | null,
) => {
  const url = `${API_ENDPOINT}api/data/metrics/`;
  const params = new URLSearchParams();
  if (calculateFor) {
    params.append('calculated_for', calculateFor);
  }
  if (viewedIn) {
    params.append('viewed_in', viewedIn);
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const EventsAPI = {
  addNewActivity,
  addNewActivityByCamera,
  stopActivityByCamera,
  getListOfActivities,
  getSingleActivity,
  updateActivity,
  deleteActivity,
  uploadVideoFile,

  deletePlaylistLink,

  createDownloadLink,

  getListOfAttributes,

  getEpisodes,
  getEpisodesByIds,
  updateEpisodeByUserTimer,
  removeUserTimer,
  getPreFilterGames,

  getEventsDateLimits,
  downloadArchive,

  getUserEpisodeNames,
  addNewUserEpisode,
  getUserEpisodesByIds,
  getGameStatsBySeason,
  getEpisodesCount,

  getPlayerGamesStats,
  getPlayerGameEventCoordinates,
  getPlayerEventsCloud,
  getPlayerGameEventDetails,
  getPlayerGameStatsBySeason,

  getMetricsRegistry,
};
