import React, { useEffect, useState } from 'react';

import { Button, Checkbox, Drawer, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { EventsAPI } from 'api/events';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';
import { formatDatetimeToDate } from 'types/functions';
import { formatScore } from 'utils/functions';

import { ReactComponent as CloseCrossIcon } from '../../assets/img/icons/faCloseCross.svg';
import Loader from '../Loader';
import './index.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Title = ({ onClose }: { onClose: any }) => {
  const [t] = useTranslation();
  return (
    <Flex justify="space-between" align="center">
      <div>{t('Select matches')}</div>
      <CloseCrossIcon className="enabledClickable" onClick={onClose} />
    </Flex>
  );
};
const Footer = ({
  onClose,
  acceptCallable,
  isDisabled,
}: {
  onClose: any;
  acceptCallable: any;
  isDisabled: boolean;
}) => {
  const [t] = useTranslation();
  return (
    <div className="flex-row f-ga-8">
      <Button style={{ flex: 1 }} type="default" onClick={onClose}>
        {t('Cancel')}
      </Button>
      <Button
        onClick={() => acceptCallable()}
        disabled={isDisabled}
        style={{ flex: 1 }}
        type="primary"
      >
        {t('Select')}
      </Button>
    </div>
  );
};

const GameSelectSidePanel = () => {
  const [t] = useTranslation();
  const { selectedMatchesStats, showGameSelectionModal } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    setSelectedMatchesStats,
    setSelectedSeason,
    setShowGameSelectionModal,
    setManualGamesSelectionSection,
    setStatsCustomGameListPlayer,
  } = teamPlayerReducer.actions;
  const { playerId } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [localSelected, setLocalSelected] = useState(selectedMatchesStats);
  const [matches, setMatches] = useState([]);

  const setGames = () => {
    dispatch(setSelectedMatchesStats(localSelected));
    dispatch(setStatsCustomGameListPlayer(localSelected));
    dispatch(setSelectedSeason('manual'));
    dispatch(setShowGameSelectionModal(false));
  };
  const closeCallBack = () => {
    dispatch(setShowGameSelectionModal(false));
    dispatch(setStatsCustomGameListPlayer([]));
    dispatch(setManualGamesSelectionSection(''));
  };
  const toggleSelectedMatch = (matchId: string) => {
    if (localSelected.includes(matchId)) {
      setLocalSelected((prev: string[]) => prev.filter((el) => el !== matchId));
    } else {
      setLocalSelected((prev: string[]) => [...prev, matchId]);
    }
  };
  const { openedTeamPlayer } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  useEffect(() => {
    const getMatches = async () => {
      if (playerId && openedTeamPlayer) {
        const filter: any = {
          players: {
            [openedTeamPlayer.team.id.toString()]: [openedTeamPlayer.id],
          },
          type: 'game',
        };
        setIsLoading(true);
        const matchesStatsResponse = await EventsAPI.getPreFilterGames(filter);
        if (matchesStatsResponse.data?.results) {
          setMatches(matchesStatsResponse.data.results);
        }
        setIsLoading(false);
      }
    };
    showGameSelectionModal && getMatches().then();
  }, [showGameSelectionModal, openedTeamPlayer]);
  const getGameName = (game: any) => {
    return `${game.home_team || t('Unknown team')} ${
      formatScore(game.result) || ''
    } ${game.away_team || t('Unknown team')}`;
  };
  return (
    <Drawer
      width={400}
      closable={false}
      styles={{
        body: {
          padding: '0px 24px',
        },
        content: {
          borderRadius: '12px 0px 0px 12px',
        },
      }}
      onClose={closeCallBack}
      open={showGameSelectionModal}
      title={<Title onClose={closeCallBack} />}
      footer={
        <Footer
          onClose={closeCallBack}
          isDisabled={localSelected.length === 0}
          acceptCallable={setGames}
        />
      }
    >
      {isLoading && (
        <div className="flex-row jc-c">
          <Loader color="black" />
        </div>
      )}
      {!isLoading &&
        matches.map((match: any) => {
          return (
            <div
              className="gameBlock flex-row f-ga-12 oy-s"
              style={{ padding: '8px 0px' }}
              onClick={() => toggleSelectedMatch(match.id)}
            >
              <div>
                <Checkbox checked={localSelected.includes(match.id)} />
              </div>
              <div className="flex-column oy-h">
                <div
                  className={'enabledClickable gameBlockTitle elipsis-text'}
                  style={{
                    color: `var(${
                      localSelected.includes(match.id)
                        ? '--colorTextHeading'
                        : '--colorTextLabel'
                    })`,
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => {}}
                >
                  {getGameName(match)}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    alignSelf: 'stretch',
                    color: `var(${
                      localSelected.includes(match.id)
                        ? '--colorTextLabel'
                        : '--colorTextDescription'
                    })`,
                    overflow: 'hidden',
                  }}
                >
                  <div>{formatDatetimeToDate(match.started_at)}</div>
                  {match.country && (
                    <>
                      <div className="filtersPanelTopBar_content_matchLine_divider_light"></div>
                      <div className="elipsis-text">{match.country}</div>
                    </>
                  )}
                  {match.tournament && (
                    <>
                      <div className="filtersPanelTopBar_content_matchLine_divider_light elipsis-text"></div>
                      <div className="elipsis-text">{match.tournament}</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </Drawer>
  );
};
export default GameSelectSidePanel;
