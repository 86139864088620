import './index.css';
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  AC_ClearErrorAction,
  SendLoginRequestAction,
} from 'actions/auth.actions';
import WarningSign from 'assets/img/warningSign.svg';
import InputWithClear from 'components/InputWithClear';
import { AppStateType } from 'reducers';

const LoginPage = () => {
  const { error } = useSelector((state: AppStateType) => state.authReducer);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hidden, setHidden] = useState<boolean>(false);
  const [inputPassType, setInputPassType] = useState('password');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginError, setLoginError] = useState(false);
  const [t] = useTranslation();
  useEffect(() => {
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  const toggleInputPassType = () => {
    if (inputPassType === 'password') {
      setInputPassType('text');
    } else {
      setInputPassType('password');
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleHidden = () => {
    setHidden((prev) => !prev);
    toggleInputPassType();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      if (error) {
        setLoginError(true);
        dispatch(AC_ClearErrorAction());
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [error]);
  const sendLogin = (e: any) => {
    e.preventDefault();
    // @ts-ignore
    dispatch(SendLoginRequestAction(email, password));
  };
  const { loggedIn } = useSelector((state: AppStateType) => state.authReducer);
  useEffect(() => {
    const localToken = localStorage.getItem('authtoken');
    const redirectLink = localStorage.getItem('redirectTo');
    if (localToken && redirectLink) {
      window.location.href = redirectLink;
    }
  }, [loggedIn]);
  const getTopOffset = (fieldRef: any) => {
    if (fieldRef.current) {
      return fieldRef?.current?.getBoundingClientRect().top;
    }
  };
  const getLeftOffset = (fieldRef: any) => {
    return fieldRef?.current?.offsetLeft + fieldRef.current.clientWidth + 20;
  };
  const passwordRef = useRef<any>();

  return (
    <div className="loginFormContainer">
      <div className="loginFormHeader">{t('Log in')}</div>
      <div className="loginForm">
        <form name="loginForm">
          <div>
            <InputWithClear
              isRequired={true}
              valueController={{ value: email, setValue: setEmail }}
              fieldType={'email'}
              fieldName={'email'}
              labelText={t('Enter email:')}
              validate={true}
            />
            <InputWithClear
              isRequired={true}
              valueController={{ value: password, setValue: setPassword }}
              fieldType={'password'}
              fieldName={'password'}
              labelText={t('Enter password:')}
              clearIcon={false}
              ref={passwordRef}
            />
            <label>
              <a href="/recover-password">{t('Forgot password?')}</a>
            </label>
            {error && (
              <div
                className="warningFieldRequired"
                style={{
                  top: `${getTopOffset(passwordRef)}px`,
                  left: `${getLeftOffset(passwordRef)}px`,
                }}
              >
                <img src={WarningSign} />
                <div>{t('Login or password is wrong')}</div>
              </div>
            )}
          </div>
          <div className="buttonContainer">
            <button onClick={sendLogin}>{t('Enter')}</button>
          </div>
          <div className="linkContainer">
            <Link to="/join">{t('Register')}</Link>
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoginPage;
