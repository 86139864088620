import React, { FC, ReactElement, ReactPortal } from 'react';

import './index.css';
import { ConfigProvider } from 'antd';
import Layout, { Content } from 'antd/es/layout/layout';
// import { useSelector } from 'react-redux';

// import { AC_setLanguageAction } from 'actions/interface.actions';
// import i18n from 'i18n';
// import { AppStateType } from 'reducers';
// import { useAppDispatch } from 'store';

import { Toast } from 'ui/Toast/Toast';

import NavigationPanelV2 from '../NavigationPanelV2';

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;
type ReactNodeArray = Array<ReactNode>;
// eslint-disable-next-line @typescript-eslint/ban-types
type ReactFragment = {} | ReactNodeArray;
type ReactNode =
  | ReactChild
  | ReactFragment
  | ReactPortal
  | boolean
  | null
  | undefined
  | string;

type ContentContainerV2Interface = {
  pageTitle: string;
  page: any;
  showNavigationPanel?: boolean;
  styleMode?: string;
  rightPanel?: any;
  children?: any;
  style?: any;
};
const ContentContainerV2: FC<ContentContainerV2Interface> = (props) => {
  // const { style } = useSelector(
  //   (state: AppStateType) => state.interfaceReducer,
  // );
  // const useNarrowMargins = () => {
  //   return window.location.pathname === '/player'
  //     ? 'narrowPageContentContainer'
  //     : '';
  // };
  // const dispatch = useAppDispatch();
  // const [languageSelectorVisible, setLanguageSelectorVisible] = useState(false);
  // const toggleVisibleSelector = () => {
  //   setLanguageSelectorVisible((prev) => !prev);
  // };
  // const changeLanguage = (languageCode: string) => {
  //   dispatch(AC_setLanguageAction(languageCode));
  //   setLanguageSelectorVisible(false);
  //   // localStorage.setItem('i18nextLng', languageCode);
  //   i18n.changeLanguage(languageCode);
  // };

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            colorBgLayout: 'var(--colorBgLayout)',
          },
        },
      }}
    >
      <Layout style={{ height: '100%' }}>
        <NavigationPanelV2 page={props.page} />
        <Content
          style={{
            padding: props?.style?.padding || '0 48px',
            overflowY: 'auto',
          }}
        >
          {props.children}
          <Toast />
        </Content>
      </Layout>
    </ConfigProvider>
    // <div className={`mainContainer ${style}`}>
    //     {
    //         props.showNavigationPanel && <NavigationPanelV2/>
    //     }
    //     <div className={`pageContentContainer ${useNarrowMargins()} ${props.showNavigationPanel ? null : 'wideScreenContainer'}`}>
    //         <div className="pageTitle" >
    //             <div>{props.pageTitle}</div>
    //         </div>
    //         <div className="pageContent">{props.children}</div>
    //     </div>
    //     {props.rightPanel}
    // </div>
  );
};
export default ContentContainerV2;
