import { API_ENDPOINT } from 'types/constants';
import { UserUpdateType } from 'types/types';
import myAxios from 'utils/axios';

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  // console.log('API_ENDPOINT', API_ENDPOINT)
  const url = `${API_ENDPOINT}api/auth/token/`;
  try {
    return await myAxios.POST(url, {
      email: email,
      password: password,
    });
  } catch (e: any) {
    console.log(e);
    throw e;
  }
};
export const registerByOTPCode = async ({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}) => {
  const url = `${API_ENDPOINT}api/auth/invitation/code`;
  try {
    const response = await myAxios.POST(url, {
      email: email,
      password: password,
      code: code,
    });
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const resetPassword = async ({ email }: { email: string }) => {
  const url = `${API_ENDPOINT}api/auth/reset-password/`;
  try {
    const response = await myAxios.POST(url, { email: email });
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const checkResetToken = async (token: string) => {
  const url = `${API_ENDPOINT}api/auth/confirm-new-password/`;
  try {
    return await myAxios.GET(url, { token: token });
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const checkRegistrationLink = async (linkId: string) => {
  const url = `${API_ENDPOINT}api/auth/invitation/${linkId}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const registerByLink = async (
  email: string,
  password: string,
  linkId: string,
) => {
  const url = `${API_ENDPOINT}api/auth/invitation/${linkId}`;
  try {
    return await myAxios.POST(url, {
      email: email,
      password: password,
      link: linkId,
    });
  } catch (e: any) {
    console.log('e', e);
    return e.response;
  }
  return {};
};
export const confirmNewPassword = async (
  newPassword: string,
  token: string,
) => {
  const url = `${API_ENDPOINT}api/auth/confirm-new-password/`;
  try {
    return await myAxios.POST(
      url,
      { password: newPassword },
      {},
      { token: token },
    );
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const getCurrentUser = async () => {
  const url = `${API_ENDPOINT}api/auth/users/me/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const updateUser = async (
  userId: number,
  updateParameters: UserUpdateType,
) => {
  const url = `${API_ENDPOINT}api/auth/users/${userId}/`;
  try {
    return await myAxios.PATCH(url, updateParameters);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

export const AuthAPI = {
  login,
  registerByOTPCode,
  resetPassword,
  checkResetToken,
  confirmNewPassword,
  checkRegistrationLink,
  registerByLink,
  getCurrentUser,
  updateUser,
};
