import React, { FC, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RegistriesAPI } from 'api/registries';
import CrossIconGrey from 'assets/img/CrossIconGrey.svg';
import SelectDownArrowBlack from 'assets/img/SelectDownArrowBlack.svg';
import SelectUpArrowBlack from 'assets/img/SelectUpArrowBlack.svg';
import { AppStateType } from 'reducers';
import { TournamentSimpleType } from 'types/types';

import './index.css';

interface TournamentSelectorInterface {
  currentSelectedTournament: TournamentSimpleType | null;
  setCurrentSelectedTournament: (
    tournament: TournamentSimpleType | null,
  ) => void;
  showTournamentsSelector: boolean;
  setShowTournamentsSelector: (prev: any) => void;
}
const TournamentSelector: FC<TournamentSelectorInterface> = ({
  currentSelectedTournament,
  setCurrentSelectedTournament,
  showTournamentsSelector,
  setShowTournamentsSelector,
}) => {
  const { currentTeam } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  const [tournamentsAvaliable, setTournamentsAvaliable] = useState([]);
  const [t] = useTranslation();
  const { clearEditedCalendarEventState } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const selectorRef = useRef<any>(null);
  useEffect(() => {
    const fillTournaments = async () => {
      if (currentTeam) {
        const response = await RegistriesAPI.getTournaments({
          team: currentTeam?.id,
        });
        setTournamentsAvaliable(response.data?.results || []);
      } else {
        setTournamentsAvaliable([]);
      }
    };
    fillTournaments().then();
  }, [currentTeam]);
  useEffect(() => {
    if (clearEditedCalendarEventState) {
      setShowTournamentsSelector(false);
    }
  }, [clearEditedCalendarEventState]);
  const clearTournamentSelection = () => {
    setCurrentSelectedTournament(null);
  };

  return (
    <>
      <div className={'flex-row w100 j-sb f-ga-6'}>
        <div
          className={`activeTournamentSelector f-grow ${showTournamentsSelector ? 'topOpened' : ''}`}
          style={{
            width: '80%',
            color: `${currentSelectedTournament ? 'black' : 'grey'}`,
          }}
          ref={selectorRef}
          onClick={() => setShowTournamentsSelector((prev: boolean) => !prev)}
        >
          <div
            className={'flex-row j-sb f-grow w100'}
            style={{ overflow: 'hidden' }}
          >
            <div className={'elipsis-text'} style={{ maxWidth: '60%' }}>
              {currentSelectedTournament?.name || t('Select tournament')}
            </div>
            <img
              src={
                showTournamentsSelector
                  ? SelectUpArrowBlack
                  : SelectDownArrowBlack
              }
            />
          </div>
        </div>
        {currentSelectedTournament && (
          <img
            className={'enabledClickable'}
            src={CrossIconGrey}
            onClick={clearTournamentSelection}
          />
        )}
      </div>
      {showTournamentsSelector && (
        <div
          className={'activeTournamentSelector opened'}
          onClick={() => setShowTournamentsSelector((prev: boolean) => !prev)}
          style={{
            width: `${selectorRef.current?.offsetWidth - 34}px` || 'unset',
          }}
        >
          {tournamentsAvaliable.length > 0 && (
            <div className={'tournamentsSelectionList f-column'}>
              {tournamentsAvaliable
                .filter(
                  (tournament: TournamentSimpleType) =>
                    tournament?.id !== currentSelectedTournament?.id,
                )
                .map((filteredTournament: TournamentSimpleType, index) => {
                  return (
                    <div
                      key={index}
                      className={'flex-row f-ga-4'}
                      onClick={() =>
                        setCurrentSelectedTournament(filteredTournament)
                      }
                    >
                      <input
                        id={filteredTournament.id.toString()}
                        value={filteredTournament.id}
                        type="radio"
                        style={{ width: '10px' }}
                      />
                      <label
                        className={'enabledClickable elipsis-text'}
                        htmlFor={filteredTournament.id.toString()}
                      >
                        {filteredTournament.name}
                      </label>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default TournamentSelector;
