import React, { FC, useRef } from 'react';

import { Checkbox, ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';

import './index.css';

import { addSelectedTournamentFilterAction } from 'actions/filter.actions';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { TeamType, TournamentType } from 'types/types';

import TooltipWrapper from '../../../Library/tooltipWrapper';

const TournamentSelector = (props: any) => {
  const tournamentRef = useRef<any>(null);
  const makeID = (tournamentId: number, prefix: string) => {
    return `${prefix}-${tournamentId.toString()}`;
  };
  const checkboxRef = useRef(null);
  return (
    <>
      <div className={'flex-row f-ga-8 oy-h'}>
        <TooltipWrapper
          className="w100"
          title={props.tournament.name}
          placement="left"
        >
          <ConfigProvider
            theme={{
              token: {
                fontFamily: 'SFProTextRegular',
                fontSize: 14,
                lineHeight: 22,
                paddingXS: 10,
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox
              ref={checkboxRef}
              className="tournament_name"
              checked={props.selectedTournaments
                ?.map((el: TournamentType) => el.id || el)
                .includes(props.tournament?.id)}
              id={makeID(props.tournament.id, 't')}
              onChange={() =>
                props.toggleTournament(props.tournament, tournamentRef)
              }
            >
              {props.tournament.name}
            </Checkbox>
          </ConfigProvider>
        </TooltipWrapper>
      </div>
    </>
  );
};

interface Interface {
  activeFilterTeam: TeamType | null;
  tournaments: TournamentType[] | null;
  displaySeasonsByTournamentId: number | null;
  toggleShowSeasons: (tournamentId: number) => void;
}

const TournamentsFilter: FC<Interface> = ({
  activeFilterTeam,
  // displaySeasonsByTournamentId,
  // toggleShowSeasons,
}) => {
  const { currentTeamTournaments, selectedTournaments } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const dispatch = useAppDispatch();
  const toggleTournament = (tournament: TournamentType) => {
    if (activeFilterTeam) {
      let newSelectedTournaments;
      if (selectedTournaments.includes(tournament)) {
        newSelectedTournaments = selectedTournaments.filter(
          (selectedTournament: TournamentType) =>
            selectedTournament.id !== tournament.id,
        );
      } else {
        newSelectedTournaments = [...selectedTournaments, tournament];
      }
      dispatch(addSelectedTournamentFilterAction(newSelectedTournaments));
    }
  };
  return (
    <>
      {currentTeamTournaments?.map(
        (tournament: TournamentType, index: number) => {
          return (
            <div className={'flex-column f-ga-8 w100'} key={index}>
              <TournamentSelector
                activeFilterTeam={activeFilterTeam}
                selectedTournaments={selectedTournaments}
                tournament={tournament}
                toggleTournament={toggleTournament}
                // displaySeasonsByTournamentId={displaySeasonsByTournamentId}
                // updateSeasons={updateSeasons}
                // toggleShowSeasons={toggleShowSeasons}
              />
            </div>
          );
        },
      )}
    </>
  );
};
export default TournamentsFilter;
