import React, { useEffect, useState } from 'react';

import { Button, Checkbox, ConfigProvider, Flex, Input, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import { PlaylistsAPI } from 'api/playlists';
import './index.css';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { ReactComponent as PlusIcon } from 'assets/img/icons/faPlus.svg';
import { AppStateType } from 'reducers';
import { notificationsReducer } from 'reducers/notifications.reducer';
import { useAppDispatch } from 'store';
import { generateUUID } from 'types/crypto';
import { initTimer } from 'utils/functions';

import { ReactComponent as CheckboxFilled } from '../../../assets/img/icons/faCheckboxFilled.svg';

const PlaylistNode = ({
  playlist,
  checked,
  toggleSelected,
}: {
  playlist: any;
  checked: boolean;
  toggleSelected: any;
}) => {
  return (
    <>
      <div
        className={'enabledClickable flex-row ai-c f-ga-4'}
        style={{ height: 40 }}
      >
        <ConfigProvider
          theme={{
            token: {
              colorBgElevated: 'var(--colorBgSpotlight)',
              colorText: 'var(--colorText)',
            },
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Checkbox
              checked={checked}
              onChange={toggleSelected}
              type="checkbox"
            >
              {playlist.title}
            </Checkbox>
          </ConfigProvider>
        </ConfigProvider>
        <div style={{ flex: 1 }} className="folder-name"></div>
      </div>
    </>
  );
};

const AddToPlaylistModal = ({
  isOpen,
  handleOk,
  closeCallable,
  episodeIds,
}: {
  isOpen: boolean;
  handleOk: any;
  closeCallable: any;
  episodeIds: any;
}) => {
  const [t] = useTranslation();
  const { filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState<any[]>([]);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const populatePlaylists = async () => {
    const response = await PlaylistsAPI.getPlaylists({ flat: true });
    setPlaylists(response.data);
  };
  useEffect(() => {
    if (isOpen) {
      populatePlaylists().then();
    }
  }, [isOpen]);
  const close = () => {
    setSelectedPlaylists([]);
    closeCallable();
  };
  const submit = () => {
    savePlaylistSelection();
    setSelectedPlaylists([]);
    handleOk();
  };

  const [newPlaylistName, setNewPlaylistName] = useState(null);
  useEffect(() => {
    if (newPlaylistName === '') {
      setSelectedPlaylists((prev: string[]) =>
        prev.filter((el) => el !== null),
      );
    }
  }, [newPlaylistName]);
  const [inputStatus, setInputStatus] = useState<any>('');
  const dispatch = useAppDispatch();
  const { showNotification } = notificationsReducer.actions;
  const savePlaylistSelection = async () => {
    // Тут сохраняем плейлист
    let ids = selectedPlaylists.filter((el) => el !== null);
    let newCreatedPlaylist: any;
    if (selectedPlaylists.length > 0 && selectedPlaylists.includes(null)) {
      const newPlaylistResponse = await PlaylistsAPI.addPlaylist(
        newPlaylistName || '',
      );
      if (newPlaylistResponse?.status === 'error') {
        setInputStatus('error');
        return;
      }
      newCreatedPlaylist = newPlaylistResponse.data;
      if (selectedPlaylists.includes(null) && newCreatedPlaylist) {
        ids = [...ids, newCreatedPlaylist.id];
        setNewPlaylistName(null);
      }
    }
    const createdUserEpisodes: string[] = [];
    let processedEpisodeIds: string[] = [...episodeIds];
    const process = async (
      ep: any,
      processedEpisodeIds: string[],
    ): Promise<string[]> => {
      const timer = ep.user_timer ? ep.user_timer : ep.file_timer;
      let offset;
      if (ep?.user_timer !== undefined && ep?.user_timer !== null) {
        offset = ep?.user_timer.started_at - ep?.game_timer.started_at;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        offset = ep?.file_timer.started_at - ep?.game_timer.started_at;
      }
      const newUserEpisode: any = {
        video: ep.video.id,
        file_timer: {
          started_at: timer?.started_at,
          finished_at: timer?.finished_at,
        },
        game_timer: {
          started_at: initTimer(ep, 'started_at', true),
          finished_at: initTimer(ep, 'finished_at', true),
        },
        user_event_name:
          ep.user_event_name ||
          ep.merged_name ||
          ep.event_name ||
          ep.event_type.name,
        keyframes: ep.keyframes,
      };
      if (ep.id.includes('temp-') || ep.merged_name) {
        newUserEpisode.game_timer.started_at += offset;
        newUserEpisode.game_timer.finished_at += offset;
      }
      const response = await EventsAPI.addNewUserEpisode(newUserEpisode);
      createdUserEpisodes.push(response.data.id);
      processedEpisodeIds = processedEpisodeIds.filter((el) => el !== ep.id);
      return processedEpisodeIds;
    };

    for (let vidId = 0; vidId < filteredVideos.length; vidId++) {
      const vid = filteredVideos[vidId];
      const user_eps: any[] | undefined = vid?.user_episodes?.filter(
        (el: any) => episodeIds.includes(el.id) && el.isTemporary,
      );

      if (user_eps) {
        for (let i = 0; i < user_eps.length; i++) {
          const ep = user_eps[i];
          processedEpisodeIds = await process(ep, processedEpisodeIds);
        }
      }
      const changed_eps: any[] | undefined = vid?.episodes?.filter(
        (el: any) =>
          (episodeIds.includes(el.id) && el.isTemporary) ||
          (episodeIds.includes(el.id) && el.user_event_name) ||
          (episodeIds.includes(el.id) && el.merged_name),
      );
      if (changed_eps) {
        for (let i = 0; i < changed_eps.length; i++) {
          const ep = changed_eps[i];
          processedEpisodeIds = await process(ep, processedEpisodeIds);
        }
      }
    }
    const finalEpisodesIdsList = [
      ...processedEpisodeIds,
      ...createdUserEpisodes,
    ];
    PlaylistsAPI.addToPlaylists(finalEpisodesIdsList, ids).then(() => {
      let text;
      if (ids.length > 1) {
        text =
          t('Episode was added to') +
          ` ${ids.length} ` +
          t('playlists', { count: ids.length });
      } else {
        text = `${t('Episode was added to playlist')} "${playlists.filter((p: any) => p.key === ids[0])[0]?.title || newCreatedPlaylist.title}"`;
      }
      dispatch(
        showNotification({
          notificationParameters: [
            {
              id: generateUUID(),
              text: text,
              epsiodeIds: finalEpisodesIdsList,
              playlistIds: ids,
            },
          ],
        }),
      );
    });
  };
  const toggleSelected = (playlist: any) => {
    if (selectedPlaylists.includes(playlist.key)) {
      setSelectedPlaylists((prev: string[]) =>
        prev.filter((el) => el !== playlist.key),
      );
    } else {
      setSelectedPlaylists((prev: string[]) => [...prev, playlist.key]);
    }
  };
  const [addPlaylistMode, setAddPlaylistMode] = useState(false);
  const [playlistCreating, setPlalistCreating] = useState(false);
  const createNewPlaylist = async () => {
    setPlalistCreating(true);
    const newPlaylistResponse = await PlaylistsAPI.addPlaylist(
      newPlaylistName || '',
    );
    if (newPlaylistResponse?.status === 'error') {
      setInputStatus('error');
    } else {
      await populatePlaylists();
      setAddPlaylistMode(false);
    }
    setNewPlaylistName(null);
    setPlalistCreating(false);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'var(--colorBgLayout)',
            headerBg: 'var(--colorBgLayout)',
            titleColor: 'var(--colorText)',
            colorText: 'var(--colorText)',
            paddingContentHorizontal: 12,
            borderRadiusLG: 16,
          },
        },
      }}
    >
      <Modal
        title={t('Add to playlists')}
        open={isOpen}
        closable
        width={360}
        style={{ maxHeight: '400px' }}
        styles={{
          content: {
            padding: 32,
            width: 400,
            borderRadius: 18,
          },
        }}
        closeIcon={<CloseCross />}
        onCancel={close}
        footer={[
          <Flex vertical gap={18} justify="space-between">
            {addPlaylistMode ? (
              <Flex
                gap={4}
                align="center"
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.04)',
                  paddingRight: 12,
                  borderRadius: 8,
                  height: 32,
                }}
              >
                <ConfigProvider
                  theme={{
                    token: {
                      colorTextPlaceholder: 'var(--colorTextPlaceholder)',
                      fontFamily: 'SFProTextRegular',
                      fontSize: 14,
                      colorBorder: 'var(--colorBorder)',
                    },
                  }}
                >
                  <Input
                    disabled={playlistCreating}
                    required={true}
                    status={inputStatus}
                    className="new-folder-input"
                    type="text"
                    onFocus={() => {
                      dispatch(AC_SetPlayerHotkeysAllowedAction(false));
                    }}
                    name="newPlaylistName"
                    onChange={(event: any) => {
                      setNewPlaylistName(event.target.value);
                    }}
                    onBlur={() => {
                      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                    }}
                    autoFocus={true}
                    maxLength={100}
                    placeholder={t('Enter playlist name')}
                    style={{
                      background: 'var(--colorBgContainer)',
                      color: 'var(--colorText)',
                    }}
                  ></Input>
                  <Button
                    disabled={playlistCreating}
                    style={{
                      border: '1px solid var(--colorIcon)',
                      background: 'transparent',
                      height: 24,
                      width: 32,
                      lineHeight: 0,
                    }}
                    className="bnt-line-height"
                    onClick={() => {
                      setNewPlaylistName(null);
                      setAddPlaylistMode(false);
                      setPlalistCreating(false);
                      dispatch(AC_SetPlayerHotkeysAllowedAction(true));
                    }}
                    icon={<CloseCross />}
                  />
                  <Button
                    disabled={playlistCreating}
                    style={{
                      height: 24,
                      width: 32,
                      border: '1px solid var(--colorPrimaryBase)',
                      background: 'rgba(22, 104, 220, 0.4)',
                    }}
                    className="bnt-line-height"
                    onClick={() => createNewPlaylist()}
                    icon={<CheckboxFilled />}
                    type="default"
                  />
                </ConfigProvider>
              </Flex>
            ) : (
              <Flex
                gap={16}
                align="center"
                className="enabledClickable"
                onClick={() => setAddPlaylistMode(true)}
                style={{
                  height: 32,
                }}
              >
                <PlusIcon />
                <div>{t('Create playlist')}</div>
              </Flex>
            )}
            <div className="flex-row f-ga-8">
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      defaultBg: 'var(--colorBgContainer)',
                      colorText: 'var(--colorText)',
                      colorBorder: 'var(--colorBorder)',
                      defaultHoverBg: 'var(--colorBgContainer)',
                      defaultActiveBg: 'var(--colorBgContainer)',
                    },
                  },
                }}
              >
                <Button style={{ flex: 1 }} type="default" onClick={close}>
                  {t('Cancel')}
                </Button>
              </ConfigProvider>
              <ConfigProvider
                theme={{
                  components: {
                    Button: {
                      colorTextDisabled: 'var(--colorTextDisabled)',
                      borderColorDisabled: 'var(--colorBorder)',
                    },
                  },
                }}
              >
                <Button
                  style={{ flex: 1 }}
                  disabled={selectedPlaylists.length === 0}
                  type="primary"
                  onClick={submit}
                >
                  {t('Add')}
                </Button>
              </ConfigProvider>
            </div>
          </Flex>,
        ]}
      >
        <div
          style={{
            width: '360px',
            height: '1px',
            margin: '4px 0 4px -24px',
            background: 'rgba(255, 255, 255, 0.08)',
            overflow: 'auto',
          }}
        ></div>
        <div style={{ maxHeight: 224, overflow: 'auto' }}>
          {playlists?.map((playlist: any) => {
            return (
              <PlaylistNode
                playlist={playlist}
                checked={selectedPlaylists.includes(playlist.key)}
                toggleSelected={() => toggleSelected(playlist)}
              />
            );
          })}
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default AddToPlaylistModal;
