import React, { FC, useEffect, useRef, useState } from 'react';

import './index.css';
import { Input, Tooltip } from 'antd';
import { InputStatus } from 'antd/es/_util/statusUtils';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { AppendNewPlaylist } from '../../../actions/player.acitons';
import { PlaylistsAPI } from '../../../api/playlists';
import { ReactComponent as WarningSign } from '../../../assets/img/warningSign.svg';
import { AppStateType } from '../../../reducers';
import { useAppDispatch } from '../../../store';
import {
  PlaylistType,
  SelectedEpisodesType,
  // TeamType,
} from '../../../types/types';
import Loader from '../../Loader';

interface Interface {
  isOpen: boolean;
  setIsOpen: any;
  episodes: SelectedEpisodesType;
  closeControl: any;
}
const PlaylistCreationModal: FC<Interface> = ({
  isOpen,
  setIsOpen,
  episodes,
  closeControl,
}) => {
  const elementAddInputRef = useRef(null);
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );

  const [existingPlaylists, setExistingPlaylists] = useState<Array<any>>([]);
  const [loadingPlaylists, setLoadingPlaylists] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentUserPlaylists, setCurrentUserPlaylists] = useState<
    Array<PlaylistType>
  >([]);
  const [currentUserPlaylistsIds, setCurrentUserPlaylistsIds] = useState<
    Array<string>
  >([]);

  const [newAddedPlaylist, setNewAddedPlaylist] = useState<string>('');
  const [selectNewPlaylist, setSelectNewPlaylist] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setLoadingPlaylists(true);
      PlaylistsAPI.getPlaylists({})
        .then((response: any) => {
          setExistingPlaylists(response.data.results);
          setLoadingPlaylists(false);
        })
        .catch(() => {
          setLoadingPlaylists(false);
        });
    }
  }, [isOpen]);

  const updateCurrentUserPlaylists = (event: any, playlist: PlaylistType) => {
    if (event.target.checked && playlist.id) {
      setCurrentUserPlaylists((prev: Array<PlaylistType>) => [
        ...prev,
        playlist,
      ]);
      //@ts-ignore
      setCurrentUserPlaylistsIds((prev: string[]) => [...prev, playlist.id]);
    } else {
      setCurrentUserPlaylists((prev) =>
        Array.from(prev.filter((t) => t.id !== playlist.id)),
      );
      setCurrentUserPlaylistsIds((prev) =>
        prev.filter((el) => el !== playlist.id),
      );
    }
    if (newAddedPlaylist === '') {
      setSelectNewPlaylist(false);
    }
  };
  const dispatch = useAppDispatch();
  const [inputStatus, setInputStatus] = useState<InputStatus>('');
  const [inputError, setInputError] = useState<InputStatus>('');
  const savePlaylistSelection = async () => {
    // Тут сохраняем плейлист
    if (currentUser) {
      let ids = [...currentUserPlaylistsIds];
      if (newAddedPlaylist) {
        // console.log('newAddedPlaylist', newAddedPlaylist);
        const newPlaylistResponse =
          await PlaylistsAPI.addPlaylist(newAddedPlaylist);
        if (newPlaylistResponse?.status === 'error') {
          setInputStatus('error');
          setInputError(newPlaylistResponse?.detail || 'Unknown error');
          return;
        }
        const newCreatedPlaylist = newPlaylistResponse.data;
        dispatch(AppendNewPlaylist(newCreatedPlaylist));
        if (selectNewPlaylist && newCreatedPlaylist) {
          //@ts-ignore
          setCurrentUserPlaylists((prev: Array<PlaylistType>) => [
            ...prev,
            newCreatedPlaylist,
          ]);
          ids = [...ids, newCreatedPlaylist.id];
          //@ts-ignore
          setCurrentUserPlaylistsIds([...ids]);
          setSelectNewPlaylist(false);
        }
        setNewAddedPlaylist('');
      }
      const episodesFlatList = Object.values(episodes)
        .map((value) => value)
        .flat();
      // console.log('episodesFlatList', episodes)
      PlaylistsAPI.addToPlaylists(episodesFlatList, ids).then(() => {
        setCurrentUserPlaylistsIds([]);
        setCurrentUserPlaylists([]);
        setIsOpen(false);
        closeControl(false);
      });
    }
  };
  const [t] = useTranslation();
  return (
    <ReactModal
      // style={{overlay: {display: "flex"}}}
      overlayClassName="modalOverlay"
      className="teamsAddModal"
      isOpen={isOpen}
      onRequestClose={() => {
        setIsOpen((prev: boolean) => !prev);
      }}
      shouldCloseOnEsc={true}
    >
      <div className="modalTitle">{t('Select playlist')}</div>
      <div className="teamsListContainer">
        {loadingPlaylists && (
          <div className={'flex-row j-ce'}>
            <Loader color={'black'} />
          </div>
        )}
        {!loadingPlaylists &&
          existingPlaylists.map((playlist, index) => {
            return (
              <div key={index}>
                <input
                  type="checkbox"
                  value={playlist.id}
                  defaultChecked={currentUserPlaylistsIds.includes(playlist.id)}
                  onClick={(event) =>
                    updateCurrentUserPlaylists(event, playlist)
                  }
                  id={playlist.id}
                  name="teams"
                />
                <label
                  htmlFor={playlist.id}
                  className={'enabledClickable'}
                  style={{
                    color: `${currentUserPlaylistsIds.includes(playlist.id) ? '#212121' : ''}`,
                  }}
                >
                  {playlist.name}
                </label>
              </div>
            );
          })}
      </div>
      <div className={'w100'} style={{ position: 'relative' }}>
        <Tooltip
          title={
            <div className="flex-row f-ga-4 j-ce ai-c">
              <WarningSign />
              {t(inputError)}
            </div>
          }
          placement={'left'}
          overlayInnerStyle={{
            backgroundColor: 'white',
            color: 'red',
            border: '1px solid red',
          }}
          arrow={false}
          open={inputError !== ''}
        >
          <Input
            status={inputStatus}
            placeholder={t('Enter playlist name')}
            ref={elementAddInputRef}
            name={'newPlaylist'}
            className="newPlaylist"
            required={false}
            maxLength={500}
            value={newAddedPlaylist}
            onChange={(e) => {
              setInputStatus('');
              setInputError('');
              setNewAddedPlaylist(e.target.value);
            }}
            allowClear={true}
            onFocus={() => setSelectNewPlaylist(true)}
            onBlur={() => {
              if (!newAddedPlaylist) {
                setSelectNewPlaylist(false);
              }
            }}
            prefix={
              <input
                type="checkbox"
                name="newSelected"
                checked={selectNewPlaylist}
                onFocus={() => {
                  if (!selectNewPlaylist) {
                    setSelectNewPlaylist(true);
                  }
                }}
                onChange={() => {
                  setSelectNewPlaylist((prev) => !prev);
                }}
              />
            }
          />
        </Tooltip>
      </div>
      <div className="modalControlButtons">
        <div onClick={() => setIsOpen(false)}>{t('Cancel')}</div>
        <div
          className={`${!newAddedPlaylist && currentUserPlaylistsIds.length === 0 ? 'disabled' : ''}`}
          onClick={() => {
            if (newAddedPlaylist || currentUserPlaylistsIds.length > 0) {
              savePlaylistSelection();
            }
          }}
        >
          {t('Select')}
        </div>
      </div>
    </ReactModal>
  );
};
export default PlaylistCreationModal;
