import React, { FC, useState } from 'react';

import { ConfigProvider, Dropdown, MenuProps, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import './index.css';
interface Interface {
  speedSetCallback: any;
  children: any;
  handleMouseEnter: any;
  handleMouseLeave: any;
  rewindSpeed: number;
}
const RewindSpeedIndex: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const [stepSizeTooltipVisible, setStepSizeTooltipVisible] = useState(false);
  const items: MenuProps['items'] = [
    {
      key: 0,
      label: <div className="non-selectable-context">{t('Rewind step')}</div>,
      disabled: true,
    },
    { type: 'divider' },
    {
      key: 1,
      label: <div onClick={() => props.speedSetCallback(1)}>1 {t('sec')}</div>,
      disabled: false,
    },
    {
      key: 2,
      label: <div onClick={() => props.speedSetCallback(2)}>2 {t('sec')}</div>,
      disabled: false,
    },
    {
      key: 3,
      label: <div onClick={() => props.speedSetCallback(3)}>3 {t('sec')}</div>,
      disabled: false,
    },
    {
      key: 4,
      label: <div onClick={() => props.speedSetCallback(4)}>4 {t('sec')}</div>,
      disabled: false,
    },
    {
      key: 5,
      label: <div onClick={() => props.speedSetCallback(5)}>5 {t('sec')}</div>,
      disabled: false,
    },
    {
      key: 6,
      label: (
        <div onClick={() => props.speedSetCallback(10)}>10 {t('sec')}</div>
      ),
      disabled: false,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
          // lineHeight: 16
        },
      }}
    >
      <Tooltip
        title={t(`Rewind step ${props.rewindSpeed}s`)}
        placement="top"
        align={{ offset: [10, -35] }}
        open={stepSizeTooltipVisible}
      >
        <div
          onMouseEnter={() => {
            props.handleMouseEnter(setStepSizeTooltipVisible);
          }}
          onMouseLeave={() => props.handleMouseLeave(setStepSizeTooltipVisible)}
        >
          <Dropdown
            className="enabledClickable"
            menu={{ items }}
            trigger={['click']}
            overlayStyle={{
              width: 'max-content',
            }}
            placement="top"
          >
            {props.children}
          </Dropdown>
        </div>
      </Tooltip>
    </ConfigProvider>
  );
};
export default RewindSpeedIndex;
