import React, { useEffect, useRef } from 'react';

import { Tooltip } from 'antd';

const ElementWrapper = ({ onMouseEnter, onMouseLeave, ...rest }) => {
  const ref = useRef(null);

  useEffect(() => {
    const span = ref.current;
    if (span) {
      span.addEventListener('mouseenter', onMouseEnter);
      span.addEventListener('mouseleave', onMouseLeave);
      return () => {
        span.removeEventListener('mouseenter', onMouseEnter);
        span.removeEventListener('mouseleave', onMouseLeave);
      };
    }
  }, [onMouseEnter, onMouseLeave]);
  return (
    <div
      ref={ref}
      style={{
        display: 'inline-block',
      }}
      {...rest}
    />
  );
};

const TooltipWrapper = ({ children, ...rest }) => {
  return (
    <Tooltip {...rest}>
      <ElementWrapper>{children}</ElementWrapper>
    </Tooltip>
  );
};
export default TooltipWrapper;
