import { ThunkAction } from 'redux-thunk';

import { PlaylistsAPI } from 'api/playlists';
import { AppStateType } from 'reducers';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { GameVideoType, PlaylistType } from 'types/types';

const {
  AC_setOpenedPlaylistId,
  AC_setOpenedPlaylist,
  AC_setPlaylistIsLoading,
  AC_setPlaylistOpenError,
  AC_setPlaylistsAndFolders,
  AC_setFoldersList,
  AC_setSharedPlaylist,
} = playlistReducerV2.actions;

export const openPlaylistActionV2 =
  (
    playlistId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      const playlistDetail = await PlaylistsAPI.getPlaylistDetails(playlistId);
      dispatch(AC_setPlaylistIsLoading(true));
      dispatch(AC_setOpenedPlaylistId(playlistId));
      const response = playlistDetail.data;
      const playlistGames: GameVideoType[] | PlaylistType[] = [];
      for (let index = 0; index < response.playlist_events.length; index++) {
        const episode = response.playlist_events[index];
        if (playlistGames.length === 0) {
          const newGame: GameVideoType = {
            id: `${episode.activity.id}-0`,
            game_type: '',
            tournament: {
              name: episode.activity.tournament,
              country: { name: episode.activity.country },
            },
            result: episode.activity.result,
            started_at: episode.activity.date,
            name: episode.activity.name,
            home_team: episode.activity.home_team,
            away_team: episode.activity.away_team,
            episodes: [episode],
            isExpanded: true,
            sections: [],
            game_events: [],
            periods: episode.activity.periods,
          };
          playlistGames.push(newGame);
        } else {
          const el = playlistGames.slice(-1)[0];
          if (el.id.slice(0, -2) === episode.activity.id) {
            el.episodes?.push(episode);
          } else {
            const newGame: GameVideoType = {
              id: `${episode.activity.id}-${playlistGames.length}`,
              game_type: '',
              name: episode.activity.name,
              home_team: episode.activity.home_team,
              away_team: episode.activity.away_team,
              tournament: {
                name: episode.activity.tournament,
                country: { name: episode.activity.country },
              },
              result: episode.activity.result,

              started_at: episode.activity.date,
              episodes: [episode],
              isExpanded: true,
              sections: [],
              game_events: [],
              periods: episode.activity.periods,
            };
            playlistGames.push(newGame);
          }
        }
      }
      const finalPlaylist = {
        ...response,
        playlist_events: playlistGames,
      };
      dispatch(AC_setOpenedPlaylist(finalPlaylist));
      dispatch(AC_setPlaylistIsLoading(false));
    } catch (e) {
      dispatch(AC_setPlaylistIsLoading(false));
      dispatch(AC_setPlaylistOpenError('Error opening playlist'));
    }
  };
export const getRootPlaylistsActionV2 =
  (
    sorting?: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    PlaylistsAPI.getPlaylists({ sorting: sorting || 'manual' })
      .then((response: any) => {
        dispatch(AC_setPlaylistsAndFolders(response.data.hierarchy));
      })
      .finally(() => {
        // setRendering(false);
      });
  };
export const addFolderAction =
  (
    folderName: string,
    folderParent?: string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      await PlaylistsAPI.addFolder(folderName, folderParent);
      dispatch(getRootPlaylistsActionV2());
    } catch (e) {
      console.log(e);
    }
  };
export const addPlaylistAction =
  (
    playlistName: string,
    folderParent?: string | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      await PlaylistsAPI.addPlaylist(playlistName, folderParent);
      dispatch(getRootPlaylistsActionV2());
    } catch (e) {
      console.log(e);
    }
  };
export const updatePlaylistAction =
  (): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      // await PlaylistsAPI.updatePlaylist(playlistId, playlistName, folderParent);
      dispatch(getRootPlaylistsActionV2());
    } catch (e) {
      console.log(e);
    }
  };
export const updateFolderAction =
  (
    folderData: any,
    folderId: string,
    refresh = false,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      await PlaylistsAPI.updateFolder(folderData, folderId);
      if (refresh) {
        dispatch(getRootPlaylistsActionV2());
      }
    } catch (e) {
      console.log(e);
    }
  };
export const deleteFolderAction =
  (
    folderId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      await PlaylistsAPI.deleteFolder(folderId);
      dispatch(getRootPlaylistsActionV2());
    } catch (e) {
      console.log(e);
    }
  };
export const getFoldersAction =
  (
    level?: number,
    parent?: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    const { setPlaylistsAndFoldersLoading } = playlistReducerV2.actions;
    try {
      await dispatch(setPlaylistsAndFoldersLoading(true));
      const response: any = await PlaylistsAPI.getFolders(level, parent);
      await dispatch(AC_setFoldersList(response.data?.results));
      await dispatch(setPlaylistsAndFoldersLoading(false));
    } catch (e) {
      console.log(e);
    }
  };
export const getSharedLinkAction =
  (
    sharedLink: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, any> =>
  async (dispatch) => {
    try {
      dispatch(AC_setPlaylistIsLoading(true));
      const r = await PlaylistsAPI.getPlaylistByShareLink(sharedLink);
      const response = r.data;
      // console.log('re', r);
      if (r.status === 200 && Object.keys(response).length > 0) {
        const playlistGames: GameVideoType[] | PlaylistType[] = [];
        for (let index = 0; index < response.playlist_events.length; index++) {
          const episode = response.playlist_events[index];
          if (playlistGames.length === 0) {
            const newGame: GameVideoType = {
              id: episode.activity.id,
              game_type: '',
              tournament: {
                name: episode.activity.tournament,
                country: { name: episode.activity.country },
              },
              result: episode.activity.result,
              started_at: episode.activity.date,
              name: episode.activity.name,
              home_team: episode.activity.home_team,
              away_team: episode.activity.away_team,
              episodes: [episode],
              isExpanded: true,
              sections: [],
              game_events: [],
              periods: episode.activity.periods,
            };
            playlistGames.push(newGame);
          } else {
            const el = playlistGames.slice(-1)[0];
            if (el.id === episode.activity.id) {
              el.episodes?.push(episode);
            } else {
              const newGame: GameVideoType = {
                id: episode.activity.id,
                game_type: '',
                name: episode.activity.name,
                home_team: episode.activity.home_team,
                away_team: episode.activity.away_team,
                tournament: {
                  name: episode.activity.tournament,
                  country: { name: episode.activity.country },
                },
                result: episode.activity.result,
                started_at: episode.activity.date,
                episodes: [episode],
                isExpanded: true,
                sections: [],
                game_events: [],
                periods: episode.activity.periods,
              };
              playlistGames.push(newGame);
              // console.log(0)
            }
          }
        }
        const finalPlaylist = {
          ...response,
          playlist_events: playlistGames,
          name: response.name,
        };
        dispatch(AC_setSharedPlaylist(finalPlaylist));
        dispatch(AC_setPlaylistIsLoading(false));
      } else {
        // dispatch(
        //   AC_setSharedPlaylist({
        //     playlist_events: 'error',
        //     share_link: 'error',
        //   }),
        // );
        dispatch(AC_setPlaylistOpenError('error'));
        dispatch(AC_setPlaylistIsLoading(false));
      }
    } catch (e) {
      console.log(e);
    }
  };
