import { FilterActionTypes } from 'actions/filter.actions';
import {
  ADD_ACTIVE_TEAM_DATE_LIMITS,
  ADD_SELECTED_TOURNAMENT,
  OPEN_FILTER,
  PREFILTER_REQUEST,
  PREFILTER_REQUEST_ERROR,
  PREFILTER_REQUEST_SUCCESS,
  SET_ACTIVE_TEAM_FILTER,
  SET_CURRENT_TEAM_TOURNAMENTS,
  SET_INTERMEDIATE_ACTIVITIES,
  SET_LAST_X_FILTER,
  SET_SELECTED_ATTRIBUTES,
  SET_SELECTED_DATE_LIMITS_FILTER,
  SET_SELECTED_MATCHES,
  SET_SELECTED_PLAYERS,
  SET_SELECTED_TIME_INTERVALS,
  SET_SHOW_MY_EPISODES_FILTER,
  SET_SHOW_PLAYER_GAMES_FILTER,
  SET_SHOW_PLAYER_INTERVALS_FILTER,
  SET_VISIBLE_FILTER_ATTRIBUTES,
  SET_VISIBLE_PLAYERS_TYPE,
} from 'types/action.types';
import { EpisodeAttributeType, EpisodeFilterPanelTypes } from 'types/types';

type InitialStateType = {
  activeFilter: EpisodeFilterPanelTypes | string;
  activeTeam: number | null;
  playersLoading: boolean;
  teamsLoading: boolean;
  playersVisible: any;
  selectedPlayers: string[];
  selectedGames: string[];
  currentTeamTournaments: any[];
  selectedTournaments: any[];
  prefilteredGamesLoading: boolean;
  prefilteredGames: any[];
  currentTeamDateLimits: any[];
  selectedDateLimits: any;
  showPlayerGames: boolean;

  selectedAttributes: number[];
  episodeAttributes: Array<EpisodeAttributeType>;

  showMyEpisdes: boolean;

  selectedTimingIntervals: any;
  showPlayerIntervals: boolean;

  lastXFilter: number | null;

  intermediateAcitivityIds: string[];
};

const initialState: InitialStateType = {
  activeFilter: '',

  teamsLoading: false,
  activeTeam: null,

  playersLoading: false,
  playersVisible: {},
  selectedPlayers: [],

  prefilteredGamesLoading: false,
  prefilteredGames: [],
  selectedGames: [],

  currentTeamTournaments: [],
  selectedTournaments: [],
  currentTeamDateLimits: [],
  selectedDateLimits: {},
  showPlayerGames: false,

  selectedAttributes: [],
  episodeAttributes: [],
  showMyEpisdes: false,

  selectedTimingIntervals: {},
  showPlayerIntervals: false,
  intermediateAcitivityIds: [],

  lastXFilter: null,
};

const filtersReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: FilterActionTypes,
): InitialStateType => {
  switch (action.type) {
    case SET_ACTIVE_TEAM_FILTER:
      return {
        ...state,
        playersVisible: {},
        playersLoading: action.payload !== null,
        activeTeam: action.payload,
        selectedPlayers: [],
      };
    case SET_VISIBLE_PLAYERS_TYPE:
      return {
        ...state,
        playersLoading: false,
        playersVisible: action.payload,
        selectedPlayers: [],
      };
    case SET_SELECTED_PLAYERS:
      return {
        ...state,
        selectedPlayers: action.payload,
      };
    case SET_SELECTED_MATCHES:
      return {
        ...state,
        selectedGames: action.payload,
      };
    case OPEN_FILTER: {
      return {
        ...state,
        activeFilter: action.filter,
      };
    }
    case SET_CURRENT_TEAM_TOURNAMENTS:
      return {
        ...state,
        currentTeamTournaments: action.payload,
      };
    case ADD_SELECTED_TOURNAMENT:
      return {
        ...state,
        selectedTournaments: action.payload,
      };
    case ADD_ACTIVE_TEAM_DATE_LIMITS:
      return {
        ...state,
        currentTeamDateLimits: action.payload,
      };
    case PREFILTER_REQUEST:
      return {
        ...state,
        prefilteredGamesLoading: true,
      };
    case PREFILTER_REQUEST_ERROR:
      return {
        ...state,
        prefilteredGames: [],
        prefilteredGamesLoading: false,
      };
    case PREFILTER_REQUEST_SUCCESS:
      return {
        ...state,
        prefilteredGames: action.payload,
        prefilteredGamesLoading: false,
      };
    case SET_SELECTED_DATE_LIMITS_FILTER:
      return {
        ...state,
        selectedDateLimits: action.payload,
      };
    case SET_LAST_X_FILTER:
      return {
        ...state,
        lastXFilter: action.payload,
      };
    case SET_SELECTED_ATTRIBUTES:
      return {
        ...state,
        selectedAttributes: action.payload,
      };
    case SET_VISIBLE_FILTER_ATTRIBUTES:
      return {
        ...state,
        episodeAttributes: action.payload,
      };
    case SET_SELECTED_TIME_INTERVALS:
      return {
        ...state,
        selectedTimingIntervals: action.payload,
      };
    case SET_SHOW_MY_EPISODES_FILTER:
      return {
        ...state,
        showMyEpisdes: action.payload,
      };
    case SET_SHOW_PLAYER_GAMES_FILTER:
      return {
        ...state,
        showPlayerGames: action.payload,
      };
    case SET_SHOW_PLAYER_INTERVALS_FILTER:
      return {
        ...state,
        showPlayerIntervals: action.payload,
      };
    case SET_INTERMEDIATE_ACTIVITIES:
      return {
        ...state,
        intermediateAcitivityIds: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default filtersReducer;
