import React, { useEffect, useState } from 'react';

import { Button, Checkbox, ConfigProvider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { AppStateType } from 'reducers';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

import { EventsAPI } from '../../../api/events';
import { formatDatetimeToDate } from '../../../types/functions';
import { formatScore } from '../../../utils/functions';
import './index.css';
import Loader from '../../Loader';

const GamesSelectionModal = () => {
  const {
    selectedMatchesStats,
    selectedMatchesBestPlayers,
    manualGamesSelectionSection,
    showGameSelectionModal,
  } = useSelector((state: AppStateType) => state.teamReducer);
  const {
    setSelectedMatchesStats,
    setSelectedSeason,
    setSelectedBestPlayerSeason,
    setSelectedMatchesBestPlayers,
    setShowGameSelectionModal,
    setManualGamesSelectionSection,
  } = teamReducer.actions;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { teamId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const toggleSelectedMatch = (matchId: string) => {
    if (localSelected.includes(matchId)) {
      setLocalSelected((prev: string[]) => prev.filter((el) => el !== matchId));
    } else {
      setLocalSelected((prev: string[]) => [...prev, matchId]);
    }
  };
  const [localSelected, setLocalSelected] = useState(
    manualGamesSelectionSection === 'statistics'
      ? selectedMatchesStats
      : selectedMatchesBestPlayers,
  );
  useEffect(() => {
    if (manualGamesSelectionSection === 'statistics') {
      setLocalSelected(selectedMatchesStats);
    } else {
      setLocalSelected(selectedMatchesBestPlayers);
    }
  }, [manualGamesSelectionSection]);
  const [matches, setMatches] = useState([]);
  useEffect(() => {
    const getMatches = async () => {
      if (teamId) {
        const filter: any = {
          games: {},
        };
        filter.games[teamId] = {};
        setIsLoading(true);
        const matchesStatsResponse = await EventsAPI.getPreFilterGames(filter);
        if (matchesStatsResponse.data?.results) {
          setMatches(matchesStatsResponse.data.results);
        }
        setIsLoading(false);
      }
    };
    showGameSelectionModal && getMatches().then();
  }, [showGameSelectionModal]);
  useEffect(() => {
    if (manualGamesSelectionSection === 'statistics') {
      setLocalSelected(selectedMatchesStats);
    } else {
      setLocalSelected(selectedMatchesBestPlayers);
    }
  }, [matches, manualGamesSelectionSection]);
  const getGameName = (game: any) => {
    return `${game.home_team || t('Unknown team')} ${
      formatScore(game.result) || ''
    } ${game.away_team || t('Unknown team')}`;
  };
  const setGames = () => {
    if (manualGamesSelectionSection === 'statistics') {
      dispatch(setSelectedMatchesStats(localSelected));
      dispatch(setSelectedSeason('manual'));
    } else {
      dispatch(setSelectedMatchesBestPlayers(localSelected));
      dispatch(setSelectedBestPlayerSeason('manual'));
    }
    dispatch(setShowGameSelectionModal(false));
  };
  const closeCallBack = () => {
    dispatch(setShowGameSelectionModal(false));
    if (manualGamesSelectionSection === 'statistics') {
      dispatch(setManualGamesSelectionSection(''));
      // dispatch(setSelectedSeason(selectedMatchesStats));
    } else if (manualGamesSelectionSection === 'bestPlayers') {
      dispatch(setManualGamesSelectionSection(''));
      // dispatch(setSelectedBestPlayerSeason(selectedMatchesBestPlayers));
    }
  };
  return (
    <ConfigProvider>
      <Modal
        open={showGameSelectionModal}
        title={t('Select matches')}
        style={{ maxHeight: 400, padding: '16px 24px 8px 24px' }}
        width={408}
        closable={true}
        maskClosable={true}
        onCancel={closeCallBack}
        styles={{
          body: {
            maxHeight: 400,
            overflow: 'auto',
          },
          content: {
            overflow: 'hidden',
            padding: '16px 0px 16px 0px',
          },
          footer: {
            position: 'sticky',
            padding: '0px 24px 8px 24px',
            overflow: 'hidden',
          },
          header: {
            position: 'sticky',
            padding: '0px 24px 8px 24px',
            borderBottom: '1px solid var(--colorBorder, rgba(0, 0, 0, 0.15))',
          },
        }}
        footer={
          <div className="flex-row f-ga-8">
            <Button style={{ flex: 1 }} type="default" onClick={closeCallBack}>
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => setGames()}
              disabled={localSelected.length === 0}
              style={{ flex: 1 }}
              type="primary"
            >
              {t('Select')}
            </Button>
          </div>
        }
      >
        <div style={{ overflow: 'auto', padding: '0px 24px 0px 24px' }}>
          {isLoading && (
            <div className="flex-row jc-c">
              <Loader color="black" />
            </div>
          )}
          {!isLoading &&
            matches.map((match: any) => {
              return (
                <div
                  className="gameBlock flex-row f-ga-12 oy-s"
                  style={{ padding: '8px 0px' }}
                  onClick={() => toggleSelectedMatch(match.id)}
                >
                  <div>
                    <Checkbox checked={localSelected.includes(match.id)} />
                  </div>
                  <div className="flex-column oy-h">
                    <div
                      className={'enabledClickable gameBlockTitle elipsis-text'}
                      style={{
                        color: `var(${
                          localSelected.includes(match.id)
                            ? '--colorTextHeading'
                            : '--colorTextLabel'
                        })`,
                      }}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onClick={() => {}}
                    >
                      {getGameName(match)}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '6px',
                        alignSelf: 'stretch',
                        color: `var(${
                          localSelected.includes(match.id)
                            ? '--colorTextLabel'
                            : '--colorTextDescription'
                        })`,
                        overflow: 'hidden',
                      }}
                    >
                      <div>{formatDatetimeToDate(match.started_at)}</div>
                      {match.country && (
                        <>
                          <div className="filtersPanelTopBar_content_matchLine_divider_light"></div>
                          <div className="elipsis-text">{match.country}</div>
                        </>
                      )}
                      {match.tournament && (
                        <>
                          <div className="filtersPanelTopBar_content_matchLine_divider_light elipsis-text"></div>
                          <div className="elipsis-text">{match.tournament}</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    </ConfigProvider>
  );
};
export default GamesSelectionModal;
