import { API_ENDPOINT } from '../types/constants';
import myAxios from '../utils/axios';

const getDownloads = async () => {
  const url = `${API_ENDPOINT}api/data/downloads/`;
  const params = new URLSearchParams();
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const getDownloadStatus = async (downloadId: string) => {
  const url = `${API_ENDPOINT}api/data/downloads/${downloadId}/status/`;
  const params = new URLSearchParams();
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    // console.log(e);
    return e.response.data;
  }
};
const restartDownloads = async (downloadIds: string[]) => {
  const restartDownload = async (downloadId: string) => {
    try {
      const url = `${API_ENDPOINT}api/data/downloads/${downloadId}/restart/`;
      const payload = {
        ids: downloadIds,
      };
      return await myAxios.POST(url, payload);
    } catch (e: any) {
      console.log(e);
      return e.response.data;
    }
  };
  downloadIds.forEach(restartDownload);
};
const getFiles = async (downloadIds: number[]) => {
  const url = `${API_ENDPOINT}api/data/downloads/files/`;
  try {
    const payload = {
      ids: downloadIds,
    };
    return await myAxios.POST(url, payload);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const deleteDownloads = async (downloadIds: string[]) => {
  const url = `${API_ENDPOINT}api/data/downloads/delete/`;
  try {
    const payload = {
      ids: downloadIds,
    };
    return await myAxios.DELETE(url, payload);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
export const DownloadsAPI = {
  getDownloads,
  getDownloadStatus,
  restartDownloads,
  getFiles,
  deleteDownloads,
};
