import { ReelAnimateHandler } from 'components/VideoPlayerController/ReelsEditPlayerController/ReelAnimateHanlder';
import { OpenedPlaylistEventType } from 'types/playlist.types';

import { VideListPlayHandler } from './VideoListPlayHandler';
import {
  GameVideoType,
  KeyFrameType,
  PlaylistType,
  UserType,
} from '../../types/types';

type Interface = {
  videoElements: any;
  sharedPlaylist: any;
  currentPlayedVideo: any;
  isPlaying: boolean;
  currentEpisode: any;
  currentPlayerState: any;
  playerRef: any;
  currentUser: UserType | null;
  playConsecutive: boolean;
  playlists: { playlists: OpenedPlaylistEventType[] | undefined };
  playedVideoSet: GameVideoType | PlaylistType | null;
  playerMode: string;
  editedEpisodeRange: number[];
  visibleRange: number[];
};
export const usePlaylistHandler = ({
  sharedPlaylist,
  videoElements,
  currentPlayedVideo,
  isPlaying,
  currentEpisode,
  currentPlayerState,
  currentUser,
  playConsecutive,
  playlists,
  playedVideoSet,
  playerMode,
  editedEpisodeRange,
  visibleRange,
}: Interface) => {
  return new VideListPlayHandler({
    videoElements: videoElements,
    sharedPlaylist: sharedPlaylist,
    currentPlayedVideo: currentPlayedVideo,
    isPlaying: isPlaying,
    currentEpisode: currentEpisode,
    currentPlayerState: currentPlayerState,
    currentUser: currentUser,
    playConsecutive: playConsecutive,
    playlists: playlists,
    playedVideoSet: playedVideoSet,
    playerMode: playerMode,
    editedEpisodeRange: editedEpisodeRange,
    visibleRange: visibleRange,
  });
};

type ReelsAnimateHanlerInterface = {
  keyframes: KeyFrameType[];
  setReelWindowPosition: any;
  isPlaying: boolean;
  setReelsDimesions: any;
  currentPlayerState: any;
  currentVideoScreenWidth: any;
  currentVideoScreenHeight: any;
  reelEditorPosition: any;
};

export const useReelHandler = ({
  keyframes,
  setReelWindowPosition,
  setReelsDimesions,
  isPlaying,
  currentPlayerState,
  currentVideoScreenWidth,
  currentVideoScreenHeight,
  reelEditorPosition,
}: ReelsAnimateHanlerInterface) => {
  return new ReelAnimateHandler({
    keyframes: keyframes,
    setReelWindowPosition: setReelWindowPosition,
    setReelsDimesions: setReelsDimesions,
    isPlaying: isPlaying,
    currentPlayerState: currentPlayerState,
    currentVideoScreenWidth: currentVideoScreenWidth,
    currentVideoScreenHeight: currentVideoScreenHeight,
    reelEditorPosition: reelEditorPosition,
  });
};
