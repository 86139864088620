import React, { useState } from 'react';

import ContentContainerV2 from 'components/ContentContainerV2';
import {
  TournamentHeaderBlock,
  TournamentPageController,
} from 'components/TournamentController';

const TournamentPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  return (
    <>
      <ContentContainerV2
        pageTitle={''}
        page={'tournamentProfile'}
        showNavigationPanel={showNavigationPanel}
        style={{ padding: '0 0' }}
      >
        <TournamentHeaderBlock />
        <TournamentPageController />
      </ContentContainerV2>
    </>
  );
};
export default TournamentPage;
