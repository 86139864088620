import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthAPI } from 'api/authentication';
import WarningSign from 'assets/img/warningSign.svg';
import InputWithClear from 'components/InputWithClear';

import './index.css';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordsAreValid, setPasswordsAreValid] = useState(false);
  const [linkHasExpired, setLinkHasExpired] = useState(false);
  const [resetSuccessful, setResetSuccessful] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    window.document.body.setAttribute('data-theme', 'light');
  }, []);
  const restorePassword = async () => {
    if (passwordsAreValid) {
      const restoreToken = searchParams.get('token');
      if (restoreToken) {
        const response = await AuthAPI.confirmNewPassword(
          password,
          restoreToken,
        );
        if (response.status === 200) {
          setResetSuccessful(true);
          setTimeout(() => {
            setResetSuccessful(false);
            navigate('/login');
          }, 3000);
        }
      }
    }
  };
  useEffect(() => {
    const checkRestoreToken = async () => {
      const restoreToken = searchParams.get('token');
      if (restoreToken) {
        const checkResponse = await AuthAPI.checkResetToken(restoreToken);
        if (checkResponse.status !== 200) {
          setLinkHasExpired(true);
        }
      }
    };
    checkRestoreToken().then();
  }, []);
  const validateSamePasswords = () => {
    if (password !== passwordRepeat) {
      setPasswordsAreValid(false);
    } else {
      setPasswordsAreValid(true);
    }
  };
  useEffect(() => {
    validateSamePasswords();
  }, [passwordRepeat, password]);

  const passwordRepeatRef = useRef(null);
  const getTopOffset = (fieldRef: any) => {
    if (fieldRef.current) {
      return fieldRef?.current?.getBoundingClientRect().top - 8;
    }
  };
  const getLeftOffset = (fieldRef: any) => {
    return fieldRef?.current?.offsetLeft + fieldRef?.current?.clientWidth + 20;
  };
  const headerText = () => {
    if (linkHasExpired) {
      return 'Ссылка недействительна';
    }
    return 'Восстановление пароля';
  };

  const [t] = useTranslation();
  return (
    <div className="resetPasswordFormContainer">
      <div className="loginFormHeader">{headerText()}</div>
      {linkHasExpired ? (
        <div className="loginForm">
          <div className={'invalidLinkMessage'}>
            {t('Link has expired')}
            <Link to={'/recover-password'}> {t('here')}</Link>.
          </div>
        </div>
      ) : (
        <div className="loginForm">
          <div>
            <InputWithClear
              isRequired={true}
              valueController={{ value: password, setValue: setPassword }}
              fieldType={'password'}
              fieldName={'password'}
              labelText={t('Make a new password:')}
              clearIcon={false}
              validate={true}
              validationCallback={setPasswordsAreValid}
            />
          </div>
          <div>
            <InputWithClear
              ref={passwordRepeatRef}
              isRequired={true}
              valueController={{
                value: passwordRepeat,
                setValue: setPasswordRepeat,
              }}
              fieldType={'password'}
              fieldName={'passwordRepeat'}
              labelText={t('Repeat password')}
              clearIcon={false}
              focusTracker={validateSamePasswords}
            />
          </div>
          <div
            className={`buttonContainer ${!passwordsAreValid ? 'disabledClickable disabled' : ''}`}
          >
            <button onClick={restorePassword}>{t('Recover')}</button>
            <div className="linkContainer">
              <Link to="/login">{t('Enter')}</Link>
            </div>
          </div>
          {!passwordsAreValid && (
            <div
              className="warningFieldRequired"
              style={{
                top: `${getTopOffset(passwordRepeatRef)}px`,
                left: `${getLeftOffset(passwordRepeatRef)}px`,
              }}
            >
              <img src={WarningSign} />
              <div>{t('Passwords do not match')}</div>
            </div>
          )}
        </div>
      )}
      <ReactModal
        overlayClassName="modalOverlay"
        className={'teamsAddModals'}
        isOpen={resetSuccessful}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onRequestClose={() => {}}
        shouldCloseOnEsc={false}
      >
        <div className="modalTitle">{t('Password was changed')}</div>
      </ReactModal>
    </div>
  );
};
export default ResetPasswordPage;
