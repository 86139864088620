import React, { FC } from 'react';

import { Button, ConfigProvider, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { AC_SetPlayerHotkeysAllowedAction } from 'actions/player.acitons';
import { addFolderAction } from 'actions/playlistV2.async.actions';
import { ReactComponent as CheckboxFilled } from 'assets/img/icons/faCheckboxFilled.svg';
import { ReactComponent as ChevronRight } from 'assets/img/icons/faChevronRight.svg';
import { ReactComponent as CloseCross } from 'assets/img/icons/faCloseCrossWhite.svg';
import { useAppDispatch } from 'store';
import { NEW_FOLDER } from 'types/constants';

const AddNewFolderNavbarForm: FC<any> = ({
  inputStatus,
  setInputStatus,
  inputRef,
  setAddNewFolder,
}: {
  inputStatus: any;
  setInputStatus: any;
  newFolderName: any;
  setNewFolderName: any;
  closeAddFolder: any;
  inputRef: any;
  setAddNewFolder: any;
}) => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [newFolderName, setNewFolderName] = React.useState<string>(() =>
    t(NEW_FOLDER),
  );
  const addNewFolderHanlder = () => {
    if (newFolderName) {
      setAddNewFolder(false);
      dispatch(addFolderAction(newFolderName));
    } else {
      setInputStatus('error');
    }
    setNewFolderName(NEW_FOLDER);
  };
  const closeAddFolder = () => {
    setInputStatus('');
    setNewFolderName(NEW_FOLDER);
    setAddNewFolder(false);
    dispatch(AC_SetPlayerHotkeysAllowedAction(true));
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextPlaceholder: 'var(--colorTextPlaceholder)',
          fontFamily: 'SFProTextRegular',
          fontSize: 14,
        },
      }}
    >
      <div>
        <ChevronRight
          className={'enabledClickable baseIcon'}
          style={{
            transform: 'rotate(90deg)',
            width: 12,
            marginLeft: 4,
          }}
        />
      </div>
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder: 'var(--colorTextPlaceholder)',
            fontFamily: 'SFProTextRegular',
            fontSize: 14,
          },
          components: {
            Input: {
              colorBorder: 'var(--colorPrimaryHover)',
            },
          },
        }}
      >
        <Input
          status={inputStatus}
          ref={inputRef}
          className="new-folder-input"
          type="text"
          name="newFolderName"
          onChange={(event: any) => {
            setInputStatus('');
            setNewFolderName(event.target.value);
          }}
          autoFocus={true}
          maxLength={100}
          placeholder={t(NEW_FOLDER)}
          defaultValue={newFolderName}
          style={{
            background: 'var(--colorBgContainer)',
            color: 'var(--colorText)',
          }}
        ></Input>
      </ConfigProvider>
      <Button
        style={{
          border: '1px solid var(--colorIcon)',
          background: 'transparent',
          height: 24,
          width: 32,
        }}
        onClick={closeAddFolder}
        icon={<CloseCross />}
      />
      <Button
        style={{
          height: 24,
          width: 32,
          border: '1px solid var(--colorPrimaryBase)',
          background: 'rgba(22, 104, 220, 0.4)',
        }}
        onClick={addNewFolderHanlder}
        icon={<CheckboxFilled />}
        type="default"
      />
    </ConfigProvider>
  );
};
export default AddNewFolderNavbarForm;
