import './index.css';
import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { GetCurrentUserAction } from 'actions/auth.actions';
import { GetTeamsAction } from 'actions/clubs.actions';
import { AC_OpenFilterAction } from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_SetActiveVideoAction,
  AC_SetPlayerLoadingAction,
} from 'actions/player.acitons';
import ContentContainer from 'components/ContentContainer';
import VideoListController from 'components/VideoListController';
import { VideoPlayerController } from 'components/VideoPlayerController';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { STYLE_TYPES } from 'types/constants';
import { EpisodeFilterPanelTypes } from 'types/types';

const VideoPlayerPage = () => {
  const dispatch = useAppDispatch();
  const { currentUser } = useSelector(
    (state: AppStateType) => state.authReducer,
  );
  const { filteredVideos } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(setStyleModeAction(STYLE_TYPES.dark));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(AC_SetPlayerLoadingAction(false));
    if (filteredVideos.length === 0 && searchParams.size === 0) {
      dispatch(AC_OpenFilterAction(EpisodeFilterPanelTypes.players));
    }
    window.document.body.setAttribute('data-theme', 'dark');
  }, []);
  const [showNavigationPanel, setShowNavigationPanel] = useState(true); // todo toggle nav panel
  useEffect(() => {
    if (currentUser) {
      dispatch(GetTeamsAction(currentUser.clubs[0]));
    }
    setShowNavigationPanel((prev) => prev);
  }, [currentUser]);
  const [wideScreenMode, setWideScreenMode] = useState(false);

  return (
    <ContentContainer
      pageTitle={''}
      page={'player'}
      theme="dark"
      boxSizing={'border-box'}
      showNavigationPanel={true}
      wideScreenMode={wideScreenMode}
      rightPanel={
        showNavigationPanel && (
          <VideoListController
            isWideScreenMode={wideScreenMode}
            toggleWideScreen={setWideScreenMode}
          />
        )
      }
    >
      <VideoPlayerController
        isNarrowScreenMode={wideScreenMode}
        toggleFullScreen={setWideScreenMode}
      />
    </ContentContainer>
  );
};
export default VideoPlayerPage;
