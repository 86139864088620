import React from 'react';

import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';

const AverageStatValueTag = ({
  stat,
  selectedMetricFilters,
  selectedPositions,
}: {
  stat: any;
  selectedMetricFilters: any;
  selectedPositions: string | undefined;
}) => {
  const [t] = useTranslation();
  const getPercentValue = () => {
    if (selectedMetricFilters.includes(`${stat.key}_success`)) {
      return stat.details[`${stat.key}_success`].per90;
      // return stat.details[`${stat.key}_success`].percent + '%';
    }
    if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
      return stat.details[`${stat.key}_fail`].per90;
      // return stat.details[`${stat.key}_fail`].percent + '%';
    }
    if (selectedPositions) {
      if (stat.by_position) {
        return stat.by_position[selectedPositions]?.per90;
      }
      return '';
    }
    return stat.per90;
  };
  return (
    <Flex
      className={`${stat?.per90 === undefined ? 'na-stat' : stat.stat?.per90 > 0 ? 'average-stat-value-container' : 'stat-value-container-zero'}`}
      style={{ width: 56, minHeight: 40 }}
      justify="flex-start"
    >
      <Flex
        className="stat-average"
        vertical
        style={{ width: '100%' }}
        flex={1}
      >
        {stat?.per90 === undefined ? (
          <span className="disabled-stat-color">{t('n/a')}</span>
        ) : stat.per90 > 0 ? (
          <span className="stat-average-value">{getPercentValue()}</span>
        ) : (
          <span className="stat-average-value">0</span>
        )}
        {stat?.pth !== null && stat?.pth !== undefined && (
          <span className={'stat-percent-value'}>{stat?.pth}pth</span>
        )}
      </Flex>
    </Flex>
  );
};
export default AverageStatValueTag;
