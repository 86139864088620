import React, { FC, useEffect } from 'react';

import './index.css';
import { ConfigProvider, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { setSelectedPlayersAction } from 'actions/filter.actions';
import { AC_DisplayFilters } from 'actions/player.acitons';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { EpisodeFilterPanelTypes } from 'types/types';

import TeamsListFilterPanel from './TeamsListFilterPanel';
import Loader from '../../../Loader';
import FiltersContainer from '../FiltersContainer';

interface FilterInterface {
  hideCallback: any;
  nextCallback: any;
  applyFilter: any;
  filtersControls: { filters: any; updateFilters: any };
  preFilterEpisodesCount: any;
  preFilterLoading: boolean;
}
// type GrouppedPlayersType = {
//   [key: string]: Array<any>;
// };
/**
 * Фильтрация событий в видео по игрокам
 * @param closeCallback
 * @param nextCallback
 * @constructor
 */
const PlayersFilter: FC<FilterInterface> = ({
  hideCallback,
  nextCallback,
  applyFilter,
  // filtersControls,
  preFilterEpisodesCount,
  preFilterLoading,
}) => {
  const { appliedFilters, filterPanelState } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { activeTeam, playersLoading, playersVisible, selectedPlayers } =
    useSelector((state: AppStateType) => state.filtersReducer);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (['init', 'reopen-filter'].includes(filterPanelState)) {
      dispatch(AC_DisplayFilters(appliedFilters));
    }
  }, [filterPanelState]);

  const isPlayerSelected = (playerId: string) => {
    return selectedPlayers.includes(playerId);
  };
  const togglePlayer = (playerId: string, teamId: number | null) => {
    if (teamId) {
      const newCount: any = {};
      newCount[teamId] = [playerId];
      dispatch(setSelectedPlayersAction([playerId]));
    }
  };

  const clearSelection = () => {
    dispatch(setSelectedPlayersAction([]));
  };
  const [t] = useTranslation();
  return (
    <FiltersContainer
      type={EpisodeFilterPanelTypes.players}
      gap={32}
      // {...constructBackCallable()}
      closeCallback={hideCallback}
      clearCallback={clearSelection}
      applyCallback={applyFilter}
      nextCallback={nextCallback}
      applyCount={preFilterEpisodesCount}
      preFilterLoading={preFilterLoading}
    >
      <div className="flex-column w100 j-sb oy-h f-ga-16 flex-1">
        <div className={'filterPanels w100 oy-h h-avail'}>
          <TeamsListFilterPanel />
          <div className={'rightPanel'}>
            <div className={'playersColumn'}>
              {playersLoading && <Loader />}
              {Object.keys(playersVisible).map(
                (playerRole: string, index: number) => {
                  return (
                    <div
                      className={'columnBlock'}
                      key={`${playerRole}-${index}`}
                    >
                      <div className={'player_role_name'}>
                        {t(`${playerRole}s`)}
                      </div>
                      {playersVisible[playerRole]
                        ?.sort((a: any, b: any) =>
                          a.first_name < b.first_name ? -1 : 1,
                        )
                        .map((player: any, index: number) => {
                          return (
                            <div
                              className={` ${selectedPlayers.includes(player.id) ? 'active' : ''}`}
                              key={index}
                            >
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorBgContainer: '#141414',
                                    colorBorder: '#424242',
                                    lineWidth: 0,
                                  },
                                }}
                              >
                                <Radio
                                  checked={isPlayerSelected(player.id)}
                                  id={player.id}
                                  name="player"
                                  onChange={() =>
                                    togglePlayer(player.id, activeTeam)
                                  }
                                >
                                  {player.first_name} {player.last_name}
                                </Radio>
                              </ConfigProvider>
                            </div>
                          );
                        })}
                    </div>
                  );
                },
              )}
            </div>
          </div>
        </div>
      </div>
    </FiltersContainer>
  );
};
export default PlayersFilter;
