import { useSelector } from 'react-redux';

import { AppStateType } from 'reducers';
import { reelsReducer } from 'reducers/reels.reducer';

import { useAppDispatch } from '../../../store';
import { KeyFrameType, ReelFramePositionType } from '../../../types/types';

export class ReelAnimateHandler {
  keyframes: KeyFrameType[];
  setReelWindowPosition: any;
  isPlaying: boolean;
  currentPlayerState: any;
  setReelsDimesions: any;
  nextFrame: KeyFrameType | null;
  previousFrame: KeyFrameType | null;
  currentFrame: ReelFramePositionType | null;
  currentVideoScreenHeight: any;
  currentVideoScreenWidth: any;
  reelEditorPosition: any;

  constructor(props: any) {
    this.keyframes = props.keyframes;
    this.setReelWindowPosition = props.setReelWindowPosition;
    this.isPlaying = props.isPlaying;
    this.setReelsDimesions = props.setReelsDimesions;
    this.currentPlayerState = props.currentPlayerState;
    this.previousFrame = null;
    this.nextFrame = null;
    this.currentFrame = null;
    this.currentVideoScreenHeight = props.currentVideoScreenHeight;
    this.currentVideoScreenWidth = props.currentVideoScreenWidth;
    this.reelEditorPosition = props.reelEditorPosition;
  }
  dispatch = useAppDispatch();
  actions = reelsReducer.actions;
  state = useSelector((state: AppStateType) => state.playerReducerV2);

  makeLinearInterpolation = () => {
    if (this.currentFrame) {
      // eslint-disable-next-line no-self-assign
      this.currentFrame = this.currentFrame;
    } else if (this.previousFrame) {
      if (this.nextFrame) {
        const msBetweenFrames = Math.round(
          this.nextFrame.time - this.previousFrame?.time,
        ); // 3492185.576 - 3487453 = 4732
        const currentMs = Math.round(
          this.currentPlayerState.playedSeconds * 1000 -
            this.previousFrame?.time,
        ); // 9600 - 9000 = 600
        const progress = currentMs / msBetweenFrames;
        this.currentFrame = {
          x: 0,
          y: 0,
          width: 0,
          height: 0,
          heightRatio:
            this.previousFrame.heightRatio +
            (this.nextFrame.heightRatio - this.previousFrame.heightRatio) *
              progress,
          widthRatio:
            this.previousFrame.widthRatio +
            (this.nextFrame.widthRatio - this.previousFrame.widthRatio) *
              progress,
          xRatio:
            this.previousFrame.xRatio +
            (this.nextFrame.xRatio - this.previousFrame.xRatio) * progress, //
          yRatio:
            this.previousFrame.yRatio +
            (this.nextFrame.yRatio - this.previousFrame.yRatio) * progress, //,
        };
        this.currentFrame.x =
          this.state.videoDimensions.width * this.currentFrame.xRatio;
        this.currentFrame.y =
          this.state.videoDimensions.height * this.currentFrame.yRatio;
        this.currentFrame.width =
          this.state.videoDimensions.width * this.currentFrame.widthRatio;
        this.currentFrame.height =
          this.state.videoDimensions.height * this.currentFrame.heightRatio;
      } else {
        // console.log('no next frame');
        // console.log(this.previousFrame);
        this.currentFrame = { ...this.previousFrame };
        this.currentFrame.height =
          this.state.videoDimensions.height * this.previousFrame.heightRatio;
        this.currentFrame.width =
          this.state.videoDimensions.width * this.previousFrame.widthRatio;
        // console.log(this.state.videoDimensions);
        // console.log(this.currentFrame);
      }
    } else if (this.nextFrame && !this.currentFrame) {
      this.currentFrame = this.nextFrame;
    }
    if (this.currentFrame) {
      this.dispatch(
        this.actions.setReelEditorPosition({
          x: this.currentFrame.xRatio * this.state.videoDimensions.width,
          y: this.currentFrame.yRatio * this.state.videoDimensions.height,
          xRatio: this.currentFrame.x / this.state.videoDimensions.width,
          yRatio: this.currentFrame.y / this.state.videoDimensions.height,
          width: this.currentFrame.width,
          height: this.currentFrame.height,
          heightRatio: this.currentFrame.heightRatio,
          widthRatio: this.currentFrame.widthRatio,
        }),
      );
      this.setReelWindowPosition({
        x: this.currentFrame.xRatio * this.state.videoDimensions.width,
        y: this.currentFrame.yRatio * this.state.videoDimensions.height,
      });
      // console.log(this.currentFrame);
      this.setReelsDimesions(this.currentFrame.height, this.currentFrame.width);
    }
  };

  monitorPlayback = () => {
    const previousFrames = this.keyframes?.filter(
      (frame: KeyFrameType) =>
        frame.time < this.currentPlayerState?.playedSeconds * 1000,
    );
    if (previousFrames.length > 0) {
      this.previousFrame = previousFrames.reduce((prev, next) => {
        return prev.time > next.time ? prev : next;
      });
    }
    const currentFrames = this.keyframes?.filter(
      (frame: KeyFrameType) =>
        frame.time === this.currentPlayerState?.playedSeconds * 1000,
    );
    if (currentFrames.length > 0) {
      this.currentFrame = currentFrames[0];
    }
    const nextFrames = this.keyframes?.filter(
      (frame: KeyFrameType) =>
        frame.time > this.currentPlayerState?.playedSeconds * 1000,
    );
    if (nextFrames.length > 0) {
      this.nextFrame = nextFrames.reduce((prev, next) => {
        return prev.time < next.time ? prev : next;
      });
    }
    this.makeLinearInterpolation();
  };
}
