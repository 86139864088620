import React, { useEffect, useRef, useState } from 'react';

import './index.css';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';

import { setStyleModeAction } from 'actions/interface.actions';
import { getSharedLinkAction } from 'actions/playlistV2.async.actions';
import ContentContainer from 'components/ContentContainer';
import VideoListController from 'components/VideoListController';
import { VideoPlayerController } from 'components/VideoPlayerController';
import { playlistReducerV2 } from 'reducers/playlist.reducer';
import { useAppDispatch } from 'store';

const SharedPlaylistPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showNavigationPanel, setShowNavigationPanel] = useState(true);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { AC_setPlaylistIsLoading } = playlistReducerV2.actions;

  useEffect(() => {
    dispatch(setStyleModeAction('dark'));
    const sharedLink = params.shareLink;
    dispatch(AC_setPlaylistIsLoading(false));
    if (sharedLink) {
      dispatch(getSharedLinkAction(sharedLink));
    }
  }, []);
  const refPlayer = useRef<ReactPlayer>(null);
  useEffect(() => {
    window.document.body.setAttribute('data-theme', 'dark');
  }, []);
  const [wideScreenMode, setWideScreenMode] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (
    <ContentContainer
      wideScreenMode={wideScreenMode}
      page={'sharedPlaylist'}
      pageTitle={''}
      showNavigationPanel={false}
      theme="dark"
      rightPanel={
        showNavigationPanel && (
          <VideoListController
            ref={refPlayer}
            isWideScreenMode={wideScreenMode}
            toggleFullScreen={setWideScreenMode}
            isShared={true}
            toggleWideScreen={setWideScreenMode}
          />
        )
      }
    >
      <VideoPlayerController
        ref={refPlayer}
        toggleFullScreen={setWideScreenMode}
        isNarrowScreenMode={wideScreenMode}
        toggleWideScreen={setWideScreenMode}
        isShared={true}
      />
    </ContentContainer>
  );
};
export default SharedPlaylistPage;
