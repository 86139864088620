import React, { FC, useEffect, useLayoutEffect, useState } from 'react';

import './index.css';
import { Checkbox, ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';

import {
  AC_SetSelectedAttributes,
  AC_SetShowMyEpisodesFilterAction,
  GetEpisodesAttributesRegister,
  setSelectedAttributes,
} from 'actions/filter.actions';
import { AC_DisplayFilters } from 'actions/player.acitons';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { EpisodeAttributeType, EpisodeFilterPanelTypes } from 'types/types';

import Loader from '../../../Loader';
import FiltersContainer from '../FiltersContainer';

interface FilterInterface {
  hideCallback: any;
  applyFilter: any;
  nextCallback: any;
  filtersControls: { filters: any; updateFilters: any };
  preFilterEpisodesCount: any;
  preFilterLoading: boolean;
}
type GrouppedAtrributesType = {
  [key: string]: Array<EpisodeAttributeType>;
};
const MetricsFilter: FC<FilterInterface> = ({
  hideCallback,
  applyFilter,
  nextCallback,
  // filtersControls,
  preFilterEpisodesCount,
  preFilterLoading,
}) => {
  const { loadingActions, displayedFilters, appliedFilters } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { selectedAttributes, episodeAttributes } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const [formEpisodeAttributes, setFormEpisodeAttributes] =
    useState<GrouppedAtrributesType>({});
  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    dispatch(GetEpisodesAttributesRegister());
    if (
      displayedFilters === null ||
      Object.keys(displayedFilters).length === 0
    ) {
      dispatch(AC_DisplayFilters(appliedFilters));
    }
  }, []);
  useEffect(() => {
    // console.log(333)
    if (episodeAttributes) {
      // setFormEpisodeAttributesLoading(true);
      const groupped: GrouppedAtrributesType = {};
      episodeAttributes.forEach((item) => {
        if (item.category) {
          const category: string = item?.category || '';
          if (!groupped[category]) {
            groupped[category] = [];
          }
          groupped[category].push(item);
        }
      });
      setFormEpisodeAttributes(groupped);
      // setFormEpisodeAttributesLoading(false);
    }
  }, [episodeAttributes]);
  /* Handle metrics filter*/
  const initLocalAttributesValue = () => {
    if (displayedFilters && displayedFilters.attributes) {
      return displayedFilters.attributes;
    } else if (appliedFilters && appliedFilters.attributes) {
      return appliedFilters.attributes;
    }
    return [];
  };
  const [localAttributesValue, setLocalAttributesValue] = useState<
    Array<string>
  >(initLocalAttributesValue());
  const updateAttribute = (value: string) => {
    if (localAttributesValue && localAttributesValue.includes(value)) {
      setLocalAttributesValue((prev: Array<string>) =>
        prev.filter((id) => id !== value),
      );
    } else {
      setLocalAttributesValue((prev: Array<string>) => [...prev, value]);
    }
    let newSelectedAttributesSet: number[];
    if (selectedAttributes.includes(Number(value))) {
      newSelectedAttributesSet = selectedAttributes.filter(
        (el) => el !== Number(value),
      );
    } else {
      newSelectedAttributesSet = [...selectedAttributes, Number(value)];
    }
    dispatch(setSelectedAttributes(newSelectedAttributesSet));
  };
  /* End metrics metrics filter*/
  const clearSelection = () => {
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_SetShowMyEpisodesFilterAction(false));
  };
  // const [t] = useTranslation();
  return (
    <FiltersContainer
      type={EpisodeFilterPanelTypes.metrics}
      gap={32}
      closeCallback={hideCallback}
      clearCallback={clearSelection}
      applyCallback={applyFilter}
      applyCount={
        preFilterEpisodesCount !== null
          ? preFilterEpisodesCount
          : selectedAttributes.length
      }
      nextCallback={nextCallback}
      allowGrow={true}
      preFilterLoading={preFilterLoading}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        {loadingActions.includes('SET_EPISODE_ATTRIBUTES_REQUEST') && (
          <div className={'flex-row j-ce'}>
            <Loader />
          </div>
        )}
        <div
          className={'filterPanels videoAttributes w100 f-grow j-sb f-ga-32'}
        >
          {!loadingActions.includes('SET_EPISODE_ATTRIBUTES_REQUEST') &&
            Object.keys(formEpisodeAttributes).map(
              (category: string, index: number) => {
                return (
                  <div className={'category flex-column'} key={index}>
                    <div className={'filtersActive title flex-row'}>
                      {category}
                    </div>
                    <div className={'hr'} />
                    <ul>
                      {formEpisodeAttributes[category].map(
                        (attribute: EpisodeAttributeType, index: number) => {
                          return (
                            <li className={'flex-row f-ga-4'} key={index}>
                              <ConfigProvider
                                theme={{
                                  token: {
                                    colorBgContainer: '#141414',
                                    colorBorder: '#424242',
                                    lineWidth: 1,
                                  },
                                }}
                              >
                                <Checkbox
                                  checked={selectedAttributes.includes(
                                    Number(attribute.id),
                                  )}
                                  onChange={() => {
                                    updateAttribute(attribute.id);
                                  }}
                                  id={`cat-${category}-attr-${attribute.name}`}
                                >
                                  {attribute.name}
                                </Checkbox>
                              </ConfigProvider>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                );
              },
            )}
        </div>
        {/* <div className={"flex-column"}>
                    <div className={"hr"}/>
                        <li className={"flex-row f-ga-4"} style={{marginTop: 13}}>
                            <ConfigProvider
                                        theme={{
                                            token: {
                                                colorBgContainer: "#141414",
                                                colorBorder: "#424242",
                                                lineWidth: 1
                                            }
                                        }}
                                    >
                            <Checkbox
                                checked={showMyEpisdes}
                                onChange={(e) => {dispatch(AC_SetShowMyEpisodesFilterAction(!showMyEpisdes))}}
                                id={`cat-userEvents-attr`}
                            >
                                {t('Your episodes')}
                            </Checkbox>
                            </ConfigProvider>
                        </li>    
                    </div> */}
      </div>
    </FiltersContainer>
  );
};
export default MetricsFilter;
