import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';
import './index.css';
interface Interface {
  speedSetCallback: any;
  children: any;
}
const VideoSpeedContext: FC<Interface> = (props: any) => {
  const [t] = useTranslation();
  const items: MenuProps['items'] = [
    {
      key: 0,
      label: <div className="non-selectable-context">{t('Speed')}</div>,
      disabled: true,
    },
    { type: 'divider' },
    {
      key: 1,
      label: <div onClick={() => props.speedSetCallback(0.25)}>x0.25</div>,
      disabled: false,
    },
    {
      key: 2,
      label: <div onClick={() => props.speedSetCallback(0.5)}>x0.5</div>,
      disabled: false,
    },
    {
      key: 3,
      label: <div onClick={() => props.speedSetCallback(1)}>x1</div>,
      disabled: false,
    },
    {
      key: 4,
      label: <div onClick={() => props.speedSetCallback(2)}>x2</div>,
      disabled: false,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
          // lineHeight: 16
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['hover']}
        overlayStyle={{
          width: 'max-content',
        }}
        placement="top"
      >
        {props.children}
      </Dropdown>
    </ConfigProvider>
  );
};
export default VideoSpeedContext;
