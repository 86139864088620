import React, { FC } from 'react';

import { ConfigProvider, Dropdown, MenuProps, Slider } from 'antd';
import './index.css';
interface Interface {
  setVolumeCallback: any;
  volume: number;
  children: any;
}
const VideoSoundContext: FC<Interface> = (props: any) => {
  const items: MenuProps['items'] = [
    {
      key: 0,
      label: (
        <div style={{ position: 'relative', left: -13 }}>
          <ConfigProvider
            theme={{
              components: {
                Slider: {
                  handleActiveColor: 'var(--Primary-5, #40A9FF)',
                  handleColor: 'var(--Primary-5, #40A9FF)',
                  // handleSizeHover: 10,
                  // handleSize: 10,
                  // handleLineWidthHover: 2,
                  // handleLineWidth: 2,
                  colorPrimaryBorderHover: 'var(--Primary-5, #40A9FF)',
                  colorFillContentHover: 'var(--Primary-5, #40A9FF)',
                },
              },
            }}
          >
            <Slider
              range={false}
              vertical={true}
              tooltip={{ open: false }}
              onChange={props.setVolumeCallback}
              style={{ height: 130 }}
              value={props.volume}
              min={0}
              max={1}
              step={0.01}
            />
          </ConfigProvider>
        </div>
      ),
      disabled: true,
    },
  ];
  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: { marginLG: 0, padding: 0 },
        },
        token: {
          colorBgElevated: 'var(--colorBgSpotlight)',
          colorText: 'var(--colorText)',
          // lineHeight: 16
          margin: 0,
        },
      }}
    >
      <Dropdown
        className="enabledClickable"
        menu={{ items }}
        trigger={['hover']}
        overlayStyle={{
          width: 40,
          padding: 0,
        }}
        placement="top"
      >
        {props.children}
      </Dropdown>
    </ConfigProvider>
  );
};
export default VideoSoundContext;
