import { ThunkAction } from 'redux-thunk';

import { CameraListFilterParams, EquipmentAPI } from 'api/equipment';
import { AppStateType } from 'reducers';
import {
  DELETE_CAMERA_REQUEST,
  DELETE_CAMERA_REQUEST_ERROR,
  DELETE_CAMERA_REQUEST_SUCCESS,
  GET_CAMERAS_LIST_REQUEST,
  GET_CAMERAS_LIST_REQUEST_ERROR,
  GET_CAMERAS_LIST_REQUEST_SUCCESS,
  SET_CURRENT_CAMERA,
  UPDATE_CAMERA_REQUEST,
  UPDATE_CAMERA_REQUEST_ERROR,
  UPDATE_CAMERA_REQUEST_SUCCESS,
} from 'types/action.types';
import { CameraType } from 'types/types';

export type EquipmentActionsTypes =
  | GetCamerasListRequestType
  | GetCamerasListRequestSuccessType
  | GetCamerasListRequestErrorType
  | UpdateCamerasRequestType
  | UpdateCamerasRequestSuccessType
  | UpdateCamerasRequestErrorType
  | DeleteCameraRequestType
  | DeleteCameraRequestSuccessType
  | DeleteCameraRequestErrorType
  | SetCurrentCameraType;

/*LIST*/
type GetCamerasListRequestType = {
  type: typeof GET_CAMERAS_LIST_REQUEST;
};
type GetCamerasListRequestSuccessType = {
  type: typeof GET_CAMERAS_LIST_REQUEST_SUCCESS;
  payload: Array<CameraType>;
};
type GetCamerasListRequestErrorType = {
  type: typeof GET_CAMERAS_LIST_REQUEST_ERROR;
  payload: any;
};
const AC_GetCamerasListRequest = (): GetCamerasListRequestType => {
  return {
    type: GET_CAMERAS_LIST_REQUEST,
  };
};
const AC_GetCamerasListErrorRequest = (
  error: any,
): GetCamerasListRequestErrorType => {
  return {
    type: GET_CAMERAS_LIST_REQUEST_ERROR,
    payload: error,
  };
};
const AC_GetCamerasListSuccessRequest = (
  cameras: Array<CameraType>,
): GetCamerasListRequestSuccessType => {
  return {
    type: GET_CAMERAS_LIST_REQUEST_SUCCESS,
    payload: cameras,
  };
};
export const GetCamerasListAction =
  (
    params?: CameraListFilterParams,
  ): ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    EquipmentActionsTypes
  > =>
  async (dispatch) => {
    try {
      dispatch(AC_GetCamerasListRequest());
      const response = await EquipmentAPI.getCamerasList(params);
      if (response.status === 200) {
        dispatch(AC_GetCamerasListSuccessRequest(response.data.results));
      } else {
        dispatch(AC_GetCamerasListErrorRequest(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
/*UPDATE*/
type UpdateCamerasRequestType = {
  type: typeof UPDATE_CAMERA_REQUEST;
};
type UpdateCamerasRequestSuccessType = {
  type: typeof UPDATE_CAMERA_REQUEST_SUCCESS;
  payload: CameraType;
};
type UpdateCamerasRequestErrorType = {
  type: typeof UPDATE_CAMERA_REQUEST_ERROR;
  payload: any;
};
const AC_UpdateCameraRequest = (): UpdateCamerasRequestType => {
  return {
    type: UPDATE_CAMERA_REQUEST,
  };
};
const AC_UpdateCamerasRequestSuccess = (
  cameras: CameraType,
): UpdateCamerasRequestSuccessType => {
  return {
    type: UPDATE_CAMERA_REQUEST_SUCCESS,
    payload: cameras,
  };
};
const AC_UpdateCamerasRequestError = (
  error: any,
): UpdateCamerasRequestErrorType => {
  return {
    type: UPDATE_CAMERA_REQUEST_ERROR,
    payload: error,
  };
};
export const UpdateCamerasAction =
  (
    cameraId: string,
    cameraData: CameraType,
  ): ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    EquipmentActionsTypes
  > =>
  async (dispatch) => {
    try {
      dispatch(AC_UpdateCameraRequest());
      const response = await EquipmentAPI.updateCamera(cameraId, cameraData);
      if (response.status === 200) {
        dispatch(AC_UpdateCamerasRequestSuccess(response.data));
      } else {
        dispatch(AC_UpdateCamerasRequestError(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

/*DELETE*/
type DeleteCameraRequestType = {
  type: typeof DELETE_CAMERA_REQUEST;
};
type DeleteCameraRequestSuccessType = {
  type: typeof DELETE_CAMERA_REQUEST_SUCCESS;
  payload: string;
};
type DeleteCameraRequestErrorType = {
  type: typeof DELETE_CAMERA_REQUEST_ERROR;
  payload: any;
};
const AC_DeleteCameraRequest = (): DeleteCameraRequestType => {
  return {
    type: DELETE_CAMERA_REQUEST,
  };
};
const AC_DeleteCameraRequestSuccess = (
  cameraId: string,
): DeleteCameraRequestSuccessType => {
  return {
    type: DELETE_CAMERA_REQUEST_SUCCESS,
    payload: cameraId,
  };
};
const AC_DeleteCameraRequestError = (
  error: any,
): DeleteCameraRequestErrorType => {
  return {
    type: DELETE_CAMERA_REQUEST_ERROR,
    payload: error,
  };
};
export const DeleteCamerasAction =
  (
    cameraId: string,
  ): ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    EquipmentActionsTypes
  > =>
  async (dispatch) => {
    try {
      dispatch(AC_DeleteCameraRequest());
      const response = await EquipmentAPI.deleteCamera(cameraId);
      if (response.status === 204) {
        dispatch(AC_DeleteCameraRequestSuccess(response.data));
      } else {
        dispatch(AC_DeleteCameraRequestError(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
type SetCurrentCameraType = {
  type: typeof SET_CURRENT_CAMERA;
  payload: CameraType;
};
/*SET CURRENT CAMERA*/
export const SetCurrentCameraAction = (
  camera: CameraType,
): SetCurrentCameraType => {
  return {
    type: SET_CURRENT_CAMERA,
    payload: camera,
  };
};
