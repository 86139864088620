import { API_ENDPOINT } from 'types/constants';
import myAxios from 'utils/axios';

const getUnreadNotifications = async () => {
  const url = `${API_ENDPOINT}api/notifications/`;
  try {
    const params = new URLSearchParams();
    params.append('is_read', false.toString());
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const readNotification = async (notificationId: string) => {
  const url = `${API_ENDPOINT}api/notifications/${notificationId}/read/`;
  try {
    return await myAxios.POST(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

export const NotificationsAPI = {
  getUnreadNotifications,
  readNotification,
};
