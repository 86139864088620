import { ThunkAction } from 'redux-thunk';

import { ClubsAPI } from 'api/clubs';
import { EventsAPI } from 'api/events';
import { RegistriesAPI } from 'api/registries';
import { AppStateType } from 'reducers';
import {
  ADD_ACTIVE_TEAM_DATE_LIMITS,
  ADD_SELECTED_TOURNAMENT,
  OPEN_FILTER,
  PREFILTER_REQUEST,
  PREFILTER_REQUEST_ERROR,
  PREFILTER_REQUEST_SUCCESS,
  SET_ACTIVE_TEAM_FILTER,
  SET_CURRENT_TEAM_TOURNAMENTS,
  SET_INTERMEDIATE_ACTIVITIES,
  SET_LAST_X_FILTER,
  SET_SELECTED_ATTRIBUTES,
  SET_SELECTED_DATE_LIMITS_FILTER,
  SET_SELECTED_MATCHES,
  SET_SELECTED_PLAYERS,
  SET_SELECTED_TIME_INTERVALS,
  SET_SHOW_MY_EPISODES_FILTER,
  SET_SHOW_PLAYER_GAMES_FILTER,
  SET_SHOW_PLAYER_INTERVALS_FILTER,
  SET_VISIBLE_FILTER_ATTRIBUTES,
  SET_VISIBLE_PLAYERS_TYPE,
} from 'types/action.types';
import {
  EpisodeAttributeType,
  EpisodeFilterPanelTypes,
  GrouppedPlayersType,
} from 'types/types';

export type FilterActionTypes =
  | OpenFilterType
  | ActiveTeamFilterType
  | SetVisiblePlayerActionType
  | SetSelectedPlayersFilterType
  | SelectedMatchesFilterType
  | SetCurrentTeamTournamentsType
  | AddSelectedTounamentType
  | PrefilterRequestType
  | PrefilterRequestSuccessType
  | PrefilterRequestErrorType
  | SetCurrentTeamDateLimitsType
  | SetLastXFilterType
  | SetSelectedDateLimitsFilterType
  | SetSelectedAttributesType
  | SetVisibleFilterAttributesType
  | SelectTimingIntervalFilterType
  | SetShowMyEpisodesFilterType
  | SetShowPlayerIntervalsFilterType
  | SetShowPlayerGamesFilterType
  | SetIntermediateActivitiesType;

type OpenFilterType = {
  type: typeof OPEN_FILTER;
  filter: EpisodeFilterPanelTypes | string;
};
export const AC_OpenFilterAction = (
  filter: EpisodeFilterPanelTypes | string,
) => {
  return {
    type: OPEN_FILTER,
    filter: filter,
  };
};
type ActiveTeamFilterType = {
  type: typeof SET_ACTIVE_TEAM_FILTER;
  payload: number | null;
};
export const AC_SetActiveTeamFilterAction = (
  teamId: number | null,
): ActiveTeamFilterType => {
  return {
    type: SET_ACTIVE_TEAM_FILTER,
    payload: teamId,
  };
};
type SelectedMatchesFilterType = {
  type: typeof SET_SELECTED_MATCHES;
  payload: string[];
};
export const AC_SelectedMatchesFilterAction = (
  matches: string[],
): SelectedMatchesFilterType => {
  return {
    type: SET_SELECTED_MATCHES,
    payload: matches,
  };
};
type SetSelectedPlayersFilterType = {
  type: typeof SET_SELECTED_PLAYERS;
  payload: string[];
};
export const AC_SetSelectedPlayersAction = (
  selectedPlayers: string[],
): SetSelectedPlayersFilterType => {
  return {
    type: SET_SELECTED_PLAYERS,
    payload: selectedPlayers,
  };
};
type SetCurrentTeamTournamentsType = {
  type: typeof SET_CURRENT_TEAM_TOURNAMENTS;
  payload: any[];
};
const AC_SetCurrentTeamTournamentsAction = (
  tournaments: any[],
): SetCurrentTeamTournamentsType => {
  return {
    type: SET_CURRENT_TEAM_TOURNAMENTS,
    payload: tournaments,
  };
};
type SetCurrentTeamDateLimitsType = {
  type: typeof ADD_ACTIVE_TEAM_DATE_LIMITS;
  payload: any[];
};
const AC_SetCurrentTeamDateLimitsAction = (
  tournaments: any[],
): SetCurrentTeamDateLimitsType => {
  return {
    type: ADD_ACTIVE_TEAM_DATE_LIMITS,
    payload: tournaments,
  };
};
type SetSelectedDateLimitsFilterType = {
  type: typeof SET_SELECTED_DATE_LIMITS_FILTER;
  payload: any;
};
export const AC_SetSelectedDateLimitsFilterAction = (
  value: any,
): SetSelectedDateLimitsFilterType => {
  return {
    type: SET_SELECTED_DATE_LIMITS_FILTER,
    payload: value,
  };
};

type SetLastXFilterType = {
  type: typeof SET_LAST_X_FILTER;
  payload: number | null;
};
export const AC_SetLastXFilterAction = (
  value: number | null,
): SetLastXFilterType => {
  return {
    type: SET_LAST_X_FILTER,
    payload: value,
  };
};

export const setActiveTeamTournamentsFilterAction =
  (
    teamId: number | null,
    flushSelectedGames = true,
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    const tournamentsResponse = await RegistriesAPI.getTournaments({
      my: true,
      team: teamId,
      games: true,
    });
    if (teamId) {
      const eventsDateLimitsResponse =
        await EventsAPI.getEventsDateLimits(teamId);
      dispatch(
        AC_SetCurrentTeamDateLimitsAction(eventsDateLimitsResponse.data),
      );
    }
    dispatch(
      AC_SetCurrentTeamTournamentsAction(tournamentsResponse.data.results),
    );
    if (flushSelectedGames) {
      dispatch(AC_SelectedMatchesFilterAction([]));
    }
  };
type SetVisiblePlayerActionType = {
  type: typeof SET_VISIBLE_PLAYERS_TYPE;
  payload: any;
};
export const AC_SetVisiblePlayersAction = (
  players: any,
): SetVisiblePlayerActionType => {
  return {
    type: SET_VISIBLE_PLAYERS_TYPE,
    payload: players,
  };
};

type AddSelectedTounamentType = {
  type: typeof ADD_SELECTED_TOURNAMENT;
  payload: any[];
};
export const AC_AddSelectedTournamentFilterAction = (
  selectedTournaments: any[],
): AddSelectedTounamentType => {
  return {
    type: ADD_SELECTED_TOURNAMENT,
    payload: selectedTournaments,
  };
};

type PrefilterRequestType = {
  type: typeof PREFILTER_REQUEST;
};
type PrefilterRequestSuccessType = {
  type: typeof PREFILTER_REQUEST_SUCCESS;
  payload: Array<any>;
};
type PrefilterRequestErrorType = {
  type: typeof PREFILTER_REQUEST_ERROR;
};
const AC_PrefilterRequest = (): PrefilterRequestType => {
  return {
    type: PREFILTER_REQUEST,
  };
};
type SetVisibleFilterAttributesType = {
  type: typeof SET_VISIBLE_FILTER_ATTRIBUTES;
  payload: Array<EpisodeAttributeType>;
};
const AC_SetVisibleFilterAttributesAction = (
  values: Array<EpisodeAttributeType>,
): SetVisibleFilterAttributesType => {
  return {
    type: SET_VISIBLE_FILTER_ATTRIBUTES,
    payload: values,
  };
};
export const GetEpisodesAttributesRegister =
  (): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch, getState) => {
    const {
      activeTeam,
      selectedPlayers,
      selectedGames,
      selectedTournaments,
      selectedDateLimits,
    } = getState().filtersReducer;
    const attributesParams = {
      team: activeTeam,
      players: selectedPlayers,
      games: selectedGames,
      tournaments: selectedTournaments.map((el) => el.id),
      dateLimits: selectedDateLimits,
    };
    try {
      // dispatch(AC_EpisodeAttributesRequest());
      const response = await EventsAPI.getListOfAttributes(attributesParams);
      if (response.status === 200) {
        dispatch(AC_SetVisibleFilterAttributesAction(response.data));
      } else {
        // dispatch(AC_EpisodeAttributesRequestError(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

type SetSelectedAttributesType = {
  type: typeof SET_SELECTED_ATTRIBUTES;
  payload: number[];
};
export const AC_SetSelectedAttributes = (
  attributes: number[],
): SetSelectedAttributesType => {
  return {
    type: SET_SELECTED_ATTRIBUTES,
    payload: attributes,
  };
};
export const setSelectedAttributes =
  (
    attributes: number[],
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    dispatch(AC_SetSelectedAttributes(attributes));
    // dispatch(AC_SelectedMatchesFilterAction([]));
    // dispatch(PrefilterGamesAction());
  };
const AC_PrefilterRequestError = (): PrefilterRequestErrorType => {
  return { type: PREFILTER_REQUEST_ERROR };
};
export const AC_PrefilterRequestSuccess = (
  games: Array<any>,
): PrefilterRequestSuccessType => {
  return {
    type: PREFILTER_REQUEST_SUCCESS,
    payload: games,
  };
};

export const PrefilterGamesAction =
  (): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch, getState) => {
    const {
      activeTeam,
      selectedTournaments,
      selectedDateLimits,
      lastXFilter,
      selectedPlayers,
    } = getState().filtersReducer;
    if (activeTeam) {
      try {
        dispatch(AC_PrefilterRequest());
        const newFiltersV2: any = {
          type: 'game',
          games: {},
        };
        newFiltersV2.games[activeTeam] = {};
        if (selectedTournaments.length > 0) {
          newFiltersV2.games[activeTeam].tournaments = selectedTournaments.map(
            (el) => el.id || el,
          );
        }
        if (Object.keys(selectedDateLimits).length > 0) {
          const shiftedDateLimits: any = {};
          for (const year of Object.keys(selectedDateLimits)) {
            shiftedDateLimits[year] = [
              selectedDateLimits[year][0] + 1,
              selectedDateLimits[year][1],
            ];
          }
          newFiltersV2.games[activeTeam].date_limits = {
            ...shiftedDateLimits,
          };
        }
        if (lastXFilter) {
          newFiltersV2.games[activeTeam].lastX = lastXFilter;
        }
        // console.log('selectedPlayers', selectedPlayers);
        if (selectedPlayers.length > 0) {
          newFiltersV2.players = {};
          newFiltersV2.players[activeTeam] = selectedPlayers;
        }
        // if (selectedAttributes.length > 0){
        //     newFiltersV2.attributes = selectedAttributes;
        // }
        // console.log("newFiltersV2", newFiltersV2);

        const response = await EventsAPI.getPreFilterGames(newFiltersV2);
        const results = response.data.results;

        dispatch(AC_PrefilterRequestSuccess(results));
      } catch (e) {
        console.log(e);
        dispatch(AC_PrefilterRequestError());
      }
    }
  };
type SelectTimingIntervalFilterType = {
  type: typeof SET_SELECTED_TIME_INTERVALS;
  payload: any;
};
export const AC_SelectTimingIntervalFilterAction = (
  intervals: any,
): SelectTimingIntervalFilterType => {
  return {
    type: SET_SELECTED_TIME_INTERVALS,
    payload: intervals,
  };
};
type SetShowMyEpisodesFilterType = {
  type: typeof SET_SHOW_MY_EPISODES_FILTER;
  payload: any;
};
export const AC_SetShowMyEpisodesFilterAction = (
  show: boolean,
): SetShowMyEpisodesFilterType => {
  return {
    type: SET_SHOW_MY_EPISODES_FILTER,
    payload: show,
  };
};
type SetShowPlayerIntervalsFilterType = {
  type: typeof SET_SHOW_PLAYER_INTERVALS_FILTER;
  payload: any;
};
export const AC_SetShowPlayerIntervalsFilterAction = (
  show: boolean,
): SetShowPlayerIntervalsFilterType => {
  return {
    type: SET_SHOW_PLAYER_INTERVALS_FILTER,
    payload: show,
  };
};
type SetShowPlayerGamesFilterType = {
  type: typeof SET_SHOW_PLAYER_GAMES_FILTER;
  payload: boolean;
};
export const AC_SetShowPlayerGamesFilterAction = (
  show: boolean,
): SetShowPlayerGamesFilterType => {
  return {
    type: SET_SHOW_PLAYER_GAMES_FILTER,
    payload: show,
  };
};

type SetIntermediateActivitiesType = {
  type: typeof SET_INTERMEDIATE_ACTIVITIES;
  payload: any[];
};
export const AC_setIntermediateActivitiesAction = (
  activityIds: any[],
): SetIntermediateActivitiesType => {
  return {
    type: SET_INTERMEDIATE_ACTIVITIES,
    payload: activityIds,
  };
};
export const setSelectedPlayersAction =
  (
    value: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    dispatch(AC_SetSelectedPlayersAction(value));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedDateLimitsFilterAction({}));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_SetShowPlayerIntervalsFilterAction(false));
    dispatch(AC_SelectTimingIntervalFilterAction([]));
    dispatch(PrefilterGamesAction());
  };

export const setSelectedDateLimitsFilterAction =
  (
    value: any,
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    dispatch(AC_SetSelectedDateLimitsFilterAction(value));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(PrefilterGamesAction());
  };

export const setLastXFilterAction =
  (
    teamId: number | null,
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    dispatch(AC_SetLastXFilterAction(teamId));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(PrefilterGamesAction());
  };

export const setActiveTeamFilterAction =
  (
    teamId: number | null,
    flushSelectedGames = true,
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    try {
      if (teamId) {
        dispatch(AC_SetActiveTeamFilterAction(teamId));
        const response = await ClubsAPI.getPlayers({ team: teamId });
        if (response.data.results.length > 0) {
          const groupped: GrouppedPlayersType = {};
          response.data.results.forEach((player: any) => {
            const category: string = player.type.label;
            if (!groupped[category]) {
              groupped[category] = [];
            }
            groupped[category].push(player);
          });
          for (const [key, value] of Object.entries(groupped)) {
            groupped[key] = value.sort((a, b) =>
              a.first_name < b.first_name ? -1 : 1,
            );
          }
          dispatch(AC_SetVisiblePlayersAction(groupped));
          dispatch(
            setActiveTeamTournamentsFilterAction(teamId, flushSelectedGames),
          );
          dispatch(PrefilterGamesAction());
        } else {
          dispatch(AC_SetVisiblePlayersAction({}));
        }
      } else {
        dispatch(AC_SetActiveTeamFilterAction(null));
        dispatch(AC_SetVisiblePlayersAction({}));
      }
    } catch (e) {
      console.log(e);
    }
  };

export const addSelectedTournamentFilterAction =
  (
    selectedTournaments: any[],
  ): ThunkAction<Promise<void>, AppStateType, undefined, FilterActionTypes> =>
  async (dispatch) => {
    dispatch(AC_AddSelectedTournamentFilterAction(selectedTournaments));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(PrefilterGamesAction());
  };
