import { ThunkAction } from 'redux-thunk';

import { EventsAPI } from '../api/events';
import { AppStateType } from '../reducers';
import {
  ADD_NEW_CALENDAR_EVENT,
  ADD_NEW_CALENDAR_EVENT_ERROR,
  ADD_NEW_CALENDAR_EVENT_SUCCESS,
  CLEAR_EDITED_CALENDAR_EVENT_STATE,
  CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH,
  DELETE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT_ERROR,
  DELETE_CALENDAR_EVENT_SUCCESS,
  FILE_UPLOAD_IN_PROGRESS,
  NEW_ACTIVE_DATE_TYPE,
  SET_CALENDAR_EVENTS_ERROR,
  SET_CALENDAR_EVENTS_SUCCESS,
  SET_CALENDAR_EVENTS_TYPE,
  SET_CALENDAR_SIDE_FORM_MODE,
  SET_EDITED_CALENDAR_EVENT,
  SET_VIEW_CALENDAR_EVENT,
  UPDATE_CALENDAR_ERROR,
  UPDATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_SUCCESS,
} from '../types/action.types';
import { parseDayFromApi } from '../types/functions';
import {
  CalendarDayEventType,
  CalendarEventTypeAPI,
  CalendarEventUpdateTypeAPI,
  CalendarSideModeMode,
  DateType,
} from '../types/types';

export type CalendarActionTypes =
  | NewActiveDateActionType
  | CalendarEventsType
  | CalendarEventsErrorType
  | CalendarEventsSuccessType
  | SetEditedCalendarEventType
  | AddNewCalendarEventSuccessType
  | AddNewCalendarEventRequestType
  | AddNewCalendarEventErrorType
  // | SetCurrentTeamType
  | SetViewCalendarEventType
  | DeleteCalendarEventType
  | DeleteCalendarEventErrorType
  | DeleteCalendarEventSuccessType
  | SetCalendarSideFormModeType
  | UpdateEventType
  | UpdateEventSuccessType
  | UpdateEventErrorType
  | ClearEditedCalendarEventStateType
  | ClearEditedCalendarEventStateFinishType
  | FileUploadInProgressType;

type UpdateEventType = {
  type: typeof UPDATE_CALENDAR_EVENT;
};
type NewActiveDateActionType = {
  type: typeof NEW_ACTIVE_DATE_TYPE;
  payload: DateType | null;
};
type CalendarEventsType = {
  type: typeof SET_CALENDAR_EVENTS_TYPE;
};
type CalendarEventsSuccessType = {
  type: typeof SET_CALENDAR_EVENTS_SUCCESS;
  payload: Array<CalendarDayEventType>;
};
type CalendarEventsErrorType = {
  type: typeof SET_CALENDAR_EVENTS_ERROR;
  payload: any;
};
type SetEditedCalendarEventType = {
  type: typeof SET_EDITED_CALENDAR_EVENT;
  payload: CalendarDayEventType | null;
};
type SetViewCalendarEventType = {
  type: typeof SET_VIEW_CALENDAR_EVENT;
  payload: {
    event: CalendarDayEventType | null;
    positions: any | null;
  };
};
type AddNewCalendarEventSuccessType = {
  type: typeof ADD_NEW_CALENDAR_EVENT_SUCCESS;
  payload: CalendarDayEventType;
};
type AddNewCalendarEventRequestType = {
  type: typeof ADD_NEW_CALENDAR_EVENT;
};
type AddNewCalendarEventErrorType = {
  type: typeof ADD_NEW_CALENDAR_EVENT_ERROR;
  payload: any;
};
type DeleteCalendarEventType = {
  type: typeof DELETE_CALENDAR_EVENT;
};
type SetCalendarSideFormModeType = {
  type: typeof SET_CALENDAR_SIDE_FORM_MODE;
  payload: CalendarSideModeMode;
};

export const SetActiveDateAction = (
  newDate: DateType,
): NewActiveDateActionType => {
  return {
    type: NEW_ACTIVE_DATE_TYPE,
    payload: newDate,
  };
};
export const ClearActiveDateAction = (): NewActiveDateActionType => {
  return {
    type: NEW_ACTIVE_DATE_TYPE,
    payload: null,
  };
};

export const AC_SetCalendarEventsAction = (): CalendarEventsType => {
  return {
    type: SET_CALENDAR_EVENTS_TYPE,
  };
};
export const AC_SetCalendarEventsErrorAction = (
  error: any,
): CalendarEventsErrorType => {
  return {
    type: SET_CALENDAR_EVENTS_ERROR,
    payload: error,
  };
};
export const AC_SetCalendarEventsSuccessAction = (
  events: Array<CalendarDayEventType>,
): CalendarEventsSuccessType => {
  return {
    type: SET_CALENDAR_EVENTS_SUCCESS,
    payload: events,
  };
};
export const SetCalendarEventsAction =
  (
    teamId: number,
    year: number,
    month: number,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_SetCalendarEventsAction());
      const eventResponse = await EventsAPI.getListOfActivities(
        teamId,
        year,
        month,
      );
      if (eventResponse.status === 200) {
        const results = eventResponse.data.results.map((result: any) => {
          return {
            ...result,
            date: parseDayFromApi(result),
          };
        });
        dispatch(AC_SetCalendarEventsSuccessAction(results));
      } else {
        dispatch(AC_SetCalendarEventsErrorAction(eventResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const SetEditedCalendarEvent = (
  event: CalendarDayEventType,
): SetEditedCalendarEventType => {
  return {
    type: SET_EDITED_CALENDAR_EVENT,
    payload: event,
  };
};
export const ClearEditedCalendarEvent = (): SetEditedCalendarEventType => {
  return {
    type: SET_EDITED_CALENDAR_EVENT,
    payload: null,
  };
};

const AC_AddNewCalendarEventRequest = (): AddNewCalendarEventRequestType => {
  return {
    type: ADD_NEW_CALENDAR_EVENT,
  };
};
export const AC_AddNewCalendarEventSuccess = (
  newEvent: CalendarDayEventType,
): AddNewCalendarEventSuccessType => {
  return {
    type: ADD_NEW_CALENDAR_EVENT_SUCCESS,
    payload: newEvent,
  };
};
const AC_AddNewCalendarEventError = (e: any): AddNewCalendarEventErrorType => {
  return {
    type: ADD_NEW_CALENDAR_EVENT_ERROR,
    payload: e,
  };
};
export const AC_UpdateEventSuccessAction = (
  updatedEvent: any,
): UpdateEventSuccessType => {
  return {
    type: UPDATE_CALENDAR_SUCCESS,
    payload: updatedEvent,
  };
};
export const AddNewCalendarEvent =
  (
    clubId: number,
    newEvent: CalendarEventTypeAPI,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_AddNewCalendarEventRequest());
      const eventResponse = await EventsAPI.addNewActivity(clubId, newEvent);
      if (eventResponse.status === 201) {
        const newEvt: CalendarDayEventType = {
          ...eventResponse.data,
          date: parseDayFromApi(eventResponse.data),
        };
        dispatch(AC_AddNewCalendarEventSuccess(newEvt));
      } else {
        dispatch(AC_AddNewCalendarEventError(eventResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const AddNewCalendarEventByCameraRecord =
  (
    newEvent: CalendarEventTypeAPI,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_AddNewCalendarEventRequest());
      const eventResponse = await EventsAPI.addNewActivityByCamera(newEvent);
      if (eventResponse.status === 201) {
        const newEvt: CalendarDayEventType = {
          ...eventResponse.data,
          date: parseDayFromApi(eventResponse.data),
        };
        dispatch(AC_AddNewCalendarEventSuccess(newEvt));
      } else {
        dispatch(AC_AddNewCalendarEventError(eventResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
export const StopCalendarEventByCameraRecord =
  (
    eventId: string,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_AddNewCalendarEventRequest());
      const stoppedEventResponse =
        await EventsAPI.stopActivityByCamera(eventId);
      if (stoppedEventResponse.status === 202) {
        const eventResponse = await EventsAPI.getSingleActivity(eventId);
        if (eventResponse.status === 200) {
          const newEvent: CalendarDayEventType = {
            ...eventResponse.data,
            date: parseDayFromApi(eventResponse.data),
          };
          dispatch(AC_UpdateEventSuccessAction(newEvent));
        }
      } else {
        dispatch(AC_AddNewCalendarEventError(stoppedEventResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
// type SetCurrentTeamType = {
//     type: typeof SET_CURRENT_TEAM_EVENT
//     payload: TeamType
// }
// export const SetCurrentSelectedTeamEvent = (newSelectedTeam: TeamType): SetCurrentTeamType => {
//     return {
//         type: SET_CURRENT_TEAM_EVENT,
//         payload: newSelectedTeam
//     }
// }
export const SetViewCalendarEvent = (
  event: CalendarDayEventType,
  positions: any,
): SetViewCalendarEventType => {
  return {
    type: SET_VIEW_CALENDAR_EVENT,
    payload: {
      event: event,
      positions: positions,
    },
  };
};
export const ClearViewCalendarEvent = (): SetViewCalendarEventType => {
  return {
    type: SET_VIEW_CALENDAR_EVENT,
    payload: {
      event: null,
      positions: null,
    },
  };
};

type DeleteCalendarEventSuccessType = {
  type: typeof DELETE_CALENDAR_EVENT_SUCCESS;
  payload: CalendarDayEventType;
};
type DeleteCalendarEventErrorType = {
  type: typeof DELETE_CALENDAR_EVENT_ERROR;
  payload: any;
};

export const AC_DeleteCalendarEventAction = (): DeleteCalendarEventType => {
  return {
    type: DELETE_CALENDAR_EVENT,
  };
};
export const AC_DeleteCalendarEventSuccessAction = (
  evt: CalendarDayEventType,
): DeleteCalendarEventSuccessType => {
  return {
    type: DELETE_CALENDAR_EVENT_SUCCESS,
    payload: evt,
  };
};
export const AC_DeleteCalendarEventErrorAction = (
  error: any,
): DeleteCalendarEventErrorType => {
  return {
    type: DELETE_CALENDAR_EVENT_ERROR,
    payload: error,
  };
};
export const DeleteCalendarEventAction =
  (
    evt: CalendarDayEventType,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_DeleteCalendarEventAction());
      if (evt.id) {
        const eventResponse = await EventsAPI.deleteActivity(evt.id);
        if (eventResponse.status === 204) {
          dispatch(AC_DeleteCalendarEventSuccessAction(evt));
        } else {
          dispatch(AC_DeleteCalendarEventErrorAction(eventResponse.data));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

export const SetCalendarSideFormModeAction = (
  mode: CalendarSideModeMode,
): SetCalendarSideFormModeType => {
  return {
    type: SET_CALENDAR_SIDE_FORM_MODE,
    payload: mode,
  };
};

/*UPDATE*/
export const AC_UpdateEventAction = (): UpdateEventType => {
  return {
    type: UPDATE_CALENDAR_EVENT,
  };
};
type UpdateEventSuccessType = {
  type: typeof UPDATE_CALENDAR_SUCCESS;
  payload: CalendarDayEventType;
};
type UpdateEventErrorType = {
  type: typeof UPDATE_CALENDAR_ERROR;
  payload: any;
};

export const AC_UpdateEventErrorAction = (error: any): UpdateEventErrorType => {
  return {
    type: UPDATE_CALENDAR_ERROR,
    payload: error,
  };
};
export const UpdateEventAction =
  (
    eventId: string,
    updatedEvent: CalendarEventTypeAPI | CalendarEventUpdateTypeAPI,
  ): ThunkAction<Promise<void>, AppStateType, undefined, CalendarActionTypes> =>
  async (dispatch) => {
    try {
      dispatch(AC_UpdateEventAction());
      const eventResponse = await EventsAPI.updateActivity(
        eventId,
        updatedEvent,
      );
      if (eventResponse.status === 200) {
        const newEvent: CalendarDayEventType = {
          ...eventResponse.data,
          date: parseDayFromApi(eventResponse.data),
        };
        dispatch(AC_UpdateEventSuccessAction(newEvent));
      } else {
        dispatch(AC_UpdateEventErrorAction(eventResponse.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
type ClearEditedCalendarEventStateFinishType = {
  type: typeof CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH;
};
export const AC_ClearEditedCalendarEventStateFinish =
  (): ClearEditedCalendarEventStateFinishType => {
    return {
      type: CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH,
    };
  };
type ClearEditedCalendarEventStateType = {
  type: typeof CLEAR_EDITED_CALENDAR_EVENT_STATE;
};
export const AC_ClearEditedCalendarEventState =
  (): ClearEditedCalendarEventStateType => {
    return {
      type: CLEAR_EDITED_CALENDAR_EVENT_STATE,
    };
  };
type FileUploadInProgressType = {
  type: typeof FILE_UPLOAD_IN_PROGRESS;
  payload: boolean;
};
export const AC_FileUploadInProgress = (
  state: boolean,
): FileUploadInProgressType => {
  return {
    type: FILE_UPLOAD_IN_PROGRESS,
    payload: state,
  };
};
