import { SET_LANGUAGE, SET_STYLE_MODE_ACTION } from 'types/action.types';

export type InterfaceActionsTypes =
  | SetStyleModeActionType
  | SetLanguageActionType;

type SetStyleModeActionType = {
  type: typeof SET_STYLE_MODE_ACTION;
  payload: string;
};
export const setStyleModeAction = (mode: string): SetStyleModeActionType => {
  return {
    type: SET_STYLE_MODE_ACTION,
    payload: mode,
  };
};
type SetLanguageActionType = {
  type: typeof SET_LANGUAGE;
  payload: string;
};
export const AC_setLanguageAction = (
  languageCode: string,
): SetLanguageActionType => {
  return {
    type: SET_LANGUAGE,
    payload: languageCode,
  };
};
