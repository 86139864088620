import { FC, forwardRef, useEffect, useState } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import WarningSign from '../../assets/img/warningSign.svg';

interface PasswordWarningInterface {
  validationRules: {
    BL: boolean;
    SL: boolean;
    DIGITS: boolean;
    MIN: boolean;
  };
  passwordIsOnFocus: boolean;
  fieldRef: any;
}

const PasswordWarning: FC<PasswordWarningInterface> = forwardRef(
  ({ validationRules, passwordIsOnFocus }, ref: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const setWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    useEffect(() => {
      window.addEventListener('resize', setWindowDimensions);
      return () => {
        window.removeEventListener('resize', setWindowDimensions);
      };
    }, []);

    const getTopOffset = (fieldRef: any) => {
      if (fieldRef?.current) {
        return fieldRef?.current?.getBoundingClientRect().top - 5;
      }
    };
    const getLeftOffset = (fieldRef: any) => {
      return fieldRef?.current?.offsetLeft + fieldRef?.current.clientWidth + 20;
    };
    const [t] = useTranslation();
    return (
      <div
        className="passwordWarning"
        style={{
          top: `${getTopOffset(ref)}px`,
          left: `${getLeftOffset(ref)}px`,
        }}
      >
        <div>
          {!validationRules.BL && !passwordIsOnFocus ? (
            <img src={WarningSign} />
          ) : null}
          <div
            className={!validationRules.BL ? 'notSatisfiedValidationRule' : ''}
          >
            {t('Capital latin letters')}
          </div>
        </div>
        <div>
          {!validationRules.SL && !passwordIsOnFocus ? (
            <img src={WarningSign} />
          ) : null}
          <div
            className={!validationRules.SL ? 'notSatisfiedValidationRule' : ''}
          >
            {t('Small latin letters')}
          </div>
        </div>
        <div>
          {!validationRules.DIGITS && !passwordIsOnFocus ? (
            <img src={WarningSign} />
          ) : null}
          <div
            className={
              !validationRules.DIGITS ? 'notSatisfiedValidationRule' : ''
            }
          >
            {t('Цифры')}
          </div>
        </div>
        <div>
          {!validationRules.MIN && !passwordIsOnFocus ? (
            <img src={WarningSign} />
          ) : null}
          <div
            className={!validationRules.MIN ? 'notSatisfiedValidationRule' : ''}
          >
            {t('Not less than 8 symbols')}
          </div>
        </div>
      </div>
    );
  },
);
export default PasswordWarning;
