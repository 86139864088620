import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  protocolGameID: string;
};
const initialState: InitialStateType = {
  protocolGameID: '',
};
export const protocolReducer = createSlice({
  name: 'reelsReducer',
  initialState: initialState,
  reducers: {
    openGameProtocol(state, action) {
      state.protocolGameID = action.payload;
    },
  },
});
