import React from 'react';

import ReactDOM from 'react-dom/client';
import 'index.css';
import { Provider } from 'react-redux';
import 'assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf';

import App from 'App';
import store from 'store';
import { setupResponseInterceptor } from 'utils/axios';
import 'i18n';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
setupResponseInterceptor(store);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>
);
