import React from 'react';

import { Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FieldIconBlue } from '../../../../assets/img/FieldIconBlue.svg';

import './index.css';

const ProtocolButton = ({
  playedTime,
  role,
  roleStats = [],
}: {
  playedTime: any | undefined;
  role: any | undefined;
  roleStats: [];
}) => {
  const openProtocol = () => {
    console.log('open protocol');
  };
  const [t] = useTranslation();
  return (
    <Flex
      align="center"
      style={{
        height: '100%',
        flex: 0,
        minWidth: 56,
      }}
      justify="center"
    >
      <Flex vertical style={{ height: '100%' }} justify="center" align="center">
        {roleStats.length > 2 ? (
          <Tooltip
            title={() => {
              return roleStats.map((role: any) => {
                return (
                  <div>
                    {role.started_at}’–{role.finished_at}’ | {t(role.role)} |{' '}
                    {role.schema}
                  </div>
                );
              });
            }}
          >
            <div
              style={{ height: 22, marginLeft: -15, marginRight: -10 }}
              className="labelText"
            >
              {role?.value
                ?.split(', ')
                .map((r: string) => t(r))
                .join(', ')}
            </div>
          </Tooltip>
        ) : (
          <div
            style={{ height: 22, marginLeft: -15, marginRight: -10 }}
            className="labelText"
          >
            {role?.value
              ?.split(', ')
              .map((r: string) => t(r))
              .join(', ')}
          </div>
        )}
        <div
          style={{
            height: 30,
            width: 30,
            textAlign: 'center',
            marginTop: 5,
          }}
        >
          <FieldIconBlue
            className="enabledClickable"
            onClick={(e) => {
              e.stopPropagation();
              openProtocol();
            }}
          />
        </div>
        <div style={{ height: 22 }} className="labelText">
          {playedTime?.value
            ? `${Math.round(playedTime?.value / 1000 / 60)}’`
            : ''}
        </div>
      </Flex>
    </Flex>
  );
};
export default ProtocolButton;
