import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import {
  AddNewCalendarEvent,
  ClearEditedCalendarEvent,
  ClearViewCalendarEvent,
  DeleteCalendarEventAction,
  SetActiveDateAction,
  SetCalendarSideFormModeAction,
  StopCalendarEventByCameraRecord,
  SetEditedCalendarEvent,
  UpdateEventAction,
  AddNewCalendarEventByCameraRecord,
  AC_ClearEditedCalendarEventStateFinish,
} from 'actions/calendar.actions';
import { ClubsAPI } from 'api/clubs';
import { EquipmentAPI } from 'api/equipment';
import { RegistriesAPI } from 'api/registries';
import PlayButtonThumbnail from 'assets/img/PlayButtonThumbnail.svg';
import SelectDownArrowBlack from 'assets/img/SelectDownArrowBlack.svg';
import SelectDownArrowBlue from 'assets/img/SelectDownArrowBlue.svg';
import VideoThumbnail from 'assets/img/VideoThumbnail.png';
import Video from 'assets/video/Video.mp4';
import Loader from 'components/Loader';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { MONTHS_SHORT, DATE_TIME_FORMAT } from 'types/constants';
import { generateUUID } from 'types/crypto';
import { constructTimeZoneName } from 'types/functions';
import {
  CalendarDayEventType,
  CalendarEventTypeAPI,
  CalendarSideModeMode,
  CameraType,
  CityType,
  ClubType,
  DateType,
  EventType,
  TournamentSimpleType,
  VideoFileType,
} from 'types/types';
import dayjs from 'utils/dayjsLocal';

import CameraSelectionList from './CameraSelectionList';
import TournamentSelector from './TorunamentSelector';
import VideoUploadController from './VideoUploadController';
import CameraCheckWindow from '../CameraCheckWindow';
import DropDownSearch from '../DropDownSearch';
import DropDownSearchPop from '../DropDownSearch/DropDownSearchPop';
import InputWithClear from '../InputWithClear';
import './index.css';
import CameraControlButton from '../Library/buttons/CameraControlButton';
import ConfirmActionModal, { StyleOptions } from '../modals/ConfirmActionModal';
import WarningModal from '../modals/WarningModal';

type EventTypes = keyof typeof EventType;

const EventControlPanel = () => {
  const { activeDate, editedEvent, mode, clearEditedCalendarEventState } =
    useSelector((state: AppStateType) => state.calendarReducer);
  const { currentTeam } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  const { fileUploadInProgress } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );

  const [activeEventType, setActiveEventType] = useState<EventTypes>('game');
  const [activeHostType, setActiveHostType] = useState<string>('home');
  const [activeCamera, setActiveCamera] = useState<
    CameraType | null | undefined
  >(null);
  const [counterpart, setCounterpart] = useState<string | null | undefined>('');
  const [description, setDescription] = useState<string | null | undefined>();
  const [activeEventHour, setActiveEventHour] = useState<any>();
  const [activeEventMinute, setActiveEventMinute] = useState<any>();
  const [timeZoneCity, setTimeZoneCity] = useState<
    CityType | null | undefined
  >();
  const [temporaryZoneCity, setTemporaryTimeZoneCity] = useState(null);
  const [showCitySelector, setShowCitySelector] = useState(false);
  const [cameraIsEnabled, setCameraIsEnabled] = useState<boolean>();
  const [
    cameraCollidesWithOtherPlannedEvents,
    setCameraCollidesWithOtherPlannedEvents,
  ] = useState<boolean>(false);
  const [tooltipId, setTooltipId] = useState('');
  const [fileNames, setFileNames] = useState<Array<any>>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationError, setValidationError] = useState(false);
  const [showCameraList, setShowCameraList] = useState<boolean>(false);
  const [showCameraCheckWindow, setShowCameraCheckWindow] =
    useState<boolean>(false);
  const [showCameraCheckPreview, setShowCameraCheckPreview] =
    useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clubs, setClubs] = useState<Array<ClubType>>([]);
  const [cities, setCities] = useState<Array<CityType>>([]);

  const citySelectorRef = useRef<any>(null);
  const eventDateRef = useRef<any>(null);
  const cameraSelectorRef = useRef<any>(null);
  const cameraButtonRef = useRef<any>(null);
  const [stopRecordModalIsOpen, setStopRecordModalIsOpen] =
    useState<boolean>(false);

  const [currentSelectedTournament, setCurrentSelectedTournament] =
    useState<TournamentSimpleType | null>(null);

  const dispatch = useAppDispatch();
  const TODAY = new Date();
  const { language } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  useEffect(() => {
    // console.log()
  }, [language]);
  useEffect(() => {
    if (clearEditedCalendarEventState) {
      clearState();
      dispatch(AC_ClearEditedCalendarEventStateFinish());
    }
  }, [clearEditedCalendarEventState]);
  useEffect(() => {
    const timeZoneSetter = async () => {
      // set current user timzone to selector
      const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const zoneDetails = await RegistriesAPI.getTimeZones(zone);
      if (zoneDetails?.data?.count > 0) {
        setTimeZoneCity(zoneDetails.data.results[0]);
      }
    };
    timeZoneSetter().then();
  }, []);
  useEffect(() => {
    // Установка ссылки на ID tooltip в случае, если текущая команда не выбрана
    if (currentTeam) {
      setTooltipId('');
    } else {
      setTooltipId('evtTooltipName');
    }
  }, [currentTeam]);
  useEffect(() => {
    if (activeDate) {
      const today = dayjs(new Date());
      // console.log('HERE', activeEventHour, activeEventMinute)
      if (activeEventHour === undefined || activeEventMinute === undefined) {
        setCameraIsEnabled(true);
        // setCameraCollidesWithOtherPlannedEvents(false);
        return;
      }
      const activeDateDatetime = dayjs(
        new Date(
          activeDate?.year,
          activeDate?.month,
          activeDate?.date,
          activeEventHour || today.hour(),
          activeEventMinute !== undefined ? activeEventMinute : today.minute(),
        ),
      );
      if (today.isBefore(activeDateDatetime)) {
        setCameraIsEnabled(true);
      } else {
        if (activeCamera?.status?.isRecording) {
          setCameraIsEnabled(true);
          return;
        }
        setCameraIsEnabled(false);
      }
      activeCamera &&
        activeEventHour &&
        activeEventMinute &&
        activeCamera.id &&
        checkCameraIsAvailable(activeCamera.id, activeDateDatetime);
      checkDateCollision(activeDateDatetime);
    } else {
      setCameraIsEnabled(true);
    }
  }, [activeDate, activeEventHour, activeEventMinute, activeCamera]);
  useLayoutEffect(() => {
    const x = async () => {
      if (editedEvent && editedEvent.date) {
        setLoadingState(true);
        const zoneName =
          typeof editedEvent.date.time_zone === 'string'
            ? editedEvent.date.time_zone
            : editedEvent.date.time_zone?.name;
        const city = editedEvent?.city;
        // console.log('city', city, 'zoneName', zoneName, 'id', editedEvent.city_id)
        const tzResponse = await RegistriesAPI.getTimeZonesV2(
          '',
          city,
          zoneName,
          editedEvent.city_id,
        );
        const tzDetails = tzResponse.data.results[0];

        const dj = dayjs(editedEvent.date.started_at).tz(zoneName);
        setCounterpart(editedEvent?.name);
        setActiveEventType(editedEvent?.type);
        setActiveHostType(editedEvent?.game_type || '');
        setDescription(editedEvent?.description);
        setTimeZoneCity(tzDetails);
        setActiveCamera(editedEvent?.camera);
        setActiveEventHour(dj.hour().toString().padStart(2, '0'));
        setActiveEventMinute(dj.minute().toString().padStart(2, '0'));
        setFileNames(editedEvent?.activity_videos || []);
        if (editedEvent.tournament) {
          const tournament = await RegistriesAPI.getTournaments({
            id: editedEvent.tournament,
          });
          if (tournament.data.results.length) {
            setCurrentSelectedTournament(tournament.data.results[0]);
          }
        } else {
          setCurrentSelectedTournament(null);
        }
        dispatch(
          SetActiveDateAction({
            date: dj.date(),
            month: dj.month(),
            year: dj.year(),
          }),
        );
        setLoadingState(false);
      }
    };
    x().then();
  }, [editedEvent]);

  const checkCameraIsAvailable = async (
    cameraId: string,
    checkDate: dayjs.Dayjs,
  ) => {
    // Проверка доступности камеры
    if (timeZoneCity && cameraId) {
      const timeZone = timeZoneCity.name;
      // @ts-ignore
      const d = checkDate.tz(timeZone);
      EquipmentAPI.checkCameraIsAvailable(
        cameraId,
        d.format(DATE_TIME_FORMAT),
      ).then((response) => {
        if (response.status === 200) {
          if (response.data.result.length > 0) {
            if (response.data.result.includes(editedEvent?.id)) {
              setCameraCollidesWithOtherPlannedEvents(false);
              return;
            } else {
              setCameraCollidesWithOtherPlannedEvents(true);
              return;
            }
          } else {
            setCameraCollidesWithOtherPlannedEvents(false);
            return;
          }
        }
        setCameraCollidesWithOtherPlannedEvents(true);
      });
    }
  };
  const checkDateCollision = (date: dayjs.Dayjs) => {
    // Check collision of event in existing events
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dayDate = new Date(
      date.year(),
      date.month(),
      date.date(),
      date.hour(),
      date.minute(),
    );

    // const collisionExists = .filter(
    //   (evt: CalendarDayEventType) => {
    //     const evtDate = dayjs(
    //       new Date(
    //         evt.date.year,
    //         evt.date.month,
    //         evt.date.day,
    //         evt.date.startHour,
    //         evt.date.startMinute,
    //       ),
    //     );
    //     return date.isSame(evtDate);
    //   },
    // );
    // return setValidationError(
    // collisionExists.length > 0 && editedEvent === undefined,
    // );
  };
  const updateActiveDate = (
    e: any,
    day?: number,
    year?: number,
    month?: number,
    // hour?: number,
    // minute?: number,
  ) => {
    if (!e.target.validity.valid) {
      e.preventDefault();
      return;
    }
    const actualDay =
      day !== undefined && (1 <= day || day <= 31)
        ? day
        : activeDate?.date || TODAY.getDate();
    const actualMonth =
      month !== undefined && (0 <= month || month <= 11)
        ? month
        : activeDate?.month || TODAY.getMonth();
    const actualYear =
      year !== undefined && (1900 <= year || year <= 2100)
        ? year
        : activeDate?.year || TODAY.getFullYear();
    const newDate: DateType = {
      date: actualDay !== undefined ? actualDay : activeDate?.date || 0,
      month: actualMonth !== undefined ? actualMonth : activeDate?.month || 0,
      year: actualYear !== undefined ? actualYear : activeDate?.year || 0,
    };
    dispatch(SetActiveDateAction(newDate));
  };

  /* Activity methods */
  const addNewActivity = () => {
    // Создание нового события в календаре
    if (currentTeam && timeZoneCity && activeDate) {
      const constructDateTimeWithTZ = () => {
        const date = {
          day: activeDate?.date || 1,
          month: activeDate?.month + 1 || 0,
          year: activeDate?.year || 0,
          startHour: activeEventHour || 0,
          startMinute: activeEventMinute || 0,
          time_zone: timeZoneCity || { name: '' },
        };
        const ds = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}T${date.startHour.toString().padStart(2, '0')}:${date.startMinute.toString().padStart(2, '0')}`;
        const tz = timeZoneCity.name;
        // @ts-ignore
        const dj = dayjs.tz(ds, tz);
        return dj.format(DATE_TIME_FORMAT);
      };
      // console.log('EVENT VIDEOS', fileNames)
      const videos = fileNames.map((file: VideoFileType) => {
        if (file.id) {
          const x: VideoFileType = {
            id: file.id,
            name: file.name,
            isSent: true,
            percent: 100,
            status: 'NEW',
          };
          return x;
        }
      });
      const newEvent: CalendarEventTypeAPI = {
        teams: [currentTeam?.id],
        type:
          (editedEvent?.type && EventType[editedEvent?.type]) ||
          EventType[activeEventType],
        game_type: editedEvent?.game_type || activeHostType,
        name: counterpart || '',
        description: editedEvent?.description || description || '',
        started_at: constructDateTimeWithTZ(),
        time_zone: timeZoneCity?.name,
        event_videos: videos !== undefined ? videos : [],
        camera: activeCamera?.id,
        city: timeZoneCity.city,
        city_id: timeZoneCity.city_id,
        tournament: currentSelectedTournament?.id,
      };
      dispatch(AddNewCalendarEvent(currentTeam.club, newEvent));
      clearState();
    }
  };
  const updateEvent = () => {
    // Сохранение редактируемого события
    if (editedEvent && activeDate && timeZoneCity) {
      const constructDateTimeWithTZ = () => {
        const date = {
          day: activeDate?.date || 1,
          month: activeDate?.month + 1 || 0,
          year: activeDate?.year || 0,
          startHour: activeEventHour || 0,
          startMinute: activeEventMinute || 0,
          timeZone: timeZoneCity || { name: '' },
        };

        const ds = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}T${date.startHour.toString().padStart(2, '0')}:${date.startMinute.toString().padStart(2, '0')}`;
        const tz = timeZoneCity.name;
        // @ts-ignore
        const dj = dayjs.tz(ds, tz);

        return dj.format(DATE_TIME_FORMAT);
      };

      const updatedEvent: CalendarEventTypeAPI = {
        id: editedEvent?.id || generateUUID(),
        teams: editedEvent?.teams,
        type: EventType[activeEventType],
        game_type: activeHostType,
        name: counterpart || '',
        description: description || '',
        started_at: constructDateTimeWithTZ(),
        camera: activeCamera?.id,
        time_zone: timeZoneCity?.name,
        city: timeZoneCity.city,
        tournament: currentSelectedTournament?.id || null,
        city_id: timeZoneCity.city_id,
        event_videos: fileNames.filter((file: VideoFileType) => {
          if (file.id) {
            return { id: file.id, name: file.name };
          }
        }),
      };
      // console.log('updatedEvent', updatedEvent);
      updatedEvent.id &&
        dispatch(UpdateEventAction(updatedEvent.id, updatedEvent));
      clearState();
    }
  };
  const deleteEvent = () => {
    // Удаление события
    editedEvent && dispatch(DeleteCalendarEventAction(editedEvent));
    dispatch(ClearViewCalendarEvent());
    setModalIsOpen(false);
    dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.create));
    clearState();
  };
  const cancelAction = () => {
    clearState();
    dispatch(ClearViewCalendarEvent());
    dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.create));
    setCameraCollidesWithOtherPlannedEvents(false);
  };

  const startRecord = () => {
    if (currentTeam && activeCamera && timeZoneCity) {
      dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.record));
      const newE: CalendarEventTypeAPI = {
        teams: [currentTeam?.id],
        type: EventType[activeEventType],
        game_type: activeHostType,
        name: counterpart || '',
        description: description || '',
        camera: activeCamera.id,
        time_zone: timeZoneCity.name,
        city: timeZoneCity.city,
      };
      dispatch(AddNewCalendarEventByCameraRecord(newE));
      // dispatch(SetEditedCalendarEvent(newE));
    }
  };
  const stopRecord = () => {
    if (editedEvent && timeZoneCity && activeDate) {
      editedEvent?.id &&
        dispatch(StopCalendarEventByCameraRecord(editedEvent?.id));
      dispatch(SetCalendarSideFormModeAction(CalendarSideModeMode.create));
      clearState();
      setStopRecordModalIsOpen(false);
    }
  };
  const pauseRecord = () => {
    if (currentTeam) {
      const newEvent: CalendarDayEventType = {
        id: generateUUID(),
        teams: [currentTeam?.id],
        type: EventType[activeEventType],
        game_type: activeHostType,
        name: counterpart || '',
        description: description || '',
        date: {
          day: activeDate?.date || 1,
          month: activeDate?.month || 0,
          year: activeDate?.year || 0,
          startHour: activeEventHour || 0,
          startMinute: activeEventMinute || 0,
          time_zone: timeZoneCity || null,
        },
        camera: {
          id: '1',
          name: 'Some camera ID 1',
          status: {
            isRecording: false,
            recordingTime: '00:00:01',
            readyToRecord: true,
            spaceLeftHours: 12,
            totalSpaceHours: 12,
            recordedEventId: null,
          },
        },
      };
      dispatch(SetEditedCalendarEvent(newEvent));
    }
  };
  /* Event methods */
  const clearState = () => {
    // хендлер очистки формы
    const t: DateType = {
      month: activeDate?.month || 0,
      year: activeDate?.year || TODAY.getFullYear(),
      date: TODAY.getDate(),
    };
    dispatch(SetActiveDateAction(t));
    dispatch(ClearEditedCalendarEvent());
    setCounterpart('');
    setActiveEventType(EventType['game']);
    setActiveHostType('home');
    setDescription('');
    setActiveCamera(null);
    setActiveEventHour('');
    setActiveEventMinute('');
    setFileNames([]);
    setCurrentSelectedTournament(null);
  };

  const searchForClubs = async (value: string) => {
    // Поиск по клубам
    if (value) {
      const zones = await ClubsAPI.getClubs(value);
      if (zones.status === 200) {
        setClubs(zones.data.results);
      }
    }
  };
  const [loadingState, setLoadingState] = useState(false);
  const [loadingCitiesState, setLoadingCitiesState] = useState(false);
  const searchForCities = async (value: string) => {
    // Поиск по городам/таймзонам
    if (value?.length > 2) {
      setLoadingCitiesState(true);
      const zones = await RegistriesAPI.getTimeZonesV2(value);
      if (zones.status === 200) {
        setCities(zones.data.results);
      }
      setLoadingCitiesState(false);
    }
  };

  const eventHasCameraRecord = (): boolean => {
    // Проверка на то, что событие имеет загруженные камерой видео
    if (editedEvent?.activity_videos) {
      return (
        editedEvent?.activity_videos?.filter(
          (video: VideoFileType) => video.camera !== null,
        ).length > 0
      );
    }
    return false;
  };

  const checkDatesAreEditable = (): boolean => {
    if (mode === CalendarSideModeMode.edit) {
      return eventHasCameraRecord();
    }
    return false;
  };
  const [t] = useTranslation();
  const getEditedEventDatePart = (part: string) => {
    // console.log('editedEvent', editedEvent)
    if (typeof editedEvent?.date.time_zone === 'string') {
      const dj = dayjs(editedEvent?.date.started_at).tz(
        editedEvent?.date.time_zone,
      );
      switch (part) {
        case 'day': {
          return dj.date().toString().padStart(2, '0');
        }
        case 'month': {
          return dj.month();
        }
        case 'year': {
          return dj.year();
        }
        case 'hour': {
          return dj.hour().toString().padStart(2, '0');
        }
        case 'minute': {
          return dj.minute().toString().padStart(2, '0');
        }
      }
    }
    return editedEvent?.date.day;
  };
  const [showTournamentsSelector, setShowTournamentsSelector] = useState(false);
  useEffect(() => {
    showCitySelector && setShowCitySelector((prev) => !prev);
    showCameraList && setShowCameraList((prev) => !prev);
  }, [showTournamentsSelector]);
  return (
    <div className="eventAddContainer" style={{ position: 'relative' }}>
      {loadingState && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(230,237,252,0.95)',
            width: '95%',
            height: '95%',
            position: 'absolute',
          }}
        >
          <Loader color={'black'} />
        </div>
      )}
      <div className="eventCreationFormHeader">
        {mode === CalendarSideModeMode.create
          ? t('New event')
          : mode === CalendarSideModeMode.edit
            ? t('Edit event')
            : t('Record event')}
      </div>
      <div className="eventCreationForm">
        <div className="eventTypesContainer">
          <div
            onClick={() => setActiveEventType('game')}
            className={`eventTypeSelector ${activeEventType === 'game' ? 'activeEventType' : ''}`}
          >
            {t('Match')}
          </div>
          <div
            onClick={() => setActiveEventType('training')}
            className={`eventTypeSelector ${activeEventType === 'training' ? 'activeEventType' : ''}`}
          >
            {t('Training')}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div className="eventTeamContainer">
            <DropDownSearch
              fieldName="counterpart"
              placeholder={t('Counterpart')}
              valueController={{ value: counterpart, setValue: setCounterpart }}
              searchElementsController={{
                searchElements: clubs,
                setSearchElements: searchForClubs,
              }}
              isRequired={true}
              maxLength={80}
            />
            {activeEventType === EventType.game && (
              <div className="hostSelectorContainer">
                <div
                  onClick={() => setActiveHostType('home')}
                  className={`${activeHostType === 'home' ? 'activeHostSelection' : ''}`}
                >
                  {t('H')}
                </div>
                <div
                  className={`${activeHostType === 'away' ? 'activeHostSelection' : ''}`}
                  onClick={() => setActiveHostType('away')}
                >
                  {t('A')}
                </div>
              </div>
            )}
          </div>
          <div className="eventDescriptionContainer">
            <InputWithClear
              placeholder={t('Description')}
              fieldType={'textarea'}
              fieldName={'eventDescription'}
              isRequired={false}
              valueController={{ value: description, setValue: setDescription }}
              maxLength={500}
            />
          </div>
          <div className="eventDateContainer" ref={eventDateRef}>
            <div>
              <input
                type="number"
                disabled={checkDatesAreEditable()}
                pattern={'[0-9]{2}'}
                maxLength={2}
                name={'eventDay'}
                min={1}
                max={31}
                onChange={(e) => updateActiveDate(e, Number(e.target.value))}
                value={activeDate?.date}
                defaultValue={
                  (editedEvent &&
                    editedEvent?.date.day > 0 &&
                    getEditedEventDatePart('day')) ||
                  activeDate?.date ||
                  ''
                }
              />
              <select
                name={'eventMonth'}
                disabled={checkDatesAreEditable()}
                onChange={(e) => {
                  updateActiveDate(
                    e,
                    undefined,
                    undefined,
                    Number(e.target.value),
                  );
                }}
                defaultValue={
                  (editedEvent &&
                    Number(getEditedEventDatePart('month')) - 1) ||
                  (activeDate && activeDate?.month - 1) ||
                  0
                }
                value={(activeDate && activeDate.month) || 0}
              >
                {MONTHS_SHORT.map((monthName: string, index: number) => {
                  return (
                    <option
                      key={generateUUID()}
                      selected={
                        (editedEvent &&
                          getEditedEventDatePart('month') === index) ||
                        activeDate?.month === index
                      }
                      value={index}
                    >
                      {t(monthName)}
                    </option>
                  );
                })}
              </select>
              <input
                name={'eventYear'}
                disabled={checkDatesAreEditable()}
                onChange={(e) =>
                  updateActiveDate(e, undefined, Number(e.target.value))
                }
                type="number"
                pattern={'[0-9]{4}'}
                maxLength={4}
                minLength={4}
                max={2100}
                min={1900}
                defaultValue={
                  (editedEvent &&
                    editedEvent?.date.year > 0 &&
                    getEditedEventDatePart('year')) ||
                  activeDate?.year ||
                  ''
                }
              />
            </div>
            <div>
              <input
                name={'eventHour'}
                disabled={checkDatesAreEditable()}
                max={23}
                min={0}
                pattern={'[0-9]{2}'}
                type="number"
                onChange={(e) => setActiveEventHour(e.target.value.slice(0, 2))}
                defaultValue={
                  (editedEvent && getEditedEventDatePart('hour')) ||
                  activeEventHour
                }
                value={activeEventHour}
              />
              <div>:</div>
              <input
                name={'eventMinute'}
                onChange={(e) =>
                  setActiveEventMinute(
                    e.target.value.slice(0, 2).toString().padStart(2, '0'),
                  )
                }
                disabled={checkDatesAreEditable()}
                min={0}
                max={59}
                pattern={'[0-9]{2}'}
                type="number"
                value={activeEventMinute}
                defaultValue={
                  (editedEvent &&
                    editedEvent.date.startMinute.toString().padStart(2, '0')) ||
                  activeEventMinute
                }
              />
            </div>
          </div>
        </div>
        {activeEventType === 'game' && (
          <div className={'w100'}>
            <TournamentSelector
              currentSelectedTournament={currentSelectedTournament}
              setCurrentSelectedTournament={setCurrentSelectedTournament}
              showTournamentsSelector={showTournamentsSelector}
              setShowTournamentsSelector={setShowTournamentsSelector}
            />
          </div>
        )}
        <div className="timeZoneContainer">
          <div className="flex-row" ref={citySelectorRef}>
            <label>{t('Time zone')}:&nbsp;</label>
            <div
              className={`timeZoneSelector ${mode === CalendarSideModeMode.record ? '' : 'enabledClickable'}`}
              onClick={() => {
                if (mode !== CalendarSideModeMode.record) {
                  showCameraList && setShowCameraList((prev) => !prev);
                  setShowCitySelector((prev) => !prev);
                  showTournamentsSelector &&
                    setShowTournamentsSelector((prev) => !prev);
                }
              }}
            >
              {timeZoneCity ? constructTimeZoneName(timeZoneCity) : ''}{' '}
              <img
                src={SelectDownArrowBlue}
                style={{
                  transform: `${showCitySelector ? 'rotate(180deg)' : ''}`,
                }}
              />
            </div>
          </div>
          {showCitySelector && (
            <div
              style={{
                position: 'absolute',
                top: `${citySelectorRef?.current?.offsetTop + citySelectorRef?.current?.clientHeight}px`,
                width: `${citySelectorRef?.current?.clientWidth}px`,
              }}
            >
              <DropDownSearchPop
                fieldName={'timeZoneCity'}
                placeholder={t('City')}
                valueController={{
                  value: temporaryZoneCity,
                  setValue: setTemporaryTimeZoneCity,
                }}
                maxLength={100}
                conditionalDisplay={true}
                allowNonDropDown={false}
                callbackFunction={(v: any) => {
                  setTimeZoneCity(v);
                  setShowCitySelector(false);
                }}
                secondaryCallback={() => {
                  setTemporaryTimeZoneCity(null);
                  setShowCitySelector(false);
                }}
                searchElementsController={{
                  searchElements: cities,
                  setSearchElements: searchForCities,
                }}
                loadingState={loadingCitiesState}
              />
            </div>
          )}
        </div>
      </div>
      <div className={'hr'} />
      <div
        className={`cameraControlContainer ${cameraIsEnabled ? '' : 'cameraIsDisabled'}`}
      >
        <div
          className={'cameraSelectorContainer '}
          onClick={() => {
            if (cameraIsEnabled) {
              setShowCameraList(!showCameraList);
              showCitySelector && setShowCitySelector((prev) => !prev);
              showTournamentsSelector &&
                setShowTournamentsSelector((prev) => !prev);
            }
          }}
          ref={cameraSelectorRef}
        >
          <div className={'selectedCamera'}>
            {activeCamera?.name || t('Not selected')}
            <img
              style={{ transform: `${showCameraList ? 'rotate(180deg)' : ''}` }}
              src={SelectDownArrowBlack}
            />
          </div>
        </div>
        {cameraIsEnabled && showCameraList && (
          <CameraSelectionList
            value={activeCamera}
            callbackFunction={(value: any) => {
              setShowCameraList(false);
              setActiveCamera(value);
            }}
            anchorRefData={cameraSelectorRef.current.getBoundingClientRect()}
          />
        )}
        {activeCamera && (
          <div className={'cameraCheckButtonContainer'}>
            <div
              className={'timeZoneSelector'}
              onClick={() => {
                if (cameraIsEnabled) {
                  setShowCameraCheckPreview(!showCameraCheckPreview);
                }
              }}
            >
              {t('Check camera')} ({t('as of')} 15.01, 14:00){' '}
              <img
                style={{
                  transform: `${showCameraCheckPreview ? 'rotate(180deg)' : ''}`,
                }}
                src={SelectDownArrowBlue}
              />
            </div>
          </div>
        )}
        {activeCamera && showCameraCheckPreview && (
          <div
            className={'cameraPreviewContainer enabled flex-column f-ga-8'}
            onClick={() => {
              if (cameraIsEnabled) {
                setShowCameraCheckWindow(true);
              }
            }}
          >
            <img className={'cameraCheckPreview'} src={VideoThumbnail} />
            <div>
              <span>{t('Setup in application')}</span>
            </div>
            <img className={'playButtonThumbnail'} src={PlayButtonThumbnail} />
          </div>
        )}
        {activeCamera && (
          <CameraControlButton
            activeCamera={activeCamera}
            isEnabled={!cameraCollidesWithOtherPlannedEvents}
            fieldRef={cameraButtonRef}
            startRecordCallback={startRecord}
            stopRecordCallback={() => setStopRecordModalIsOpen(true)}
            pauseRecordCallback={pauseRecord}
            eventId={editedEvent?.id}
          />
        )}
      </div>
      <VideoUploadController
        fileNames={fileNames}
        setFileNames={setFileNames}
      />
      <div className={'eventCreationControlContainer'}>
        {mode === CalendarSideModeMode.create ? (
          <>
            <div
              onClick={() => clearState()}
              className={'clearButton enabledClickable'}
            >
              {t('Clear')}
            </div>
            {currentTeam && !fileUploadInProgress ? (
              // дублирую, тк не разобрался почему data-tooltip при обнулении все равно показывает сам tooltip
              <div
                onClick={() => {
                  addNewActivity();
                }}
                className={'timeZoneSelector enabledClickable'}
              >
                {t('Add')}
              </div>
            ) : (
              <div
                className={'timeZoneSelector disabledClickable disabled'}
                data-tooltip-id={tooltipId}
                data-tooltip-content={t(
                  'Select current team to create an event',
                )}
                data-tooltip-place="left"
              >
                {t('Add')}
              </div>
            )}
          </>
        ) : (
          <>
            {mode === CalendarSideModeMode.record ? (
              <div style={{ width: '60%' }} />
            ) : (
              <div
                onClick={() => setModalIsOpen(true)}
                className={'deleteButton warningColor enabledClickable'}
                style={{ width: '100%' }}
              >
                {t('Delete')}
              </div>
            )}
            <div className={'flex-row j-sb'} style={{ width: '100%' }}>
              <div
                onClick={() => cancelAction()}
                className={'clearButton enabledClickable'}
              >
                {t('Cancel')}
              </div>
              <div
                onClick={() => updateEvent()}
                className={'timeZoneSelector enabledClickable'}
              >
                {t('Done')}
              </div>
            </div>
          </>
        )}
      </div>
      {validationError && (
        <WarningModal
          title={t('Time is occupiped')}
          content={t(
            'Another event is already planned for this time. Please select another time.',
          )}
          referencedField={eventDateRef.current.getBoundingClientRect()}
        />
      )}
      {cameraCollidesWithOtherPlannedEvents && (
        <WarningModal
          title={`${t('Камера')} "${activeCamera?.name}" ${t('is busy')}`}
          content={t('Select another time or camera')}
          referencedField={cameraButtonRef?.current?.getBoundingClientRect()}
        />
      )}
      {showCameraCheckWindow && activeCamera && (
        <CameraCheckWindow
          camera={activeCamera}
          video={{
            id: '2213',
            url: Video,
            duration: 46.144,
          }}
          confirmAction={() => {
            if (mode === CalendarSideModeMode.create) {
              setShowCameraCheckPreview(false);
              setShowCameraCheckWindow(false);
              addNewActivity();
            } else {
              setShowCameraCheckPreview(false);
              setShowCameraCheckWindow(false);
              updateEvent();
            }
          }}
          backAction={() => {
            setShowCameraCheckPreview(false);
            setShowCameraCheckWindow(false);
            setShowCameraCheckWindow(false);
          }}
        />
      )}
      <ConfirmActionModal
        title={t('Stop video recording?')}
        contentText={t('You cannot resume record.')}
        style={StyleOptions.warning}
        actionText={t('Stop')}
        actionCallback={stopRecord}
        isModalOpen={stopRecordModalIsOpen}
        setIsModalOpen={setStopRecordModalIsOpen}
      />

      <ConfirmActionModal
        title={t('Delete event?')}
        contentText={t('You cannot restore event.')}
        style={StyleOptions.warning}
        actionText={t('Delete')}
        actionCallback={deleteEvent}
        isModalOpen={modalIsOpen}
        setIsModalOpen={setModalIsOpen}
      />
      {tooltipId && (
        <Tooltip id={tooltipId} noArrow={true} className={'advancedTooltip'} />
      )}
    </div>
  );
};
export default EventControlPanel;
