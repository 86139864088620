/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  ConfigProvider,
  Divider,
  Flex,
  Select,
  Skeleton,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './index.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import GameSelectSidePanel from 'components/GameSelectSidePane';
import { AppStateType } from 'reducers';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { useAppDispatch } from 'store';

import AverageStatValueTag from './AverageStatValueTag';
import ConsolidatedStatValueTag from './ConsolidatedStatValueTag';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as DownOutlinedBlack } from '../../../assets/img/icons/faDownOutlinedBlack.svg';

const PlayerStatisticsBlock = () => {
  const [t] = useTranslation();
  const { openedTeamPlayer, selectedMatchesStats, positions } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const {
    setSelectedMatchesStats,
    setStatsCustomGameListPlayer,
    setShowGameSelectionModal,
  } = teamPlayerReducer.actions;
  const dispatch = useAppDispatch();
  const [expandedStats, setExpandedStats] = useState<string[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<
    string | undefined
  >(undefined);
  const [seasons, setSeasons] = useState<any[]>([]);
  const [selectedSeason, setSelectedSeason] = useState<string>();
  const [hoveredMetricFilter, setHoveredMetricFilters] = useState<string[]>([]);
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<string[]>(
    [],
  );
  const STATS_PLACEHOLDER = [
    {
      label: t('Goals'),
      key: 'goal',
      stat: 'loading',
    },
    {
      label: t('Assists'),
      key: 'goal_pass',
      stat: 'loading',
    },
    {
      label: t('Shots'),
      key: 'shot',
      stat: 'loading',
    },
    {
      label: t('Passes'),
      key: 'pass',
      stat: 'loading',
      children: [
        {
          label: t('forward__'),
          stat: 'loading',
          key: 'pass_forward',
        },
        {
          label: t('progressive'),
          stat: 'loading',
          key: 'pass_progressive',
        },
        {
          label: t('key'),
          stat: 'loading',
          key: 'key_pass',
        },
        {
          label: t('to 3/3'),
          stat: 'loading',
          key: 'pass_to_3rd',
        },
        {
          label: t('to pen. area'),
          stat: 'loading',
          key: 'pass_to_box',
        },
        {
          label: t('crosses'),
          stat: 'loading',
          key: 'pass_cross',
        },
        {
          label: t('long'),
          stat: 'loading',
          key: 'pass_long',
        },
        // <ConfigProvider
        //   theme={{
        //     token: {
        //       marginLG: 0,
        //     },
        //   }}
        // >
        //   <Divider />
        // </ConfigProvider>,
      ],
    },
    {
      label: t('Dribbles'),
      stat: 'loading',
      key: 'dribble',
    },
    {
      label: t('Tackles'),
      stat: 'loading',
      key: 'tackle',
    },
    {
      label: t('Tackles 1v1'),
      stat: 'loading',
      key: 'tackle1-1',
    },
    {
      label: t('Interceptions'),
      stat: 'loading',
      key: 'interception',
    },
    {
      label: t('2nd balls'),
      stat: 'loading',
      key: 'recovery',
    },
    {
      label: t('Shots blocked'),
      stat: 'loading',
      key: 'shots_blocked',
    },
    {
      label: t('Duels'),
      stat: 'loading',
      key: 'duel',
      children: [
        {
          label: t('ground defensive'),
          stat: 'loading',
          key: 'ground_duel_defence',
        },
        {
          label: t('aerial defensive'),
          stat: 'loading',
          key: 'aerial_duel_defence',
        },
        {
          label: t('ground offensive'),
          stat: 'loading',
          key: 'ground_duel_offence',
        },
        {
          label: t('aerial offensive'),
          stat: 'loading',
          key: 'aerial_duel_offence',
        },
      ],
    },
    {
      label: t('Losses'),
      stat: 'loading',
      key: 'turnover',
      children: [
        {
          label: t('inaccurate passes'),
          stat: 'loading',
          key: 'turnover_pass_inac',
        },
        {
          label: t('bad 1st touches'),
          stat: 'loading',
          key: 'turnover_receipt_fail',
        },
        {
          label: t('unsuccessful dribbles'),
          stat: 'loading',
          key: 'turnover_dribble_fail',
        },
        {
          // eslint-disable-next-line quotes
          label: t("opponent's tackles"),
          stat: 'loading',
          key: 'turnover_tackled',
        },
        // <ConfigProvider
        //   theme={{
        //     token: {
        //       marginLG: 0,
        //     },
        //   }}
        // >
        //   <Divider />
        // </ConfigProvider>,
      ],
    },
    {
      label: t('Corners'),
      stat: 'loading',
      key: 'corner',
    },
    {
      label: t('Direct free kicks'),
      stat: 'loading',
      key: 'freekick_shot',
    },
    {
      label: t('Indirect free kicks'),
      stat: 'loading',
      key: 'freekick_pass',
    },
    {
      label: t('Penalties'),
      stat: 'loading',
      key: 'penalty',
    },
    {
      label: t('Throw-ins'),
      stat: 'loading',
      key: 'out',
    },
    {
      label: t('Mistakes'),
      stat: 'loading',
      key: 'error',
    },
    {
      label: t('Fouls'),
      stat: 'loading',
      key: 'foul',
    },
    {
      label: t('Fouls suffered'),
      stat: 'loading',
      key: 'foul_taken',
    },
    {
      label: t('YC'),
      stat: 'loading',
      key: 'yellow_cards',
    },
    {
      label: t('RC'),
      stat: 'loading',
      key: 'red_cards',
    },
  ];
  const [playerStats, setPlayerStats] = useState<any[]>(STATS_PLACEHOLDER);
  const getSeasonStats = async (seasonId: string) => {
    setPlayerStats(STATS_PLACEHOLDER);
    const response = await EventsAPI.getPlayerGameStatsBySeason(
      seasonId,
      openedTeamPlayer.id,
      selectedPositions || '',
      selectedMatchesStats,
      selectedPositions
        ? positions[selectedPositions].duration > 90 * 60 * 1000
        : false,
    );
    const finalStats: any = [];
    playerStats.forEach((stat) => {
      const populatedStat: any = {
        label: stat.label,
        key: stat.key,
        stat: response.data.stats.filter((s: any) => s.key === stat?.key)[0],
      };
      if (stat.children?.length > 0) {
        populatedStat.children = [];
        stat.children.forEach((childStat: any) => {
          const childPopulatedStat = {
            ...childStat,
            stat: response.data.stats.filter(
              (s: any) => s.key === childStat.key,
            )[0],
          };
          populatedStat.children.push(childPopulatedStat);
        });
      }
      finalStats.push(populatedStat);
    });
    setPlayerStats(finalStats);
  };
  useEffect(() => {
    if (openedTeamPlayer) {
      if (
        selectedSeason &&
        selectedSeason !== 'manual' &&
        selectedMatchesStats.length === 0
      ) {
        const seasonId = selectedSeason.split('-')[1] || selectedSeason;
        getSeasonStats(seasonId).then();
      } else if (selectedMatchesStats.length > 0) {
        getSeasonStats('manual').then();
      }
    }
  }, [
    openedTeamPlayer,
    selectedSeason,
    selectedMatchesStats,
    // selectedPositions,
  ]);
  useEffect(() => {
    if (openedTeamPlayer) {
      PlayersAPI.getPlayerSeasons(openedTeamPlayer.id, true).then((result) => {
        const seasonsList = result.data;
        if (seasonsList !== undefined) {
          // setTournamentIsLoading(true);
          // setFullSeasons(seasonsList);
          const s = seasonsList.map((season: any) => {
            const startYear = dayjs(season.start_date).format('YYYY');
            const endYear = dayjs(season.end_date).year().toString();
            let label: string;
            if (startYear === endYear) {
              label = `${season.tournament.name} ${startYear}`;
            } else {
              label = `${season.tournament.name} ${startYear}/${endYear}`;
            }
            return {
              label: label,
              value: `${season.tournament.id}-${season.id}`,
            };
          });
          if (s.length) {
            setSelectedSeason(s[0].value);
            setSeasons(s);
          }
          // setTournamentIsLoading(false);
        }
      });
    }
  }, [openedTeamPlayer, setExpandedStats]);
  const makeLink = (matches: any[], team?: any, stats?: any[], part?: any) => {
    let link = '/player?';
    let added = false;
    matches.forEach((match) => {
      if (match.id) {
        link = `${link}${added ? '&' : ''}eventId=${match.id}`;
        added = true;
      }
    });
    if (team) {
      if (part === 'highlights') {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      } else {
        link = `${link}${added ? '&' : ''}teamId=${team?.id}`;
        added = true;
      }
    }
    link = `${link}${added ? '&' : ''}season=${selectedSeason}`;
    added = true;
    if (stats?.length) {
      stats.forEach((stat: any) => {
        link = `${link}${added ? '&' : ''}m=${stat.key}`;
        added = true;
      });
    }
    link = `${link}&pl=${openedTeamPlayer.id}`;
    // if (matches.length) {
    //   console.log('matches');
    //   console.log(matches);
    //   dispatch(
    //     AC_setIntermediateActivitiesAction(
    //       matches.map((match) => {
    //         if (match.id !== null) {
    //           return match.id;
    //         }
    //       }) || [],
    //     ),
    //   );
    // }
    // dispatch(
    //   setStatsCustomGameList(
    //     matches.map((match) => {
    //       if (match.id !== null) {
    //         return match.id;
    //       }
    //     }),
    //   ),
    // );
    return link;
  };
  const navigate = useNavigate();
  const toggleSelectedMetric = async (
    metricString: string,
    removedMetricString: string,
  ) => {
    if (selectedMetricFilters.includes(metricString)) {
      if (selectedMetricFilters.length === 1) {
        setSelectedMetricFilters([]);
      } else if (selectedMetricFilters.length > 1) {
        setSelectedMetricFilters((prev) =>
          prev.filter((el) => el !== metricString),
        );
      }
    } else {
      if (selectedMetricFilters.length === 0) {
        setSelectedMetricFilters([metricString]);
      } else {
        setSelectedMetricFilters((prev) => [
          ...prev.filter((p) => p !== removedMetricString),
          metricString,
        ]);
      }
    }
  };
  const makeLinkAndNavigate = (stat: any) => {
    navigate(makeLink([{ id: null }], openedTeamPlayer.team, [stat]));
  };
  const renderMetricFilters = (metric: string, metricObj: any) => {
    const metricStringSuccess = `${metric}_success`;
    const metricStringFail = `${metric}_fail`;
    if (
      (selectedMetricFilters &&
        (selectedMetricFilters.includes(metricStringSuccess) ||
          selectedMetricFilters.includes(metricStringFail))) ||
      hoveredMetricFilter.includes(metric)
    ) {
      return (
        <Flex gap={2}>
          <Flex className="success-checkbox" justify="center">
            <ConfigProvider
              theme={{
                token: {},
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringSuccess)}
                // disabled={loadingCoordinates}
                onClick={() => {
                  toggleSelectedMetric(metricStringSuccess, metricStringFail);
                }}
              />
            </ConfigProvider>
          </Flex>
          <Flex className="fail-checkbox" justify="center">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: 'grey',
                },
              }}
            >
              <Checkbox
                checked={selectedMetricFilters.includes(metricStringFail)}
                // disabled={loadingCoordinates}
                onClick={() => {
                  toggleSelectedMetric(metricStringFail, metricStringSuccess);
                }}
              />
            </ConfigProvider>
          </Flex>
        </Flex>
      );
    } else {
      return <Flex children={undefined}></Flex>;
    }
  };

  return (
    <div className="block-wrapper right">
      <div className="block-title">
        <div>{t('Statistics')}</div>
        <div>
          {positions.length > 0 && (
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorPrimaryBase, #1668DC);',
                },
                components: {
                  Select: {
                    optionSelectedColor:
                      'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                  },
                },
              }}
            >
              <Select
                placeholder={
                  <div className="select-placeholder">{t('All positions')}</div>
                }
                variant="borderless"
                className={'hasSelection'}
                value={selectedPositions}
                onSelect={(value: string) => {
                  if (value === 'clear') {
                    setSelectedPositions(undefined);
                  } else {
                    setSelectedPositions(value);
                  }
                }}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              >
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                    color: 'black',
                  }}
                  className="enabledClickable"
                  key={0}
                  onClick={(e: any) => {
                    e.preventDefault();
                  }}
                  label={''}
                  value={'clear'}
                >
                  {t('Clear filter')}
                </Select.Option>
                {positions.map((position: any, index: number) => (
                  <Select.Option
                    key={index + 1}
                    label={position.value}
                    value={position.value}
                    style={{
                      color: 'black',
                    }}
                  >
                    {position.label}
                  </Select.Option>
                ))}
              </Select>
            </ConfigProvider>
          )}
        </div>
        <ConfigProvider
          theme={{
            token: {
              colorText: 'var(--colorPrimaryBase, #1668DC);',
            },
            components: {
              Select: {
                optionSelectedColor:
                  'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
              },
            },
          }}
        >
          <Select
            variant="borderless"
            value={selectedSeason}
            onSelect={(value) => {
              // alert(value);
              if (value === 'manual') {
                setSelectedSeason(value);
                dispatch(setShowGameSelectionModal(true));
              } else {
                dispatch(setSelectedMatchesStats([]));
                dispatch(setStatsCustomGameListPlayer([]));
                setSelectedSeason(value);
              }
            }}
            popupMatchSelectWidth={false}
            placement="bottomRight"
          >
            {seasons.map((season: any, index: number) => (
              <Select.Option
                key={index}
                label={season.value}
                value={season.value}
                style={{
                  color: 'black',
                }}
              >
                {season.label}
              </Select.Option>
            ))}
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderTop: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
                color: 'black',
              }}
              label={'last10'}
              value={'last10'}
            >
              {t('10 last games')}
            </Select.Option>
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderTop: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
                color: 'black',
              }}
              label={'last5'}
              value={'last5'}
            >
              {t('5 last games')}
            </Select.Option>
            <Select.Option
              style={{
                mpaddingTop: 10,
                borderTop: '0.5px solid rgba(0,0,0,0.1)',
                borderRaius: 0,
                color: 'black',
              }}
              label={'manual'}
              value={'manual'}
            >
              {t('Select matches')}
            </Select.Option>
          </Select>
        </ConfigProvider>
      </div>
      <div className="block-content">
        <div className="block-column block-colum-player-stats">
          <Flex className="block-column-title" justify="space-between">
            <div className="stats-name">{t('Actions')}</div>
            <Flex>
              <div className="stats-header">{t('Total')}</div>
              <div className="stats-header" style={{ minWidth: 86 }}>
                {t('per 90 min')}
              </div>
            </Flex>
          </Flex>
          <Divider style={{ margin: '0 0 8px 0', width: 'auto' }} />
          <Flex
            vertical
            style={{ margin: '0 16px 8px 16px', alignSelf: 'stretch' }}
          >
            {playerStats?.map((stat: any) => {
              return (
                <>
                  <Flex
                    justify="flex-start"
                    style={{ width: '100%', margin: '4px 0px' }}
                  >
                    <Flex
                      justify="space-between"
                      style={{ width: '100%' }}
                      align="center"
                      gap={8}
                      onMouseEnter={(e: any) => {
                        e.preventDefault();
                        if (
                          stat.stat.percent !== null &&
                          stat.stat.percent !== undefined
                        ) {
                          setHoveredMetricFilters((prev) => [
                            ...prev,
                            stat.key,
                          ]);
                        }
                      }}
                      onMouseLeave={() => {
                        setHoveredMetricFilters((prev) =>
                          prev.filter((el: string) => el !== stat.key),
                        );
                      }}
                    >
                      <Flex
                        style={{
                          marginLeft: 0,
                          overflow: 'hidden',
                        }}
                        align="center"
                        justify="flex-start"
                        gap={8}
                        flex={1}
                        className={`${stat.children?.length ? 'enabledClickable' : ''}`}
                        onClick={() => {
                          if (
                            stat.children?.length &&
                            expandedStats.includes(stat.key)
                          ) {
                            setExpandedStats((prev) =>
                              prev.filter((item: string) => item !== stat.key),
                            );
                          } else {
                            setExpandedStats((prev) => [...prev, stat.key]);
                          }
                        }}
                      >
                        {stat.label}
                        {stat.children?.length && (
                          <DownOutlinedBlack
                            className={`enabledClickable ${expandedStats.includes(stat.key) ? 'rotate180' : 'rotate90'}`}
                          />
                        )}
                      </Flex>
                      <Flex>{renderMetricFilters(stat.key, stat)}</Flex>
                      <Flex gap={16}>
                        {stat.stat === 'loading' ? (
                          <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                            <Skeleton.Button
                              active
                              style={{ minWidth: 70, minHeight: 40 }}
                            ></Skeleton.Button>
                          </div>
                        ) : (
                          <ConsolidatedStatValueTag
                            stat={stat.stat}
                            makeLink={makeLinkAndNavigate}
                            selectedMetricFilters={selectedMetricFilters}
                            selectedPositions={selectedPositions}
                          />
                        )}
                        {stat.stat === 'loading' ? (
                          <div style={{ borderRadius: 8, overflow: 'hidden' }}>
                            <Skeleton.Button
                              active
                              style={{ minWidth: 90, minHeight: 40 }}
                            />
                          </div>
                        ) : (
                          <AverageStatValueTag
                            stat={stat.stat}
                            selectedMetricFilters={selectedMetricFilters}
                            selectedPositions={selectedPositions}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                  {stat.children?.length > 0 && (
                    <Flex
                      vertical
                      style={{ width: '100%', margin: '4px 0px' }}
                      gap={8}
                      className={`${expandedStats.includes(stat.key) ? 'expanded' : 'collapsed'}`}
                    >
                      {stat.children.map((child: any) => {
                        return stat.stat === 'loading' ? (
                          <Skeleton.Button
                            active
                            shape={'round'}
                            style={{ width: 75, height: 32 }}
                          ></Skeleton.Button>
                        ) : (
                          <Flex
                            justify="space-between"
                            style={{ width: '100%' }}
                            gap={8}
                          >
                            <Flex
                              style={{
                                marginLeft: 20,
                                overflow: 'hidden',
                              }}
                              align="center"
                              justify="space-between"
                              gap={8}
                              flex={1}
                              onMouseEnter={(e: any) => {
                                e.preventDefault();
                                if (
                                  child.stat.percent !== null &&
                                  child.stat.percent !== undefined
                                ) {
                                  setHoveredMetricFilters((prev) => [
                                    ...prev,
                                    child.key,
                                  ]);
                                }
                              }}
                              onMouseLeave={() => {
                                setHoveredMetricFilters((prev) =>
                                  prev.filter((el: string) => el !== child.key),
                                );
                              }}
                            >
                              <Flex>{child.label}</Flex>
                              {renderMetricFilters(child.key, child)}
                            </Flex>
                            <Flex gap={16}>
                              <ConsolidatedStatValueTag
                                stat={child.stat}
                                selectedMetricFilters={selectedMetricFilters}
                                makeLink={makeLinkAndNavigate}
                                selectedPositions={selectedPositions}
                              />
                              <AverageStatValueTag
                                stat={child.stat}
                                selectedMetricFilters={selectedMetricFilters}
                                selectedPositions={selectedPositions}
                              />
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  )}
                </>
              );
            })}
          </Flex>
        </div>
      </div>
      <GameSelectSidePanel />
    </div>
  );
};
export default PlayerStatisticsBlock;
