import React from 'react';

import { Flex, Skeleton } from 'antd';

const PlaylistsSkeleton = () => {
  return (
    <Flex gap={8} vertical style={{ width: '100%' }}>
      <Skeleton.Button
        active
        style={{
          opacity: 1,
          width: '100%',
          height: 32,
          backgroundColor:
            'var(--colorFillQuaternary, rgba(255, 255, 255, 0.1))',
        }}
      ></Skeleton.Button>
      <Skeleton.Button
        active
        style={{
          opacity: 0.7,
          width: '100%',
          height: 32,
          backgroundColor:
            'var(--colorFillQuaternary, rgba(255, 255, 255, 0.1))',
        }}
      ></Skeleton.Button>
      <Skeleton.Button
        active
        style={{
          opacity: 0.4,
          width: '100%',
          height: 32,
          backgroundColor:
            'var(--colorFillQuaternary, rgba(255, 255, 255, 0.1))',
        }}
      ></Skeleton.Button>
    </Flex>
  );
};
export default PlaylistsSkeleton;
