import { CalendarActionTypes } from 'actions/calendar.actions';
import {
  ADD_NEW_CALENDAR_EVENT,
  ADD_NEW_CALENDAR_EVENT_ERROR,
  ADD_NEW_CALENDAR_EVENT_SUCCESS,
  CLEAR_EDITED_CALENDAR_EVENT_STATE,
  CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH,
  DELETE_CALENDAR_EVENT,
  DELETE_CALENDAR_EVENT_ERROR,
  DELETE_CALENDAR_EVENT_SUCCESS,
  FILE_UPLOAD_IN_PROGRESS,
  NEW_ACTIVE_DATE_TYPE,
  SET_CALENDAR_EVENTS_ERROR,
  SET_CALENDAR_EVENTS_SUCCESS,
  SET_CALENDAR_EVENTS_TYPE,
  SET_CALENDAR_SIDE_FORM_MODE,
  SET_EDITED_CALENDAR_EVENT,
  SET_VIEW_CALENDAR_EVENT,
  UPDATE_CALENDAR_ERROR,
  UPDATE_CALENDAR_EVENT,
  UPDATE_CALENDAR_SUCCESS,
} from 'types/action.types';
import { filterAndIndex } from 'types/functions';
import {
  CalendarDayEventType,
  CalendarSideModeMode,
  DateType,
} from 'types/types';

type InitialStateType = {
  isLoading: boolean;
  activeDate: DateType | null;
  events: Array<CalendarDayEventType>;
  editedEvent: CalendarDayEventType | null;
  viewedEvent: CalendarDayEventType | null;
  currentReferencePositions: any;
  mode: CalendarSideModeMode;
  clearEditedCalendarEventState: boolean;
  error: any;
  fileUploadInProgress: boolean;
};
const initialState: InitialStateType = {
  isLoading: false,
  activeDate: null,
  events: [],
  editedEvent: null,
  viewedEvent: null,
  currentReferencePositions: null,
  mode: CalendarSideModeMode.create,
  clearEditedCalendarEventState: false,
  error: null,
  fileUploadInProgress: false,
};
const calendarReducer = (
  state = initialState,
  action: CalendarActionTypes,
): InitialStateType => {
  switch (action.type) {
    case NEW_ACTIVE_DATE_TYPE:
      return {
        ...state,
        activeDate: action.payload,
      };
    case SET_CALENDAR_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: [...action.payload],
      };
    case SET_CALENDAR_EVENTS_TYPE:
      return {
        ...state,
        isLoading: true,
      };
    case SET_CALENDAR_EVENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SET_EDITED_CALENDAR_EVENT:
      return {
        ...state,
        editedEvent: action.payload,
      };
    case CLEAR_EDITED_CALENDAR_EVENT_STATE:
      return {
        ...state,
        clearEditedCalendarEventState: true,
      };
    case CLEAR_EDITED_CALENDAR_EVENT_STATE_FINISH:
      return {
        ...state,
        clearEditedCalendarEventState: false,
      };
    case SET_VIEW_CALENDAR_EVENT:
      return {
        ...state,
        viewedEvent: action.payload.event,
        currentReferencePositions: action.payload.positions,
      };
    case ADD_NEW_CALENDAR_EVENT:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_NEW_CALENDAR_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ADD_NEW_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        events: [...state.events, action.payload],
        isLoading: false,
      };
    case DELETE_CALENDAR_EVENT:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_CALENDAR_EVENT_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case DELETE_CALENDAR_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: [...state.events.filter((evt) => evt.id !== action.payload.id)],
      };
    case SET_CALENDAR_SIDE_FORM_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case UPDATE_CALENDAR_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_CALENDAR_EVENT:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_CALENDAR_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { index } = filterAndIndex(state.events, action.payload.id, 'id');
      return {
        ...state,
        events: [
          ...state.events.slice(0, index),
          { ...action.payload },
          ...state.events.slice(index + 1),
        ],
        isLoading: false,
        editedEvent: null,
        mode: CalendarSideModeMode.create,
      };
    case FILE_UPLOAD_IN_PROGRESS:
      return {
        ...state,
        fileUploadInProgress: action.payload,
      };
    default: {
      return state;
    }
  }
};
export default calendarReducer;
