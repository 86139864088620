import { API_ENDPOINT } from '../types/constants';
import myAxios from '../utils/axios';

const getFolders = async (
  level?: number | null,
  parent?: string | null,
  sorting?: string | null,
) => {
  const url = `${API_ENDPOINT}api/data/folders/`;
  const params = new URLSearchParams();
  if (level !== undefined && level !== null) {
    params.append('level', level.toString());
  }
  if (parent) {
    params.append('parent', parent);
  }
  if (sorting) {
    params.append('sorting', sorting);
  }
  try {
    return await myAxios.GET(url, params);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const addFolder = async (
  folderName: string,
  parentFolderId?: string | null,
) => {
  const url = `${API_ENDPOINT}api/data/folders/`;
  try {
    const payload = {
      name: folderName,
      parent: parentFolderId,
    };
    return await myAxios.POST(url, payload);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const updateFolder = async (folderData: any, folderId: string) => {
  const url = `${API_ENDPOINT}api/data/folders/${folderId}/`;
  try {
    return await myAxios.PUT(url, folderData);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const deleteFolder = async (folderId: string) => {
  const url = `${API_ENDPOINT}api/data/folders/${folderId}/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log(e);
    return e.response.data;
  }
};
const getPlaylists = async (queryParams: any) => {
  const url = `${API_ENDPOINT}api/data/playlists/`;
  try {
    const params = new URLSearchParams();

    if (queryParams.root_folder) {
      params.append('root_folder', true.toString());
    }
    if (queryParams.folderId) {
      params.append('folder', queryParams.folderId);
    }
    // alert(queryParams.sorting);
    if (queryParams.sorting) {
      params.append('sorting', queryParams.sorting);
    }
    if (queryParams.flat) {
      params.append('flat', queryParams.flat);
    }
    return await myAxios.GET(url, params);
  } catch (e: any) {
    if (e.response.status === 400) {
      return {
        status: 'error',
        ...e.response.data,
      };
    }
    return e.response.data;
  }
  return {};
};
const reorderPlaylistEvents = async (playlistId: string, payload: any) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/reorder/`;
  try {
    return await myAxios.PATCH(url, payload);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const updatePlaylistElement = async (
  playlistId: string,
  episodeId: string,
  payload: any,
) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/event/${episodeId}/`;
  try {
    return await myAxios.PATCH(url, payload);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const removePlaylistElement = async (playlistId: string, episodeId: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/event/${episodeId}/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const reorderPlaylist = async (playlistId: string, payload: any) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/reorder_playlists/`;
  try {
    return await myAxios.PATCH(url, payload);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const reorderFolder = async (folderId: string, payload: any) => {
  const url = `${API_ENDPOINT}api/data/folders/${folderId}/reorder_folders/`;
  try {
    return await myAxios.PATCH(url, payload);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const addPlaylist = async (
  playlistName?: string,
  parentFolder?: string | null,
) => {
  const url = `${API_ENDPOINT}api/data/playlists/`;
  try {
    return await myAxios.POST(url, {
      name: playlistName,
      folder: parentFolder,
    });
  } catch (e: any) {
    if (e.response.status === 400) {
      return {
        status: 'error',
        ...e.response.data,
      };
    }
    return e.response.data;
  }
  return {};
};

const updatePlaylist = async (
  playlistId: string,
  playlistName?: string,
  parentFolder?: string | null,
) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/`;
  const payload = { name: playlistName, folder: parentFolder };
  try {
    const response = await myAxios.PATCH(url, payload);
    return response.data;
  } catch (e: any) {
    console.log('e', e);
    if (e.response.status === 400) {
      return {
        status: 'error',
        ...e.response.data,
      };
    }
    return e.response.data;
  }
  return {};
};
const getPlaylistDetails = async (playlistId: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const addToPlaylists = async (episodes: string[], playlists: string[]) => {
  const url = `${API_ENDPOINT}api/data/playlists/add/`;
  const payload = {
    playlists: playlists,
    episodes: episodes,
  };
  try {
    return await myAxios.POST(url, payload);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};

const createShareLink = async (playlistId: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/link/`;
  try {
    return await myAxios.POST(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
const deletePlaylist = async (playlistId: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/${playlistId}/`;
  try {
    return await myAxios.DELETE(url);
  } catch (e: any) {
    console.log('e', e);
    return e;
  }
  return {};
};
const getPlaylistByShareLink = async (shareLink: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/shared/${shareLink}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response;
  }
  return {};
};
const getSuggestedPlaylistName = async (language: string) => {
  const url = `${API_ENDPOINT}api/data/playlists/suggest_name/?language=${language}`;
  try {
    return await myAxios.GET(url);
  } catch (e: any) {
    console.log('e', e);
    return e.response.data;
  }
  return {};
};
export const PlaylistsAPI = {
  getFolders,

  addFolder,
  updateFolder,
  deleteFolder,
  getPlaylists,
  reorderPlaylistEvents,
  reorderPlaylist,
  reorderFolder,

  addPlaylist,
  updatePlaylist,
  getPlaylistDetails,
  addToPlaylists,
  deletePlaylist,
  updatePlaylistElement,
  removePlaylistElement,
  getSuggestedPlaylistName,

  createShareLink,
  getPlaylistByShareLink,
};
