import React, { useEffect, useState } from 'react';

// import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LogoGrey from 'assets/img/LogoSotaGrey.svg';
import './index.css';

export const TournamentHeaderBlock = () => {
  const HEADER_STATS = [
    {
      name: 'Голы',
      teams: [
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
      ],
    },
    {
      name: 'Голы с пенальти',
      teams: [
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
        {
          name: 'Team 1',
          data: 12,
          percent: 12,
        },
      ],
    },
    {
      name: 'Голы с дабл-пенальти',
      teams: [
        {
          name: 'Team 1',
          data: 12,
        },
        {
          name: 'Team 1',
          data: 12,
        },
        {
          name: 'Team 1',
          data: 12,
        },
      ],
    },
    {
      name: 'Удары',
      teams: [
        {
          name: 'Team 1',
          data: 12,
        },
      ],
    },
    {
      name: 'Удары в створ',
      teams: [
        {
          name: 'Team 1',
          data: 12,
        },
        {
          name: 'Team 1',
          data: 12,
        },
        {
          name: 'Team 1',
          data: 12,
        },
      ],
    },
  ];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [headerStats, setHeaderStats] = useState<any>(HEADER_STATS);

  // const [t] = useTranslation();
  const { tournamentId } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [lineup, setLineup] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [teamData, setTeamData] = useState<any>();

  useEffect(() => {
    console.log();
  }, [tournamentId]);
  return (
    <div className="team-header-block flex-row f-ga-24">
      <div className="team-header-logo">
        <img src={teamData?.image || LogoGrey} alt={'Team logo'} />
        <div className="team-header-name">{teamData?.name}</div>
        <div className="team-header-league">{teamData?.league?.name}</div>
      </div>
      <div className="block-wrapper tournament-header-stats">
        {headerStats.map((stat: any, indexStat: number) => (
          <div key={indexStat} className="tournament-stat-block">
            <div className="tournament-stat-title">{stat.name}</div>
            {stat.teams.map((teams: any, index: number) => (
              <div key={index} className="tournament-stat-element">
                <div className="tournament-stat-name">{teams.name}</div>
                <div className="tournament-stat-value">
                  <span className="tournament-stat-data">{teams.data}</span>
                  {teams.percent && (
                    <span className="tournament-stat-percent">
                      {teams.percent}%
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
