import { InterfaceActionsTypes } from 'actions/interface.actions';
import { SET_LANGUAGE, SET_STYLE_MODE_ACTION } from 'types/action.types';
import { STYLE_TYPES } from 'types/constants';

type InitialStateType = {
  style: string;
  language: string;
};
const initialState: InitialStateType = {
  style: STYLE_TYPES.light,
  language: localStorage.getItem('i18nextLng') || 'ru',
};
const interfaceReducer = (
  state = initialState,
  action: InterfaceActionsTypes,
): InitialStateType => {
  switch (action.type) {
    case SET_STYLE_MODE_ACTION:
      return {
        ...state,
        style: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};
export default interfaceReducer;
