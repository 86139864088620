import React, { FC, useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { OnProgressProps } from 'react-player/types/base';
import { useSelector } from 'react-redux';

import { AC_SetIsPlayingVideoAction } from 'actions/player.acitons';
import { ReactComponent as ForwardIcon } from 'assets/img/icons/faForwardIcon.svg';
import { ReactComponent as NextKeyFrameIcon } from 'assets/img/icons/faNextKeyFrameIcon.svg';
import { ReactComponent as PlayIcon } from 'assets/img/icons/faPlayIcon.svg';
import { ReactComponent as PreviousKeyFrameIcon } from 'assets/img/icons/faPreviousKeyFrameIcon.svg';
import { ReactComponent as RepeatIcon1 } from 'assets/img/icons/faRepeatIcon.svg';
import { ReactComponent as RepeatIcon10 } from 'assets/img/icons/faRepeatIcon10.svg';
import { ReactComponent as RepeatIcon2 } from 'assets/img/icons/faRepeatIcon2.svg';
import { ReactComponent as RepeatIcon3 } from 'assets/img/icons/faRepeatIcon3.svg';
import { ReactComponent as RepeatIcon4 } from 'assets/img/icons/faRepeatIcon4.svg';
import { ReactComponent as RepeatIcon5 } from 'assets/img/icons/faRepeatIcon5.svg';
import { ReactComponent as RewindIcon } from 'assets/img/icons/faRewindIcon.svg';
import { ReactComponent as PauseIcon } from 'assets/img/PauseIcon.svg';
import { AppStateType } from 'reducers';
import { hotkeysReducer } from 'reducers/hotkeys.reducer';
import { playerReducerV2 } from 'reducers/player.reducer';
import { reelsReducer } from 'reducers/reels.reducer';
import { useAppDispatch } from 'store';
import { WINDOW_MODE_WIDTH, WINDOW_MODE_WIDTH_MS } from 'types/constants';
import {
  formatMillisecondsToTime,
  // formattedTimeSeconds,
} from 'types/functions';
import { EpisodeTypes } from 'types/types';
import { detectOS } from 'utils/functions';

import HelperPlayerButtons from './HelperPlayerButtons';
import RewindSpeedIndex from '../../contexts/RewindSpeedContext';
import VideoTimelineZoomControl from '../VideoTimelineZoomControl';

interface Interface {
  refPlayer: any;
  playerState: OnProgressProps | undefined;
  // handleVideoScroll: (milliseconds: number) => void;
  isNarrowScreenMode: boolean;
  toggleFullScreen: any;
  setPlaybackRate: any;
  playbackRate: number;
  volume: number;
  setVolume: any;
  rewindSpeed: number;
  setRewindSpeed: any;
  setIsMuted: any;
  to: number;
  from: number;
}
const VideoPlayerButtonsController: FC<Interface> = ({
  refPlayer,
  playerState,
  isNarrowScreenMode,
  toggleFullScreen,
  playbackRate,
  setPlaybackRate,
  volume,
  setVolume,
  rewindSpeed,
  setRewindSpeed,
  setIsMuted,
  to,
  from,
}) => {
  const { activeVideo, playerMode, isPlaying, isLoading, editedEpisodeRange } =
    useSelector((state: AppStateType) => state.playerReducer);
  const { playedEpisode } = useSelector(
    (state: AppStateType) => state.playerReducerV2,
  );
  const { setCreateEpisodeMode } = playerReducerV2.actions;
  const dispatch = useAppDispatch();
  const [t] = useTranslation();
  const { reelsModeOn, keyframes, toggleFrameState } = useSelector(
    (state: AppStateType) => state.reelsReducer,
  );
  const REWIND_INDEX_ICONS: any = {
    1: <RepeatIcon1 />,
    2: <RepeatIcon2 />,
    3: <RepeatIcon3 />,
    4: <RepeatIcon4 />,
    5: <RepeatIcon5 />,
    10: <RepeatIcon10 />,
  };
  const getCurrentTimerPosition = () => {
    let currentTime = 0;
    let episodeLength = 0;
    if (playerState?.playedSeconds !== undefined && playedEpisode) {
      let timer;
      let offset = 0;
      let windowTime = WINDOW_MODE_WIDTH;
      if (playedEpisode && playedEpisode.episode_type === EpisodeTypes.period) {
        windowTime = 0;
      }
      if (playedEpisode?.user_timer) {
        timer = playedEpisode.user_timer;
      } else if (playedEpisode.episode_type === EpisodeTypes.period) {
        timer = playedEpisode.game_timer;
        offset = windowTime;
      } else {
        timer = playedEpisode.file_timer;
        offset = windowTime;
      }
      if (editedEpisodeRange.length > 0) {
        currentTime = playerState?.playedSeconds - from / 1000 - 0;
        episodeLength = (to - from) / 1000;
      } else {
        if (playedEpisode) {
          if (
            playedEpisode.episode_type === EpisodeTypes.period &&
            playedEpisode?.period_offset !== undefined
          ) {
            currentTime =
              playerState?.playedSeconds -
              playedEpisode.period_offset / 1000 +
              playedEpisode.game_timer.started_at / 1000;
            episodeLength = timer.finished_at / 1000 + offset;
          } else {
            if (timer.started_at > offset) {
              currentTime =
                playerState?.playedSeconds - (timer.started_at / 1000 - offset);
              const epiosodeStart = timer.started_at
                ? timer.started_at / 1000 - offset
                : offset;
              episodeLength = timer.finished_at / 1000 + offset - epiosodeStart;
            } else {
              currentTime = playerState?.playedSeconds;
              episodeLength = timer.finished_at / 1000 + offset;
            }
          }
        }
      }
    }
    if (currentTime >= 0) {
      if (currentTime <= episodeLength) {
        return formatMillisecondsToTime(currentTime * 1000, true);
      }
      return formatMillisecondsToTime(episodeLength * 1000, true);
    }
    return formatMillisecondsToTime(0, true);
  };
  const getCurrentVideoDuration = () => {
    if (playedEpisode?.user_timer) {
      return formatMillisecondsToTime(
        playedEpisode?.user_timer.finished_at -
          playedEpisode?.user_timer.started_at,
        true,
      );
    } else {
      let timer = playedEpisode?.file_timer;
      if (playedEpisode && playedEpisode.episode_type === EpisodeTypes.period) {
        timer = playedEpisode?.game_timer;
        return formatMillisecondsToTime(timer.finished_at, true);
      }
      if (timer?.started_at > WINDOW_MODE_WIDTH_MS) {
        return formatMillisecondsToTime(
          timer?.finished_at +
            WINDOW_MODE_WIDTH_MS -
            (timer?.started_at - WINDOW_MODE_WIDTH_MS),
          true,
        );
      } else {
        return formatMillisecondsToTime(
          timer?.finished_at + WINDOW_MODE_WIDTH_MS,
          true,
        );
      }
    }
  };
  const { toggleNextEpisode, togglePreviousEpisode } = hotkeysReducer.actions;
  const [playIcon, setPlayIcon] = useState(<></>);
  useEffect(() => {
    if (isPlaying || isLoading) {
      setPlayIcon(<PauseIcon className={'playerButton'} />);
    } else {
      setPlayIcon(<PlayIcon />);
    }
  }, [isLoading, isPlaying]);
  const [playPreviousTooltipVisible, setPlayPreviousTooltipVisible] =
    useState(false);
  const [playNextTooltipVisible, setPlayNextTooltipVisible] = useState(false);
  const handleMouseEnter = (setter: any) => {
    setter(true);
    setTimeout(() => {
      setter(false);
    }, 1500);
  };
  const handleMouseLeave = (setter: any) => {
    setter(false);
  };
  const { resetReelsState, setToggleFrameState } = reelsReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;

  const nextEpsiodeButtonHandler = (callback: any) => {
    dispatch(resetReelsState());
    if (editedEpisodeRange.length > 0) {
      dispatch(resetEditMode());
    }
    dispatch(callback(true));
    dispatch(setCreateEpisodeMode(false));
  };
  const goToPreviousFrame = () => {
    if (keyframes.length > 0 && playerState?.playedSeconds !== undefined) {
      const previousFrames = [...keyframes]
        .sort((a, b) => b.time - a.time)
        .filter(
          (keyframe) => keyframe.time < playerState?.playedSeconds * 1000,
        );
      if (previousFrames.length > 0) {
        refPlayer?.current?.seekTo(previousFrames[0].time / 1000);
      }
    }
  };
  const goToNextFrame = () => {
    if (keyframes.length > 0 && playerState?.playedSeconds !== undefined) {
      const previousFrames = [...keyframes]
        .sort((a, b) => a.time - b.time)
        .filter(
          (keyframe) => keyframe.time > playerState?.playedSeconds * 1000,
        );
      if (previousFrames.length > 0) {
        refPlayer?.current?.seekTo(previousFrames[0].time / 1000);
      }
    }
  };
  useEffect(() => {
    if (toggleFrameState === 'next') {
      goToNextFrame();
      dispatch(setToggleFrameState(''));
    } else if (toggleFrameState === 'previous') {
      goToPreviousFrame();
      dispatch(setToggleFrameState(''));
    }
  }, [toggleFrameState]);
  return (
    <div className={'flex-row j-sb ai-c'} style={{ width: '100%', height: 40 }}>
      {activeVideo ? (
        <>
          <div className={'cameraCheckWindow_Timer flex-row f-ga-8 j-ce ai-c'}>
            <span>{getCurrentTimerPosition()}</span>
            <span style={{ textAlign: 'center' }}>/</span>
            <span>{getCurrentVideoDuration()}</span>
          </div>
          <div className={'flex-row f-ga-4 ai-c '}>
            {reelsModeOn && (
              <span
                className={'enabledClickable flex-column flex-row j-ce'}
                onMouseEnter={() =>
                  handleMouseEnter(setPlayPreviousTooltipVisible)
                }
                onMouseLeave={() =>
                  handleMouseLeave(setPlayPreviousTooltipVisible)
                }
                onClick={goToPreviousFrame}
              >
                <Tooltip
                  open={playPreviousTooltipVisible}
                  title={t(
                    `Previous keyframe ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + <-`,
                  )}
                  align={{ offset: [10, -35] }}
                >
                  <PreviousKeyFrameIcon />
                </Tooltip>
              </span>
            )}
            <span
              className={'enabledClickable flex-column flex-row j-ce'}
              onClick={() => dispatch(AC_SetIsPlayingVideoAction(!isPlaying))}
            >
              {playIcon}
            </span>
            {reelsModeOn && (
              <span
                className={'enabledClickable flex-column flex-row j-ce'}
                onMouseEnter={() => handleMouseEnter(setPlayNextTooltipVisible)}
                onMouseLeave={() => handleMouseLeave(setPlayNextTooltipVisible)}
                onClick={goToNextFrame}
              >
                <Tooltip
                  align={{ offset: [10, -35] }}
                  open={playNextTooltipVisible}
                  style={{ width: 200 }}
                  title={t(
                    `Next keyframe ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + ->`,
                  )}
                >
                  <NextKeyFrameIcon />
                </Tooltip>
              </span>
            )}
            {!reelsModeOn && (
              <>
                <span
                  className="flex-row j-ce enabledClickable"
                  style={{ width: 40 }}
                >
                  <Tooltip
                    open={playPreviousTooltipVisible}
                    title={t(
                      `Previous episode ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + <-`,
                    )}
                    align={{ offset: [10, -35] }}
                  >
                    <RewindIcon
                      onMouseEnter={() =>
                        handleMouseEnter(setPlayPreviousTooltipVisible)
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(setPlayPreviousTooltipVisible)
                      }
                      onClick={() =>
                        nextEpsiodeButtonHandler(togglePreviousEpisode)
                      }
                    />
                  </Tooltip>
                </span>
                <span
                  className="flex-row j-ce enabledClickable"
                  style={{ width: 40 }}
                >
                  <Tooltip
                    align={{ offset: [10, -35] }}
                    open={playNextTooltipVisible}
                    title={t(
                      `Next episode ${detectOS() === 'MacOS' ? 'CMD' : 'CTRL'} + ->`,
                    )}
                  >
                    <ForwardIcon
                      onMouseEnter={() =>
                        handleMouseEnter(setPlayNextTooltipVisible)
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(setPlayNextTooltipVisible)
                      }
                      onClick={() =>
                        nextEpsiodeButtonHandler(toggleNextEpisode)
                      }
                    />
                  </Tooltip>
                </span>
                <span className="flex-row j-ce" style={{ width: 40 }}>
                  <RewindSpeedIndex
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    speedSetCallback={setRewindSpeed}
                    rewindSpeed={rewindSpeed}
                  >
                    {REWIND_INDEX_ICONS[rewindSpeed]}
                  </RewindSpeedIndex>
                </span>
              </>
            )}
          </div>
          {playerMode === '' ? (
            <VideoTimelineZoomControl />
          ) : (
            <HelperPlayerButtons
              playbackRate={playbackRate}
              setPlaybackRate={setPlaybackRate}
              toggleFullScreen={toggleFullScreen}
              isNarrowScreenMode={isNarrowScreenMode}
              volume={volume}
              setVolume={setVolume}
              setIsMuted={setIsMuted}
              playerState={playerState}
            />
          )}
        </>
      ) : (
        <div style={{ height: '27px' }} />
      )}
    </div>
  );
};
export default VideoPlayerButtonsController;
