import './index.css';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { GetCurrentUserAction } from 'actions/auth.actions';
import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
  AC_SetSelectedPlayersAction,
} from 'actions/filter.actions';
import { setStyleModeAction } from 'actions/interface.actions';
import {
  AC_GetVideoEpisodesSuccess,
  AC_SetActiveVideoAction,
  AC_SetSelectedGames,
  AC_SetVisibleRangeAction,
} from 'actions/player.acitons';
import { EventsAPI } from 'api/events';
import CalendarEventsController from 'components/CalendarEvents';
import ContentContainer from 'components/ContentContainer';
import EventControlPanel from 'components/EventControlPanel';
import LanguageSelector from 'components/LanguageSelector';
import TeamSelector from 'components/TeamSelector';
import { playerReducerV2 } from 'reducers/player.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { useAppDispatch } from 'store';

const CalendarPage = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { setStatsCustomGameList } = teamReducer.actions;
  const { resetEditMode } = playerReducerV2.actions;

  useEffect(() => {
    dispatch(GetCurrentUserAction());
    dispatch(setStyleModeAction('light'));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(setStatsCustomGameList([]));
    dispatch(AC_GetVideoEpisodesSuccess([]));
    dispatch(AC_SetLastXFilterAction(null));
    dispatch(AC_SetSelectedAttributes([]));
    dispatch(AC_AddSelectedTournamentFilterAction([]));
    dispatch(AC_SetSelectedGames([]));
    dispatch(AC_SelectedMatchesFilterAction([]));
    dispatch(AC_SetActiveVideoAction(null));
    dispatch(AC_SetSelectedPlayersAction([]));
    dispatch(resetEditMode());
    dispatch(AC_SetVisibleRangeAction([]));
    const x = async () => {
      const downloadLink = params.downloadId;
      if (downloadLink) {
        await EventsAPI.downloadArchive(downloadLink);
        const nav = localStorage.getItem('redirectTo') || 'player';
        navigate(nav);
      }
    };
    x().then();
    window.document.body.setAttribute('data-theme', 'light');
  }, []);

  const { t } = useTranslation();
  return (
    <ContentContainer
      wideScreenMode={false}
      pageTitle={t('Teams and players')}
      showNavigationPanel={true}
      page={'main'}
      rightPanel={
        <div className="rightSidePanel">
          {<LanguageSelector />}
          <TeamSelector />
          <EventControlPanel />
        </div>
      }
    >
      <CalendarEventsController />
    </ContentContainer>
  );
};
export default CalendarPage;
