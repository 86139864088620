import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  nextEpisodeCommand: boolean;
  previousEpisodeCommand: boolean;
};
const initialState: InitialStateType = {
  nextEpisodeCommand: false,
  previousEpisodeCommand: false,
};

export const hotkeysReducer = createSlice({
  name: 'hotkeysReducer',
  initialState: initialState,
  reducers: {
    toggleNextEpisode(state, action) {
      state.nextEpisodeCommand = action.payload;
    },
    togglePreviousEpisode(state, action) {
      state.previousEpisodeCommand = action.payload;
    },
  },
});
