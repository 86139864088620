import React, { FC, useEffect, useRef, useState } from 'react';

import './index.css';
import UserIcon from 'assets/img/UserIcon.svg';
import { constructTimeZoneName, isCityType } from 'types/functions';
import { CityType, TeamType } from 'types/types';

import InputWithClear from '../../InputWithClear';
import Loader from '../../Loader';

interface Interface {
  fieldName: string;
  placeholder: string;
  valueController: { value: any; setValue: any };
  maxLength: number;
  isRequired?: boolean;
  searchElementsController?: {
    searchElements: Array<any>;
    setSearchElements: any;
  };
  conditionalDisplay?: boolean;
  callbackFunction?: any;
  allowNonDropDown?: boolean;
  secondaryCallback?: any;
  loadingState: boolean;
}

const DropDownSearchPop: FC<Interface> = ({
  fieldName,
  placeholder,
  valueController,
  maxLength,
  callbackFunction,
  isRequired = false,
  conditionalDisplay = false,
  secondaryCallback,
  searchElementsController,
  loadingState,
}) => {
  const searchRef = useRef<any>(null);

  const { value } = valueController;
  // const dropDownControlledSetValue = (value: any) => {
  //   if (!callbackFunction) {
  //     setValue(value);
  //   }
  // };
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [fieldIsOnFocus, setFieldIsOnFocus] = useState(false);
  const { searchElements, setSearchElements } = searchElementsController || {};
  useEffect(() => {
    if (value) {
      setDropdownVisible(value?.length > 0 && fieldIsOnFocus);
      setSearchElements(value);
    } else {
      setSearchElements(value);
    }
  }, [value]);

  useEffect(() => {
    if (!fieldIsOnFocus && !secondaryCallback) {
      setDropdownVisible(false);
    } else if (fieldIsOnFocus) {
      setDropdownVisible(true);
    }
  }, [fieldIsOnFocus]);

  const getTopOffset = (fieldRef: any) => {
    const scrollH = fieldRef?.current?.scrollHeight + 4;
    return conditionalDisplay
      ? scrollH
      : fieldRef?.current?.getBoundingClientRect().top + scrollH;
  };
  const getLeftOffset = (fieldRef: any) => {
    return fieldRef?.current?.offsetLeft;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  const onOptionClick = (value: any) => {
    callbackFunction(value);
  };

  const constructName = (element: TeamType | CityType) => {
    if (isCityType(element)) {
      return constructTimeZoneName(element);
    }
    return element.name;
  };
  return (
    <div className="popDropdownWrapper">
      <div className="dropDownSearchContainer">
        <InputWithClear
          placeholder={placeholder}
          fieldType={'text'}
          fieldName={fieldName}
          isRequired={isRequired}
          valueController={valueController}
          maxLength={maxLength}
          ref={searchRef}
          focusTracker={setFieldIsOnFocus}
          clearIcon={true}
        />
        {loadingState && (
          <div className="flex-row j-ce m10">
            <Loader color={'black'} />
          </div>
        )}
        {dropdownVisible &&
          !loadingState &&
          value &&
          searchElements &&
          searchElements?.length > 0 && (
            <div
              style={{
                top: `${getTopOffset(searchRef)}px`,
                left: `${getLeftOffset(searchRef)}px`,
                width: `${searchRef?.current?.scrollWidth - 16}px`,
              }}
              className="popdropdownListContainer display-flex j-sb"
            >
              <div className="innerDropdownListContainer flex-column j-sb">
                {searchElements?.map((element: TeamType | CityType) => {
                  return (
                    <div
                      className="teamDDListElement"
                      onMouseDown={() => {
                        onOptionClick(element);
                      }}
                    >
                      <input defaultValue={element.id} type="radio" />
                      <img src={UserIcon} />
                      <label className="elipsis-text">
                        {constructName(element)}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default DropDownSearchPop;
