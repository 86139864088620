import React, { useEffect } from 'react';

import anime from 'animejs/lib/anime.es.js';
const Loader = ({ seconds, color }: { seconds?: number; color?: string }) => {
  const paths: string[][] = [
    [
      'M30.5 16C30.5 17.5216 30.2605 19.0337 29.7903 20.4807',
      'M30.5 16C30.5 17.5216 30.2605 19.0337 29.7903 20.4807',
      'M30.5 16C30.5 17.5216 30.2605 19.0337 29.7903 20.4807',
      'M30.5 16C30.5 17.5216 30.2605 19.0337 29.7903 20.4807',
      'M30.5 16C30.5 17.5216 30.2605 19.0337 29.7903 20.4807',
    ],
    [
      'M8.75297 28.5583C7.43525 27.7975 6.24549 26.8341 5.22737 25.7033',
      'M1.82121 12.9829C2.13756 11.4946 2.6862 10.0654 3.44698 8.74766',
      'M5.22565 25.7057C4.20752 24.5749 3.37371 23.291 2.75484 21.901',
      'M2.75798 21.8981C2.1391 20.5081 1.74287 19.0293 1.58382 17.5161',
      'M1.58447 17.518C1.42542 16.0047 1.50555 14.4759 1.8219 12.9876',
    ],
    [
      'M8.75094 3.44365C10.0687 2.68286 11.4979 2.13423 12.9862 1.81788',
      'M25.7051 5.22767C26.8358 6.2458 27.7992 7.43556 28.56 8.75327',
      'M12.9848 1.81452C14.4732 1.49817 16.002 1.41805 17.5152 1.57709',
      'M17.5145 1.58173C19.0278 1.74077 20.5065 2.13701 21.8966 2.75588',
      'M21.8972 2.75403C23.2872 3.3729 24.5712 4.20671 25.7019 5.22484',
    ],
  ];
  const ms = seconds ? seconds * 1000 : 1000;
  useEffect(() => {
    anime({
      targets: '.spinner #spinner0',
      keyframes: [
        {
          value: paths[1][0],
          rotate: 120,
          duration: ms * 0.31,
          easing: 'linear',
        },
        {
          value: paths[2][0],
          rotate: 240,
          duration: ms * 0.21,
          easing: 'linear',
        },
        {
          value: paths[0][0],
          rotate: 360,
          duration: ms * 0.48,
          easing: 'easeOutQuad',
        },
      ],
      loop: true,
      autoplay: true,
    });
    anime({
      targets: '.spinner #spinner1',
      keyframes: [
        {
          value: paths[1][1],
          rotate: 120,
          duration: ms * 0.265,
          easing: 'linear',
        },
        {
          value: paths[2][1],
          rotate: 240,
          duration: ms * 0.21,
          easing: 'linear',
        },
        {
          value: paths[0][1],
          rotate: 360,
          duration: ms * 0.525,
          easing: 'easeOutQuad',
        },
      ],
      loop: true,
      autoplay: true,
    });
    anime({
      targets: '.spinner #spinner2',
      keyframes: [
        {
          value: paths[1][2],
          rotate: 120,
          duration: ms * 0.22,
          easing: 'linear',
        },
        {
          value: paths[2][2],
          rotate: 240,
          duration: ms * 0.23,
          easing: 'linear',
        },
        {
          value: paths[0][2],
          rotate: 360,
          duration: ms * 0.55,
          easing: 'easeOutQuad',
        },
      ],
      loop: true,
      autoplay: true,
    });
    anime({
      targets: '.spinner #spinner3',
      keyframes: [
        {
          value: paths[1][3],
          rotate: 120,
          duration: ms * 0.19,
          easing: 'linear',
        },
        {
          value: paths[2][3],
          rotate: 240,
          duration: ms * 0.225,
          easing: 'linear',
        },
        {
          value: paths[0][3],
          rotate: 360,
          duration: ms * 0.585,
          easing: 'easeOutQuad',
        },
      ],
      loop: true,
      autoplay: true,
    });
    anime({
      targets: '.spinner #spinner4',
      keyframes: [
        {
          value: paths[1][4],
          rotate: 120,
          duration: ms * 0.18,
          easing: 'linear',
        },
        {
          value: paths[2][4],
          rotate: 240,
          duration: ms * 0.235,
          easing: 'linear',
        },
        {
          value: paths[0][4],
          rotate: 360,
          duration: ms * 0.585,
          easing: 'easeOutQuad',
        },
      ],
      loop: true,
      autoplay: true,
    });
  }, []);
  const COLORS: any = {
    white: 'var(--white)',
    black: 'var(--black)',
    blue: 'var(--new-main-light)',
  };
  const spinnerColor = color ? COLORS[color] : COLORS.white;
  return (
    <svg
      style={{ margin: 'auto' }}
      className={'spinner'}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
    >
      {paths[0].map((el: string, index: number) => {
        return (
          <path
            style={{ transformOrigin: '50% 50%' }}
            key={index}
            d={el}
            id={`spinner${index}`}
            stroke={spinnerColor}
            strokeWidth="3"
            strokeLinecap="round"
          />
        );
      })}
    </svg>
  );
};
export default Loader;
