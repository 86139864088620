import React, { FC, useEffect, useState } from 'react';

import { ConfigProvider, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import {
  AC_SetSelectedAttributes,
  setLastXFilterAction,
  setSelectedDateLimitsFilterAction,
} from 'actions/filter.actions';
import CheckBoxWithRange from 'components/CheckboxWithRange';
import { AppStateType } from 'reducers';
import { useAppDispatch } from 'store';
import { INIT_TEAM_TOURNAMENT_DATES_REQUEST } from 'types/action.types';
import { DateLimitType, TeamType } from 'types/types';
import dayjs from 'utils/dayjsLocal';

import TournamentsFilter from '../TournamentsFilter';

import './style.css';

interface GameSelectorComponentInterface {
  activeFilterTeam: TeamType | null;
}

const GameSelectorComponent: FC<GameSelectorComponentInterface> = ({
  activeFilterTeam,
}) => {
  const { currentTeamTournaments, loadingActions } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { currentTeamDateLimits, lastXFilter, activeTeam, selectedDateLimits } =
    useSelector((state: AppStateType) => state.filtersReducer, shallowEqual);
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  /* Handle tournament filter*/
  /* End handle tournament filter*/

  /* Handle date limits filter */
  const updateSelectedDateLimits = (value: any) => {
    if (activeTeam) {
      const newLimits = {
        // ...selectedDateLimits,
        ...value,
      };
      if (Object.keys(newLimits).includes(value)) {
        delete newLimits[value];
      }
      // activeTeam && setSelectedDataLimits(prev => newLimits);
      // setDisplayedFilters(true, {date_limits: newLimits});
      dispatch(setSelectedDateLimitsFilterAction(newLimits));
      dispatch(AC_SetSelectedAttributes([]));
    }
  };
  /* End handle date limits filter */

  /* Handle lastX games filter */

  const updateLastXValue = (value: number | null) => {
    if (activeFilterTeam) {
      dispatch(setLastXFilterAction(value));
    }
  };
  /* End handles for lastX games filter*/

  /* Handle game_type filter */
  /* End handles for game_type */

  /* Handle results filter */
  /* End results filter */

  const [displaySeasonsByTournamentId, setDisplaySeasonsByTournamentId] =
    useState<number | null>(null);

  const toggleShowSeasons = (tournamentId: number) => {
    if (displaySeasonsByTournamentId === tournamentId) {
      setDisplaySeasonsByTournamentId(null);
    } else {
      setDisplaySeasonsByTournamentId(tournamentId);
    }
  };

  useEffect(() => {
    if (currentTeamTournaments.length === 0) {
      setLastXFilterAction(10);
    }
  }, [currentTeamTournaments]);

  return (
    <>
      <div className={'game_selector_column_1'}>
        <div className="title">{t('Year')}</div>
        <div className={'years'}>
          {/* {loadingActions?.includes(INIT_TEAM_TOURNAMENT_DATES_REQUEST) && <Loader />} */}
          {!loadingActions?.includes(INIT_TEAM_TOURNAMENT_DATES_REQUEST) &&
            currentTeamDateLimits?.map(
              (season: DateLimitType, index: number) => {
                return (
                  <div key={index}>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgContainer: '#141414',
                          colorBorder: '#424242',
                          lineWidth: 1,
                        },
                      }}
                    >
                      <CheckBoxWithRange
                        rangeType={'months'}
                        // range={[season.from, season.to]}
                        valuesController={{
                          values: selectedDateLimits || {},
                          setValues: updateSelectedDateLimits,
                        }}
                        name={`${dayjs(season.from).format('YYYY')}`}
                        label={`${dayjs(season.from).format('YYYY')}`}
                      />
                    </ConfigProvider>
                  </div>
                );
              },
            )}
        </div>
        <div className={'divider'} />
        <div className="last_x_filters">
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: '#141414',
                colorBorder: '#424242',
                lineWidth: 1,
              },
            }}
          >
            <Radio.Group
              className={'flex-column f-ga-12'}
              onChange={(e: any) => {
                updateLastXValue(e.target.value);
              }}
              value={lastXFilter}
            >
              <div className={'flex-row f-ga-6 ai-c 1'}>
                <Radio id={'lastX'} name="lastX" value={1}>
                  {t('Last match')}
                </Radio>
              </div>
              <div className={'flex-row f-ga-6 ai-c'}>
                <Radio id={'last5'} name="lastX" value={5}>
                  {t('Last 5 matches')}
                </Radio>
              </div>
              <div className={'flex-row f-ga-6 ai-c'}>
                <Radio id={'last10'} name="lastX" value={10}>
                  {t('Last 10 matches')}
                </Radio>
              </div>
            </Radio.Group>
          </ConfigProvider>
        </div>
      </div>
      <div className="game_selector_column_2">
        <div className={'title'}>{t('Tournaments')}</div>
        {/* {loadingActions?.includes(INIT_TEAM_TOURNAMENT_DATES_REQUEST) && <Loader />} */}
        <div className="flex-column f-ga-8">
          {!loadingActions?.includes(INIT_TEAM_TOURNAMENT_DATES_REQUEST) && (
            <TournamentsFilter
              activeFilterTeam={activeFilterTeam}
              tournaments={currentTeamTournaments}
              displaySeasonsByTournamentId={displaySeasonsByTournamentId}
              toggleShowSeasons={toggleShowSeasons}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default GameSelectorComponent;
