import React, { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { SetActiveDateAction } from '../../../actions/calendar.actions';
import LeftCalendarScroll from '../../../assets/img/LeftCalendarScroll.svg';
import RightCalendarScroll from '../../../assets/img/RightCalendarScroll.svg';
import { AppStateType } from '../../../reducers';
import { MONTHS } from '../../../types/constants';
import { DateType } from '../../../types/types';
import './index.css';

interface CalendarDropDownInterface {
  callBack: any;
  callBackNoCollapse: any;
}
const CalendarDropDown: FC<CalendarDropDownInterface> = ({ callBack }) => {
  const YEARS: Array<number> = Array.from({ length: 201 }, (_, i) => 1900 + i);
  const { activeDate } = useSelector(
    (state: AppStateType) => state.calendarReducer,
  );
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [localMonth, setLocalMonth] = useState(activeDate?.month);
  const [localYear, setLocalYear] = useState(activeDate?.year);
  const updateYear = (yearUpdate: number) => {
    // const newDate:DateType = {
    //     date: activeDate?.date || 0,
    //     month: activeDate?.month || 0,
    //     year: (activeDate?.year || 0) + yearUpdate
    // }
    setLocalYear((prev) => prev && prev + yearUpdate);
    // dispatch(SetActiveDateAction(newDate));
    // callBackNoCollapse();
  };
  const chooseMonth = (newMonth: number) => {
    const newDate: DateType = {
      date: activeDate?.date || 0,
      month: newMonth,
      year: localYear || 0,
    };
    dispatch(SetActiveDateAction(newDate));
    callBack();
  };
  const [t] = useTranslation();
  return (
    <div
      className={'calendarDropdownContainer flex-column'}
      style={{
        top: '200px',
        left: '',
      }}
    >
      <div className={'calendarDropdown_YearContainer '}>
        <img
          src={LeftCalendarScroll}
          onClick={() => updateYear(-1)}
          className={'enabledClickable'}
        />
        {activeDate !== null ? (
          YEARS.filter(
            (value: number) =>
              !!localYear && value >= localYear - 2 && value <= localYear + 2,
          ).map((year: number) => <div>{year}</div>)
        ) : (
          <></>
        )}
        <img
          onClick={() => updateYear(1)}
          src={RightCalendarScroll}
          className={'enabledClickable'}
        />
      </div>
      <div className={'calendarDropDown_Months'}>
        {MONTHS.map((month: string, index: number) => {
          return (
            <div
              onClick={() => chooseMonth(index)}
              className={`monthDD enabledClickable ${localMonth === index ? 'activeMonth' : ''}`}
            >
              {t(month)}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CalendarDropDown;
