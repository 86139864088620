import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { AC_setLanguageAction } from '../../actions/interface.actions';
import CheckMark from '../../assets/img/CheckMark.svg';
import { ReactComponent as SelectArrowLeftGrey } from '../../assets/img/SelectArrowLeftGrey.svg';
import i18n from '../../i18n';
import { AppStateType } from '../../reducers';
import { useAppDispatch } from '../../store';
import { LANGUAGES } from '../../types/constants';
import './style.css';
const LanguageSelector = () => {
  const [languageSelectorVisible, setLanguageSelectorVisible] = useState(false);
  const toggleVisibleSelector = () => {
    setLanguageSelectorVisible((prev) => !prev);
  };
  const { language } = useSelector(
    (state: AppStateType) => state.interfaceReducer,
  );
  const dispatch = useAppDispatch();

  const changeLanguage = (languageCode: string) => {
    dispatch(AC_setLanguageAction(languageCode));
    setLanguageSelectorVisible(false);
    localStorage.setItem('i18nextLng', languageCode);
    i18n.changeLanguage(languageCode);
  };
  // const [t] = useTranslation();
  // useEffect(() => {
  //   const metaElement = document.getElementById('meta-top-name');
  //   const titleElement = document.getElementById('title-top-name');
  //   if (metaElement && titleElement) {
  //     metaElement.setAttribute('content', t('Platform'));
  //     titleElement.innerHTML = t('Platform');
  //   }
  // }, [language]);
  return (
    <>
      <div
        className={`language-selector enabledClickable ${languageSelectorVisible ? 'active' : ''}`}
        onClick={() => toggleVisibleSelector()}
      >
        {LANGUAGES[language]}{' '}
        <SelectArrowLeftGrey
          style={{
            transform: `${languageSelectorVisible ? 'rotate(90deg)' : 'rotate(-90deg)'}`,
          }}
        />
      </div>
      {languageSelectorVisible && (
        <div className={'language-dropdown enabledClickable'}>
          {Object.keys(LANGUAGES).map((lang: string) => {
            return (
              <div
                className={'language-row'}
                onClick={() => {
                  // localStorage.setItem('i18nextLng', lang);
                  changeLanguage(lang);
                }}
              >
                <div>{LANGUAGES[lang]}</div>
                {language === lang ? <img src={CheckMark} /> : <></>}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default LanguageSelector;
