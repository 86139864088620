import React from 'react';

import { useTranslation } from 'react-i18next';

const ErrorPage = () => {
  const [t] = useTranslation();
  return <>{t('NOT FOUND!')}</>;
};

export default ErrorPage;
