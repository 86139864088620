import { AuthActionsType } from 'actions/auth.actions';
import { UserType } from 'types/types';

type InitialStateType = {
  loading: boolean;
  loggedIn: boolean;
  redirectTo: string;
  recoverSent: boolean;
  currentUser: UserType | null;
  error: any;
};
const initialState: InitialStateType = {
  loading: false,
  loggedIn: false,
  redirectTo: '/',
  recoverSent: false,
  currentUser: null,
  error: null,
};
const authReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: AuthActionsType,
): InitialStateType => {
  switch (action.type) {
    case 'LOGIN_ACTION_SUCCESS':
      return {
        ...state,
        loggedIn: true,
        loading: false,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'LOGIN_ACTION_ERROR':
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: action.payload,
      };
    case 'RECOVER_PASSWORD_ACTION_SUCCESS':
      return {
        ...state,
        loading: false,
        recoverSent: true,
      };
    case 'SET_CURRENT_USER':
      return {
        ...state,
        currentUser: action.payload,
      };
    case 'SET_CURRENT_USER_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default authReducer;
