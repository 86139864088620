/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './index.css';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Row,
  Select,
  Space,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { AC_setIntermediateActivitiesAction } from 'actions/filter.actions';
import { EventsAPI } from 'api/events';
import { PlayersAPI } from 'api/player';
import MatchSkeleton from 'components/Skeletons/MatchSkeleton';
import { AppStateType } from 'reducers';
import { metricsReducer } from 'reducers/metrics.reducer';
import { protocolReducer } from 'reducers/protocol.reducer';
import { teamReducer } from 'reducers/team.reducer';
import { teamPlayerReducer } from 'reducers/teamPlayer.reducer';
import { PLAYER_ROLES_ABBREVIATED } from 'types/constants';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MatchRow from './MatchRow';

const PlayerMatchesBlock = () => {
  const [t] = useTranslation();
  const { setTopPlayerRole, setPositions } = teamPlayerReducer.actions;
  const [matches, setMatches] = useState<any>([]);
  const { expandedGameClickedId } = useSelector(
    (state: AppStateType) => state.teamReducer,
  );
  const { openedTeamPlayer, positions } = useSelector(
    (state: AppStateType) => state.teamPlayerReducer,
  );
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { openGameProtocol } = protocolReducer.actions;
  const [expanded, setExpanded] = useState<string[]>(
    expandedGameClickedId && expandedGameClickedId.expanded
      ? [expandedGameClickedId.id]
      : [],
  );
  const TIME_OPTIONS = [
    {
      label: t('> 1 min'),
      value: 'ANY',
    },
    {
      label: t('> 15 min'),
      value: '15',
    },
    {
      label: t('> 30 min'),
      value: '30',
    },
    {
      label: t('> 45 min'),
      value: '45',
    },
    {
      label: t('> 60 min'),
      value: '60',
    },
    {
      label: t('> 75 min'),
      value: '75',
    },
    {
      label: t('> 90 min'),
      value: '90',
    },
  ];
  useEffect(() => {
    if (openedTeamPlayer) {
      PlayersAPI.getPlayerRoles(openedTeamPlayer.id).then((response) => {
        const allPositions = [
          // {
          //   value: 'ALL',
          //   label: t('All positions'),
          //   clasName: 'select-divider',
          // },
          ...response.data.roles.map((role: string) => {
            return { value: role, label: t(role) };
          }),
        ];
        dispatch(setPositions(allPositions));
        const allSchemas: any = [
          ...response.data.schemas.map((schema: any) => {
            return {
              value: schema.id,
              label: schema.name,
            };
          }),
        ];
        setPlayedSchemas(allSchemas);
        dispatch(setTopPlayerRole(response.data.top_roles));
      });
    }
  }, [openedTeamPlayer]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPositions, setSelectedPositions] = useState<
    string | undefined
  >(undefined);
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [playedSchemas, setPlayedSchemas] = useState([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedPlayedSchemas, setSelectedPlayedSchemas] = useState<
    string | undefined
  >(undefined);
  const { setMetricsRegistry } = metricsReducer.actions;
  const { metricsRegistry } = useSelector(
    (state: AppStateType) => state.metricsReducer,
  );
  useEffect(() => {
    EventsAPI.getMetricsRegistry('player', null).then((response) => {
      dispatch(setMetricsRegistry(response.data));
    });
  }, []);
  const MATCHES_PER_PAGE = 1000;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const listenerRef = useRef(false);
  useEffect(() => {
    if (!openedTeamPlayer?.id) {
      return;
    }
    setLoading(true);
    EventsAPI.getPlayerGamesStats(
      null,
      1,
      MATCHES_PER_PAGE,
      selectedMinutes || 'ANY',
      selectedPlayedSchemas || 'ALL',
      selectedPositions || 'ALL',
      openedTeamPlayer.id,
    ).then((matchesStatsResponse) => {
      if (matchesStatsResponse.data) {
        setMatches(matchesStatsResponse.data.results); // TODO add pagination later
        setTotal(matchesStatsResponse.data.total);
      }
      setLoading(false);
    });
  }, [
    selectedMinutes,
    selectedPlayedSchemas,
    selectedPositions,
    openedTeamPlayer,
  ]);

  const matchesRef = useRef<any>();
  return (
    <Flex vertical style={{ width: '100%' }} gap={8}>
      <Flex
        className="block-title"
        justify="flex-start"
        style={{ width: '100%' }}
        gap={16}
      >
        <div style={{ flex: 0 }}>{t('Matches')}</div>
        <Flex align="center" justify="flex-start">
          {positions.length > 0 && (
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorPrimaryBase, #1668DC);',
                },
                components: {
                  Select: {
                    optionSelectedColor:
                      'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                  },
                },
              }}
            >
              <Select
                placeholder={
                  <div className="select-placeholder">{t('All positions')}</div>
                }
                variant="borderless"
                className={'hasSelection'}
                value={selectedPositions}
                onSelect={(value: string) => {
                  if (value === 'clear') {
                    setSelectedPositions(undefined);
                  } else {
                    setSelectedPositions(value);
                  }
                }}
                popupMatchSelectWidth={false}
                placement="bottomRight"
              >
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                    color: 'black',
                  }}
                  className="enabledClickable"
                  key={0}
                  value={'clear'}
                >
                  {t('Clear filter')}
                </Select.Option>
                {positions.map((position: any, index: number) => (
                  <Select.Option
                    key={index + 1}
                    label={position.value}
                    value={position.value}
                    style={{
                      color: 'black',
                    }}
                  >
                    {position.label}
                  </Select.Option>
                ))}
              </Select>
            </ConfigProvider>
          )}
          <div className="enabledClickable">
            {playedSchemas.length > 0 && (
              <ConfigProvider
                theme={{
                  token: {
                    colorText: 'var(--colorPrimaryBase, #1668DC);',
                  },
                  components: {
                    Select: {
                      optionSelectedColor:
                        'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                    },
                  },
                }}
              >
                <Select
                  placeholder={
                    <div className="select-placeholder">
                      {t('All formations')}
                    </div>
                  }
                  variant="borderless"
                  value={selectedPlayedSchemas}
                  popupMatchSelectWidth={false}
                  onSelect={(value: string) => {
                    if (value === 'clear') {
                      setSelectedPlayedSchemas(undefined);
                    } else {
                      setSelectedPlayedSchemas(value);
                    }
                  }}
                  placement="bottomRight"
                >
                  <Select.Option
                    style={{
                      mpaddingTop: 10,
                      borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                      borderRaius: 0,
                      color: 'black',
                    }}
                    className="enabledClickable"
                    onClick={() => setSelectedPlayedSchemas(undefined)}
                    key={0}
                    label={''}
                    value={'clear'}
                  >
                    {t('Clear filter')}
                  </Select.Option>
                  {playedSchemas.map((schema: any, index: number) => (
                    <Select.Option
                      key={index + 1}
                      label={schema.value}
                      value={schema.value}
                      style={{
                        color: 'black',
                      }}
                    >
                      {schema.label}
                    </Select.Option>
                  ))}
                </Select>
              </ConfigProvider>
            )}
          </div>
          <div className="">
            <ConfigProvider
              theme={{
                token: {
                  colorText: 'var(--colorPrimaryBase, #1668DC);',
                },
                components: {
                  Select: {
                    optionSelectedColor:
                      'var(--colorPrimaryBase, rgba(22, 104, 220, 1))',
                  },
                },
              }}
            >
              <Select
                placeholder={
                  <div className="select-placeholder">{t('Played time')}</div>
                }
                variant="borderless"
                value={selectedMinutes}
                popupMatchSelectWidth={false}
                onSelect={(value: string) => {
                  if (value === 'clear') {
                    setSelectedMinutes(undefined);
                  } else {
                    setSelectedMinutes(value);
                  }
                }}
                placement="bottomRight"
                className={'hasSelection'}
              >
                <Select.Option
                  style={{
                    mpaddingTop: 10,
                    borderBottom: '0.5px solid rgba(0,0,0,0.1)',
                    borderRaius: 0,
                    color: 'black',
                  }}
                  className="enabledClickable"
                  key={0}
                  onClick={(e: any) => {
                    e.preventDefault();
                  }}
                  label={''}
                  value={'clear'}
                >
                  {t('Clear filter')}
                </Select.Option>
                {TIME_OPTIONS.map((option: any, index: number) => (
                  <Select.Option
                    key={index + 1}
                    label={option.value}
                    value={option.value}
                    style={{
                      color: 'black',
                    }}
                  >
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </ConfigProvider>
          </div>
        </Flex>
      </Flex>
      <Flex vertical gap={8} ref={matchesRef}>
        {!loading &&
          matches.map((match: any) => (
            <MatchRow
              match={match}
              expanded={expanded}
              setExpanded={setExpanded}
              matches={matches}
            />
          ))}
        {loading && <MatchSkeleton />}
        <div ref={matchesRef} style={{ width: '100%', height: 1 }}></div>
      </Flex>
    </Flex>
  );
};
export default PlayerMatchesBlock;
