import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import ClearCrossIcon from 'assets/img/clearCrossIcon.svg';
import PasswordClosed from 'assets/img/passwordClosed.svg';
import PasswordVisible from 'assets/img/passwordVisible.svg';
import WarningSign from 'assets/img/warningSign.svg';

import PasswordWarning from '../PasswordWarning';

import './index.css';
interface InputInterface {
  isRequired: boolean;
  valueController: any;
  fieldType: string;
  fieldName: string;
  clearIcon?: boolean;
  labelText?: string;
  validate?: boolean;
  placeholder?: string;
  maxLength?: number;
  focusTracker?: any;
  validationCallback?: any;
  // ref?: any
}

const InputWithClear = forwardRef<ForwardedRef<any>, InputInterface>(
  (
    {
      isRequired,
      valueController,
      fieldType,
      fieldName,
      labelText,
      placeholder,
      clearIcon = true,
      validate = false,
      maxLength,
      focusTracker,
      validationCallback,
    },
    ref,
  ) => {
    const { value, setValue } = valueController;
    const [currentFieldType, setCurrentFieldType] = useState(fieldType);
    const [hidden, setHidden] = useState<boolean>(false);
    const [fieldIsOnFocus, setFieldIsOnFocus] = useState(false);
    const [fieldIsValid, setFieldIsValid] = useState(true);
    const [validationRules, setValidationRules] = useState({
      BL: false,
      SL: false,
      DIGITS: false,
      MIN: false,
    });
    const [isElipsisActive, setIsElipsisActive] = useState(false);
    const inputRef = useRef(null);
    const toggleHidden = () => {
      setHidden((prev) => !prev);
      toggleInputPassType();
    };
    const toggleInputPassType = () => {
      if (currentFieldType === 'password') {
        setCurrentFieldType('text');
      } else {
        setCurrentFieldType('password');
      }
    };
    useLayoutEffect(() => {
      const updateRef = ref || inputRef;

      setIsElipsisActive(
        //@ts-ignore
        updateRef?.current?.offsetWidth < updateRef?.current?.scrollWidth ||
          //@ts-ignore
          updateRef?.current?.offsetHeight < updateRef?.current?.scrollHeight,
      );
    }, [fieldIsOnFocus, value]);

    const updateFocus = () => {
      const updateRef = ref || inputRef;

      setFieldIsOnFocus(
        // @ts-ignore
        document.activeElement === updateRef?.current,
      );
      // console.log('updateFocusTrigger')
      if (focusTracker) {
        focusTracker(
          // @ts-ignore
          document.activeElement === updateRef?.current,
        );
      }
      // setPasswordIsOnFocus(prev => document.activeElement === passwordRef.current);
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const setWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    useEffect(() => {
      // console.log("USEFFECT WINDOW INPUT WITH CLEAR")
      window.addEventListener('resize', setWindowDimensions);
      return () => {
        window.removeEventListener('resize', setWindowDimensions);
      };
    }, []);

    const validateEmail = () => {
      const isValid = /\S+@\S+\.\S+/.test(value);
      setFieldIsValid(isValid);
      if (validationCallback) {
        validationCallback(isValid);
      }
      updateFocus();
    };

    const validatePassword = (value: any) => {
      if (validate) {
        const validation = {
          BL: /[A-Z]+/.test(value),
          SL: /[a-z]+/.test(value),
          DIGITS: /[0-9]+/.test(value),
          MIN: value.length >= 8,
        };
        setValidationRules(validation);
        const isValid = Object.values(validation).indexOf(false) === -1;
        setFieldIsValid(isValid);
        if (validationCallback) {
          validationCallback(isValid);
        }
      }
      setValue(value);
      updateFocus();
    };

    const validationMethods: any = {
      email: validateEmail,
      password: validatePassword,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      textarea: () => {},
    };

    const changeMethods: any = {
      email: setValue,
      text: setValue,
      textarea: setValue,
      password: validatePassword,
    };
    const validateField = (event: any) => {
      // console.log("blurTriggered");
      if (validate) {
        validationMethods[fieldType](event);
      }
      if (focusTracker) {
        // console.log('type focustracker', typeof focusTracker)
        const updateRef = ref || inputRef;
        // @ts-ignore
        focusTracker(document.activeElement === updateRef?.current);
        setFieldIsOnFocus(false);
      } else {
        setFieldIsOnFocus(false);
      }
    };

    const getTopOffset = (fieldRef: any) => {
      return fieldRef?.current?.getBoundingClientRect().top - 8;
    };
    const getLeftOffset = (fieldRef: any) => {
      return (
        fieldRef?.current?.offsetLeft + fieldRef?.current?.clientWidth + 20
      );
    };
    const [t] = useTranslation();

    return (
      <>
        {labelText && <label>{labelText}</label>}
        <div
          className={`inputWithIcon ${fieldType === 'textarea' ? 'flex-start' : ''}`}
        >
          {fieldType !== 'textarea' ? (
            <input
              maxLength={maxLength}
              //@ts-ignore
              ref={ref || inputRef}
              required={isRequired}
              value={value}
              placeholder={placeholder}
              onFocus={updateFocus}
              onBlur={(event) => validateField(event.target.value)}
              onChange={(event) => changeMethods[fieldType](event.target.value)}
              name={fieldName}
              type={currentFieldType}
              className={!fieldIsValid ? 'warningCheckbox' : ''}
            />
          ) : (
            <textarea
              maxLength={maxLength}
              //@ts-ignore
              ref={ref || inputRef}
              required={isRequired}
              value={value}
              placeholder={placeholder}
              onFocus={updateFocus}
              onBlur={(event) => validateField(event.target.value)}
              onChange={(event) => changeMethods[fieldType](event.target.value)}
              name={fieldName}
              className={!fieldIsValid ? 'warningCheckbox' : ''}
            />
          )}

          {value && !fieldIsOnFocus && clearIcon && (
            <img
              className={`inputRightIcon ${fieldType === 'textarea' ? 'mt10' : ''}`}
              src={ClearCrossIcon}
              onClick={() => {
                setValue('');
                updateFocus();
              }}
            />
          )}
          {fieldType === 'password' && (
            <img
              className="inputRightIcon"
              src={hidden ? PasswordVisible : PasswordClosed}
              onClick={toggleHidden}
            />
          )}
          {isElipsisActive && fieldIsOnFocus && maxLength && (
            <div
              className={`inputRightIcon ${fieldType === 'textarea' ? 'mt10' : ''}`}
            >
              {maxLength - value.length}
            </div>
          )}
          {!fieldIsValid && fieldType === 'email' && (
            <div
              className="emailFieldRequired"
              style={{
                top: `${getTopOffset(ref || inputRef)}px`,
                left: `${getLeftOffset(ref || inputRef)}px`,
              }}
            >
              <img src={WarningSign} />
              <div>{t('Please enter valid email')}</div>
            </div>
          )}
          {fieldType === 'password' && value && validate && (
            <PasswordWarning
              validationRules={validationRules}
              passwordIsOnFocus={fieldIsOnFocus}
              fieldRef={ref || inputRef}
            />
          )}
        </div>
      </>
    );
  },
);
export default InputWithClear;
