import React, { FC } from 'react';

import './index.css';
import { Checkbox, ConfigProvider, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  AC_SelectTimingIntervalFilterAction,
  AC_SetShowPlayerIntervalsFilterAction,
} from '../../../../actions/filter.actions';
import { AppStateType } from '../../../../reducers';
import { useAppDispatch } from '../../../../store';
import { EpisodeFilterPanelTypes } from '../../../../types/types';
import CheckBoxWithRange from '../../../CheckboxWithRange';
import FiltersContainer from '../FiltersContainer';

interface FilterInterface {
  hideCallback: any;
  applyFilter: any;
  filtersControls: { filters: any; updateFilters: any };
  preFilterEpisodesCount: any;
  preFilterLoading: boolean;
}
const TimingFilter: FC<FilterInterface> = ({
  hideCallback,
  applyFilter,
  // filtersControls,
  preFilterEpisodesCount,
  preFilterLoading,
}) => {
  const { selectedPlayers, selectedTimingIntervals, showPlayerIntervals } =
    useSelector((state: AppStateType) => state.filtersReducer);

  const dispatch = useAppDispatch();

  const clear = () => {
    dispatch(AC_SelectTimingIntervalFilterAction({}));
    dispatch(AC_SetShowPlayerIntervalsFilterAction(false));
  };

  const [t] = useTranslation();

  const setSelectedTimingIntervals = (value: any) => {
    dispatch(AC_SelectTimingIntervalFilterAction(value));
  };

  return (
    <FiltersContainer
      type={EpisodeFilterPanelTypes.timing}
      gap={24}
      closeCallback={hideCallback}
      clearCallback={clear}
      applyCallback={applyFilter}
      applyCount={preFilterEpisodesCount}
      preFilterLoading={preFilterLoading}
    >
      <div className={'filterPanels timingPanels w100 f-grow'}>
        <div className={'leftPanel f-grow oy-v'}>
          <div className={'w100 flex-column f-ga-16'}>
            <div className={'filtersActive title flex-row'}>{t('Match')}</div>
            <div
              style={{
                display: 'flex',

                alignItems: 'flex-start',
                gap: '40px',
                alignSelf: 'stretch',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  flex: '1 0 0',
                }}
              >
                <div className="w100" style={{ height: 80 }}>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: '#141414',
                        colorBorder: '#424242',
                        lineWidth: 1,
                      },
                    }}
                  >
                    <CheckBoxWithRange
                      disabledTracks={true}
                      rangeType={'numbers'}
                      range={[0, 46]}
                      name={'first_period'}
                      label={`${t('First period')}`}
                      valuesController={{
                        values: selectedTimingIntervals,
                        setValues: setSelectedTimingIntervals,
                      }}
                    />
                  </ConfigProvider>
                </div>
                <div className="w100" style={{ height: 80 }}>
                  <div
                    style={{
                      height: 1,
                      marginBottom: 12,

                      background: 'rgba(255, 255, 255, 0.12)',
                      width: '100%',
                    }}
                  ></div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: '#141414',
                        colorBorder: '#424242',
                        lineWidth: 1,
                      },
                    }}
                  >
                    <CheckBoxWithRange
                      disabledTracks={true}
                      rangeType={'numbers'}
                      range={[45, 91]}
                      name={'second_period'}
                      label={`${t('Second period')}`}
                      valuesController={{
                        values: selectedTimingIntervals,
                        setValues: setSelectedTimingIntervals,
                      }}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  flex: '1 0 0',
                }}
              >
                <div className="w100" style={{ height: 80 }}>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: '#141414',
                        colorBorder: '#424242',
                        lineWidth: 1,
                      },
                    }}
                  >
                    <CheckBoxWithRange
                      disabledTracks={true}
                      rangeType={'numbers'}
                      range={[0, 3]}
                      name={'additional_time_1'}
                      label={`${t('Additional time 1')}`}
                      valuesController={{
                        values: selectedTimingIntervals,
                        setValues: setSelectedTimingIntervals,
                      }}
                    />
                  </ConfigProvider>
                </div>
                <div className="w100" style={{ height: 80 }}>
                  <div
                    style={{
                      height: 1,
                      marginBottom: 12,
                      background: 'rgba(255, 255, 255, 0.12)',
                      width: '100%',
                    }}
                  ></div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorBgContainer: '#141414',
                        colorBorder: '#424242',
                        lineWidth: 1,
                      },
                    }}
                  >
                    <CheckBoxWithRange
                      disabledTracks={true}
                      rangeType={'numbers'}
                      range={[0, 3]}
                      name={'additional_time_2'}
                      label={`${t('Additional time 2')}`}
                      valuesController={{
                        values: selectedTimingIntervals,
                        setValues: setSelectedTimingIntervals,
                      }}
                    />
                  </ConfigProvider>
                </div>
              </div>
            </div>
            <div className="games_filter_other_filters w100">
              {selectedPlayers.length > 0 && (
                <>
                  <div
                    style={{
                      height: 1,
                      background: 'rgba(255, 255, 255, 0.12)',
                      width: '100%',
                    }}
                  ></div>
                  <Tooltip
                    title={t(
                      'This metric calculation in planned for future releases',
                    )}
                  >
                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgContainer: '#141414',
                          colorBorder: '#424242',
                          lineWidth: 1,
                        },
                      }}
                    >
                      <Checkbox
                        disabled
                        checked={showPlayerIntervals}
                        onChange={() =>
                          dispatch(
                            AC_SetShowPlayerIntervalsFilterAction(
                              !showPlayerIntervals,
                            ),
                          )
                        }
                        type="checkbox"
                      >
                        {t('Показать только сыгранное игроком время матча ')}
                      </Checkbox>
                    </ConfigProvider>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </FiltersContainer>
  );
};
export default TimingFilter;
