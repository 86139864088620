import React, { useEffect, useState } from 'react';

import { Divider, Flex, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as PlayCircle } from '../../../../assets/img/icons/faPlayCircle.svg';

import './index.css';

const ConsolidatedStatValueTag = ({
  stat,
  makeLink,
  selectedMetricFilters,
  selectedPositions,
}: {
  stat: any;
  makeLink: any;
  selectedMetricFilters: any;
  selectedPositions: string | undefined;
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [displayedValue, setDisplayedValue] = useState(null);
  useEffect(() => {
    stat && setDisplayedValue(stat.total);
  }, [stat]);
  const [t] = useTranslation();
  const getMetricValue = () => {
    if (selectedMetricFilters.includes(`${stat.key}_success`)) {
      return stat.details[`${stat.key}_success`].total;
    }
    if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
      return stat.details[`${stat.key}_fail`].total;
    }
    if (selectedPositions) {
      if (stat?.by_position) {
        return stat.by_position[selectedPositions] || 0;
      }
      return 0;
    }
    return stat.total;
  };
  const getPercentValue = () => {
    if (selectedMetricFilters.includes(`${stat.key}_success`)) {
      return '';
      // return stat.details[`${stat.key}_success`].percent + '%';
    }
    if (selectedMetricFilters.includes(`${stat.key}_fail`)) {
      return '';
      // return stat.details[`${stat.key}_fail`].percent + '%';
    }
    return stat.percent + '%';
  };
  return (
    <>
      {displayedValue !== null ? (
        <Tooltip
          open={false}
          title={t('View episodes')}
          trigger={'hover'}
          placement="top"
        >
          <Flex
            className={`${stat?.total === undefined ? 'na-stat' : stat?.total > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
            style={{ minWidth: 130, height: 40 }}
            justify={stat?.total !== undefined ? 'space-between' : 'center'}
            onClick={(e) => {
              e.stopPropagation();
              if (stat?.total) {
                navigate(makeLink(stat));
              }
            }}
          >
            {displayedValue ? <PlayCircle height={40} /> : <></>}
            {stat?.total === undefined ? (
              <div
                style={{ height: 40 }}
                className={'flex-row j-ce ai-c disabled-stat-color'}
              >
                {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
              </div>
            ) : stat?.total > 0 ? (
              <div style={{ height: 40 }} className={'flex-row ai-c'}>
                {stat?.total !== undefined ? `${getMetricValue()}` : t('n/a')}
              </div>
            ) : (
              <span className="stat-average-value zero-value">0</span>
            )}
            {stat?.percent !== null && stat?.percent !== undefined && (
              <>
                <Divider
                  type="vertical"
                  style={{
                    height: '70%',
                    width: 1,
                    margin: 0,
                    background: 'var(--colorPrimaryText, #8DC5F8)',
                  }}
                />
                <Flex vertical>
                  {stat?.percent !== null && stat?.percent !== undefined && (
                    <span
                      className={'stat-average-value'}
                      style={{ fontSize: 12, lineHeight: '16px' }}
                    >
                      {getPercentValue()}
                    </span>
                  )}
                  {stat?.percent !== null && stat?.percent !== undefined && (
                    <span
                      className={'stat-percent-value'}
                      style={{ fontSize: 11, lineHeight: '16px' }}
                    >
                      {stat?.pth}pth
                    </span>
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Tooltip>
      ) : (
        <Flex
          className={`${stat?.total === undefined ? 'na-stat' : stat?.total > 0 ? 'stat-value-container enabledClickable' : 'stat-value-container-zero'}`}
          style={{ minWidth: 130, height: 40 }}
          justify={stat?.total !== undefined ? 'space-between' : 'center'}
          onClick={(e) => {
            e.stopPropagation();
            if (stat?.total) {
              navigate(makeLink(stat));
            }
          }}
        >
          {displayedValue ? <PlayCircle height={40} /> : <></>}
          {stat?.total === undefined ? (
            <div
              style={{ height: 40 }}
              className={'flex-row j-ce ai-c disabled-stat-color'}
            >
              {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
            </div>
          ) : stat?.total > 0 ? (
            selectedPositions ? (
              <div style={{ height: 40 }} className={'flex-row ai-c'}>
                {stat.by_position[selectedPositions] !== null
                  ? `${stat?.by_position[selectedPositions]}`
                  : t('n/a')}
              </div>
            ) : (
              <div style={{ height: 40 }} className={'flex-row ai-c'}>
                {stat?.total !== undefined ? `${stat?.total}` : t('n/a')}
              </div>
            )
          ) : (
            <span className="stat-average-value zero-value">0</span>
          )}
          {stat?.percent !== null && stat?.percent !== undefined && (
            <>
              <Divider type="vertical" style={{ height: '100%', width: 1 }} />
              <Flex vertical>
                {stat?.percent !== null && stat?.percent !== undefined && (
                  <span className={'stat-average-value'}>{stat?.percent}%</span>
                )}
                {stat?.pth !== null && stat?.pth !== undefined && (
                  <span className={'stat-percent-value'}>{stat?.pth}pth</span>
                )}
              </Flex>
            </>
          )}
        </Flex>
      )}
    </>
  );
};
export default ConsolidatedStatValueTag;
