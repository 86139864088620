import './index.css';
import React, { FC, useEffect, useState } from 'react';

import WarningSign from '../../../assets/img/warningSign.svg';
interface WarningModalInterface {
  title: string;
  content?: string;
  referencedField: any;
}
const WarningModal: FC<WarningModalInterface> = ({
  title,
  content,
  referencedField,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  return (
    <div
      className={'warning warningModal'}
      style={{
        top: `${referencedField?.top}px`,
        left: `${referencedField?.left - referencedField?.width}px`,
      }}
    >
      <div className={'flex-row f-ga-8 ai-c'}>
        <img src={WarningSign} />
        <div>{title}</div>
      </div>
      {content && <div>{content}</div>}
    </div>
  );
};
export default WarningModal;
