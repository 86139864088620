import React from 'react';

import { Flex } from 'antd';

import PlayerMatchesBlock from '../PlayerMatchesBlock';
import PlayerStatisticsBlock from '../PlayerStatisticsBlock';

const PlayerDataBlock = () => {
  return (
    <Flex
      style={{ width: '100%', padding: '16px 0px' }}
      justify="space-between"
      gap={24}
    >
      <PlayerMatchesBlock />
      <Flex>
        <PlayerStatisticsBlock />
      </Flex>
    </Flex>
  );
};
export default PlayerDataBlock;
