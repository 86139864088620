import React, { FC } from 'react';
import './style.css';
interface CustomTabInterface {
  type: React.Key;
  text: string;
  onClick: (value: any) => void;
  chosen: boolean;
  hasNotification?: boolean;
  textAlign: 'left' | 'center';
  selfAlign: 'vertical' | 'horizontal';
}
const CustomTab: FC<CustomTabInterface> = ({
  type,
  text,
  onClick,
  chosen = false,
  hasNotification = false,
  textAlign = 'center',
  selfAlign = 'horizontal',
}) => {
  return (
    <div
      className={`custom_tab ${chosen ? 'chosen' : ''} enabledClickable ${textAlign} ${selfAlign} elipsis-text`}
      onClick={() => onClick(type)}
    >
      {text}
      {hasNotification && (
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Ellipse 363">
            <circle cx="4" cy="4" r="4" fill="#3A78F1" />
            <circle cx="4" cy="4" r="4" fill="white" fill-opacity="0.2" />
          </g>
        </svg>
      )}
    </div>
  );
};
export default CustomTab;
