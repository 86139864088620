import React, { FC, useEffect } from 'react';

import { useSelector } from 'react-redux';

import {
  AC_AddSelectedTournamentFilterAction,
  AC_SelectTimingIntervalFilterAction,
  AC_SelectedMatchesFilterAction,
  AC_SetLastXFilterAction,
  AC_SetSelectedAttributes,
  AC_SetSelectedDateLimitsFilterAction,
  AC_SetShowPlayerIntervalsFilterAction,
  // PrefilterGamesAction,
  setActiveTeamFilterAction,
} from '../../../../actions/filter.actions';
import { AppStateType } from '../../../../reducers';
import { useAppDispatch } from '../../../../store';
import { TeamType } from '../../../../types/types';
import CustomTab from '../../../Library/Tab';
import Loader from '../../../Loader';
interface Interface {
  setVisiblePlayers?: (value: any) => void;
  setVisiblePlayersFlatList?: (value: any) => void;
}
const TeamsListFilterPanel: FC<Interface> = () => {
  const { teams, loadingActions } = useSelector(
    (state: AppStateType) => state.clubsReducer,
  );
  const { activeTeam, selectedPlayers } = useSelector(
    (state: AppStateType) => state.filtersReducer,
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (teams.length > 0 && activeTeam === null) {
      dispatch(setActiveTeamFilterAction(teams[0].id));
    }
  }, [teams, activeTeam, dispatch]);
  const localSelectActiveTeam = async (team: TeamType) => {
    if (team.id !== activeTeam) {
      dispatch(AC_SelectedMatchesFilterAction([]));
      dispatch(AC_AddSelectedTournamentFilterAction([]));
      dispatch(AC_SetSelectedDateLimitsFilterAction({}));
      dispatch(AC_SetSelectedAttributes([]));
      dispatch(AC_SetLastXFilterAction(null));
      dispatch(AC_SelectTimingIntervalFilterAction([]));
      dispatch(AC_SetShowPlayerIntervalsFilterAction(false));
      dispatch(setActiveTeamFilterAction(team.id));
      // dispatch(PrefilterGamesAction());
      // TODO add other clearing
    }
  };
  const getTeamSelectedCountText = (team: TeamType) => {
    if (selectedPlayers.length > 0 && activeTeam === team.id) {
      return `(${selectedPlayers.length})`;
    }
    return '';
  };
  return (
    <div className={'leftPanel'}>
      {loadingActions.includes('SET_TEAMS') && <Loader />}
      {teams.map((team: TeamType) => {
        return (
          <CustomTab
            key={team.id}
            type="team"
            text={`${team.name} ${getTeamSelectedCountText(team)}`}
            onClick={() => localSelectActiveTeam(team)}
            chosen={activeTeam === team?.id}
            textAlign={'left'}
            selfAlign="vertical"
          />
        );
      })}
    </div>
  );
};
export default TeamsListFilterPanel;
