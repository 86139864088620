/**
 * Returns an empty string if Crypto API or randomUUID is not supported by browser.
 */
export function generateUUID(): string {
  let cryptoRef: any;
  let r: string | undefined = '';
  /* eslint-disable-next-line no-restricted-globals */
  if (typeof self.crypto !== 'undefined') {
    /* eslint-disable-next-line no-restricted-globals */
    cryptoRef = self.crypto;
    r = cryptoRef.randomUUID?.();
  }

  return r ? r : '';
}
