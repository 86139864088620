import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  selected: {
    [key: string]: {
      episodes: string[];
      allSelected: boolean;
    };
  };
  episodeIdsToAddToPlaylist: string[];
};
const initialState: InitialStateType = {
  selected: {},
  episodeIdsToAddToPlaylist: [],
};
export const videosListReducer = createSlice({
  name: 'videosList',
  initialState: initialState,
  reducers: {
    setEpisodeIdsToAddToPlaylist(state, action) {
      state.episodeIdsToAddToPlaylist = action.payload.episodeIds;
    },
    AC_initialzeSelectedEpisodes(state, action) {
      state.selected = action.payload;
    },
    AC_selectEpisode(state, action) {
      if (Object.keys(state.selected).includes(action.payload.gameId)) {
        state.selected[action.payload.gameId].episodes.push(
          action.payload.episodeId,
        );
      } else {
        state.selected[action.payload.gameId] = {
          episodes: [action.payload.episodeId],
          allSelected: false,
        };
      }
      state.selected[action.payload.gameId].allSelected =
        state.selected[action.payload.gameId].episodes.length ===
        action.payload.totalGameLength;
    },
    AC_deselectEpisode(state, action) {
      // console.log('action', action.payload);
      state.selected[action.payload.gameId].episodes = state.selected[
        action.payload.gameId
      ].episodes.filter(
        (episodeId: string) => episodeId !== action.payload.episodeId,
      );
      state.selected[action.payload.gameId].allSelected = false;
      if (state.selected[action.payload.gameId]?.episodes?.length === 0) {
        delete state.selected[action.payload.gameId];
      }
      return state;
    },
    AC_selectGame(state, action) {
      if (
        state.selected &&
        Object.keys(state.selected)?.includes(action.payload.gameId)
      ) {
        state.selected[action.payload.gameId].episodes =
          action.payload.episodes;
        state.selected[action.payload.gameId].allSelected = true;
      } else {
        state.selected = {
          ...state.selected,
          [action.payload.gameId]: {
            episodes: action.payload.episodes,
            allSelected: true,
          },
        };
      }
    },
    AC_deselectGame(state, action) {
      delete state.selected[action.payload];
    },
  },
});
