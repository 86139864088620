import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  openedTeamPlayer: any;
  gameEventCoordinates: any;
  showGameSelectionModal: boolean;
  selectedMatchesStats: string[];
  selectedSeason: string | null;
  manualGamesSelectionSection: string;
  statsCustomGameListPlayer: any[];
  topPlayerRole: string[] | null;
  teamPlayerLoading: boolean;
  positions: any;
};
const initialState: InitialStateType = {
  openedTeamPlayer: null,
  gameEventCoordinates: {},
  selectedSeason: null,
  showGameSelectionModal: false,
  selectedMatchesStats: [],
  manualGamesSelectionSection: '',
  statsCustomGameListPlayer: [],
  topPlayerRole: [],
  teamPlayerLoading: false,
  positions: [],
};
export const teamPlayerReducer = createSlice({
  name: 'teamPlayerReducer',
  initialState: initialState,
  reducers: {
    setOpenedPlayer(state, action) {
      state.openedTeamPlayer = action.payload;
    },
    clearGameCoordinates(state, action) {
      state.gameEventCoordinates[action.payload.matchId] = {};
    },
    setTeamPlayerLoading(state, action) {
      state.teamPlayerLoading = action.payload;
    },
    setPositions(state, action) {
      state.positions = action.payload;
    },
    leaveSingleEvent(state, action) {
      Object.keys(state.gameEventCoordinates[action.payload.matchId]).forEach(
        (metric: string) =>
          Object.keys(
            state.gameEventCoordinates[action.payload.matchId][metric],
          ).map((result: string) => {
            if (
              metric !== action.payload.metric ||
              result !== action.payload.result
            ) {
              state.gameEventCoordinates[action.payload.matchId][metric][
                result
              ]?.map((coordinate: any) => (coordinate.visible = false));
            }
          }),
      );
    },
    setGameEventCoordinates(state, action) {
      if (
        Object.keys(state.gameEventCoordinates).length === 0 ||
        !Object.keys(state.gameEventCoordinates).includes(
          action.payload.matchId,
        )
      ) {
        state.gameEventCoordinates = {
          ...state.gameEventCoordinates,
          [action.payload.matchId]: {
            [action.payload.metric]: {
              [action.payload.result]: action.payload.coordinates,
            },
          },
        };
        return;
      }
      if (
        Object.keys(
          state.gameEventCoordinates[action.payload.matchId],
        ).includes(action.payload.metric)
      ) {
        state.gameEventCoordinates[action.payload.matchId][
          action.payload.metric
        ] = {
          ...state.gameEventCoordinates[action.payload.matchId][
            action.payload.metric
          ],
          [action.payload.result]: action.payload.coordinates,
        };
      } else {
        state.gameEventCoordinates[action.payload.matchId] = {
          ...state.gameEventCoordinates[action.payload.matchId],
          [action.payload.metric]: {
            [action.payload.result]: action.payload.coordinates,
          },
        };
      }
    },
    clearGameEventCoordinates(state, action) {
      state.gameEventCoordinates[action.payload.matchId][action.payload.metric][
        action.payload.result
      ]?.map((coordinate: any) => (coordinate.visible = false));
    },
    showAllGameEventCoordinates(state, action) {
      Object.keys(state.gameEventCoordinates[action.payload.matchId]).forEach(
        (metric: string) =>
          Object.keys(
            state.gameEventCoordinates[action.payload.matchId][metric],
          )?.map((result: string) =>
            state.gameEventCoordinates[action.payload.matchId][metric][
              result
            ]?.map((coordinate: any) => (coordinate.visible = true)),
          ),
      );
    },
    showGameEventCoordinates(state, action) {
      console.log(action.payload);
      state.gameEventCoordinates[action.payload.matchId][action.payload.metric][
        action.payload.result
      ]?.forEach((coordinate: any) => (coordinate.visible = true));
    },
    setShowGameSelectionModal(state, action) {
      state.showGameSelectionModal = action.payload;
    },
    setManualGamesSelectionSection(state, action) {
      state.manualGamesSelectionSection = action.payload;
    },
    setSelectedMatchesStats(state, action) {
      state.selectedMatchesStats = action.payload;
    },
    setSelectedSeason(state, action) {
      state.selectedSeason = action.payload;
    },
    setStatsCustomGameListPlayer(state, action) {
      state.statsCustomGameListPlayer = action.payload;
    },
    setTopPlayerRole(state, action) {
      state.topPlayerRole = action.payload;
    },
  },
});
