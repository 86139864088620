import React, { FC, ReactElement, ReactPortal } from 'react';

import './index.css';
import { ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
// import { useSelector } from 'react-redux';

// import { AC_setLanguageAction } from '../../actions/interface.actions';
// import i18n from '../../i18n';
// import { AppStateType } from '../../reducers';
// import { useAppDispatch } from '../../store';
import { Toast } from 'ui/Toast/Toast';

import NavigationPanelV2 from '../NavigationPanelV2';

type ReactText = string | number;
type ReactChild = ReactElement | ReactText;
type ReactNodeArray = Array<ReactNode>;
// eslint-disable-next-line @typescript-eslint/ban-types
type ReactFragment = {} | ReactNodeArray;
type ReactNode =
  | ReactChild
  | ReactFragment
  | ReactPortal
  | boolean
  | null
  | undefined
  | string;

type ContentContainerInterface = {
  pageTitle: string;
  page: any;
  showNavigationPanel?: boolean;
  theme?: string;
  rightPanel?: any;
  children?: any;
  wideScreenMode: boolean;
  boxSizing?: any;
};
const ContentContainer: FC<ContentContainerInterface> = ({
  pageTitle,
  page,
  showNavigationPanel,
  theme,
  rightPanel,
  children,
  boxSizing = 'unset',
}) => {
  const useNarrowMargins = () => {
    return window.location.pathname === '/player'
      ? 'narrowPageContentContainer'
      : '';
  };
  const backgroundColors: any = {
    dark: 'var(--colorBgContainer)',
    light: 'var(--colorBgLayout)',
  };
  return (
    <ConfigProvider
      theme={{
        cssVar: true,
        components: {
          Layout: {
            bodyBg: backgroundColors[theme || 'light'],
          },
        },
      }}
    >
      <Layout
        style={{
          height: '100%',
          display: 'flex',
          boxSizing: boxSizing,
        }}
      >
        {showNavigationPanel && (
          <NavigationPanelV2
            page={page}
            position={
              ['player', 'playlists'].includes(page) ? 'auto' : 'sticky'
            }
          />
        )}
        <Content style={{ padding: '0px' }}>
          <div style={{ display: 'flex', height: '100%' }}>
            <div
              className={`flex w100 flex-column ${useNarrowMargins()} ${showNavigationPanel ? null : 'wideScreenContainer'}`}
            >
              <div className="pageTitle">
                <div>{pageTitle}</div>
              </div>
              <div
                className="pageContent"
                style={{ gap: `${page === 'sharedPlaylist' ? '0px' : '16px'}` }}
              >
                {children}
              </div>
            </div>
            {rightPanel}
            <Toast />
          </div>
        </Content>
      </Layout>
    </ConfigProvider>
  );
};
export default ContentContainer;
