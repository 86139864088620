import React from 'react';

import './index.css';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { ReactComponent as CrossIconWhite } from '../../../assets/img/CrossIconWhite.svg';
import { ReactComponent as PlusIconWhite } from '../../../assets/img/PlusIconWhite.svg';
import { ReactComponent as ShareIconWhite } from '../../../assets/img/ShareIconWhite.svg';
import { ReactComponent as UploadIconWhite } from '../../../assets/img/UploadIconWhite.svg';

const PlaylistCreationControl = ({
  selectedCount,
  sharePlaylistCallable,
  downloadLinkCallable,
  addToPlaylistCallable,
  cancelButtonShownTo,
  closeControl,
}: {
  selectedCount: number;
  sharePlaylistCallable: any;
  downloadLinkCallable: any;
  addToPlaylistCallable: any;
  cancelButtonShownTo: any;
  closeControl: any;
}) => {
  // const SELECTED = 3;
  const [t] = useTranslation();
  const closeAll = () => {
    closeControl(false);
    downloadLinkCallable(false);
    sharePlaylistCallable('');
    // addToPlaylistCallable(false)
  };
  const additionalProps = () => {
    if (t('To playlist').length > 19) {
      return {
        'data-tooltip-id': 'playlistCreationTooltip',
        'data-tooltip-content': t('To playlist'),
        'data-tooltip-place': 'bottom',
        'data-tooltip-delay-hide': 1000,
      };
    }
  };
  return (
    <>
      <div
        className={'playlistCreationControl flex-row f-ga-8 j-sb ai-s'}
        style={{ background: 'var(--new-main-light)!important' }}
      >
        <div
          className={'enabledClickable bottomControlbutton selectedCountClass'}
        >
          {selectedCount}
        </div>
        <div className={'flex-row f-ga-8 ai-c'}>
          <div
            onClick={addToPlaylistCallable}
            className={
              'flex-row f-ga-2 f-grow bottomControlbutton enabledClickable'
            }
          >
            <PlusIconWhite />
            <div
              className="elipsis-text"
              style={{ maxWidth: '150px' }}
              {...(additionalProps() as { [key: string]: string | number })}
            >
              {t('To playlist')}
            </div>
          </div>
          <div
            className={`enabledClickable bottomControlbutton ${cancelButtonShownTo.share ? 'cancelButtonActive' : ''}`}
            onClick={() => {
              cancelButtonShownTo.share
                ? sharePlaylistCallable('')
                : sharePlaylistCallable('create');
            }}
          >
            <ShareIconWhite />
          </div>
          <div
            onClick={() => {
              cancelButtonShownTo.download
                ? downloadLinkCallable('')
                : downloadLinkCallable('merge');
            }}
            className={`enabledClickable bottomControlbutton ${cancelButtonShownTo.download ? 'cancelButtonActive' : ''}`}
          >
            <UploadIconWhite />
          </div>
          <CrossIconWhite className={'enabledClickable'} onClick={closeAll} />
        </div>
      </div>
      <Tooltip
        variant={'dark'}
        id={'playlistCreationTooltip'}
        noArrow={false}
        className={'videoAttributesTooltip'}
        place={'bottom-end'}
      />
    </>
  );
};
export default PlaylistCreationControl;
